/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import * as React from "react";

import { cn } from "../../utils/tailwind-merge";

const DEFAULT_INPUT_SIZE = 20;

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Whether to animate the placeholder and bottom border */
  animated?: boolean;
  /** Classes to add to the input element */
  inputClasses?: string;
  /** Classes to add to the input's containing element */
  inputContainerClasses?: string;
  /** The invalid state of the input */
  invalid?: boolean;
  /** A label for the input element */
  label?: string;
  /** The id of a label for the input element */
  labelledBy?: string;
  /** Which variant to show */
  variant?: "normal" | "rounded";
}

/**
 * The `TextInput` component is used to capture text input from a user.
 */
function TextInputWithRef(
  {
    animated,
    children,
    inputClasses,
    inputContainerClasses,
    invalid,
    label,
    labelledBy,
    placeholder,
    size,
    type = "text",
    variant = "normal",
    ...inputProps
  }: React.PropsWithChildren<TextInputProps>,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <div
      className={cn(
        "relative bg-transparent flex items-center",
        {
          "hover:placeholder-slate before:content-[''] before:absolute before:bottom-0 before:left-0 before:border-b-2":
            animated && variant === "normal",
          "before:transition-all before:w-0 hover:before:w-full focus-within:before:w-full":
            animated && variant === "normal",
          "border-b-[1px]": !animated && variant === "normal",
          "border transition-all rounded-sm border-lightgray focus-within:border-charcoal":
            variant === "rounded",
          "border-blush": invalid,
        },
        inputContainerClasses
      )}
    >
      <input
        aria-invalid={invalid || undefined}
        aria-label={label}
        aria-labelledby={labelledBy}
        className={cn(
          variant === "rounded"
            ? "px-4 py-3 rounded-sm"
            : "px-4 py-5 rounded-none",
          "bg-white focus:outline-none flex-1 text-input",
          inputClasses
        )}
        placeholder={placeholder}
        size={
          size ??
          (placeholder
            ? Math.max(placeholder.length, DEFAULT_INPUT_SIZE)
            : DEFAULT_INPUT_SIZE)
        }
        type={type}
        ref={ref}
        {...inputProps}
      />
      {animated && variant === "normal" && (
        <div
          data-testid="animated-border"
          className="absolute bottom-0 w-full border-b-2 opacity-20 border-slate"
        />
      )}
      {children}
    </div>
  );
}

export const TextInput = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<TextInputProps>
>(TextInputWithRef);
