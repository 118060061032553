const selector = {
  emailFormView: {
    input: "input.email-form__input",
    submit: "button.email-form__submit",
    container: "div.email-form__container",
    errorMessage: "p.email-form__error-message",
  },
  phoneFormView: {
    input: "input.phone-form__input",
    submit: "button.phone-form__submit",
  },
  postSubmitView: {
    container: "div.post-submit__container",
    firstCTA: "cta-link-element",
    secondCTA: "button.post-submit__second-cta",
  },
  wizardFormView: {
    closeButton: "button.wizard-form__close",
    noThanksButton: "button.wizard-form__no-thanks",
  },
  organicSearchBanner: {
    container: "div.organic-search-banner",
  },
  modal: {
    container: "div.modal",
  },
};

export default selector;
