/* eslint-disable import/prefer-default-export */
type Falsy = false | "" | 0 | null | undefined;

type Truthy<T> = T extends Falsy ? never : T;

/**
 * A simple type guard for truthy values.
 *
 * Can be used in place of for Array.filter(Boolean), which TypeScript doesn't handle nicely
 *
 * @see https://github.com/microsoft/TypeScript/issues/16655
 */
export function isTruthy<T>(value: T): value is Truthy<T> {
  return !!value;
}
