import { GraphQLClient } from "graphql-request";

import type { Sdk as ClientSdk } from "../../graphql/shopify/client/sdk";
import {
  IShopifyCountryCode,
  IShopifyLanguageCode,
} from "../../graphql/shopify/client/sdk";
import type { Sdk as ServerSdk } from "../../graphql/shopify/server/sdk";

export interface Config {
  shopifyDomain: string;
  shopifyStorefrontApiToken: string;
  getSdk: (client: GraphQLClient) => ClientSdk | ServerSdk;
  languageCode?: IShopifyLanguageCode;
  countryCode?: IShopifyCountryCode;
}

export default class BaseShopifyService<S extends ClientSdk | ServerSdk> {
  private countryCode: IShopifyCountryCode;

  private languageCode: IShopifyLanguageCode;

  protected storefrontClient: GraphQLClient;

  protected sdk: S;

  constructor(config: Config) {
    this.countryCode = config.countryCode || IShopifyCountryCode.Us;
    this.languageCode = config.languageCode || IShopifyLanguageCode.En;

    this.storefrontClient = new GraphQLClient(
      `https://${config.shopifyDomain}/api/2023-04/graphql.json`,
      {
        headers: {
          "X-Shopify-Storefront-Access-Token": config.shopifyStorefrontApiToken,
        },
      }
    );

    // @ts-expect-error -- This type is really hard for typescript to determine but works in practice.
    this.sdk = config.getSdk(this.storefrontClient);
  }

  public getCountryCode() {
    return this.countryCode;
  }

  public getLanguageCode() {
    return this.languageCode;
  }

  private variablesWithCountryAndLanguageCode<V>(variables: V): V & {
    countryCode: IShopifyCountryCode;
    languageCode: IShopifyLanguageCode;
  } {
    return {
      ...variables,
      countryCode: this.countryCode,
      languageCode: this.languageCode,
    };
  }

  public async request<QK extends keyof S>(
    queryKey: QK,
    // @ts-expect-error -- This type is really hard for typescript to determine but works in practice.
    variables: Omit<Parameters<S[QK]>[0], "countryCode" | "languageCode">
    // @ts-expect-error -- This type is really hard for typescript to determine but works in practice.
  ): ReturnType<S[QK]> {
    // @ts-expect-error -- This type is really hard for typescript to determine but works in practice.
    return this.sdk[queryKey](
      this.variablesWithCountryAndLanguageCode(variables)
    );
  }
}
