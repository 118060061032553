const daybedVariantReplacements = [
  // Walnut / Dark Charcoal
  {
    replacement: {
      productId: "6980932763753",
      variantId: "40444830875753",
      slug: "the-daybed",
    },
    original: {
      productId: "567859380252",
      variantId: "39594026664041",
    },
  },
  // Walnut / Fog Grey
  {
    replacement: {
      productId: "6980932763753",
      variantId: "40444830941289",
      slug: "the-daybed",
    },
    original: {
      productId: "567859380252",
      variantId: "40159567151209",
    },
  },
  // Walnut / Light Linen
  {
    replacement: {
      productId: "6980932763753",
      variantId: "40444830908521",
      slug: "the-daybed",
    },
    original: {
      productId: "567859380252",
      variantId: "39596216320105",
    },
  },
  // Natural / Dark Charcoal
  {
    replacement: {
      productId: "6980932763753",
      variantId: "40444830974057",
      slug: "the-daybed",
    },
    original: {
      productId: "567859380252",
      variantId: "39596216582249",
    },
  },
  // Natural / Fog Grey
  {
    replacement: {
      productId: "6980932763753",
      variantId: "40444831039593",
      slug: "the-daybed",
    },
    original: {
      productId: "567859380252",
      variantId: "40159567413353",
    },
  },
  // Natural / Light Linen
  {
    replacement: {
      productId: "6980932763753",
      variantId: "40444831006825",
      slug: "the-daybed",
    },
    original: {
      productId: "567859380252",
      variantId: "39596216942697",
    },
  },
  // Espresso / Dark Charcoal
  {
    replacement: {
      productId: "6980932763753",
      variantId: "40444831072361",
      slug: "the-daybed",
    },
    original: {
      productId: "567859380252",
      variantId: "40158252892265",
    },
  },
  // Espresso / Fog Grey
  {
    replacement: {
      productId: "6980932763753",
      variantId: "40444831137897",
      slug: "the-daybed",
    },
    original: {
      productId: "567859380252",
      variantId: "40159568003177",
    },
  },
  // Espresso / Light Linen
  {
    replacement: {
      productId: "6980932763753",
      variantId: "40444831105129",
      slug: "the-daybed",
    },
    original: {
      productId: "567859380252",
      variantId: "40158252925033",
    },
  },
];

export interface VariantReplacement {
  original: {
    productId: string;
    variantId: string;
  };
  replacement: {
    productId: string;
    variantId: string;
    slug: string;
  };
}

const variantReplacements: VariantReplacement[] = [
  ...daybedVariantReplacements,
];

export default variantReplacements;
