/* eslint-disable import/prefer-default-export */
import { FormattedCollectionItem } from "../../queries/data/collectionData";
import { ProductCatalog } from "../../services/catalog/types";
import { addSearchProductSelectEvent } from "../../services/elevar/events";
import { ISearchProduct, ISearchResults } from "../../services/search/types";
import makeProductUrl from "../../util/makeProductUrl";
import { getCatalogProductAndVariantFromSearchSpringItem } from "../../util/searchUtils";
import { CollectionContentItem } from "../CollectionListSection/CollectionListSection";

export function isExpandedSearchSpringProduct(item: ISearchProduct) {
  return item.uid.includes("-") && item.uid.split("-")[0] !== "";
}

export function getProductIdFromSearchSpringUID(uid: string) {
  return uid.includes("-") ? uid.split("-")[0] : uid;
}

export function pagesToResultsArray(arr: (string | ISearchResults)[]) {
  return arr.flatMap((page) => (typeof page === "string" ? [] : page.results));
}

export function searchResultsToFormattedItems(
  arr: ISearchProduct[],
  catalog: ProductCatalog,
  searchTerm: string,
  searchInput: string
) {
  return arr.filter(isExpandedSearchSpringProduct).reduce(
    (acc, item: ISearchProduct) => {
      const {
        product: catalogProduct,
        variant,
        productId,
      } = getCatalogProductAndVariantFromSearchSpringItem(item, catalog);
      if (!catalogProduct || !variant) return acc;
      const defaultImage = variant.images[0];

      if (acc.imageSet.has(defaultImage.url)) return acc;
      acc.imageSet.add(defaultImage.url);

      return {
        ...acc,
        items: [
          ...acc.items,
          {
            id: productId,
            handle: catalogProduct.slug,
            key: variant.id,
            productType: "",
            title: catalogProduct.productTitle,
            vendor: "",
            image: {
              src: defaultImage.url,
              alt: defaultImage.title,
              width: defaultImage.width,
              height: defaultImage.height,
            },
            href: makeProductUrl(catalogProduct.slug, variant.id),
            footerText: "",
            price: variant.price,
            variants: [{ title: variant.title, id: variant.id }],
            onClick: (formattedItem: FormattedCollectionItem) => {
              addSearchProductSelectEvent(
                {
                  id: String(item.id),
                  name: formattedItem.title,
                  category: formattedItem.productType,
                  variant: variant.title,
                  price: String(formattedItem.price),
                  quantity: "1",
                  list: formattedItem.href,
                  product_id: String(formattedItem.id),
                  variant_id: variant.id,
                  compare_at_price: "",
                  image: formattedItem.image?.src,
                },
                item.intellisuggestData,
                item.intellisuggestSignature,
                searchTerm,
                searchInput,
                ""
              );
            },
          } as CollectionContentItem,
        ],
      };
    },
    { items: [] as CollectionContentItem[], imageSet: new Set<string>() }
  ).items;
}
