/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { ErrorBoundary, Provider } from "@rollbar/react";

interface IClientLoggerProps {
  children: JSX.Element | JSX.Element[];
  rollbarConfig: {
    clientToken: string;
    environment: string;
  };
}

export function ClientLogger({ children, rollbarConfig }: IClientLoggerProps) {
  const config = {
    accessToken: rollbarConfig?.clientToken,
    enabled: !!rollbarConfig?.clientToken,
    environment: rollbarConfig?.environment,
    captureUncaught: true,
    captureUnhandledRejections: true,
  };

  return (
    <Provider config={config}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
}
