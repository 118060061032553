/* eslint-disable import/prefer-default-export */
import type { Asset } from "contentful";

export const getImageProps = ({
  fields: {
    description,
    title,
    file: {
      url,
      details: { image },
    },
  },
}: Asset) => ({
  alt: description || title,
  src: url,
  height: image?.height,
  width: image?.width,
});
