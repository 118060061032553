/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Dialog, Transition } from "@headlessui/react";

import { cn } from "../../utils/tailwind-merge";

interface ModalProps {
  /** whether the dialog is open  */
  isOpen: boolean;

  /** callback for when the modal is closed */
  onDismiss: () => unknown;

  /** ref to an element to apply focus on when modal is opened */
  initialFocusRef?: Parameters<typeof Dialog>[0]["initialFocus"];

  /** class name for the modal container */
  childContainerClassName?: string;

  /** class name for the modal overlay */
  overlayClassName?: string;

  /** class name for the modal container */
  containerClassName?: string;
}

/**
 * A modal dialog component
 */
export function Modal({
  children,
  isOpen,
  initialFocusRef,
  onDismiss,
  childContainerClassName,
  overlayClassName,
  containerClassName,
}: React.PropsWithChildren<ModalProps>) {
  const [overlayClosedState, overlayOpenState] = ["opacity-0", "opacity-100"];
  const [contentClosedState, contentOpenState] = [
    "opacity-0 scale-75",
    "opacity-100 scale-100",
  ];
  const [entranceTiming, exitTiming] = [
    "ease-out duration-300",
    "ease-in duration-200",
  ];

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog
        initialFocus={initialFocusRef}
        className={cn(
          "fixed inset-0 inline-flex items-center justify-center min-h-screen px-4 z-modal",
          containerClassName
        )}
        onClose={onDismiss}
        open={isOpen}
      >
        <Transition.Child
          as={Dialog.Overlay}
          data-testid="modal-overlay"
          className={cn(
            "fixed inset-0 bg-charcoal/30 motion-reduce:transition-none",
            overlayClassName
          )}
          enter={entranceTiming}
          enterFrom={overlayClosedState}
          enterTo={overlayOpenState}
          leave={exitTiming}
          leaveFrom={overlayOpenState}
          leaveTo={overlayClosedState}
        />
        <Transition.Child
          className={cn(
            "z-10 max-w-lg max-h-screen p-6 overflow-auto bg-white transform shadow-xl motion-reduce:transition-none motion-reduce:transform-none text-charcoal",
            childContainerClassName
          )}
          enter={entranceTiming}
          enterFrom={contentClosedState}
          enterTo={contentOpenState}
          leave={exitTiming}
          leaveFrom={contentOpenState}
          leaveTo={contentClosedState}
        >
          <Dialog.Description as="div">{children}</Dialog.Description>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
