import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { Asset } from "contentful";

import {
  IFaqSection,
  IMediaGallery,
  IResponsiveAsset,
} from "../../../@types/generated/contentful";
import { MergedProductVariant } from "../services/catalog/types";
import type { Product, ProductVariant } from "../services/product/api";
import { isMergeOption } from "../services/util/merge";
import { isTruthy } from "../util";
import { getByViewport } from "../util/getResponsiveAsset";

/* eslint-disable import/prefer-default-export */
export interface ImageData {
  src: string;
  alt?: string;
  height?: string | number;
  width?: string | number;
}

export interface VideoData extends ImageData {
  type: string;
}

export const isValidEmail = (emailAddress: string) => {
  const pattern =
    // eslint-disable-next-line no-control-regex
    /^([a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return pattern.test(emailAddress);
};

/** helper method to force a delay when triggering async event handlers */
export async function tick(count = 1) {
  const promises = [];
  for (let i = 0; i < count; i += 1) {
    promises.push(new Promise(process.nextTick));
  }

  return Promise.all(promises);
}

/** helper method to convert file lists to arrays */
export function fileListToArray(list: FileList | null) {
  return Array.from(list || []);
}

/** helper method to generate structured data from faq sections */
export function makeFAQPageData(sections: IFaqSection[]) {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: sections.flatMap((section) =>
      section.fields.collapsibles.map(({ fields: { title, copy } }) => ({
        "@type": "Question",
        name: title,
        acceptedAnswer: {
          "@type": "Answer",
          text: documentToPlainTextString(copy),
        },
      }))
    ),
  };

  return structuredData;
}

export interface FormattedMedia {
  content: (ImageData | VideoData)[];
  layout: "portrait" | "landscape";
}
export function formatMediaGallery(
  media?: IMediaGallery
): FormattedMedia | undefined {
  if (!media) return undefined;

  const content = media.fields.media.map(
    ({
      fields: {
        file: {
          url: src,
          details: { image },
          contentType,
        },
        description: alt,
      },
    }) => ({
      src,
      alt,
      height: image?.height,
      width: image?.width,
      type: image ? undefined : contentType,
    })
  );

  return {
    content,
    layout: media.fields.layout,
  };
}

export function filterViewports(
  variant: MergedProductVariant,
  viewport: "mobile" | "desktop"
) {
  // if viewport = undefined, that means it will be rendered on both desktop and mobile views. that is why it is included in the conditional
  return variant.responsiveMedia?.filter(
    (media) =>
      media.fields.viewport === viewport || media.fields.viewport === undefined
  );
}

export function formatMedia(
  filteredMedia: IResponsiveAsset[] | undefined,
  viewport: "mobile" | "desktop"
) {
  return filteredMedia?.map((media) => ({
    ...getByViewport([media], viewport),
    preview: media.fields.videoAsset?.fields.shopifyVideo?.previewImage.url,
  }));
}

export function isVideo(asset: Asset) {
  return asset.fields.file.contentType.includes("video");
}

export function formatPlainMedia(variant: MergedProductVariant) {
  return (
    variant.images?.map(
      (image) =>
        ({
          sys: {
            id: image.url,
            type: "Image",
            createdAt: "*",
            updatedAt: "*",
            locale: "*",
            contentType: {
              sys: { type: "Link", linkType: "ContentType", id: "Asset" },
            },
          },
          fields: {
            title: image.title,
            description: image.description,
            file: {
              url: image.url,
              details: { size: 0, image: {} },
              fileName: image.title,
              contentType: "image/png",
            },
          },
          metadata: { tags: [] },
          preview: image.url,
        } as unknown as Asset)
    ) || undefined
  );
}

export function formatOptionValues(
  options: Product["options"],
  selectedOptions: ProductVariant["selectedOptions"]
) {
  // TODO: i18n
  const isPrimaryOption = ({ name }: { name: string }) =>
    ["Size", "Configuration"].includes(name);

  const primaryOption = selectedOptions.find(isPrimaryOption);
  const variantOptionNames = selectedOptions.map(({ name }) => name);
  const optionValues = options
    .filter(
      (option) =>
        !isMergeOption(option, "Toggle") &&
        !isPrimaryOption(option) &&
        variantOptionNames.includes(option.name)
    )
    .map(({ name, values }) => {
      const matchingOption = selectedOptions.find(
        (option) => option.name === name
      );
      const matchingValue = values.find(
        ({ value }) => value === matchingOption?.value
      );
      return matchingValue;
    })
    .filter(isTruthy);

  return {
    primaryOptionValue: primaryOption?.value,
    optionValues,
  };
}

export const getCheckoutUrl = (checkoutUrl?: string) => {
  if (!checkoutUrl) return "";

  const stringNtProfileObj = localStorage.getItem("__nt_profile__");
  if (!stringNtProfileObj) return checkoutUrl;

  const ntProfileObj = JSON.parse(stringNtProfileObj);
  const url = new URL(checkoutUrl);
  url.searchParams.set("ntProfileId", ntProfileObj.id);
  return url.toString();
};
