const selectors = {
  closeButton: "cart-close-btn",
  cartIcon: "desktop-header-cart-icon",
  lineItemList: "cart-line-items-list",
  lineItem: "cart-line-item",
  lineItemImage: "cart-line-item-image",
  lineItemTitle: "cart-line-item-title",
  quantityContainer: "line-item-quantity",
  addButton: "recommended-product-card-add-to-cart",
  removeButton: "cart-remove-from-cart-btn",
  scrollElement: "cart-line-item-list-scroll-element",
  cartRecommendations: "cart-recommendations-list",
};

export default selectors;
