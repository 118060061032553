import React from "react";
import { useRouter } from "next/router";

function useIsFromMarketingEmail() {
  const [isFromMarketingEmail, setIsFromMarketingEmail] = React.useState<
    boolean | undefined
  >(undefined);
  const router = useRouter();
  const isUtmSourceEmail = router.query.utm_source === "email";
  const isUtmMediumEmail = router.query.utm_medium === "email";

  React.useEffect(() => {
    if (router.isReady) {
      setIsFromMarketingEmail(isUtmSourceEmail || isUtmMediumEmail);
    }
    // Disabled because the query params will be ready if router.isReady and
    // we don't want it to run again when the query params change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  return isFromMarketingEmail;
}

export default useIsFromMarketingEmail;
