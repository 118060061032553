/* eslint-disable react/no-array-index-key */
import * as React from "react";
import { Button, Icon } from "@components";
import classNames from "classnames";
import { useRouter } from "next/router";
import { addHeapNavigationItemClick } from "src/lib/services/elevar/events";

import { isTruthy } from "../../../util";
import selectors from "../selectors";
import type { MenuItem } from "../types";

import { BackButton } from "./BackButton";
import LinkItem from "./LinkItem";
import SlidingTransition from "./SlidingTransition";

const isCardOrArticle = (item?: MenuItem): boolean => {
  if (!item || !item.type) {
    return false;
  }

  return ["article", "card"].includes(item.type);
};

export interface MenuStackProps {
  title?: string | null;
  onBack?: () => void;
  items: { topItems?: MenuItem[]; mainItems: MenuItem[] };
  addPadding?: boolean;
  showChevronOverride?: boolean;
  children?: React.ReactNode;
  isOpen?: boolean;
  level?: number;
  resetScroll: () => void;
}
export default function MenuStack({
  title,
  items: { topItems = [], mainItems },
  onBack,
  addPadding,
  showChevronOverride = false,
  children,
  isOpen,
  level = 1,
  resetScroll,
}: MenuStackProps) {
  const router = useRouter();
  const [activeIndex, setActiveIndex] = React.useState(-1);

  // Reset the menu stack whenever the open state changes
  React.useEffect(() => {
    setActiveIndex(-1);
    resetScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const topListItems = topItems
    .flatMap((item) => item.children)
    .filter(isTruthy)
    .filter((item) => item.text && item.url);

  /** only items with text are shown in the main list */
  const mainListItems = mainItems.filter((item) => item.text);

  /** items without text have their children (if any) combined
   * and then filtered on the presence of text and a url
   */
  const untitledListItems = mainItems
    .filter((item) => !item.text)
    .flatMap((item) => item.children)
    .filter(isTruthy)
    .filter((item) => item.text && item.url);

  const handleClickAnalytics = (item: MenuItem) => {
    addHeapNavigationItemClick({
      style: item.type || "",
      isMobile: true,
      hasImage: Boolean(item.image),
      level,
      href: item.url,
      version: "legacy",
      title: item.text,
    });
  };

  return (
    <div className={classNames("relative", { "px-8": addPadding })}>
      <SlidingTransition
        show={activeIndex === -1}
        unmount={false}
        className="flex flex-col duration-300"
        exitLeft
      >
        {/* title (back) button */}
        {title && <BackButton text={title} onClick={onBack} />}

        {/* top item list */}
        {Boolean(topListItems.length) && (
          <ul className="mb-4">
            {topListItems
              .filter((item) => item.text)
              .map((item, itemIndex) => (
                <li key={`top-items-${itemIndex}`}>
                  <LinkItem
                    // @ts-expect-error this list has been filtered for truthy text
                    text={item.text}
                    // @ts-expect-error this list has been filtered for truthy URLs
                    url={item.url}
                    image={item.image}
                    type={item.type}
                    badge={item.badge}
                    highlight={item.highlight}
                    onClick={() => handleClickAnalytics(item)}
                    mobile
                  />
                </li>
              ))}
          </ul>
        )}

        {/* main item list */}
        {Boolean(mainListItems.length) && (
          <ul className="flex-1">
            {mainListItems
              .filter((item) => item.text)
              .map((item, itemIndex) => {
                const shouldShowLine =
                  !item.type || ["link", "icon"].includes(item.type);

                return (
                  <li
                    key={item.text}
                    className={classNames(
                      !item.highlight &&
                        shouldShowLine &&
                        "border-b border-b-lightgray"
                    )}
                  >
                    {item.url && (!showChevronOverride || item.highlight) ? (
                      <LinkItem
                        url={item.url}
                        // @ts-expect-error this list has been filtered for truthy text
                        text={item.text}
                        type={item.type}
                        image={item.image}
                        badge={item.badge}
                        highlight={item.highlight}
                        onClick={() => handleClickAnalytics(item)}
                        mobile
                      />
                    ) : (
                      <Button
                        variant="unstyled"
                        className="flex items-center justify-between w-full gap-4 py-5 leading-tight text-left"
                        onClick={() => {
                          if (item.url) router.push(item.url);
                          else setActiveIndex(itemIndex);
                          handleClickAnalytics(item);
                        }}
                      >
                        {item.text}
                        {(item.children || showChevronOverride) && (
                          <Icon
                            name="chevron-right"
                            className="text-xs"
                            size="inherit"
                          />
                        )}
                      </Button>
                    )}
                  </li>
                );
              })}
          </ul>
        )}

        {/* links from groups without titles */}
        {Boolean(untitledListItems.length) && (
          <ul
            className={classNames("flex flex-col", {
              "mt-6": mainListItems.length,
              "flex-1": !mainListItems.length,
            })}
          >
            {untitledListItems.map((item, itemIndex) => {
              const shouldShowLine =
                !item.type || ["link", "icon"].includes(item.type);

              const isFirstItem = itemIndex === 0;
              const previousItem = untitledListItems[itemIndex - 1];
              const isDifferentType = item.type !== previousItem?.type;
              const isSameAsPrevious =
                isCardOrArticle(item) && isCardOrArticle(previousItem);
              const addMargin =
                !isFirstItem && isDifferentType && !isSameAsPrevious;

              return (
                <li
                  key={`untitled-${itemIndex}`}
                  className={classNames({
                    "border-b border-b-lightgray":
                      !item.highlight && shouldShowLine,
                    // add margin between links of different type
                    "mt-auto": addMargin,
                  })}
                >
                  <LinkItem
                    // @ts-expect-error this list has been filtered for truthy text
                    text={item.text}
                    // @ts-expect-error this list has been filtered for truthy URLs
                    url={item.url}
                    image={item.image}
                    badge={item.badge}
                    type={item.type}
                    highlight={item.highlight}
                    onClick={() => handleClickAnalytics(item)}
                    mobile
                  />
                </li>
              );
            })}
          </ul>
        )}

        {children}
      </SlidingTransition>

      {/* child menus (absolute positioned) */}
      {mainListItems.map((item, itemIndex) =>
        item.children ? (
          <SlidingTransition
            unmount={false}
            key={`active-menu-${itemIndex}`}
            show={activeIndex === itemIndex}
            className="duration-300"
            testId={selectors.MenuStack.slideOverMenu(item.text)}
          >
            <MenuStack
              items={{ mainItems: item.children }}
              title={item.text}
              onBack={() => {
                setActiveIndex(-1);
                resetScroll();
              }}
              level={level + 1}
              resetScroll={resetScroll}
            />
          </SlidingTransition>
        ) : null
      )}
    </div>
  );
}
