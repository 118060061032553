import { createContext, ReactNode, useMemo } from "react";
import { v4 as uuid } from "uuid";

export const SessionIdContext = createContext<string>("");

export function SessionIdContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const sessionId = useMemo(() => {
    // istanbul ignore next
    if (typeof window === "undefined") {
      return "";
    }
    const storageSessionId = localStorage.getItem("sessionId");
    if (storageSessionId) {
      return storageSessionId;
    }
    const newSessionId = uuid();
    localStorage.setItem("sessionId", newSessionId);
    return newSessionId;
  }, []);
  return (
    <SessionIdContext.Provider value={sessionId}>
      {children}
    </SessionIdContext.Provider>
  );
}
