import type env from "../../env";

import {
  ProductReview,
  RawReview,
  RawReviewsData,
  ReviewsFromSearchArgs,
  ReviewsFromSearchData,
  YotpoResponse,
} from "./types";

const formatReviews = (reviews: RawReview["review"][]): ProductReview[] =>
  reviews
    .filter(({ deleted }) => deleted !== true)
    .map(
      ({
        id,
        title,
        content,
        score,
        user: { display_name },
        verified_buyer,
        created_at,
      }) => ({
        id,
        title,
        content,
        score,
        user: display_name,
        verifiedBuyer: verified_buyer,
        createdAt: new Date(created_at),
      })
    );

const formatSearchReviews = (
  reviews: ReviewsFromSearchData["response"]["reviews"]
) =>
  reviews.map((review) => ({
    ...review,
    id: review.id.toString(),
    user: review.user.display_name,
    createdAt: new Date(review.created_at),
  }));

// eslint-disable-next-line no-var
var averageRatingCache: Record<
  string,
  | Promise<{
      averageScore: number;
      totalReviews: number;
    } | null>
  | undefined
> = {};

export const clearAverageRatingCache = () => {
  averageRatingCache = {};
};
const roundAverageRating = (rating: number) => {
  const fixed = rating.toFixed(1);
  return !fixed.endsWith("0") ? parseFloat(fixed) : Math.floor(rating);
};

export default class ReviewsAPI {
  private token: typeof env.yotpoConfig.token;

  private isDisabled: boolean;

  constructor(token: string) {
    this.token = token;
    this.isDisabled = process.env.NEXT_PUBLIC_DISABLE_REVIEWS === "true";
  }

  private internalGetProductAverageRating = async (productID: string) => {
    if (this.isDisabled) return null;

    const url = `https://api-cdn.yotpo.com/v1/widget/${this.token}/products/${productID}/reviews.json`;
    const rawData = await fetch(url);
    const data: YotpoResponse<RawReviewsData> = await rawData.json();

    return data.response
      ? {
          averageScore: roundAverageRating(
            data.response.bottomline.average_score
          ),
          totalReviews: data.response.bottomline.total_review,
        }
      : null;
  };

  getProductAverageRating = async (productID: string) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!averageRatingCache.hasOwnProperty(productID))
      averageRatingCache[productID] =
        this.internalGetProductAverageRating(productID);

    return averageRatingCache[productID];
  };

  getProductReviews = async (productID: string, perPage = 10, page = 1) => {
    if (this.isDisabled) return null;

    const params = new URLSearchParams({
      page: `${page}`,
      per_page: `${perPage}`,
    }).toString();
    const url = `https://api-cdn.yotpo.com/v1/widget/${this.token}/products/${productID}/reviews.json?${params}`;
    const rawData = await fetch(url);
    const data: YotpoResponse<RawReviewsData> = await rawData.json();

    const reviews = data.response ? formatReviews(data.response.reviews) : [];
    const total = data?.response?.pagination?.total || reviews.length;
    return {
      pagination: {
        page,
        perPage,
        total,
      },
      reviews,
      bottomline: data.response?.bottomline,
    };
  };

  getReviewsFromList = async (reviewIDs: string[]) => {
    if (this.isDisabled) return null;

    const rawData: YotpoResponse<RawReview>[] = await Promise.all(
      reviewIDs.map(async (id) => {
        const url = `https://api.yotpo.com/reviews/${id}?utoken=${this.token}`;
        const data = await fetch(url);
        return data.json();
      })
    );

    const rawReviews = rawData
      .filter((data) => data.response)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .map((data) => data.response!.review);
    const reviews = formatReviews(rawReviews);
    return reviews;
  };

  getProductReviewsFromSearch = async ({
    page = 1,
    per_page = 3,
    productId,
    topic_names,
    free_text_search,
    scores,
  }: ReviewsFromSearchArgs) => {
    if (this.isDisabled) return null;

    const urlParams = new URLSearchParams({
      page: `${page}`,
      per_page: `${per_page}`,
    });

    const body = JSON.stringify({
      domain_key: productId,
      ...(scores && scores.length > 0 ? { scores } : {}),
      ...(free_text_search ? { free_text_search } : {}),
      ...(topic_names ? { topic_names } : {}),
      sortings: [
        {
          sort_by: "date",
          ascending: false,
        },
      ],
    });

    const response = await fetch(
      `https://api-cdn.yotpo.com/v1/reviews/${this.token}/filter.json?${urlParams}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body,
      }
    );

    if (!response.ok) {
      // added to help with debugging
      // eslint-disable-next-line no-console
      console.error(`getProductReviewsFromSearch failed`, {
        productId,
        topic_names,
        free_text_search,
        scores,
        error: response.statusText,
      });
      throw new Error(
        "Failed to fetch reviews from getProductReviewsFromSearch"
      );
    }

    const data: ReviewsFromSearchData = await response.json();

    return {
      pagination: {
        page: data.response.pagination.page,
        perPage: data.response.pagination.per_page,
        total: data.response.pagination.total,
      },
      reviews: formatSearchReviews(data.response.reviews),
    };
  };
}
