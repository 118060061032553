import * as React from "react";
import {
  Button,
  Heading,
  Icon,
  LoadingIndicator,
  TextInput,
} from "@components";
import { Document } from "@contentful/rich-text-types";
import classNames from "classnames";
import { useRouter } from "next/router";

import MarketingServiceContext from "../../../context/MarketingServiceContext";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import useTypedMutation from "../../../hooks/useTypedMutation";
import { addSubscribeEvent } from "../../../services/elevar/events";
import { Media } from "../../Media";
import { RichText } from "../../RichText";
import { isValidEmail } from "../../util";
import useIsFromOrganicSearch from "../hooks/useIsFromOrganicSearch";
import { InputFocusRefContext } from "../InputFocusRefContext";
import selectors from "../selectors";
import utils from "../utils";

export interface EmailFormVisualProps {
  title: string;
  body: Document;
  submitFormButtonText: string;
  dismissFormButtonText: string;
}

interface EmailFormProps extends EmailFormVisualProps {
  onFormComplete: () => void;
  orientation?: "vertical" | "horizontal";
  onClose: () => void;
}

export default function EmailForm({
  onFormComplete,
  onClose,
  title,
  body,
  submitFormButtonText,
  dismissFormButtonText,
  orientation = "vertical",
}: EmailFormProps) {
  const inputRef = React.useContext(InputFocusRefContext);
  const [email, setEmail] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const marketingService = React.useContext(MarketingServiceContext);
  const { mutate: subscribe } = useTypedMutation(["subscribe"]);
  const router = useRouter();
  const { mdVisible } = useBreakpoints();
  const isOrganicSearch = useIsFromOrganicSearch();

  const handleChange = (value: string) => {
    setEmail(value);
    setErrorMessage("");
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setErrorMessage("Please enter a valid email address");
      return;
    }

    setIsSubmitting(true);
    subscribe(
      {
        email,
        locale: router.locale || "en-US",
        customSource: utils.createCustomSource({
          isOrganicSearch,
          device: !mdVisible ? "mobile" : "desktop",
        }),
      },
      {
        onSuccess(data) {
          if (data.profileId) marketingService.setProfileId(data.profileId);
          if (data.message) {
            setErrorMessage(data.message);
            return;
          }

          addSubscribeEvent({
            klaviyo_list_id: "",
            subscriber_source: utils.createCustomSource({
              isOrganicSearch,
              device: !mdVisible ? "mobile" : "desktop",
            }),
            customer_email: email,
          });
          setErrorMessage("");
          onFormComplete();
        },
        onSettled() {
          setIsSubmitting(false);
        },
      }
    );
  };

  return (
    <div
      className={classNames("grid w-full", {
        "grid-cols-1": orientation === "vertical",
        "md:grid-cols-2 gap-x-12 px-12": orientation === "horizontal",
      })}
    >
      {orientation === "vertical" && (
        <div className="flex justify-center mt-3 md:mt-0">
          <Icon name="thuma-logo" />
        </div>
      )}
      <Media lessThan="md">
        <Heading
          as="h4"
          className={classNames("col-start-1", {
            "text-center mt-8": orientation === "vertical",
            "text-center md:text-left": orientation === "horizontal",
          })}
        >
          {title}
        </Heading>
      </Media>
      <Media greaterThanOrEqual="md">
        <Heading
          as="h3"
          className={classNames("col-start-1", {
            "text-center mt-5": orientation === "vertical",
          })}
        >
          {title}
        </Heading>
      </Media>
      <div
        className={classNames("col-start-1 text-center flex justify-center", {
          "mt-8 md:mt-5": orientation === "vertical",
          "md:text-left ": orientation === "horizontal",
        })}
      >
        <p
          className={classNames({
            "max-w-[270px]": orientation === "vertical",
          })}
        >
          <RichText richTextResponse={body} />
        </p>
      </div>

      <div
        className={classNames("w-full", {
          "md:col-start-2 md:row-start-1 row-span-2":
            orientation === "horizontal",
          "md:mt-5 flex justify-center": orientation === "vertical",
        })}
      >
        <form
          onSubmit={onSubmit}
          className={classNames("w-full", {
            "max-w-[270px]": orientation === "vertical",
          })}
        >
          <TextInput
            placeholder="Email"
            inputContainerClasses="mt-5"
            inputClasses="text-input"
            name="email"
            type="email"
            onChange={(event) => handleChange(event.target.value)}
            value={email}
            disabled={isSubmitting}
            required
            data-testid={selectors.emailFormView.input}
            ref={inputRef}
          />
          {errorMessage && (
            <p
              data-testid={selectors.emailFormView.errorMessage}
              className="mt-1 text-sm text-center text-blush"
            >
              {errorMessage}
            </p>
          )}
          <div className="flex gap-3 ">
            <Button
              type="submit"
              className="w-full mt-2 font-normal uppercase"
              data-testid={selectors.emailFormView.submit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <LoadingIndicator size="small" />
              ) : (
                submitFormButtonText
              )}
            </Button>
            {orientation === "horizontal" && (
              <Button
                variant="light-to-gray"
                className="w-full mt-5 uppercase"
                onClick={onClose}
              >
                {dismissFormButtonText}
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
