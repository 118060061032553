import { Heading } from "@components";
import classNames from "classnames";
import { useVerticalSpacing } from "src/lib/context/VerticalSpacingContext";
import Swiper from "swiper";

import { ICollectionList } from "../../../../../../@types/generated/contentful";
import useCatalog from "../../../../hooks/useCatalog";
import {
  formatAds,
  formatCmsItems,
  FormattedCollection,
  validateCmsItems,
} from "../../../../queries/data/collectionData";
import { addHeapOnCollectionListSlideChange } from "../../../../services/elevar/events";
import { getShopifyId } from "../../../../services/util/shopifyID";
import { CollectionListSection } from "../../../CollectionListSection/CollectionListSection";

import selectors from "./selectors";

export default function CollectionListSaddle({
  content: {
    fields: {
      title,
      titleStyle = "original",
      collectionItems,
      layout,
      ad,
      maxColumns,
      name,
    },
  },
  className,
}: {
  content: ICollectionList;
  className?: string;
}) {
  const { hasVerticalSpacing } = useVerticalSpacing();
  const productIds = collectionItems.map((item) =>
    getShopifyId(item.fields.product.fields.product)
  );

  const { catalog } = useCatalog(productIds);

  if (!catalog) return null;

  try {
    validateCmsItems(collectionItems, catalog);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`Could not render collection list section "${title}"`, err);
    return null;
  }

  const formattedItems = formatCmsItems(
    collectionItems,
    catalog
  ) as FormattedCollection["items"];
  const formattedAds = ad ? formatAds([ad]) : [];

  // istanbul ignore next
  const onSlideChange = (swiper: Swiper) => {
    addHeapOnCollectionListSlideChange(
      name || "",
      swiper.previousIndex,
      swiper.activeIndex
    );
  };

  return (
    <div
      className={classNames(
        "mx-auto max-w-screen-king",
        {
          "py-12": titleStyle === "original" && title,
          "px-6 full:px-0": layout === "shop-the-look",
          "px-6 full:px-8": layout !== "shop-the-look",
          "pt-8 pb-6":
            hasVerticalSpacing && titleStyle === "body-large" && title,
        },
        className
      )}
      data-testid={selectors.container}
    >
      {title && (
        <div
          className={classNames({
            "mb-4": titleStyle === "original",
            "mb-6": titleStyle === "body-large",
          })}
          data-testid="collection-list-title"
        >
          <Heading
            as="h3"
            variant="h5"
            className={classNames({
              "!text-base": titleStyle === "original",
              "!leading-7": titleStyle === "body-large",
            })}
          >
            {title}
          </Heading>
        </div>
      )}
      <CollectionListSection
        content={formattedItems}
        layout={layout}
        ads={formattedAds}
        maxColumns={maxColumns}
        onSlideChange={onSlideChange}
      />
    </div>
  );
}
