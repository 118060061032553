/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import React, { Fragment, useContext, useState } from "react";
import classNames from "classnames";
import { format } from "date-fns";

import { Heading, LinkContext } from "../../../components";
import { useTypedQuery } from "../../hooks/useTypedQuery";
import { CenteredRichText, Media, RichText } from "..";

import TrackingDetails from "./components/TrackingDetails";
import TrackYourPackage from "./components/TrackYourPackage";
import selectors from "./selectors";
import {
  IConnectedPackageTrackingSectionProps,
  IPackageTrackingSectionProps,
} from "./types";
import { findTrackingUrlWithTheBed, sortEventsByDate } from "./utils";

const richTextAlignmentMap = {
  left: "text-left",
  center: "text-center",
  right: "text-right",
};

function NoTrackingCodeFound() {
  return (
    <p
      data-testid={selectors.TrackYourPackage.message}
      className="mt-2 text-xs font-normal leading-4 text-center md:mt-10 md:leading-10 md:text-l"
    >
      Your order doesn’t have a tracking number yet.
      <br />
      Check back soon!
    </p>
  );
}

function TrackAnotherPackageText({ onClick }: { onClick: () => void }) {
  return (
    <p className="mb-6 md:mb-10">
      Track another package{" "}
      <button
        data-testid={selectors.PackageTrackingSection.resetSearchButton}
        type="button"
        className="underline"
        onClick={onClick}
      >
        here
      </button>
    </p>
  );
}

const blankTrackingEvent = {
  trackingCode: "",
  details: "",
  status: "",
  substatus: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  trackingUrl: "",
  eta: "",
  date: "",
  locationDisplay: "",
};

export function PackageTrackingSection({
  headerInitial: { fields: headerInitial },
  headerSuccess: { fields: headerSuccess },
  data,
  isLoading,
  error,
  setTrackingSearchInput,
}: IPackageTrackingSectionProps) {
  const LinkComponent = useContext(LinkContext);

  const theBedTrackingNumber = data ? findTrackingUrlWithTheBed(data) : null;
  const isThereAtLeastOneTrackingCode =
    data?.shipments?.some((shipment) => shipment.trackingCode) ?? false;

  const resetSearchInput = () => {
    setTrackingSearchInput("");
  };

  return (
    <div
      data-testid={selectors.PackageTrackingSection.container}
      className="flex flex-col items-center"
    >
      {data && (
        <>
          <Media lessThan="lg">
            <Heading
              as="h4"
              className={classNames([
                {
                  "pb-3": !theBedTrackingNumber,
                },
              ])}
            >
              Tracking Updates
            </Heading>
          </Media>
          <Media greaterThanOrEqual="lg">
            <Heading
              as="h3"
              className={classNames([
                {
                  "pb-6 md:pb-10": !theBedTrackingNumber,
                },
              ])}
            >
              Tracking Updates
            </Heading>
          </Media>
          {theBedTrackingNumber && headerSuccess ? (
            <CenteredRichText
              bgColor={headerSuccess.backgroundColor}
              textColor={headerSuccess.textColor}
              padding={headerSuccess.padding}
              bgImage={headerSuccess.backgroundImage?.fields.file.url}
              width={headerSuccess.width}
              overlayColorTop={headerSuccess.overlayColorTop}
              overlayColorMiddle={headerSuccess.overlayColorMiddle}
              overlayColorBottom={headerSuccess.overlayColorBottom}
              overlayOpacity={headerSuccess.overlayOpacity}
            >
              The Bed ships as part of three packages, organized under one
              master tracking number shown below. On rare occasions packages can
              be split in transit. For more detailed shipping information for
              all three packages of The Bed, please click{" "}
              <LinkComponent
                data-testid={
                  selectors.PackageTrackingSection.theBedTrackingNumberLink
                }
                href={theBedTrackingNumber}
                target="_blank"
                rel="noreferrer"
                variant="underline"
              >
                here
              </LinkComponent>{" "}
              to be redirected to the FedEx website.
            </CenteredRichText>
          ) : null}
        </>
      )}
      {headerInitial && !data && (
        <CenteredRichText
          title={headerInitial.title}
          bgColor={headerInitial.backgroundColor}
          textColor={headerInitial.textColor}
          padding={headerInitial.padding}
          bgImage={headerInitial.backgroundImage?.fields.file.url}
          width={headerInitial.width}
          overlayColorTop={headerInitial.overlayColorTop}
          overlayColorMiddle={headerInitial.overlayColorMiddle}
          overlayColorBottom={headerInitial.overlayColorBottom}
          overlayOpacity={headerInitial.overlayOpacity}
        >
          <div
            data-testid={selectors.PackageTrackingSection.headerInitial}
            className={classNames(
              "mx-auto lg:mx-0",
              { "max-w-xs lg:max-w-none": !headerInitial.width },
              headerInitial.textAlignment
                ? richTextAlignmentMap[headerInitial.textAlignment]
                : "text-center"
            )}
          >
            {headerInitial.title ? <br /> : null}
            <RichText richTextResponse={headerInitial.text} />
          </div>
        </CenteredRichText>
      )}
      <div className="flex flex-col items-center w-full px-4 md:px-7 xl:px-0">
        {!data || !isThereAtLeastOneTrackingCode ? (
          <TrackYourPackage
            isLoading={isLoading}
            error={error}
            onSubmit={(value) => setTrackingSearchInput(value)}
            message={
              data && !isThereAtLeastOneTrackingCode ? (
                <NoTrackingCodeFound />
              ) : undefined
            }
          />
        ) : (
          <>
            <TrackAnotherPackageText onClick={resetSearchInput} />
            {data.shipments.map((shipment, i) => {
              const isThereATrackingUrl = !!shipment.trackingUrl;

              // Intentionally violating the rule of no array index as key
              // because the order of the shipments will never change
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`package-tracking-details-${i}`}>
                  <TrackingDetails
                    carrierName={shipment.carrierName}
                    packageStatus={shipment.statusDetails.status}
                    eta={
                      shipment.eta && shipment.eta !== "pending"
                        ? format(new Date(shipment.eta), "MMMM do, yyyy")
                        : undefined
                    }
                    trackingNumber={shipment.trackingCode}
                    trackingUrl={shipment.trackingUrl}
                    events={
                      shipment.events.length > 0
                        ? sortEventsByDate(shipment.events)
                        : [
                            {
                              ...blankTrackingEvent,
                              date: shipment.statusDetails.date,
                              locationDisplay: shipment.statusDetails.details,
                            },
                          ]
                    }
                    carrierService={
                      isThereATrackingUrl ? shipment.serviceLevel.name : ""
                    }
                  />
                  {i !== data.shipments.length - 1 && (
                    <div className="w-full h-5 md:h-24" />
                  )}
                </Fragment>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default function ConnectedPackageTrackingSection({
  headerInitial,
  headerSuccess,
}: IConnectedPackageTrackingSectionProps) {
  const [trackingSearchInput, setTrackingSearchInput] = useState("");

  const { data, isLoading, error } = useTypedQuery(
    ["packageTracking", trackingSearchInput],
    { retry: false, enabled: !!trackingSearchInput }
  );
  return (
    <PackageTrackingSection
      headerInitial={headerInitial}
      headerSuccess={headerSuccess}
      data={data}
      isLoading={isLoading}
      error={error}
      setTrackingSearchInput={setTrackingSearchInput}
    />
  );
}
