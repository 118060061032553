/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DefaultOptions,
  QueryClient,
  QueryFunction,
  QueryFunctionContext,
} from "react-query";

export interface QueryClientPlugin<S extends DataServiceTypes> {
  (queryClient: QueryClient, dataServices: S): void;
}

export interface QueryClientPlugins<
  S extends DataServiceTypes = DataServiceTypes
> {
  [pluginName: string]: QueryClientPlugin<S>;
}

export function makeQueryFn(
  query: (...parameters: any[]) => any
): QueryFunction {
  // Pop the queryName off the front of the key and pass the rest as parameters to query.
  return async ({
    pageParam,
    queryKey: [, ...queryArgs],
  }: QueryFunctionContext) => {
    if (pageParam) {
      // WARNING: This is buggy - we don't know how many queryArgs to expect from each query function so we need to be very diligent about ensuring we are passing through every argument that the function is expecting to receive
      return query(...queryArgs, pageParam);
    }
    return query(...queryArgs);
  };
}

export interface DataServiceTypes {
  [dataServiceName: string]: any;
}

export default function makeQueryClient<S extends DataServiceTypes>(
  plugins: QueryClientPlugins<S>,
  dataServices: S = {} as S,
  defaultOptions: DefaultOptions = {}
) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { ...(defaultOptions?.queries || {}) },
    },
  });

  Object.keys(plugins).forEach((plugin) => {
    plugins[plugin](queryClient, dataServices);
  });

  return queryClient;
}
