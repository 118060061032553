/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";

import SheetSides from "./SheetSides";

interface SheetProps {
  /** whether the sheet is open  */
  isOpen: boolean;

  /** the side from which the sheet animates in */
  side: keyof typeof SheetSides;

  /** callback for when the sheet is closed */
  onDismiss: () => unknown;

  /** class names to pass to the sheet content */
  className?: string;

  /** ref to an element to apply focus on when sheet is opened */
  initialFocusRef?: Parameters<typeof Dialog>[0]["initialFocus"];

  /** whether to render on top of everything else */
  top?: boolean;

  /** whether the content should be unmounted when hidden */
  unmount?: boolean;

  /** The id passed to the Transition Child container */
  contentId?: string;

  /** Id for testing purposes */
  testId?: string;
}

/**
 * A modal dialog component
 */
export function Sheet({
  children,
  isOpen,
  side,
  initialFocusRef,
  onDismiss,
  className,
  unmount,
  contentId,
  top = false,
  testId,
}: React.PropsWithChildren<SheetProps>) {
  const { containerState } = SheetSides[side];
  const [overlayClosedState, overlayOpenState] = ["opacity-0", "opacity-100"];
  const [contentClosedState, contentOpenState] = [
    `opacity-0 ${SheetSides[side].closedState}`,
    `opacity-100 ${SheetSides[side].openState}`,
  ];
  const [entranceTiming, exitTiming] = [
    "ease-out duration-300",
    "ease-in duration-200",
  ];

  return (
    <Transition.Root show={isOpen} as={React.Fragment} unmount={unmount}>
      <Dialog
        data-testid={testId}
        initialFocus={initialFocusRef}
        className={classNames(
          "fixed inset-0 min-w-full min-h-screen",
          top ? "z-highest" : "z-sheet"
        )}
        onClose={onDismiss}
        open={isOpen}
        unmount={unmount}
      >
        <Transition.Child
          as={Dialog.Overlay}
          data-testid="sheet-overlay"
          className="fixed inset-0 bg-charcoal/30 motion-reduce:transition-none"
          enter={entranceTiming}
          enterFrom={overlayClosedState}
          enterTo={overlayOpenState}
          leave={exitTiming}
          leaveFrom={overlayOpenState}
          leaveTo={overlayClosedState}
          unmount={unmount}
        />
        <Transition.Child
          className={classNames(
            "z-10 fixed p-6 transition-all motion-reduce:transition-none transform motion-reduce:transform-none bg-white shadow-xl text-charcoal overflow-auto",
            containerState,
            className
          )}
          id={contentId}
          enter={entranceTiming}
          enterFrom={contentClosedState}
          enterTo={contentOpenState}
          leave={exitTiming}
          leaveFrom={contentOpenState}
          leaveTo={contentClosedState}
          unmount={unmount}
        >
          <Dialog.Description as="div">{children}</Dialog.Description>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
