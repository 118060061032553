/* istanbul ignore file */
import dynamic from "next/dynamic";

const neverStub = () => null as never;

const dynamicComponents = {
  CollapsibleSection: dynamic(() =>
    import("../CollapsibleSection").then(
      (module) => module.CollapsibleSection,
      neverStub
    )
  ),
  CollapsibleSectionGroup: dynamic(() =>
    import("../CollapsibleSectionGroup").then(
      (module) => module.CollapsibleSectionGroup,
      neverStub
    )
  ),
  AnimateVisible: dynamic(() =>
    import("../../../components/AnimateVisible").then(
      (module) => module.AnimateVisible,
      neverStub
    )
  ),
  Grid: dynamic(() =>
    import("../../../components/Grid").then((module) => module.Grid, neverStub)
  ),
  Card: dynamic(() =>
    import("../../../components/Card").then((module) => module.Card, neverStub)
  ),
  AssemblyLink: dynamic(() =>
    import("../AssemblyLink").then((module) => module.AssemblyLink, neverStub)
  ),
  CarouselSection: dynamic(() =>
    import("../CarouselSection").then(
      (module) => module.CarouselSection,
      neverStub
    )
  ),
  TextMedia: dynamic(() =>
    import("../TextMedia").then((module) => module.TextMedia, neverStub)
  ),
  Heading: dynamic(() =>
    import("../../../components/Heading").then(
      (module) => module.Heading,
      neverStub
    )
  ),
  LinkList: dynamic(() =>
    import("../LinkList").then((module) => module.LinkList, neverStub)
  ),
  RichText: dynamic(() =>
    import("../RichText").then((module) => module.RichText, neverStub)
  ),
  CenteredRichText: dynamic(() =>
    import("../CenteredRichText").then(
      (module) => module.CenteredRichText,
      neverStub
    )
  ),
  SiteVideo: dynamic(() =>
    import("../SiteVideo").then((module) => module.SiteVideo, neverStub)
  ),
  InstagramSection: dynamic(() =>
    import("../InstagramSection").then(
      (module) => module.InstagramSection,
      neverStub
    )
  ),
  ReviewSection: dynamic(() =>
    import("../ReviewSection").then((module) => module.ReviewSection, neverStub)
  ),
  PullQuoteSection: dynamic(() =>
    import("../PullQuoteSection").then(
      (module) => module.PullQuoteSection,
      neverStub
    )
  ),
  EmailSignupSection: dynamic(() =>
    import("../EmailSignupSection").then(
      (module) => module.EmailSignupSection,
      neverStub
    )
  ),
  ProductSchematics: dynamic(() =>
    import("../ProductSchematics").then(
      (module) => module.ProductSchematics,
      neverStub
    )
  ),
  Tabs: dynamic(() =>
    import("../Tabs").then((module) => module.Tabs, neverStub)
  ),
  RecommendedProductsSection: dynamic(() =>
    import("../RecommendedProductsSection").then(
      (module) => module.RecommendedProductsSection,
      neverStub
    )
  ),
  ImageSection: dynamic(() =>
    import("../ImageSection").then((module) => module.ImageSection, neverStub)
  ),
  SplitTextSection: dynamic(() =>
    import("../SplitTextSection").then(
      (module) => module.SplitTextSection,
      neverStub
    )
  ),
  PromoBlockSection: dynamic(() =>
    import("../PromoBlockSection").then(
      (module) => module.PromoBlockSection,
      neverStub
    )
  ),
  HospitalityForm: dynamic(() =>
    import("../HospitalityForm").then(
      (module) => module.HospitalityForm,
      neverStub
    )
  ),
  ShareForm: dynamic(() =>
    import("../ShareForm").then((module) => module.ShareForm, neverStub)
  ),
  HeroSection: dynamic(() =>
    import("../HeroSection").then((module) => module.HeroSection, neverStub)
  ),
  SpacerSection: dynamic(() =>
    import("../SpacerSection").then((module) => module.SpacerSection, neverStub)
  ),
  SearchResultsSection: dynamic(() =>
    import("../SearchResultsSection").then(
      (module) => module.ConnectedSearchResultsSection,
      neverStub
    )
  ),
};

export default dynamicComponents;
