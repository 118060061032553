const selectors = {
  PackageTrackingSection: {
    container: "div.package-tracking-section",
    headerInitial: "div.package-tracking-section__header",
    theBedTrackingNumberLink:
      "a.package-tracking-section__the-bed-tracking-number-link",
    resetSearchButton: "button.package-tracking-section__reset-search-button",
  },
  TrackYourPackage: {
    container: "section.track-your-package",
    button: "button.track-your-package__button",
    input: "input.track-your-package__input",
    message: "div.track-your-package__message",
  },
  PackageTrackingDetails: {
    container: "section.package-details",
    button: "button.package-details__button",
  },
};

export default selectors;
