import React from "react";
import classNames from "classnames";

import { Button, Heading, TextInput } from "../../../../components";
import { Media } from "../..";
import selectors from "../selectors";
import { ITrackYourPackageProps } from "../types";

function TrackYourPackage({
  isLoading,
  error,
  onSubmit,
  message,
}: ITrackYourPackageProps) {
  const [trackingNumber, setTrackingNumber] = React.useState("");

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(trackingNumber);
  };
  return (
    <section
      data-testid={selectors.TrackYourPackage.container}
      className={classNames(
        "bg-pearl w-full flex flex-col justify-center items-center",
        "px-8 pt-9 pb-14",
        "md:px-36 md:pt-32 md:pb-36 md:max-w-2xl",
        "lg:px-52 lg:max-w-4xl"
      )}
    >
      <Media lessThan="md">
        <Heading as="h5" className="text-center">
          Track Your Package
        </Heading>
      </Media>
      <Media greaterThanOrEqual="md">
        <Heading as="h3" className="font-normal">
          Track Your Package
        </Heading>
      </Media>
      {message}
      <form className="w-full" onSubmit={onFormSubmit}>
        <TextInput
          data-testid={selectors.TrackYourPackage.input}
          inputContainerClasses={classNames("w-full pt-10 lg:pt-14", {
            "border-blush": !!error,
            "border-charcoal": !error,
          })}
          onChange={(event) => setTrackingNumber(event.target.value)}
          placeholder="Enter your tracking number"
          value={trackingNumber}
          disabled={isLoading}
          invalid={!!error}
        />
        {error ? (
          <p className="w-full font-bold text-left text-2xs text-blush">
            {typeof error === "string"
              ? error
              : "Something went wrong. Please try again."}
          </p>
        ) : null}
        <Button
          type="submit"
          data-testid={selectors.TrackYourPackage.button}
          className={classNames(
            "uppercase w-full mt-9 px-14 md:w-full lg:mt-24"
          )}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Track"}
        </Button>
      </form>
    </section>
  );
}

export default TrackYourPackage;
