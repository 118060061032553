import { FC, useMemo } from "react";
import VerticalSpacingContext from "src/lib/context/VerticalSpacingContext";

import { ICollectionAssembly } from "../../../../../@types/generated/contentful";
import { CollectionAssembly } from "../../CollectionAssembly";
import { CollectionAssemblyItem } from "../../CollectionAssembly/CollectionAssembly";
import { RenderSectionsProps } from "../types";
import { assetToImageData } from "../util";

export default function CollectionAssemblySaddle({
  content: {
    fields: {
      name,
      title,
      collectionCtaText,
      collectionSections,
      carouselConfig: { fields: rawConfig },
    },
  },
  RenderSectionsComponent,
}: {
  content: ICollectionAssembly;
  RenderSectionsComponent: FC<RenderSectionsProps>;
}) {
  const formattedItems: CollectionAssemblyItem[] = collectionSections.map(
    ({ fields: section }) => ({
      title: section.previewTitle || section.title,
      description: section.previewDescription || section.description,
      anchor: section.sectionAnchor,
      image: assetToImageData(section.previewImage),
    })
  );

  const spacingContextValue = useMemo(() => ({ hasVerticalSpacing: true }), []);

  return (
    <CollectionAssembly
      name={name}
      title={title}
      ctaText={collectionCtaText}
      items={formattedItems}
      carouselConfig={{
        mobileCards: rawConfig.numberOfMobileCarouselCards,
        mobilePeek: rawConfig.mobilePeekPercentage,
        tabletCards: rawConfig.numberOfTabletCarouselCards,
        tabletPeek: rawConfig.tabletPeekPercentage,
        desktopCards: rawConfig.numberOfDesktopCarouselCards,
        desktopPeek: rawConfig.desktopPeekPercentage,
        showProgressBar: rawConfig.showCarouselScrollBar,
        showPagination: rawConfig.showCarouselPaginationButtons,
      }}
    >
      <VerticalSpacingContext.Provider value={spacingContextValue}>
        <RenderSectionsComponent sections={collectionSections} />
      </VerticalSpacingContext.Provider>
    </CollectionAssembly>
  );
}
