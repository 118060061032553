/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from "react";

function usePebblePost() {
  const [pebblePost, setPebblePost] = useState<PebblePost | undefined>(
    undefined
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (window._pp && window._pp.ppid && !pebblePost) {
      setPebblePost(window._pp);
    }
  });

  return pebblePost;
}

export default usePebblePost;
