/* eslint-disable import/prefer-default-export */
import { Asset } from "contentful";

import type {
  IResponsiveAsset,
  IResponsiveAssetFields,
} from "../../../@types/generated/contentful";

import { isShopifyVideo, makeContentfulAsset } from "./shopifyVideo";

type Viewports = Required<IResponsiveAssetFields>["viewport"];

export type ResponsiveAsset = { [key in Viewports]: Asset };

export function getByViewport(
  assets: IResponsiveAsset[],
  viewport: Viewports
): Asset {
  const responsiveAsset =
    assets.find(({ fields }) => fields.viewport === viewport) || assets[0];

  if (
    responsiveAsset &&
    responsiveAsset.fields.videoAsset &&
    isShopifyVideo(
      responsiveAsset.fields.videoAsset.fields.shopifyVideo,
      responsiveAsset.fields.videoAsset.sys.id
    )
  ) {
    return makeContentfulAsset(
      responsiveAsset.fields.videoAsset.fields.shopifyVideo
    );
  }

  return responsiveAsset.fields.asset;
}

export function getResponsiveAsset(
  assets?: IResponsiveAsset[]
): ResponsiveAsset | undefined {
  if (!assets || assets.length < 1) {
    return undefined;
  }
  return {
    mobile: getByViewport(assets, "mobile"),
    desktop: getByViewport(assets, "desktop"),
  };
}
