export type KeyValue = { key: string; value: string };

export function toKeyValue(data: Record<string, string>): KeyValue[] {
  return Object.entries(data).map(([key, value]) => ({ key, value }));
}

/**
 * Validates the structure and content of raw JSON key-value data.
 * @param rawValues - The raw JSON key-value data to validate.
 * @returns - The validated key value array.
 * @throws If the raw values do not conform to the expected key value array structure.
 */
export function getValidatedKeyValueArray(rawValues: unknown): KeyValue[] {
  if (!rawValues) return [];
  if (!Array.isArray(rawValues)) throw new Error("Value must be an array");

  const values = rawValues.map((rawValue) => {
    if (!("key" in rawValue))
      throw new Error(`Entry missing 'key' field: ${rawValue}`);
    if (!("value" in rawValue))
      throw new Error(`Entry missing 'value' field: ${rawValue}`);

    const valueObject = rawValue as { key: unknown; value: unknown };
    if (typeof valueObject.key !== "string")
      throw new Error(`Entry has invalid 'key' field: ${rawValue}`);
    if (typeof valueObject.value !== "string")
      throw new Error(`Entry has invalid 'value' field: ${rawValue}`);

    return valueObject as KeyValue;
  });

  return values;
}
