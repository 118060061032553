import { KeyValue } from "../../util/toKeyValue";
import type {
  OptionValue,
  Product,
  ProductOption,
  ProductVariant,
} from "../product/api";

import type { MergedOption, OptionRenderingField } from "./optionRendering";

export type MergeMode = "Toggle" | "Combine";

const MERGE_OPTION_NAME = "Merge";
const MERGE_OPTION_FIELD = "title";
const MERGE_VALUE_DELIMITER = "::";

/**
 * Inserts a merge option at the specified position within the options list.
 * @param options - The list of product options.
 * @param optionConfigs - Configuration for option rendering.
 * @param values - The values to be used for the merge option.
 * @returns - The list of product options including the newly inserted merge option.
 */
function insertMergeOption(
  options: ProductOption[],
  optionConfigs: OptionRenderingField[],
  values: OptionValue[]
): ProductOption[] {
  // Find the position where the merge option should be inserted.
  const mergeOptionIndex = Math.max(
    optionConfigs.findIndex((o) => o.name === MERGE_OPTION_NAME),
    0
  );

  // Return the list with the merge option inserted at the found index.
  return [
    ...options.slice(0, mergeOptionIndex),
    {
      name: MERGE_OPTION_NAME,
      values,
    },
    ...options.slice(mergeOptionIndex),
  ];
}

/**
 * Determines if a given option is the special merge option in "Toggle" mode.
 * @param option - The option to check.
 * @param mergeMode - The current merge mode of the operation.
 * @returns - True if the option is a merge option in "Toggle" mode; otherwise, false.
 */
export function isMergeOption(
  option: Pick<MergedOption, "name">,
  mergeMode: MergeMode | undefined
) {
  if (mergeMode !== "Toggle") return false;
  return option.name === MERGE_OPTION_NAME;
}

/**
 * Prefixes a given value with a specified key, separated by a delimiter.
 * @param key - The key to prefix.
 * @param value - The value to be prefixed.
 * @returns - The prefixed value.
 */
function prefixValue(key: string, value: string) {
  return `${key}${MERGE_VALUE_DELIMITER}${value}`;
}

/**
 * Extracts the original value from a prefixed string in "Combine" mode.
 * @param value - The prefixed value.
 * @param mergeMode - The current merge mode of the operation.
 * @returns - The original value if in "Combine" mode; otherwise, null.
 */
export function getMergedValue(
  value: string,
  mergeMode: MergeMode | undefined
): string | null {
  if (mergeMode === "Toggle") return null;

  const [, originalValue] = value.split(MERGE_VALUE_DELIMITER);
  return originalValue ?? null;
}

/**
 * Retrieves a map of merged value labels for a specific product option.
 * @param option - The product option to process.
 * @param options - The list of all product options.
 * @param mergeMode - The current merge mode of the operation.
 * @param mappings - The mappings for option values.
 * @returns - A map of merged value labels.
 */
export function getMergedValueLabels(
  option: ProductOption,
  options: ProductOption[],
  mergeMode: MergeMode | undefined,
  mappings: KeyValue[]
) {
  if (mergeMode === "Toggle" || !mappings.length) return {};
  const mapping = mappings.find(({ value }) => value === option.name);
  if (!mapping) return {};

  const sourceOption = options.find((op) => op.name === mapping.key);
  if (!sourceOption) return {};

  const valueLabels: Record<string, string> = {};
  sourceOption.values.forEach(({ value }) => {
    const key = prefixValue(mapping.key, value);
    valueLabels[key] = value;
  });

  return valueLabels;
}

/**
 * Formats a product variant by applying merge options based on the specified merge mode and mappings.
 * @param product - The product to which the variant belongs.
 * @param variant - The product variant to format.
 * @param mergeMode - The current merge mode of the operation.
 * @param mappings - The mappings for option values.
 * @returns - The formatted product variant.
 */
function formatMergedVariant(
  product: Product,
  variant: ProductVariant,
  mergeMode: MergeMode | undefined,
  mappings: KeyValue[]
): ProductVariant {
  if (!mergeMode || (mergeMode === "Combine" && !mappings.length))
    return variant;
  if (mergeMode === "Toggle")
    return {
      ...variant,
      selectedOptions: [
        ...variant.selectedOptions,
        { name: MERGE_OPTION_NAME, value: product[MERGE_OPTION_FIELD] },
      ],
    };

  return {
    ...variant,
    selectedOptions: variant.selectedOptions.map((option) => {
      const mapping = mappings.find(({ key }) => key === option.name);
      if (!mapping) return option;

      return {
        name: mapping.value,
        value: prefixValue(mapping.key, option.value),
      };
    }),
  };
}

/**
 * Flattens a list of product variants based on the given merge mode and mappings.
 * @param products - The list of products.
 * @param mergeMode - The current merge mode of the operation.
 * @param mappings - The mappings for option values.
 * @returns - The flattened list of product variants.
 */
export function flattenVariants(
  products: Product[],
  mergeMode: MergeMode | undefined,
  mappings: KeyValue[]
) {
  return products.flatMap((product) =>
    product.variants.map((variant) =>
      formatMergedVariant(product, variant, mergeMode, mappings)
    )
  );
}

/**
 * Flattens the options across all products, deduplicating and combining them as necessary.
 * @param products - The list of products.
 * @returns - The flattened list of product options.
 */
export function flattenOptions(products: Product[]) {
  const flatOptions = products.flatMap((product) => product.options);

  // Go through each flat option and dedupe them into the option map
  const optionMap = new Map<string, string[]>();
  flatOptions.forEach((option) => {
    const existingValues = optionMap.get(option.name) || [];
    const currentOptionValues = option.values.map(({ value }) => value);
    const combinedValues = Array.from(
      new Set([...existingValues, ...currentOptionValues])
    );
    optionMap.set(option.name, combinedValues);
  });

  // Extract our true list of options
  const allOptions = Array.from(optionMap.entries()).map(([name, values]) => ({
    name,
    values: values.map((value) => ({ value })),
  }));

  return allOptions;
}

/**
 * Applies mappings to product options, transforming and combining them based on the mappings provided.
 * @param options - The list of product options to map.
 * @param mappings - The mappings for option values.
 * @returns - The list of product options with mappings applied.
 */
function getMappedOptions(options: ProductOption[], mappings: KeyValue[]) {
  return options
    .filter((option) => !mappings.some(({ key }) => key === option.name))
    .map((option) => {
      const mapping = mappings.find(({ value }) => value === option.name);
      if (!mapping) return option;

      const sourceOption = options.find((o) => o.name === mapping.key);

      // Because of the validation, we can't actually get to this code path
      // istanbul ignore next
      if (!sourceOption) return option;

      return {
        ...option,
        values: option.values.concat(
          ...sourceOption.values.map(({ value }) => ({
            value: prefixValue(mapping.key, value),
          }))
        ),
      };
    });
}

/**
 * Constructs a formatted error message for validation failures.
 * @param message - The base error message.
 * @param optionNames - The list of option names that are inconsistent.
 * @returns - The formatted error message.
 */
export function makeValidationErrorMessage(
  message: string,
  optionNames: string[]
) {
  const formattedOptionNames = optionNames
    .map((name) => `- ${name}`)
    .join("\n");
  return `${message} - The following option names are inconsistent:\n${formattedOptionNames}`;
}

/**
 * Validates the consistency of option names across products and checks if mappings correctly resolve any inconsistencies.
 * @param products - The list of products.
 * @param mergeMode - The current merge mode of the operation.
 * @param mappings - The mappings for option values.
 * @returns - True if validation passes; otherwise, throws an error.
 * @throws If option names are inconsistent and not properly accounted for in mappings.
 */
function validateOptions(
  products: Product[],
  mergeMode: MergeMode | undefined,
  mappings: KeyValue[]
) {
  // Create a map to count occurrences of each option name
  const optionNameCount = new Map<string, number>();
  products.forEach((product) => {
    product.options.forEach((option) => {
      const currentCount = optionNameCount.get(option.name) || 0;
      optionNameCount.set(option.name, currentCount + 1);
    });
  });

  // Find option names that don't appear in all products
  const inconsistentOptionNames = Array.from(optionNameCount.entries())
    .filter(([, count]) => count !== products.length)
    .map(([name]) => name);

  if (mergeMode === "Toggle" && inconsistentOptionNames.length) {
    throw new Error(
      makeValidationErrorMessage(
        "Cannot merge products with inconsistent options in 'Toggle' mode",
        inconsistentOptionNames
      )
    );
  }

  if (!mappings.length && inconsistentOptionNames.length) {
    throw new Error(
      makeValidationErrorMessage(
        "Cannot merge products with inconsistent options in 'Combine' mode without mappings",
        inconsistentOptionNames
      )
    );
  }

  // Check if mappings account for the inconsistencies
  const unmappedInconsistencies = inconsistentOptionNames.filter(
    (name) => !mappings.some((mapping) => Object.values(mapping).includes(name))
  );

  if (unmappedInconsistencies.length > 0) {
    throw new Error(
      makeValidationErrorMessage(
        "Inconsistent options not accounted for in mappings",
        unmappedInconsistencies
      )
    );
  }

  return true;
}

/**
 * Merges options across products based on the specified merge mode and mappings.
 * @param products - The list of products.
 * @param optionConfigs - Configuration for option rendering.
 * @param mergeMode - The current merge mode of the operation.
 * @param mappings - The mappings for option values.
 * @returns - The merged list of product options.
 */
export function mergeOptions(
  products: Product[],
  optionConfigs: OptionRenderingField[],
  mergeMode: MergeMode | undefined,
  mappings: KeyValue[]
): ProductOption[] {
  if (products.length === 1) return products[0].options;

  validateOptions(products, mergeMode, mappings);
  const options = flattenOptions(products);

  // For "Toggle" mode, insert the merge option
  if (mergeMode === "Toggle") {
    const mergeOptionValues = products.map((p) => ({
      value: p[MERGE_OPTION_FIELD],
    }));
    const optionsWithMerge = insertMergeOption(
      options,
      optionConfigs,
      mergeOptionValues
    );
    return optionsWithMerge;
  }

  if (!mappings.length) return options;
  const mappedOptions = getMappedOptions(options, mappings);
  return mappedOptions;
}
