/* eslint-disable react/no-array-index-key */
import { Children, FC, PropsWithChildren } from "react";
import { CardCarousel, Grid } from "@components";
import classNames from "classnames";
import { ProductTileContextProvider } from "src/lib/context/ProductTileContext";
import { useVerticalSpacing } from "src/lib/context/VerticalSpacingContext";
import { addHeapViewGridSection } from "src/lib/services/elevar/events";
import { getIsInitiallyVisible } from "src/lib/util/getIsInitiallyVisible";

import {
  ICarouselConfigFields,
  IGrid,
  IGridConfigFields,
  IGridFields,
  ITextOverlayMedia,
} from "../../../../../../@types/generated/contentful";
import { useRunOnVisibleOnce } from "../../../../../hooks/useIntersectionObserver";
import { useOptionalProductContext } from "../../../../context/ProductContext";
import { AnalyticsProvider } from "../../../../hooks/useAnalytics";
import { formatOptionImages } from "../../../../queries/data/productData";
import { getOptionImage } from "../../../../util/getOptionImage";
import { Media } from "../../../Media";
import { CardType, ProductCardType, RenderSectionsProps } from "../../types";

interface GridSaddleProps {
  content: IGrid;
}
export type GridSaddleChildren = CardType | ITextOverlayMedia | ProductCardType;

export function useOptionImageOverride(item: GridSaddleChildren) {
  const productContext = useOptionalProductContext();
  const itemWithOverride = { ...item } as GridSaddleChildren;

  if (
    productContext &&
    "image" in itemWithOverride.fields &&
    "optionImages" in itemWithOverride.fields &&
    itemWithOverride.fields.optionImages?.length
  ) {
    const {
      product: { variants },
      selectedOptions,
    } = productContext;
    const rawAssets = itemWithOverride.fields.optionImages;

    const optionLength = Object.keys(selectedOptions).length;
    const formattedOptionImages = formatOptionImages(
      optionLength,
      variants,
      rawAssets,
      false
    );
    const matchingImage = getOptionImage(
      formattedOptionImages,
      selectedOptions
    );
    const matchingAsset =
      matchingImage &&
      rawAssets.find(
        (asset) => asset.fields.file.url === matchingImage.image.src
      );

    // only override the image if we find a match
    if (matchingAsset) itemWithOverride.fields.image = matchingAsset;
    return itemWithOverride;
  }

  return item;
}

export function GridChild({
  item,
  rsc: RenderSectionsComponent,
}: {
  item: IGridFields["items"][number];
  rsc: React.FC<RenderSectionsProps>;
}) {
  const itemWithLayout = item as GridSaddleChildren;
  itemWithLayout.fields = { ...itemWithLayout.fields };
  const typedItem = useOptionImageOverride(itemWithLayout);

  return (
    <RenderSectionsComponent
      sections={[typedItem]}
      disableSectionStyling
      isNested
    />
  );
}

export function GridSaddle({
  content: {
    sys: { id },
    fields: { name, title, carouselConfig: config },
  },
  children,
}: PropsWithChildren<GridSaddleProps>) {
  const { hasVerticalSpacing } = useVerticalSpacing();
  const ref = useRunOnVisibleOnce(() => {
    // Don't track the grid section if it's a carousel
    if (config?.sys.contentType.sys.id !== "carouselConfig") {
      addHeapViewGridSection(name, id);
    }
  });
  switch (config?.sys.contentType.sys.id) {
    case "carouselConfig": {
      const configFields = config?.fields as ICarouselConfigFields;
      const sharedCarouselConfig = {
        title: {
          value: title || "",
          underline: Boolean(configFields?.underlineCarouselTitle),
        },
        showPagination: Boolean(configFields?.showCarouselPaginationButtons),
        showProgressBar: Boolean(configFields?.showCarouselScrollBar),
        analyticsName: name,
      };
      const mobilePeekPercentage = configFields?.mobilePeekPercentage ?? 20;
      const tabletPeekPercentage = configFields?.tabletPeekPercentage ?? 20;
      const desktopPeekPercentage = configFields?.desktopPeekPercentage ?? 20;
      return (
        <ProductTileContextProvider value={{ productListSectionTitle: title }}>
          <div
            className={classNames("w-full mx-auto max-w-screen-king", {
              "py-8 lg:py-14": hasVerticalSpacing,
            })}
          >
            <Media lessThan="md">
              <CardCarousel
                {...sharedCarouselConfig}
                cardsToShow={configFields?.numberOfMobileCarouselCards}
                peekPercentage={mobilePeekPercentage}
              >
                {Children.map(children, (child, index) => (
                  <ProductTileContextProvider
                    value={{
                      tileIndex: index,
                      visibleOnPageload: getIsInitiallyVisible(
                        configFields?.numberOfMobileCarouselCards,
                        index,
                        Boolean(mobilePeekPercentage)
                      ),
                    }}
                  >
                    {child}
                  </ProductTileContextProvider>
                ))}
              </CardCarousel>
            </Media>
            <Media lessThan="lg" greaterThanOrEqual="md">
              <CardCarousel
                {...sharedCarouselConfig}
                cardsToShow={configFields?.numberOfTabletCarouselCards}
                peekPercentage={tabletPeekPercentage}
              >
                {Children.map(children, (child, index) => (
                  <ProductTileContextProvider
                    key={index}
                    value={{
                      tileIndex: index,
                      visibleOnPageload: getIsInitiallyVisible(
                        configFields?.numberOfTabletCarouselCards,
                        index,
                        Boolean(tabletPeekPercentage)
                      ),
                    }}
                  >
                    {child}
                  </ProductTileContextProvider>
                ))}
              </CardCarousel>
            </Media>
            <Media greaterThanOrEqual="lg">
              <CardCarousel
                {...sharedCarouselConfig}
                cardsToShow={configFields?.numberOfDesktopCarouselCards}
                peekPercentage={desktopPeekPercentage}
              >
                {Children.map(children, (child, index) => (
                  <ProductTileContextProvider
                    key={index}
                    value={{
                      tileIndex: index,
                      visibleOnPageload: getIsInitiallyVisible(
                        configFields?.numberOfDesktopCarouselCards,
                        index,
                        Boolean(desktopPeekPercentage)
                      ),
                    }}
                  >
                    {child}
                  </ProductTileContextProvider>
                ))}
              </CardCarousel>
            </Media>
          </div>
        </ProductTileContextProvider>
      );
    }
    case "gridConfig": {
      const configFields = config?.fields as IGridConfigFields;
      const { mobileColumns, tabletColumns, desktopColumns, underlineTitle } =
        configFields;
      return (
        <ProductTileContextProvider value={{ productListSectionTitle: title }}>
          <div
            ref={ref}
            key={id}
            className="relative p-6 mx-auto max-w-screen-king full:p-8"
          >
            <Grid
              underline={underlineTitle}
              title={title}
              columns={{
                mobile: mobileColumns,
                tablet: tabletColumns,
                desktop: desktopColumns,
              }}
            >
              {Children.map(children, (child, index) => (
                <ProductTileContextProvider
                  key={index}
                  value={{
                    tileIndex: index,
                    visibleOnPageload: "yes",
                  }}
                >
                  {child}
                </ProductTileContextProvider>
              ))}
            </Grid>
          </div>
        </ProductTileContextProvider>
      );
    }
    default:
      return (
        <ProductTileContextProvider value={{ productListSectionTitle: title }}>
          <div
            key={id}
            className="relative px-4 mx-auto mb-12 max-w-screen-king lg:px-8"
          >
            <Grid>
              {Children.map(children, (child, index) => (
                <ProductTileContextProvider
                  key={index}
                  value={{
                    tileIndex: index,
                    visibleOnPageload: "yes",
                  }}
                >
                  {child}
                </ProductTileContextProvider>
              ))}
            </Grid>
          </div>
        </ProductTileContextProvider>
      );
  }
}

export function GridSaddleWrapper({
  content,
  RenderSectionsComponent,
}: GridSaddleProps & { RenderSectionsComponent: FC<RenderSectionsProps> }) {
  const typedContent = content as IGrid;
  const {
    fields: {
      name,
      desktopItems,
      items: baseItems,
      tabletItems,
      carouselConfig,
    },
    sys: {
      id,
      contentType: {
        sys: { id: type },
      },
    },
  } = typedContent;

  const carouselType = `${type}${
    carouselConfig?.sys.contentType.sys.id ? "-carousel" : ""
  }`;
  if (!desktopItems && !tabletItems) {
    return (
      <AnalyticsProvider object={{ id, name, type: carouselType }}>
        <GridSaddle content={typedContent}>
          {baseItems.map((item, index) => (
            <GridChild
              key={`${item.sys.id}-${index}`}
              item={item}
              rsc={RenderSectionsComponent}
            />
          ))}
        </GridSaddle>
      </AnalyticsProvider>
    );
  }
  return (
    <AnalyticsProvider object={{ id, name, type: carouselType }}>
      <Media lessThan={tabletItems ? "sm" : "lg"}>
        <GridSaddle content={typedContent}>
          {baseItems.map((item, index) => (
            <GridChild
              key={`${item.sys.id}-mobile-${index}`}
              item={item}
              rsc={RenderSectionsComponent}
            />
          ))}
        </GridSaddle>
      </Media>
      {tabletItems ? (
        <Media
          greaterThanOrEqual="sm"
          lessThan={desktopItems ? "lg" : undefined}
        >
          <GridSaddle content={typedContent}>
            {tabletItems.map((item, index) => (
              <GridChild
                key={`${item.sys.id}-tablet-${index}`}
                item={item}
                rsc={RenderSectionsComponent}
              />
            ))}
          </GridSaddle>
        </Media>
      ) : null}
      {desktopItems ? (
        <Media greaterThanOrEqual="lg">
          <GridSaddle content={typedContent}>
            {desktopItems.map((item, index) => (
              <GridChild
                key={`${item.sys.id}-desktop-${index}`}
                item={item}
                rsc={RenderSectionsComponent}
              />
            ))}
          </GridSaddle>
        </Media>
      ) : null}
    </AnalyticsProvider>
  );
}
