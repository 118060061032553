/* eslint-disable import/prefer-default-export */
import * as React from "react";

export interface BadgeProps {
  /** The value shown in the badge */
  value: number;
  /** The aria-label to be passed onto the badge */
  ariaLabel?: string;
}

/**
 * A numeric badge floating in the top right corner of its child
 */
export function Badge({
  children,
  value,
  ariaLabel,
}: React.PropsWithChildren<BadgeProps>) {
  return (
    <div className="relative">
      <div
        aria-label={ariaLabel}
        className="absolute top-0 right-0 flex items-center justify-center w-4 h-4 text-white translate-x-full -translate-y-full rounded-full text-tiny bg-flint"
      >
        {value}
      </div>
      {children}
    </div>
  );
}
