import SearchAPI from "../../services/search/api";
import {
  ISearchResults,
  ISearchSuggestions,
} from "../../services/search/types";
import {
  makeQueryFn,
  QueryClientPlugin,
} from "../../services/util/makeQueryClient";
import type { ClientDataServices } from "../client";

export interface IAutocompleteWithSuggestions {
  searchTerm: string;
  suggestions: ISearchSuggestions;
  autocomplete: ISearchResults;
}

export interface SearchQueryFns {
  search: SearchAPI["fetchSearchResults"];
  suggestions: SearchAPI["fetchSuggestions"];
  autocomplete: SearchAPI["fetchAutocompletions"];
  autocompleteWithSuggestions: (
    value: string
  ) => Promise<IAutocompleteWithSuggestions>;
}

const searchPlugin: QueryClientPlugin<ClientDataServices> = (
  queryClient,
  { searchAPI }
) => {
  queryClient.setQueryDefaults(["search"], {
    queryFn: makeQueryFn(searchAPI.fetchSearchResults),
    getNextPageParam: ({ pagination: { nextPage } }: ISearchResults) =>
      nextPage || false,
    getPreviousPageParam: ({ pagination: { previousPage } }: ISearchResults) =>
      previousPage || false,
  });

  queryClient.setQueryDefaults(["autocomplete"], {
    queryFn: makeQueryFn(searchAPI.fetchAutocompletions),
  });

  queryClient.setQueryDefaults(["suggestions"], {
    queryFn: makeQueryFn(searchAPI.fetchSuggestions),
  });

  queryClient.setQueryDefaults(["autocompleteWithSuggestions"], {
    queryFn: makeQueryFn(async (value) => {
      const suggestions = (await queryClient.fetchQuery([
        "suggestions",
        value,
      ])) as ISearchSuggestions;
      const searchTerm =
        suggestions?.suggested?.text ??
        suggestions?.["corrected-query"] ??
        value;

      const autocomplete = (await queryClient.fetchQuery([
        "autocomplete",
        searchTerm,
        value,
      ])) as ISearchResults;

      return {
        searchTerm,
        suggestions,
        autocomplete,
      };
    }),
  });
};

export default searchPlugin;
