/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import * as React from "react";

export type ImageProps = {
  /** the location of the image file */
  src: string;

  /** text describing the visual content of the image */
  alt?: string;

  /** the render height of the image */
  height?: string | number;

  /** the render width of the image */
  width?: string | number;

  /** any classes to add to the image element */
  className?: string;

  /** whether the image is loaded first */
  priority?: boolean;

  /** testing id */
  "data-testid"?: string;

  /** how does image fit into parent div */
  objectFit?: "cover" | "contain" | undefined;
};

function StripPropsImgWrapper<T extends ImageProps>({
  src,
  alt,
  height,
  width,
  className,
  ...props
}: T) {
  return (
    <img
      src={src}
      alt={alt}
      height={height}
      width={width}
      className={className}
      data-testid={props["data-testid"]}
    />
  );
}

export const ImageContext =
  React.createContext<React.ElementType<ImageProps>>(StripPropsImgWrapper);
