/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Experience } from "@ninetailed/experience.js-react";
import { ExperienceMapper } from "@ninetailed/experience.js-utils-contentful";

import { LinkItemProps } from "../Navigation/types";
import { RichText } from "../RichText";
import { SiteBanner } from "../SiteBanner";
import { SiteBannerProps } from "../SiteBanner/SiteBanner";

import { TrimmedBannerSet } from "./types";

interface BannerProps {
  bannerSet?: TrimmedBannerSet | null;
  navLinks?: LinkItemProps[];
}

function mapBannerSetToSiteBannerProps(
  bannerSet: TrimmedBannerSet,
  navLinks?: LinkItemProps[]
): React.PropsWithChildren<SiteBannerProps> & { id: string } {
  const { fields: banner, sys } = bannerSet;
  const {
    backgroundColor,
    textColor,
    banners,
    carouselAutoplay,
    carouselDelay,
  } = banner;

  return {
    id: sys.id,
    bgColor: backgroundColor,
    color: textColor,
    carouselAutoplay,
    carouselDelay,
    navLinks,
    children:
      banners &&
      banners.map((bannerEntry) => (
        <RichText
          key={bannerEntry.sys.id}
          richTextResponse={bannerEntry.fields.text}
        />
      )),
  };
}

/**
Renders section content received from CMS
 */
export function RenderSiteBanner({ navLinks, bannerSet }: BannerProps) {
  if (bannerSet) {
    const { fields: banner } = bannerSet;
    const experiences = banner?.nt_experiences ?? [];

    const props = mapBannerSetToSiteBannerProps(bannerSet, navLinks);

    if (!experiences.length) {
      return (
        <SiteBanner
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      );
    }

    const mappedExperiences = experiences
      .filter((experience) => ExperienceMapper.isExperienceEntry(experience))
      .map((experience) =>
        ExperienceMapper.mapCustomExperience(experience, (variant) => ({
          ...mapBannerSetToSiteBannerProps(
            variant as unknown as TrimmedBannerSet,
            navLinks
          ),
        }))
      );

    return (
      <Experience
        component={SiteBanner}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        experiences={mappedExperiences}
      />
    );
  }

  return null;
}
