/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/prefer-default-export */
import * as React from "react";
import classNames from "classnames";
import {
  A11y,
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
  Swiper as ISwiper,
  SwiperOptions as ISwiperOptions,
} from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { AutoplayOptions, PaginationOptions, SwiperModule } from "swiper/types";

import "swiper/css/effect-fade";

import { Icon } from "../Icon";
import { IconProps } from "../Icon/Icon";

import "swiper/css";

interface PaginationConfigBase {
  clickable?: boolean;
  position?: "center" | "left" | "right";
  verticalAlignment?: "top" | "bottom";
  outside?: boolean;
}

interface StandardPaginationConfig extends PaginationConfigBase {
  overlay?: boolean;
  type: "bullets" | "fraction";
  textLinks?: never;
  useDots?: boolean;
}

interface NoPaginationConfig extends PaginationConfigBase {
  overlay?: never;
  type: undefined;
  textLinks?: never;
}

interface TextPaginationConfig extends PaginationConfigBase {
  overlay?: never;
  type: "text";
  textLinks: string[];
}

type PaginationConfig =
  | StandardPaginationConfig
  | TextPaginationConfig
  | NoPaginationConfig;

const defaultPaginationConfig: PaginationConfig = {
  type: "bullets",
  clickable: true,
  position: "center",
  overlay: true,
};

interface AutoPlayConfig extends AutoplayOptions {
  enabled?: boolean;
  delay?: number;
}

const defaultAutoPlayConfig: AutoPlayConfig = {
  enabled: true,
  delay: 5000,
};

export type CarouselNavigationAlignment = "center" | "top-half";

interface NavigationConfig extends Pick<IconProps, "size"> {
  alignment?: CarouselNavigationAlignment;
  position?: "inside" | "outside";
  spacing?: boolean | undefined;
  className?: string;
}

interface CarouselProps {
  /** Whether touch/dragging is allowed to move the slides */
  allowTouchMove?: boolean;
  /** If slides should scroll automatically, config for autoplay */
  autoplay?: AutoPlayConfig;
  /** Specific options to use at indicated viewport widths */
  breakpoints?: ISwiperOptions["breakpoints"];
  /** Index number of initial slide */
  initialSlide?: number;
  /** Enables loop mode */
  loop?: boolean;
  /** Whether navigation buttons are enabled */
  navigation?: boolean | NavigationConfig;
  /** callback for when swiper is initialized */
  onInit?: (swiper: ISwiper) => void;
  /** Event will be fired when currently active slide is changed */
  onSlideChange?: (swiper: ISwiper) => void;
  /** Config for pagination */
  pagination?: PaginationConfig;
  /** whether to observe the slide contents */
  shouldObserve?: boolean;
  /** Whether to use lazy loading or not */
  priority?: boolean;
  /** The number of slides to show at any time */
  slidesPerView?: number;
  /** Distance between slides in px */
  spaceBetween?: number;
  /** Whether to prevent the default handling of touch start events */
  touchStartPreventDefault?: boolean;
  /** Any additional classes to pass to the carousel */
  className?: string;
  /** Flex alignment for the cards */
  alignSlides?: "start" | "end" | "center" | "baseline" | "stretch";
  /** Add additional classes to the carousel content container */
  slidingContentContainerClassName?: string;
  /** Enable Fade transition */
  enableFade?: boolean;
  /** Optional test id */
  dataTestId?: string;
}

function CustomPaginationLink({
  children,
  index,
  isActive,
  loop,
}: React.PropsWithChildren<{
  index: number;
  isActive: boolean;
  loop: boolean;
}>) {
  const swiper = useSwiper();
  return (
    <button
      data-testid={`carousel-pagination-link-${index}`}
      className={classNames("px-4 text-xs cursor-pointer", {
        "text-flint": !isActive,
      })}
      onClick={() => (loop ? swiper.slideToLoop(index) : swiper.slideTo(index))}
      type="button"
    >
      {children}
    </button>
  );
}

/**
 * A carousel component used to show images horizontally
 */
export function Carousel({
  alignSlides = "start",
  allowTouchMove = true,
  autoplay = defaultAutoPlayConfig,
  breakpoints,
  children,
  className,
  initialSlide = 0,
  loop = true,
  navigation = true,
  onInit,
  onSlideChange,
  pagination = defaultPaginationConfig,
  priority,
  slidesPerView = 1,
  shouldObserve = false,
  spaceBetween = 0,
  touchStartPreventDefault,
  slidingContentContainerClassName,
  enableFade = false,
  dataTestId,
}: React.PropsWithChildren<CarouselProps>) {
  // Use state not refs in order to trigger a Swiper rerender
  // @see https://github.com/nolimits4web/swiper/issues/3855
  const [nextRef, setNextRef] = React.useState<HTMLElement | null>(null);
  const [prevRef, setPrevRef] = React.useState<HTMLElement | null>(null);
  const [swiperRef, setSwiperRef] = React.useState<ISwiper>();
  const [activeIndex, setActiveIndex] = React.useState<number>(initialSlide);
  const [isMounted, setIsMounted] = React.useState(false);

  /** bullet track width, in % of the total carousel width */
  const bulletTrackWidth = 60;
  /** each bullet width as a fraction of the total */
  const bulletWidth = `${bulletTrackWidth / React.Children.count(children)}%`;
  const bulletStyles =
    pagination?.type === "bullets"
      ? ({
          "--swiper-bullet-width": bulletWidth,
        } as React.CSSProperties)
      : undefined;

  // respond to external slide changes
  React.useEffect(() => {
    if (swiperRef) {
      if (loop) {
        swiperRef.slideToLoop(initialSlide, 0);
      } else {
        swiperRef.slideTo(initialSlide, 0);
      }
    }
  }, [initialSlide, swiperRef, loop]);

  // unset the navigation button refs when `navigation` is false
  React.useEffect(() => {
    if (navigation) return;

    setNextRef(null);
    setPrevRef(null);
  }, [navigation]);

  // only fire slide change events after mounting
  React.useEffect(() => {
    if (isMounted) return;

    setIsMounted(true);
  }, [activeIndex, isMounted]);

  const navOptions = {
    alignment: typeof navigation === "object" ? navigation.alignment : "center",
    position: typeof navigation === "object" ? navigation.position : "inside",
    spacing: typeof navigation === "object" ? navigation.spacing : false,
    className: typeof navigation === "object" ? navigation.className : "",
    size: typeof navigation === "object" ? navigation.size : "initial",
  };

  // Module docs: https://swiperjs.com/swiper-api#modules
  const swiperModules: SwiperModule[] = [
    A11y,
    Navigation,
    ...(autoplay.enabled ? [Autoplay] : []),
    ...(pagination.type ? [Pagination] : []),
    ...(enableFade ? [EffectFade] : []),
  ];

  const handleSwiperInit = (swiper: ISwiper) => {
    onInit?.(swiper);
    setSwiperRef(swiper);
  };

  const handleContentChange = (swiper: ISwiper) => {
    if (loop) {
      swiper.slideToLoop(0, 0);
    } else {
      swiper.slideTo(0, 0);
    }
  };

  const handleSlideChange = (swiper: ISwiper) => {
    setActiveIndex(swiper.realIndex);

    if (isMounted && onSlideChange) onSlideChange(swiper);
  };

  const alignmentClasses = {
    center: "swiper-items-center",
    start: "swiper-items-start",
    end: "swiper-items-end",
    baseline: "swiper-items-baseline",
    stretch: "swiper-items-stretch",
  };

  return (
    <div
      className={classNames(
        "relative flex items-center",
        alignmentClasses[alignSlides],
        className
      )}
      data-testid={dataTestId}
    >
      <Swiper
        a11y={{ enabled: true }}
        allowTouchMove={allowTouchMove}
        autoplay={autoplay.enabled ? autoplay : undefined}
        breakpoints={breakpoints}
        className={classNames(
          "flex flex-wrap ",
          slidingContentContainerClassName
        )}
        initialSlide={initialSlide}
        lazy={!priority}
        loop={loop}
        modules={swiperModules}
        effect={enableFade ? "fade" : "slide"}
        fadeEffect={enableFade ? { crossFade: true } : undefined}
        navigation={
          navigation && {
            nextEl: nextRef,
            prevEl: prevRef,
          }
        }
        observer={shouldObserve}
        onObserverUpdate={handleContentChange}
        onSwiper={handleSwiperInit}
        onSlideChange={handleSlideChange}
        pagination={
          pagination.type
            ? ({
                horizontalClass: classNames(
                  {
                    "swiper-pagination-horizontal":
                      pagination.position === "center",
                    [`swiper-pagination-${pagination.position}`]:
                      pagination.position !== "center",
                  },
                  pagination.outside && "swiper-pagination-outside",
                  `swiper-pagination-${
                    pagination.verticalAlignment || "bottom"
                  }`,
                  {
                    "swiper-pagination-overlay": pagination.overlay,
                    "swiper-pagination-lines":
                      pagination.type === "bullets" && !pagination.useDots,
                  }
                ),
                bulletClass: "swiper-pagination-bullet",
                ...pagination,
              } as PaginationOptions)
            : false
        }
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        threshold={15}
        style={bulletStyles}
        touchStartPreventDefault={touchStartPreventDefault}
        watchSlidesProgress /* @see https://github.com/nolimits4web/swiper/issues/5524#issuecomment-1131486477 */
      >
        {pagination?.type === "text" && (
          <div className="absolute bottom-0 left-0 right-0 z-10 flex flex-row items-center justify-center">
            {pagination.textLinks?.map((text, i) => (
              <CustomPaginationLink
                key={text}
                index={i}
                isActive={i === activeIndex}
                loop={loop}
              >
                {text}
              </CustomPaginationLink>
            ))}
          </div>
        )}
        {React.Children.map(children, (child, index) => (
          <SwiperSlide key={index}>{child}</SwiperSlide>
        ))}
      </Swiper>
      {navigation && (
        <>
          <button
            // TODO: localize this
            aria-label="Next Slide"
            className={classNames(
              "swiper-custom-navigation-next disabled:hidden",
              `swiper-custom-navigation-${navOptions.position}`,
              `swiper-custom-navigation-${navOptions.alignment}`,
              navOptions.className,
              navOptions.spacing && "pl-8"
            )}
            disabled={
              !loop && activeIndex === React.Children.count(children) - 1
            }
            ref={(el) => el && !nextRef && setNextRef(el)}
            type="button"
          >
            <Icon name="chevron-right" size={navOptions.size} />
          </button>
          <button
            // TODO: localize this
            aria-label="Previous Slide"
            className={classNames(
              "swiper-custom-navigation-prev disabled:hidden",
              `swiper-custom-navigation-${navOptions.position}`,
              `swiper-custom-navigation-${navOptions.alignment}`,
              navOptions.className,
              navOptions.spacing && "pr-8"
            )}
            disabled={!loop && activeIndex === 0}
            ref={(el) => el && !prevRef && setPrevRef(el)}
            type="button"
          >
            <Icon name="chevron-left" size={navOptions.size} />
          </button>
        </>
      )}
    </div>
  );
}
