import { useContext } from "react";
import {
  Button,
  ImageContext,
  LinkContext,
  QuantityInput,
  Swatch,
} from "@components";
import classNames from "classnames";

import type { MergedOptionValue } from "../../../services/util/optionRendering";
import { ImageData } from "../../util";
import selectors from "../selectors";

export interface LineItemProps {
  title: string;
  href: string;
  image: ImageData;
  primaryOptionValue?: string;
  price: string;
  quantity?: number;
  optionValues: MergedOptionValue[];
  shippingMessage?: string | null;
  onQuantityChange?: (quantity: number) => void;
  onAdd?: () => void;
  onRemove?: () => void;
  onClick?: () => void;
  useSquareImageOnMobile?: boolean;
}

export function LineItem({
  title,
  href,
  image,
  primaryOptionValue,
  price,
  quantity,
  optionValues,
  shippingMessage,
  onQuantityChange,
  onAdd,
  onRemove,
  onClick,
  useSquareImageOnMobile,
}: LineItemProps) {
  const ImageElement = useContext(ImageContext);
  const Link = useContext(LinkContext);

  return (
    <div
      className={classNames(
        "flex overflow-hidden leading-snug gap-4",
        "rounded-2xl bg-[#fcfcfc] pr-4 min-h-[140px]"
      )}
      data-testid={selectors.lineItem}
    >
      {/* Product thumbnail */}
      <Link
        href={href}
        className={classNames({
          "w-[100px] h-[100px] xl:w-[140px] xl:h-auto": useSquareImageOnMobile,
          "w-[140px]": !useSquareImageOnMobile,
        })}
        data-testid={selectors.lineItemImage}
        onClick={onClick}
      >
        <ImageElement
          src={image.src}
          alt={image.alt}
          className="relative h-full"
        />
      </Link>

      {/* Product Info box */}
      <div className="flex flex-col flex-1 gap-3 py-3 text-xs">
        <div className="grid gap-1">
          <div className="flex items-center justify-between gap-1">
            <Link
              href={href}
              data-testid={selectors.lineItemTitle}
              onClick={onClick}
            >
              <p>{title}</p>
            </Link>

            <span>{price}</span>
          </div>

          {primaryOptionValue && (
            <p className="font-normal text-slate">{primaryOptionValue}</p>
          )}

          {shippingMessage && (
            <p className="leading-[18px] text-flint text-2xs">
              {shippingMessage}
            </p>
          )}
        </div>

        {!!optionValues.length && (
          <ul className="flex flex-wrap gap-2">
            {optionValues.map((option) => (
              <li key={option.value} className="flex items-center gap-2">
                {option.image && (
                  <Swatch
                    image={option.image.src}
                    size="xs"
                    borderBehavior="always"
                    alt={option.image.alt}
                  />
                )}
                {option.value}
              </li>
            ))}
          </ul>
        )}

        {/* Actions */}
        <div
          className={classNames("flex mt-auto items-center", {
            "justify-between": onQuantityChange,
            "justify-end": !onQuantityChange,
          })}
        >
          {quantity && onQuantityChange && (
            <span data-testid={selectors.quantityContainer}>
              <QuantityInput onChange={onQuantityChange} value={quantity} />
            </span>
          )}

          {onAdd && (
            <Button
              className="text-xs py-0.5 !mb-0 !text-slate leading-snug"
              variant="underline"
              data-testid={selectors.addButton}
              type="button"
              onClick={onAdd}
            >
              Add to Cart
            </Button>
          )}

          {onRemove && (
            <Button
              className="text-xs py-0.5 !mb-0 !text-slate leading-snug"
              variant="underline"
              data-testid={selectors.removeButton}
              type="button"
              onClick={onRemove}
            >
              Remove
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
