/* istanbul ignore file */
/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { motion } from "framer-motion";

interface AnimateVisibleProps {
  /** Whether to fade in when it become visible on screen */
  fadeIn?: boolean;
  /** Whether to animate upwards when it becomes visible on screen */
  animateUp?: boolean;
  /** Optional classes */
  className?: string;
}

export function AnimateVisible({
  fadeIn = false,
  animateUp = false,
  children,
  className,
}: React.PropsWithChildren<AnimateVisibleProps>) {
  return (
    <motion.div
      initial={{ opacity: fadeIn ? 0 : 1, y: animateUp ? 10 : 0 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, delay: 0.1 }}
      viewport={{ once: true }}
      className={className}
    >
      {children}
    </motion.div>
  );
}
