const selectors = {
  SearchPane: {
    container: "div.search-pane",
    resultsContainer: "div.search-pane--results",
    emptyStateMessage: "div.search-pane--results--empty",
    errorStateMessage: "div.search-pane--results--error",
  },
};

export default selectors;
