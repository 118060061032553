const selectors = {
  h1: "h1-richtext",
  h2: "h2-richtext",
  h3: "h3-richtext",
  h4: "h4-richtext",
  h5: "h5-richtext",
  h6: "h6-richtext",
  img: "image-richtext",
  p: "paragraph-richtext",
  hyperlink: "hyperlink-context",
  entryLink: "entry-link-component",
};
export default selectors;
