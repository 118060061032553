import { getShopifyId } from "../services/util/shopifyID";

export default function makeProductUrl(
  handle: string,
  variantId?: string | number
) {
  return variantId
    ? `/products/${handle}?variant=${getShopifyId(variantId)}`
    : `/products/${handle}`;
}
