import { AnimateVisible } from "@components";

import { ISplitTextSection } from "../../../../../@types/generated/contentful";
import { RichText, SplitTextSection } from "../..";
import { PromoteH1Type } from "../types";

interface Props {
  content: ISplitTextSection & PromoteH1Type;
}

export default function SplitTextSectionSaddle({ content }: Props) {
  const { promoteH1 = false } = content;
  const {
    title,
    leftColumn,
    rightColumn,
    backgroundColor,
    showSeparator,
    contentRatio,
  } = content.fields;
  const columns = [leftColumn, rightColumn].map((column, index) => ({
    id: `${content.sys.id}-column-${index}`,
    content: <RichText richTextResponse={column} />,
  }));
  return (
    <AnimateVisible fadeIn animateUp key={content.sys.id}>
      <SplitTextSection
        title={title}
        columns={columns}
        background={backgroundColor}
        showSeparator={showSeparator}
        contentRatio={contentRatio}
        promoteH1={promoteH1}
      />
    </AnimateVisible>
  );
}
