/* eslint-disable import/prefer-default-export */
import * as React from "react";

import { ContextLinkProps } from "../../../components/Link/Link";
import { SiteLink } from "../SiteLink";

import selectors from "./selectors";

export function NavLink(props: React.PropsWithChildren<ContextLinkProps>) {
  return (
    <SiteLink
      data-testid={selectors.container}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      data-event-category="nav click"
    />
  );
}
