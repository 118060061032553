/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */

import type { NextRouter } from "next/router";

interface IPathTranslation {
  [locale: string]: string;
}

export const pathTranslations: IPathTranslation[] = [
  {
    "en-US": "/",
    "fr-CA": "/fr-CA/",
  },
  {
    "en-US": "/about",
    "fr-CA": "/fr-CA/a-propos",
  },
  {
    "en-US": "/collections",
    "fr-CA": "/fr-CA/encaissement",
  },
];

interface IKeyedTranslations {
  [localizedPath: string]: IPathTranslation;
}

let translationCache: IKeyedTranslations;

export function getTranslations(
  translations: IPathTranslation[] = pathTranslations,
  ignoreCache = false
): IKeyedTranslations {
  if (translationCache && !ignoreCache) {
    return translationCache;
  }
  const result = Object.fromEntries(
    translations.flatMap((translation) =>
      Object.values(translation).map((path) => [path, translation])
    )
  );
  if (!ignoreCache) {
    translationCache = result;
  }
  return result;
}

/**
 * Tries to find a path translation in the provided map for a given path and
 * locale. If none is found, the function calls itself recursively using an
 * incrementally shortened slice of the path. This behavior enables matching
 * dynamic paths such as '/collections/[slug]'. (Note that it does not handle
 * mixed dynamic/static path patterns such as '/a/[b]/c/[d]').
 *
 * i.e. given '/collections/the-gift-shop' and 'fr-CA', the function will return
 * '/fr-CA/encaissement/the-gift-shop' after one additional recursive call
 */
function findPathTranslation(
  path: string,
  locale: string,
  translations: IKeyedTranslations
): string | undefined {
  if (translations[path]?.[locale]) {
    return translations[path][locale];
  }
  // split on the last slash
  const subpaths = path.split(/\/(?=[^/]*$)/);
  if (subpaths.length > 1) {
    const translation = findPathTranslation(subpaths[0], locale, translations);
    return translation && `${translation}/${subpaths[1]}`;
  }
  return undefined;
}

/**
 * Tries to match the provided path and locale against known translations.
 * Returns the translated path if one is found, otherwise returns the original
 * path.
 */
// TODO: properly handle external paths (or ignore absolute paths altogether) either here or in SiteLink
export function getTranslatedPath(
  path: string,
  {
    defaultLocale = "en-US",
    locale,
  }: Pick<NextRouter, "defaultLocale" | "locale"> = {}
) {
  const { pathname } = new URL(path, "https://thuma.co");
  const reDefaultLocale = new RegExp(`^\\/${defaultLocale}`);
  const translations = getTranslations();
  const translation = findPathTranslation(
    // remove the default locale for matching
    pathname.replace(reDefaultLocale, ""),
    locale ?? defaultLocale,
    translations
  );
  return translation ? path.replace(pathname, translation) : path;
}

/**
 * Returns a path that includes the current locale
 */
export function getLocalizedPathname({
  pathname,
  asPath = pathname,
  defaultLocale,
  locale,
}: IRouterOrUrl) {
  if (asPath.startsWith(`/${locale}`)) {
    // if the path already includes the locale, remove it when
    // locale === defaultLocale, otherwise return the path unchanged
    return locale === defaultLocale ? asPath.replace(`/${locale}`, "") : asPath;
  }
  // if the path does not include the locale, prepend it when
  // locale !== defaultLocale otherwise return the path unchanged
  return locale === defaultLocale ? asPath : `/${locale}${asPath}`;
}

interface IRouterOrUrl
  extends Pick<NextRouter, "defaultLocale" | "locale" | "pathname"> {
  asPath?: NextRouter["asPath"];
}
