import React from "react";
import { useRouter } from "next/router";

import { IAutocompleteWithSuggestions } from "../queries/data/searchData";

import { useTypedQuery } from "./useTypedQuery";

export default function useSearch() {
  const router = useRouter();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const goToSearchResultsPage = (q: string) => {
    // TODO: localize this
    const isOnSearchPage = router.asPath.startsWith("/pages/search");
    return router
      .push(
        {
          // TODO: localize this
          pathname: "/pages/search",
          query: {
            query: q,
          },
        },
        undefined,
        { shallow: isOnSearchPage }
      )
      .then(() => {
        if (isOnSearchPage) setIsSubmitting(false);
      });
  };
  const goToRedirect = (redirect: string) => router.push(redirect);

  const submitSearch = (data: IAutocompleteWithSuggestions) => {
    // autoCompleteData is only ever a string
    // if the search term results in a redirect
    if (data.autocomplete && typeof data.autocomplete === "string") {
      goToRedirect(data.autocomplete);
    } else {
      goToSearchResultsPage(query.trim());
    }
  };

  const { data, isLoading } = useTypedQuery([
    "autocompleteWithSuggestions",
    query.trim(),
  ]);

  React.useEffect(() => {
    if (isSubmitting && !isLoading && query && data) {
      submitSearch(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isSubmitting, query]);

  return {
    query,
    setQuery,
    onSubmit: (value: string) => {
      if (!value) return;
      setQuery(value);
      setIsSubmitting(true);
    },
    isSubmitting,
  };
}
