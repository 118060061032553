/* eslint-disable react/no-unused-prop-types */
import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import * as RadixAccordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import { addHeapAccordionPaneInteracted } from "../../lib/services/elevar/events";
import { Icon } from "../Icon";

import selectors from "./selectors";

interface AccordionData {
  id: string;
  title: string;
  analyticsTitle?: string;
  wasInitiallyHidden?: boolean;
  children: ReactNode;
  /** additional attributes to add to each accordion button */
  dataAttributes?: Record<string, string>;
}

interface AccordionProps {
  /** Name identifying this component for tracking purposes */
  name: string;

  /** the various accordion items */
  items: AccordionData[];

  /** whether to show lines under collapsed accordion items */
  showItemLines?: boolean;

  /** if not using external state, id of the item to expand by default */
  defaultID?: string;

  /** the accordion value controller */
  state?: [string[], Dispatch<SetStateAction<string[]>>];

  /** allow multiple items to be open */
  multiple?: boolean;

  /** whether to animate the opening and closing */
  animated?: boolean;

  /** for single accordions, whether all panes can be collapsed */
  collapsible?: boolean;

  /** control icon classname for specific styling */
  iconClassName?: string;

  /** whether to fire an analytics event when a pane is interacted with */
  trackInteraction?: boolean;

  /** optional onclick, can be used for analytics or anything related to clicking the trigger */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * Fires an event to indicate that an accordion pane has been interacted with.
 * @param name - The name of the accordion.
 * @param items - An array of accordion data items.
 * @param paneId - The optional ID of the pane that was interacted with.
 */
export const fireAccordionInteractedEvent = (
  name: string,
  items: AccordionData[],
  opened: boolean,
  paneId: string
) => {
  const activePaneIndex = items.findIndex((item) => item.id === paneId);
  const activePane = items[activePaneIndex];

  // Only trigger when a pane is found
  if (!paneId || !activePane) return;

  // fire heap event to indicate the newly activated accordion pane
  addHeapAccordionPaneInteracted({
    accordionSectionName: name,
    accordionID: paneId,
    accordionTitle: activePane.analyticsTitle ?? activePane.title,
    accordionPosition: activePaneIndex + 1,
    interactionType: opened ? "opened" : "closed",
    visibleOnPageload: !activePane.wasInitiallyHidden ? "yes" : "no",
  });
};

function AccordionItem({
  item,
  isOpen,
  showItemLines,
  iconClassName,
  onClick,
  animated,
}: {
  item: AccordionData;
  isOpen: boolean;
  showItemLines?: boolean;
  iconClassName?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  animated?: boolean;
}) {
  return (
    <RadixAccordion.Item key={item.id} value={item.id}>
      <RadixAccordion.Header>
        <RadixAccordion.Trigger
          onClick={onClick}
          className={classNames(
            "flex items-center justify-between w-full gap-2 py-4 text-left",
            {
              "border-b border-flint/25": !isOpen && showItemLines,
            }
          )}
        >
          {item.title}

          <Icon className={iconClassName} name={isOpen ? "minus" : "plus"} />
        </RadixAccordion.Trigger>
      </RadixAccordion.Header>
      {animated ? (
        <AnimatePresence>
          <RadixAccordion.Content asChild forceMount>
            <motion.div
              initial={isOpen ? "open" : "closed"}
              animate={isOpen ? "open" : "closed"}
              exit="closed"
              className="overflow-hidden"
              variants={{
                closed: { height: 0, opacity: 0 },
                open: { height: "auto", opacity: 1 },
              }}
              transition={{ duration: 0.3 }}
              key={item.id}
            >
              {item.children}
            </motion.div>
          </RadixAccordion.Content>
        </AnimatePresence>
      ) : (
        <RadixAccordion.Content>{item.children}</RadixAccordion.Content>
      )}
    </RadixAccordion.Item>
  );
}

function SingleAccordion({
  name,
  items,
  showItemLines,
  state,
  defaultID,
  collapsible,
  iconClassName,
  animated,
  trackInteraction,
  onClick,
}: AccordionProps) {
  const internalState = useState(defaultID ? [defaultID] : []);
  const [[activePaneId], setActivePaneIds] = state ?? internalState;

  const handleValueChange = (paneId: string) => {
    setActivePaneIds([paneId]);
    if (trackInteraction)
      fireAccordionInteractedEvent(
        name,
        items,
        !!paneId,
        paneId || activePaneId
      );
  };

  return (
    <RadixAccordion.Root
      type="single"
      data-testid={selectors.singleAccordion}
      defaultValue={defaultID}
      value={activePaneId}
      onValueChange={handleValueChange}
      collapsible={collapsible}
    >
      {items.map((item) => (
        <AccordionItem
          key={item.id}
          item={item}
          isOpen={activePaneId === item.id}
          showItemLines={showItemLines}
          iconClassName={iconClassName}
          onClick={onClick}
          animated={animated}
        />
      ))}
    </RadixAccordion.Root>
  );
}

function MultiAccordion({
  name,
  items,
  showItemLines,
  state,
  defaultID,
  iconClassName,
  animated,
  trackInteraction,
  onClick,
}: AccordionProps) {
  const internalState = useState<string[]>(defaultID ? [defaultID] : []);
  const [activePaneIds, setActivePaneIds] = state ?? internalState;

  const handleValueChange = (paneIds: string[]) => {
    const newlyOpenedPaneId = paneIds.find(
      (paneId) => !activePaneIds.includes(paneId)
    );
    const newlyClosedPaneId = activePaneIds.find(
      (paneId) => !paneIds.includes(paneId)
    );

    // this is just for typescript, we won't ever fall back to the empty string
    // istanbul ignore next
    const paneId = newlyOpenedPaneId || newlyClosedPaneId || "";

    setActivePaneIds(paneIds);
    if (trackInteraction)
      fireAccordionInteractedEvent(name, items, !!newlyOpenedPaneId, paneId);
  };

  return (
    <RadixAccordion.Root
      type="multiple"
      data-testid={selectors.multiAccordion}
      defaultValue={defaultID ? [defaultID] : undefined}
      value={activePaneIds}
      onValueChange={handleValueChange}
    >
      {items.map((item) => (
        <AccordionItem
          key={item.id}
          item={item}
          isOpen={activePaneIds.includes(item.id)}
          showItemLines={showItemLines}
          iconClassName={iconClassName}
          onClick={onClick}
          animated={animated}
        />
      ))}
    </RadixAccordion.Root>
  );
}

/**
 * Displays various expandable and collapsible content blocks
 */
export function AccordionV2({ multiple = false, ...props }: AccordionProps) {
  if (multiple) return <MultiAccordion {...props} />;

  return <SingleAccordion {...props} />;
}
