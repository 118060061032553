import { useEffect, useRef } from "react";
import { QueryClient } from "react-query";
import { Router } from "next/router";

import ZustandCartPersister from "../services/cart/persister";

/**
 * A hook that listens for changes in the router query and
 * pulls in the cart ID if present to update the persister
 */
export default function useCartTransferrer(
  router: Router,
  cartPersister: ZustandCartPersister,
  queryClient: QueryClient
) {
  const invalidatedQueriesRef = useRef(false);
  useEffect(() => {
    const encodedCartId = router.query.cartId;
    if (typeof encodedCartId !== "string") return;

    // we can safely use `atob` here because
    // this hook only runs on the client (browser env)
    const cartId = atob(encodedCartId);
    cartPersister.setCartID(cartId);

    // we need the app to refetch the cart after this happens
    // otherwise the cart will appear empty
    if (invalidatedQueriesRef.current) return;
    queryClient.invalidateQueries(["cart"]);
    invalidatedQueriesRef.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, router.pathname]);
}
