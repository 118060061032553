import type { Mutation } from "react-query";

import {
  addToCartEvent,
  removeFromCartEvent,
} from "../../services/elevar/events";
import type { QueryClientPlugin } from "../../services/util/makeQueryClient";
import type { ClientDataServices } from "../client";

import { CartMutation as CartMutationKeys, CartMutationFns } from "./cartData";

type UnknownMutation = Mutation<unknown, unknown, void, unknown>;

type CartMutation<T extends CartMutationKeys> = Mutation<
  Awaited<ReturnType<CartMutationFns[T]>>,
  unknown,
  Parameters<CartMutationFns[T]>[0]
>;

export function isCartMutation<T extends CartMutationKeys>(
  mutationType: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutation: any
): mutation is CartMutation<T> {
  const key = mutation?.options?.mutationKey;
  return (
    mutation?.state?.status === "success" &&
    ((typeof key === "string" && key === mutationType) ||
      (Array.isArray(key) && key.some((k) => k === mutationType)))
  );
}

export function cartMutationListener(
  mutation:
    | UnknownMutation
    | CartMutation<CartMutationKeys.AddToCart>
    | CartMutation<CartMutationKeys.Remove>
    | CartMutation<CartMutationKeys.Update>
    | undefined
) {
  if (isCartMutation(CartMutationKeys.AddToCart, mutation)) {
    const items = mutation.options.variables;

    if (items?.lineItems?.length) {
      addToCartEvent({
        items: items.lineItems,
        location: items?.location ?? "unknown",
      });
    }
  }
  if (isCartMutation(CartMutationKeys.Remove, mutation)) {
    const items = mutation.options.variables;
    if (items && items.payload.length > 0) {
      removeFromCartEvent({ items: items.payload });
    }
  }
  if (isCartMutation(CartMutationKeys.Update, mutation)) {
    const items = mutation.options.variables?.payload;
    if (items) {
      const addedItems = items.filter(
        ({ lineItem, quantity }) => quantity > lineItem.quantity
      );
      if (addedItems.length > 0) {
        addToCartEvent({ items: addedItems, location: "unknown" });
      }
      const removedItems = items.filter(
        ({ lineItem, quantity }) => quantity < lineItem.quantity
      );
      if (removedItems.length > 0) {
        removeFromCartEvent({ items: removedItems });
      }
    }
  }
}

const AnalyticsPlugin: QueryClientPlugin<ClientDataServices> =
  function AnalyticsPlugin(queryClient) {
    queryClient.getMutationCache().subscribe(cartMutationListener);
  };

export default AnalyticsPlugin;
