import { ComponentProps, useEffect, useMemo, useState } from "react";
import { Hydrate, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ImageContext, LinkContext } from "@components";
import type { AppProps } from "next/app";
import Script from "next/script";
import useOneTrustModal from "src/lib/hooks/useOneTrustModal";

import "swiper/css/navigation";
import "swiper/css/pagination";

import {
  ClientLogger,
  ContentfulSEO,
  GTM,
  Layout,
  SiteImage,
  SiteLink,
} from "../lib/components";
import { Elevar } from "../lib/components/Elevar/Elevar";
import ResetCartOnLocaleChangeProvider from "../lib/components/ResetCartOnLocaleChangeProvider/ResetCartOnLocaleChangeProvider";
import { CrossingMindsProvider } from "../lib/context/CrossingMindsContext";
import FeatureFlagContext from "../lib/context/FeatureFlagContext";
import MarketingServiceContext from "../lib/context/MarketingServiceContext";
import { SessionIdContextProvider } from "../lib/context/SessionIdContext";
import {
  IShopifyCountryCode,
  IShopifyLanguageCode,
} from "../lib/graphql/shopify/client/sdk";
import useCartTransferrer from "../lib/hooks/useCartTransferrer";
import useSyncEventsLocale from "../lib/hooks/useSyncEventsLocale";
import NTProvider from "../lib/providers/NinetailedProvider";
import VercelSpeedInsightsProvider from "../lib/providers/VercelSpeedInsightsProvider";
import clientPlugins from "../lib/queries/client";
import CartAPI from "../lib/services/cart/api";
import ZustandCartPersister from "../lib/services/cart/persister";
import CrossingMindsAPI from "../lib/services/crossingminds/api";
import ZustandMarketingCapturePersister from "../lib/services/marketing-capture/persister";
import MarketingCaptureService from "../lib/services/marketing-capture/service";
import RecommendedProductsAPI from "../lib/services/recommended-products/api";
import ReviewsAPI from "../lib/services/reviews/api";
import SearchAPI from "../lib/services/search/api";
import usePageLoadID from "../lib/services/search/usePageLoadID";
import ClientShopifyService from "../lib/services/shopify/ClientShopifyService";
import type { CommonProps } from "../lib/services/util/compose";
import makeQueryClient from "../lib/services/util/makeQueryClient";
import { usePersistUserAttributionData } from "../lib/services/util/userAttributionStore";
import { CanonicalUrlContext, getBanner } from "../lib/util";
import { LocaleCode, Localized } from "../lib/util/locale";
import localeMap from "../lib/util/localeMap";

import "tailwindcss/tailwind.css";
import "swiper/css";
import "../components/Carousel/Carousel.css";
import "../components/CardCarousel/CardCarousel.css";
import "../lib/components/RichText/RichText.css";
import "../lib/components/ProductForm/ProductForm.css";
import "../lib/assets/css/exceptions.css";
import "../../public/styles/cookie.css";

if (process.env.NEXT_PUBLIC_ENABLE_MSW_IN_BROWSER === "true") {
  import("../mocks").then((mod) => mod.initMocks());
}

function MyApp({
  Component,
  router,
  pageProps,
}: AppProps<CommonProps>): JSX.Element {
  const {
    canonicalUrl,
    contentEnvironment,
    spaceID,
    shopifyConfig,
    shopifyTestConfig,
    yotpoConfig,
    environment,
    gtmConfig,
    origin,
    dehydratedState,
    layoutData,
    siteMetadata,
    pageMetadata,
    reactQueryDevToolsEnabled,
    rebuyConfig,
    rollbarConfig,
    searchSpringConfig,
    ninetailedConfig,
    ninetailed,
    bannerSet,
    crossingMindsConfig,
  }: ComponentProps<typeof Component> = pageProps;

  // augmenting nav props with props on the page
  layoutData.desktopNav.transparentNav = Boolean(pageProps.transparentNav);

  const oneTrustTrigger = useOneTrustModal();

  const [canonical, setCanonical] = useState(canonicalUrl);
  const canonicalContextValue = useMemo(
    () => ({ canonical, setCanonical }),
    [canonical]
  );

  useEffect(() => {
    // If a canonical is provided in page props
    // the page is also likely controlling it at runtime,
    // thus, we do not clear it here
    if (canonicalUrl) return;

    // if no canonical is in page props, clear the state value
    setCanonical(null);
  }, [router.asPath, canonicalUrl]);

  const cartPersister = useMemo(() => new ZustandCartPersister(), []);
  const marketingCapturePersister = useMemo(
    () => new ZustandMarketingCapturePersister(),
    []
  );
  const marketingService = useMemo(
    () => new MarketingCaptureService(marketingCapturePersister),
    [marketingCapturePersister]
  );

  const localizedShopifyService = useMemo(() => {
    const baseConfigObj = {
      shopifyDomain:
        environment === "test_store"
          ? shopifyTestConfig.domain
          : shopifyConfig.domain,
      shopifyStorefrontApiToken:
        environment === "test_store"
          ? shopifyTestConfig.token
          : shopifyConfig.token,
      languageCode: IShopifyLanguageCode.En,
    };

    return (Object.keys(localeMap) as LocaleCode[]).reduce(
      (acc, locale) => ({
        ...acc,
        [locale]: new ClientShopifyService({
          ...baseConfigObj,
          countryCode: localeMap[locale].countryCode as IShopifyCountryCode,
        }),
      }),
      {} as unknown as Localized<ClientShopifyService>
    );
  }, [
    environment,
    shopifyTestConfig.domain,
    shopifyTestConfig.token,
    shopifyConfig.domain,
    shopifyConfig.token,
  ]);

  const localizedCartApi = useMemo(
    () =>
      (Object.keys(localeMap) as LocaleCode[]).reduce(
        (acc, locale) => ({
          ...acc,
          [locale]: new CartAPI(localizedShopifyService[locale], cartPersister),
        }),
        {} as unknown as Localized<CartAPI>
      ),
    [localizedShopifyService, cartPersister]
  );

  const queryClient = useMemo(
    () =>
      makeQueryClient(
        clientPlugins,
        {
          localizedCartApi,
          reviewsAPI: new ReviewsAPI(yotpoConfig.token),
          recommendedProductsAPI: new RecommendedProductsAPI(rebuyConfig.key),
          recommendationsAPI: new CrossingMindsAPI(
            crossingMindsConfig,
            localizedShopifyService[router.locale as LocaleCode]
          ),
          searchAPI: new SearchAPI(searchSpringConfig.siteId),
          marketingService,
        },
        { queries: { staleTime: Infinity } }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      localizedCartApi,
      yotpoConfig.token,
      rebuyConfig.key,
      searchSpringConfig.siteId,
      localizedShopifyService,
      marketingService,
      router.locale,
    ]
  );

  const {
    desktopNav,
    mobileNav,
    footerNav,
    marketingCapture,
    globalBannerSet,
    globalHighlightedSection,
  } = layoutData;

  // find banner to render
  const { banner: globalBanner } = siteMetadata;
  const pageBanner = pageMetadata?.banner;
  const overrideGlobalBanner = pageMetadata?.overrideGlobalBanner;

  const banner = getBanner({
    pageBannerSet: bannerSet,
    pageBanner,
    globalBannerSet,
    globalBanner,
    overrideGlobalBanner,
  });

  useSyncEventsLocale();
  useCartTransferrer(router, cartPersister, queryClient);
  usePersistUserAttributionData(router);
  usePageLoadID(router);

  return (
    <>
      <ClientLogger rollbarConfig={rollbarConfig}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={dehydratedState}>
            <CanonicalUrlContext.Provider value={canonicalContextValue}>
              <ContentfulSEO
                origin={origin}
                pageMetadata={pageMetadata}
                siteMetadata={siteMetadata}
                isStaging={environment === "staging"}
                locale={router.locale}
              />
              {gtmConfig ? <GTM gtmConfig={gtmConfig} /> : null}
              <Elevar />
              <ImageContext.Provider value={SiteImage}>
                <LinkContext.Provider value={SiteLink}>
                  <MarketingServiceContext.Provider value={marketingService}>
                    <ResetCartOnLocaleChangeProvider />
                    <NTProvider
                      environment={ninetailedConfig.environment}
                      token={ninetailedConfig.token}
                      experiments={ninetailed.experiments}
                      audiences={ninetailed.audiences}
                    >
                      <FeatureFlagContext.Provider
                        value={ninetailed.featureFlags}
                      >
                        <VercelSpeedInsightsProvider>
                          <SessionIdContextProvider>
                            <CrossingMindsProvider
                              databaseId={crossingMindsConfig.databaseId || ""}
                              password={crossingMindsConfig.password || ""}
                              serviceLoginId={
                                crossingMindsConfig.serviceLoginId || ""
                              }
                            >
                              <Layout
                                desktopNav={desktopNav}
                                mobileNav={mobileNav}
                                isStaging={environment === "staging"}
                                footerNav={footerNav}
                                environment={contentEnvironment}
                                space={spaceID}
                                marketingCapture={marketingCapture}
                                banner={banner}
                                globalHighlightedSection={
                                  globalHighlightedSection
                                }
                              >
                                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                <Component {...pageProps} />

                                {/* sitewide UI items */}
                                {oneTrustTrigger}
                              </Layout>
                            </CrossingMindsProvider>
                          </SessionIdContextProvider>
                        </VercelSpeedInsightsProvider>
                      </FeatureFlagContext.Provider>
                    </NTProvider>
                  </MarketingServiceContext.Provider>
                </LinkContext.Provider>
              </ImageContext.Provider>
            </CanonicalUrlContext.Provider>
            {reactQueryDevToolsEnabled ? <ReactQueryDevtools /> : null}
          </Hydrate>
        </QueryClientProvider>
      </ClientLogger>

      <Script
        src={`https://cdn-widgetsrepository.yotpo.com/v1/loader/${yotpoConfig.loyaltyGuid}`}
        async
      />
      <Script
        src={`https://cdn.swellrewards.com/loader/${yotpoConfig.loyaltyGuid}.js`}
        async
      />
    </>
  );
}

export default MyApp;
