/* eslint-disable import/prefer-default-export */
import * as React from "react";
import classNames from "classnames";

import { IconMap } from "./IconMap";

export interface IconProps {
  /** Additional classnames to apply to component */
  className?: string;
  /** Id to identify this component in the dom */
  id?: string;
  /** Sets the component's display type to inline */
  inline?: boolean;
  /** A label for accessibility */
  label?: string;
  /** A string that represents the name of an icon */
  name: keyof typeof IconMap;
  /** The hight and width styling preset to use for the SVG element */
  size?: "full" | "inherit" | "initial";
}

const sizingStyles = {
  /** the SVG will fill the available space of its parent */
  full: { height: "100%", width: "100%" },
  /** size is imposed by the font-size of the parent element */
  inherit: { height: "1em", width: "auto" },
  /** the original height and width of the svg element are used */
  initial: undefined,
};

/**
 * This is an icon component, which will select an icon based on the "name" prop passed to it
 */
export function Icon({
  className,
  id,
  inline = true,
  label,
  name,
  size = "initial",
}: IconProps) {
  const Component = IconMap[name];
  const style = sizingStyles[size];
  return (
    <span
      aria-label={label}
      role="img"
      className={classNames(inline ? "inline-block" : "block", className)}
      tabIndex={-1}
      id={id}
    >
      {Component && (
        <Component
          aria-hidden
          data-testid={`${name}-icon`}
          style={style}
          name={name}
        />
      )}
    </span>
  );
}
