/* eslint-disable import/prefer-default-export,react/jsx-props-no-spreading */
import React from "react";
import classNames from "classnames";

import { Button } from "../Button";
import { Icon } from "../Icon";
import { LoadingIndicator } from "../LoadingIndicator";

import selectors from "./selectors";

interface SearchBarProps extends React.HTMLProps<HTMLInputElement> {
  onClear: () => void;
  onSubmit?: () => void;
  isLoading?: boolean;
  sticky?: boolean;
  mobile?: boolean;
}

function SearchBar(
  {
    onClear,
    onSubmit,
    sticky,
    mobile = false,
    isLoading = false,
    ...inputProps
  }: SearchBarProps,
  inputRef: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <div
      className={classNames("flex items-center gap-2 border-b border-b-slate", {
        "bg-white sticky lg:bg-transparent z-20 lg:static": sticky,
        "top-[calc(88px+18.75px+32px)]": sticky && mobile,
        "top-[88px]": sticky && !mobile,
      })}
    >
      <Icon name="search" size="inherit" />
      {/* TODO: refactor text input component to accept ref so it can be used here */}
      <input
        name="search-query"
        ref={inputRef}
        placeholder="Search"
        className="flex-1 p-4 outline-none ring-0 text-input"
        data-testid={selectors.input}
        {...inputProps}
      />
      {isLoading && <LoadingIndicator size="small" />}
      {!isLoading && inputProps.value && (
        <>
          {onClear && (
            <Button
              variant="unstyled"
              className="ml-2"
              onClick={onClear}
              data-testid={selectors.clearButton}
            >
              <Icon name="close" size="inherit" className="text-tiny" />
            </Button>
          )}
          {onSubmit && (
            <Button
              variant="unstyled"
              className="ml-2"
              onClick={onSubmit}
              data-testid={selectors.submitButton}
            >
              <Icon name="arrow-right" size="inherit" className="text-tiny" />
            </Button>
          )}
        </>
      )}
    </div>
  );
}

export default React.forwardRef(SearchBar);
