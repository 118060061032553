import { NextRouter, useRouter } from "next/router";

import { LocaleCode } from "../util/locale";

interface ITypedRouter extends Omit<NextRouter, "locale"> {
  locale: LocaleCode;
}

function useTypedRouter() {
  const router = useRouter();
  return router as ITypedRouter;
}

export default useTypedRouter;
