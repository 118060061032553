/* eslint-disable import/prefer-default-export */
import React from "react";
import {
  Button,
  Heading,
  Icon,
  LinkContext,
  LoadingIndicator,
  TextInput,
} from "@components";
import { useRouter } from "next/router";

import MarketingServiceContext from "../../../context/MarketingServiceContext";
import useTypedMutation from "../../../hooks/useTypedMutation";
import selectors from "../selectors";

interface PhoneFormViewProps {
  onFormComplete: () => void;
}

export function PhoneFormView({ onFormComplete }: PhoneFormViewProps) {
  const [phone, setPhone] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [profileId, setProfileId] = React.useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const marketingService = React.useContext(MarketingServiceContext);
  const { mutate: subscribe } = useTypedMutation(["subscribe"]);
  const router = useRouter();
  const Link = React.useContext(LinkContext);

  React.useEffect(() => {
    const fetchProfileId = async () => {
      const id = await marketingService.getProfileId();
      setProfileId(id);
    };
    fetchProfileId();
  }, [marketingService]);

  const handleChange = (value: string) => {
    setPhone(value);
    setErrorMessage("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    subscribe(
      {
        phoneNumber: phone,
        locale: router.locale || "en-US",
        customSource: "SMS capture form",
        profileId,
      },
      {
        onSuccess: (data) => {
          if (data.profileId) marketingService.setProfileId(data.profileId);
          if (data.phoneNumber) setPhone(data.phoneNumber);
          if (data.message) {
            setErrorMessage(data.message);
            return;
          }

          setErrorMessage("");
          onFormComplete();
        },
        onSettled() {
          setIsSubmitting(false);
        },
      }
    );
  };

  return (
    <div className="flex flex-col items-center">
      <Icon name="thuma-logo" />
      <Heading as="h3" className="mt-5 text-center">
        Stay in the know.
      </Heading>
      <p className="text-center">
        Join the list for news, product releases, & more.
      </p>
      <form className="w-full" onSubmit={handleSubmit}>
        <TextInput
          placeholder="Phone Number"
          inputContainerClasses="mt-5 text-input"
          onChange={(event) => handleChange(event.target.value)}
          disabled={isSubmitting}
          value={phone}
          type="tel"
          name="phone"
          required
          pattern="[0-9\-\+]{0,7}[0-9]{3}-?[0-9]{3}-?[0-9]{4}"
          data-testid={selectors.phoneFormView.input}
        />
        {errorMessage && (
          <p className="mt-1 text-sm text-center text-blush">{errorMessage}</p>
        )}
        <p className="mt-3 leading-4 text-2xs">
          By signing up for texts, you agree to receive automated recurring
          marketing (including cart reminders) and support messages from Thuma.
          You also accept the{" "}
          <Link href="/pages/privacy-statement" className="underline">
            Terms and Privacy
          </Link>
          . Consent is not a condition of purchase. Msg frequency varies. Msg &
          data rates may apply. Text STOP to unsubscribe.
        </p>
        <Button
          type="submit"
          className="w-full mt-5"
          data-testid={selectors.phoneFormView.submit}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <LoadingIndicator size="small" />
          ) : (
            "SIGN UP FOR TEXTS"
          )}
        </Button>
      </form>
    </div>
  );
}
