import React from "react";
import { Heading, ImageContext, LinkContext } from "@components";
import classNames from "classnames";

import { CardItemProps } from "../types";

export function MobileCard({
  type,
  url,
  title,
  subtext,
  image,
  textClassName,
  onClick,
}: CardItemProps) {
  const ImageElement = React.useContext(ImageContext);
  const LinkElement = React.useContext(LinkContext);
  return (
    <LinkElement
      className={classNames("flex flex-col text-black", {
        "focus-visible:outline-offset-0": type !== "stacked",
      })}
      href={url}
      onClick={onClick}
    >
      <ImageElement {...image} alt={image.alt} />
      {title ? (
        <div className={textClassName}>
          <Heading
            as="h5"
            variant="h5"
            className={classNames("text-base", {
              "mt-[19px]": type === "full-bleed" || type === "2-col-grid",
              "mt-[17px]": type === "carousel" || type === "stacked",
            })}
          >
            {title}
          </Heading>
          {subtext ? (
            <span className="leading-normal text-2xs">{subtext}</span>
          ) : null}
        </div>
      ) : null}
    </LinkElement>
  );
}
