/* eslint-disable import/prefer-default-export */
import * as React from "react";
import classNames from "classnames";

export interface OverlayProps {
  /** the image overlay opacity */
  overlayOpacity?: string;

  /** the image overlay top color  */
  overlayColorTop?: string;

  /** the image overlay middle color  */
  overlayColorMiddle?: string;

  /** the image overlay bottom color  */
  overlayColorBottom?: string;

  className?: string;
}

export function Overlay({
  overlayOpacity = "0",
  overlayColorTop = "transparent",
  overlayColorMiddle = "transparent",
  overlayColorBottom = "transparent",
  className,
}: OverlayProps) {
  const linearOverlay = `linear-gradient(${overlayColorTop}, ${overlayColorMiddle}, ${overlayColorBottom})`;
  const imageOverlayOpacity = parseInt(overlayOpacity, 10) / 100;

  return (
    <div
      className={classNames(
        "absolute top-0 bottom-0 left-0 right-0",
        className
      )}
      style={{ background: linearOverlay, opacity: imageOverlayOpacity }}
    />
  );
}
