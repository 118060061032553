/* eslint-disable react/jsx-props-no-spreading */
import { Children, ComponentProps, ReactNode, useMemo } from "react";
import { Carousel } from "@components";
import classNames from "classnames";

import { Media } from "../../Media";
import type { CollectionListWrapperProps } from "../CollectionListSection";

type CarouselProps = ComponentProps<typeof Carousel>;

const makeCarouselProps = (
  slidesPerView: number,
  slideCount: number,
  children: ReactNode
): CarouselProps => {
  const paginationConfig: CarouselProps["pagination"] = {
    type: "bullets",
    overlay: false,
    clickable: true,
  };
  const noPagination: CarouselProps["pagination"] = { type: undefined };
  const spaceBetween = 16;

  const shouldShowPagination = slideCount > slidesPerView;
  return {
    autoplay: { enabled: false },
    loop: false,
    navigation: false,
    spaceBetween,
    pagination: shouldShowPagination ? paginationConfig : noPagination,
    slidesPerView,
    children: Children.map(children, (child) => (
      <div
        className={classNames("w-full", {
          "mb-12 lg:mb-16": shouldShowPagination,
        })}
      >
        {child}
      </div>
    )),
  };
};

interface CollectionCarouselProps extends CollectionListWrapperProps {
  overrides?: {
    slidesPerView?: {
      base?: number;
      twin?: number;
      full?: number;
      queen?: number;
    };
  };
}

export default function CollectionCarousel({
  children,
  maxColumns,
  onSlideChange,
  overrides,
}: CollectionCarouselProps) {
  const slideCount = Children.count(children);

  const queenCarouselProps = useMemo(
    () =>
      makeCarouselProps(
        overrides?.slidesPerView?.queen ?? maxColumns,
        slideCount,
        children
      ),
    [maxColumns, slideCount, children, overrides?.slidesPerView?.queen]
  );
  const fullDesktopCarouselProps = useMemo(
    () =>
      makeCarouselProps(
        overrides?.slidesPerView?.full ?? 4,
        slideCount,
        children
      ),
    [slideCount, children, overrides?.slidesPerView?.full]
  );
  const twinCarouselProps = useMemo(
    () => ({
      ...makeCarouselProps(
        overrides?.slidesPerView?.twin ?? 3,
        slideCount,
        children
      ),
      spaceBetween: 12,
    }),
    [slideCount, children, overrides?.slidesPerView?.twin]
  );
  const baseCarouselProps = useMemo(
    () => ({
      ...makeCarouselProps(
        overrides?.slidesPerView?.base ?? 1.2,
        slideCount,
        children
      ),
      spaceBetween: 12,
    }),
    [slideCount, children, overrides?.slidesPerView?.base]
  );

  return (
    <>
      <Media greaterThanOrEqual="queen">
        <Carousel onSlideChange={onSlideChange} {...queenCarouselProps} />
      </Media>
      <Media greaterThanOrEqual="full" lessThan="queen">
        <Carousel onSlideChange={onSlideChange} {...fullDesktopCarouselProps} />
      </Media>
      <Media greaterThanOrEqual="twinXl" lessThan="full">
        <Carousel onSlideChange={onSlideChange} {...twinCarouselProps} />
      </Media>
      <Media lessThan="twinXl">
        <Carousel onSlideChange={onSlideChange} {...baseCarouselProps} />
      </Media>
    </>
  );
}
