/* eslint-disable import/prefer-default-export */
import React from "react";
import cn from "classnames";

import { theme } from "../../../../tailwind.config";

import selectors from "./selectors";

type QueryStringOptions = keyof typeof theme.screens;

type DisplayStyle =
  | "inline"
  | "block"
  | "inline-block"
  | "flex"
  | "inline-flex"
  | "grid"
  | "inline-grid";

export interface IMediaProps extends React.HTMLAttributes<HTMLDivElement> {
  greaterThanOrEqual?: QueryStringOptions;
  lessThan?: QueryStringOptions;
  children: React.ReactNode;
  displayStyle?: DisplayStyle;
}

type IMediaQueryClassnameMap = {
  greaterThanOrEqual: Record<QueryStringOptions, Record<DisplayStyle, string>>;
  lessThan: Record<QueryStringOptions, string>;
};

const MEDIA_QUERY_CLASSNAME_MAP: IMediaQueryClassnameMap = {
  greaterThanOrEqual: {
    xs: {
      inline: "xs:inline",
      block: "xs:block",
      "inline-block": "xs:inline-block",
      flex: "xs:flex",
      "inline-flex": "xs:inline-flex",
      grid: "xs:grid",
      "inline-grid": "xs:inline-grid",
    },
    twin: {
      inline: "twin:inline",
      block: "twin:block",
      "inline-block": "twin:inline-block",
      flex: "twin:flex",
      "inline-flex": "twin:inline-flex",
      grid: "twin:grid",
      "inline-grid": "twin:inline-grid",
    },
    sm: {
      inline: "sm:inline",
      block: "sm:block",
      "inline-block": "sm:inline-block",
      flex: "sm:flex",
      "inline-flex": "sm:inline-flex",
      grid: "sm:grid",
      "inline-grid": "sm:inline-grid",
    },
    twinXl: {
      inline: "twinXl:inline",
      block: "twinXl:block",
      "inline-block": "twinXl:inline-block",
      flex: "twinXl:flex",
      "inline-flex": "twinXl:inline-flex",
      grid: "twinXl:grid",
      "inline-grid": "twinXl:inline-grid",
    },
    md: {
      inline: "md:inline",
      block: "md:block",
      "inline-block": "md:inline-block",
      flex: "md:flex",
      "inline-flex": "md:inline-flex",
      grid: "md:grid",
      "inline-grid": "md:inline-grid",
    },
    full: {
      inline: "full:inline",
      block: "full:block",
      "inline-block": "full:inline-block",
      flex: "full:flex",
      "inline-flex": "full:inline-flex",
      grid: "full:grid",
      "inline-grid": "full:inline-grid",
    },
    lg: {
      inline: "lg:inline",
      block: "lg:block",
      "inline-block": "lg:inline-block",
      flex: "lg:flex",
      "inline-flex": "lg:inline-flex",
      grid: "lg:grid",
      "inline-grid": "lg:inline-grid",
    },
    xl: {
      inline: "xl:inline",
      block: "xl:block",
      "inline-block": "xl:inline-block",
      flex: "xl:flex",
      "inline-flex": "xl:inline-flex",
      grid: "xl:grid",
      "inline-grid": "xl:inline-grid",
    },
    queen: {
      inline: "queen:inline",
      block: "queen:block",
      "inline-block": "queen:inline-block",
      flex: "queen:flex",
      "inline-flex": "queen:inline-flex",
      grid: "queen:grid",
      "inline-grid": "queen:inline-grid",
    },
    "2xl": {
      inline: "2xl:inline",
      block: "2xl:block",
      "inline-block": "2xl:inline-block",
      flex: "2xl:flex",
      "inline-flex": "2xl:inline-flex",
      grid: "2xl:grid",
      "inline-grid": "2xl:inline-grid",
    },
    king: {
      inline: "king:inline",
      block: "king:block",
      "inline-block": "king:inline-block",
      flex: "king:flex",
      "inline-flex": "king:inline-flex",
      grid: "king:grid",
      "inline-grid": "king:inline-grid",
    },
    caliKing: {
      inline: "caliKing:inline",
      block: "caliKing:block",
      "inline-block": "caliKing:inline-block",
      flex: "caliKing:flex",
      "inline-flex": "caliKing:inline-flex",
      grid: "caliKing:grid",
      "inline-grid": "caliKing:inline-grid",
    },
  },
  lessThan: {
    xs: "xs:hidden",
    twin: "twin:hidden",
    sm: "sm:hidden",
    twinXl: "twinXl:hidden",
    md: "md:hidden",
    full: "full:hidden",
    lg: "lg:hidden",
    queen: "queen:hidden",
    xl: "xl:hidden",
    king: "king:hidden",
    "2xl": "2xl:hidden",
    caliKing: "caliKing:hidden",
  },
};

export function Media({
  className,
  lessThan,
  greaterThanOrEqual,
  displayStyle = "block",
  ...props
}: IMediaProps) {
  return (
    <div
      data-testid={selectors.container}
      className={cn(
        greaterThanOrEqual && [
          "hidden",
          MEDIA_QUERY_CLASSNAME_MAP.greaterThanOrEqual[greaterThanOrEqual][
            displayStyle
          ],
        ],
        lessThan && [
          !greaterThanOrEqual && displayStyle !== "block" && displayStyle,
          MEDIA_QUERY_CLASSNAME_MAP.lessThan[lessThan],
        ],
        className
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
