/* eslint-disable import/prefer-default-export */
import { useContext } from "react";
import {
  Accordion,
  ContextLinkProps,
  ImageContext,
  LinkContext,
} from "@components";
import { Document } from "@contentful/rich-text-types";
import classNames from "classnames";

import SiteLogo from "../Navigation/components/SiteLogo";
import { EmailSignupProps, Media, RichText } from "..";

import { FooterEmailSection } from "./FooterEmailSection";

export interface SocialLink extends ContextLinkProps {
  iconURL: string;
}

export interface LinkColumn {
  heading: string;
  links: ContextLinkProps[];
}

export interface FooterProps {
  data: {
    columns: LinkColumn[];
    socialLinks?: SocialLink[];
    terms: Document | null;
    subHeader?: string;
    emailConfig: EmailSignupProps;
  };
}

function FooterColumn({ heading, links }: LinkColumn) {
  const Link = useContext(LinkContext);

  return (
    <div key={heading} className="w-1/3 lg:min-w-[160px] text-xs">
      <p className="mb-3 font-medium leading-5 uppercase">{heading}</p>
      <ul
        className={classNames({
          "flex gap-4 md:gap-6": links[0] && "iconURL" in links[0],
        })}
      >
        {links.map((link) => (
          <li
            className={classNames("leading-5 mb-3 mr-3", {
              "w-5 h-5 mb-0.5": "iconURL" in link,
            })}
            key={`${link.title}${link.href}`}
          >
            <Link href={link.href} variant="underline">
              {link.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

function SocialLinks({
  socialLinks,
  className,
}: {
  socialLinks: SocialLink[];
  className?: string;
}) {
  const Link = useContext(LinkContext);
  const Image = useContext(ImageContext);

  return (
    <div className={classNames("flex gap-x-4", className)}>
      {socialLinks?.map(({ iconURL, title, href }) => (
        <div key={title}>
          <Link href={href}>
            <Image src={iconURL} alt={title} height={16} width={16} />
          </Link>
        </div>
      ))}
    </div>
  );
}

export function FooterNav({
  data: { columns = [], terms, emailConfig, socialLinks, subHeader },
}: FooterProps) {
  const Link = useContext(LinkContext);

  // TODO: Need to have Wunderkind update their footer to have the same styling as the one we have here
  return (
    <footer
      className="w-screen max-w-full text-charcoal bg-pearl"
      data-testid="footer-nav"
    >
      <div className="p-6 m-auto full:px-8 full:py-12 max-w-screen-king">
        <div className="flex flex-col justify-between gap-4 lg:flex-row">
          <div className="[text-wrap:balance]">
            <div className="flex justify-between w-full">
              <Media greaterThanOrEqual="lg">
                <SiteLogo />
              </Media>
              <Media lessThan="lg">
                <SiteLogo mobile />
              </Media>
              <Media lessThan="lg">
                <SocialLinks socialLinks={socialLinks ?? []} />
              </Media>
            </div>
            <p className="mt-6 text-2xs lg:mt-7">{subHeader}</p>
            <div className="mt-4 lg:mt-5">
              <FooterEmailSection
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...emailConfig}
              />
            </div>
            <Media greaterThanOrEqual="lg">
              <SocialLinks
                socialLinks={socialLinks ?? []}
                className="mt-8 lg:mt-11"
              />
            </Media>
          </div>
          <div className="lg:flex-1 lg:max-w-[50%]">
            <Media lessThan="lg">
              <Accordion
                name="footer"
                expandedIconName="minus"
                iconName="plus"
                buttonClasses="py-3 text-xs font-sans"
                mode="single-open"
                unmount={false}
                items={columns.map(({ heading, links }) => ({
                  id: heading,
                  title: heading.toUpperCase(),
                  children: (
                    <ul className="flex flex-col gap-2 mb-3 ml-3">
                      {links.map((link) => (
                        <li
                          key={`${link.title}${link.href}`}
                          className="text-xs font-normal text-charcoal"
                        >
                          <Link href={link.href}>{link.title}</Link>
                        </li>
                      ))}
                    </ul>
                  ),
                }))}
              />
            </Media>
            <Media greaterThanOrEqual="lg">
              <nav className="lg:flex lg:justify-between lg:mb-12">
                {columns.map(({ heading, links }) => (
                  <FooterColumn key={heading} heading={heading} links={links} />
                ))}
              </nav>
            </Media>
            {terms && (
              <div className="flex flex-col gap-1 mt-16 leading-normal text-2xs md:text-xs md:flex-row lg:mt-14">
                <RichText richTextResponse={terms} />
              </div>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}
