/* eslint-disable import/prefer-default-export */
import * as React from "react";

import Button, { ButtonProps } from "../Button/Button";

import selectors from "./selectors";

export interface CopyTextButtonProps extends Omit<ButtonProps, "variant"> {
  stringToCopy: string;
  successCopyText: string;
  successMessageDuration?: number;
}

export function CopyTextButton({
  stringToCopy,
  successCopyText,
  onClick,
  children,
  successMessageDuration = 5000,
  ...props
}: CopyTextButtonProps) {
  const [isCopied, setIsCopied] = React.useState(false);

  const copyStringToClipboard = () => {
    navigator.clipboard.writeText(stringToCopy);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, successMessageDuration);
  };

  return (
    <Button
      variant="unstyled"
      data-testid={selectors.button}
      onClick={(event) => {
        if (onClick) onClick(event);
        copyStringToClipboard();
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {isCopied ? successCopyText : children}
    </Button>
  );
}
