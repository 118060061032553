import type CartAPI from "../services/cart/api";
import CrossingMindsAPI from "../services/crossingminds/api";
import RecommendedProductsAPI from "../services/recommended-products/api";
import SearchAPI from "../services/search/api";
import { QueryClientPlugins } from "../services/util/makeQueryClient";
import { Localized } from "../util/locale";

import analyticsPlugin from "./data/analytics";
import cartPlugin, { CartMutationFns, CartQueryFns } from "./data/cartData";
import catalogPlugin, { catalogQueryFns } from "./data/catalog";
import packageTrackingPlugin, {
  PackageTrackingQueryFns,
} from "./data/packageTracking";
import recommendationsPlugin, {
  recommendationsQueryFns,
} from "./data/recommendationsData";
import recommendedProductsPlugin, {
  recommendedProductsQueryFns,
} from "./data/recommendedProductsData";
import searchPlugin, { SearchQueryFns } from "./data/searchData";
import subscribePlugin, { SubscribeMutationFns } from "./data/subscribe";
import shared, {
  SharedDataServices,
  SharedMutations,
  SharedQueries,
} from "./shared";

export interface ClientDataServices {
  localizedCartApi: Localized<CartAPI>;
  recommendedProductsAPI: RecommendedProductsAPI;
  recommendationsAPI: CrossingMindsAPI;
  searchAPI: SearchAPI;
}

export type ClientQueries = SharedQueries &
  CartQueryFns &
  ReturnType<typeof catalogQueryFns> &
  ReturnType<typeof recommendedProductsQueryFns> &
  ReturnType<typeof recommendationsQueryFns> &
  PackageTrackingQueryFns &
  SearchQueryFns;
export type ClientMutations = SharedMutations &
  CartMutationFns &
  SubscribeMutationFns;

export type QueryData<K extends keyof ClientQueries> = ReturnType<
  ClientQueries[K]
>;

const clientPlugins: QueryClientPlugins<
  ClientDataServices & SharedDataServices
> = {
  analyticsPlugin,
  cartPlugin,
  catalogPlugin,
  recommendationsPlugin,
  recommendedProductsPlugin,
  packageTrackingPlugin,
  searchPlugin,
  subscribePlugin,
  ...shared,
};

export default clientPlugins;
