import { createContext } from "react";

import type { MergedProductVariant } from "../services/catalog/types";
import type { MergedOption } from "../services/util/optionRendering";

/**
 * Retrieves a list of variant IDs based on certain canonical options from a set of product variants.
 *
 * @param canonicalOptionNames - An array of strings representing the canonical options.
 * @param variants - An array of product variants.
 * @param defaultVariant - A default product variant, used for comparison.
 *
 * @returns - An array of variant IDs that match the canonical options and default variant's other options.
 */
export function getCanonicalVariantIds(
  canonicalOptionNames: string[],
  variants: MergedProductVariant[],
  defaultVariant: MergedProductVariant
): string[] {
  // If there are no canonical options or more than one, return an empty array or all variant IDs.
  if (canonicalOptionNames.length !== 1) {
    return canonicalOptionNames.length > 1 ? variants.map((v) => v.id) : [];
  }

  // Extract the single canonical option name.
  const [optionName] = canonicalOptionNames;

  // Create an object of default values for options other than the canonical one from the default variant.
  const defaultOtherOptions = defaultVariant.selectedOptions
    .filter((opt) => opt.name !== optionName)
    .reduce<Record<string, string>>(
      (acc, { name, value }) => ({ ...acc, [name]: value }),
      {}
    );

  // Filter the variants to find those that have the selected option matching the canonical option
  // and all other selected options matching the default values.
  const matchingVariants = variants.filter((variant) => {
    // Find the selected option that matches the canonical option name.
    const option = variant.selectedOptions.find(
      (opt) => opt.name === optionName
    );
    // Check if all other selected options match the default values.
    const otherOptionsMatch = variant.selectedOptions
      .filter((opt) => opt.name !== optionName)
      .every((opt) => defaultOtherOptions[opt.name] === opt.value);

    // A variant matches if the canonical option is found and all other options match the default values.
    return option && otherOptionsMatch;
  });

  return matchingVariants.map((variant) => variant.id);
}

/**
 * Extracts the names of canonical options from an array of options.
 *
 * @param options - An array of option objects, each having a 'name' and 'isCanonical' property.
 * @returns - An array of names of the canonical options.
 */
export function getCanonicalOptionNames(options: MergedOption[]) {
  // First, filter out the canonical options from the array.
  const canonicalOptions = options.filter((option) => option.isCanonical);

  // Then, map the filtered canonical options to their names.
  return canonicalOptions.map((option) => option.name);
}

/**
 * Retrieves the current canonical product variant or null.
 *
 * @param canonicalOptionNames - An array of canonical option names.
 * @param canonicalVariants - An array of canonical product variants.
 * @param currentVariant - The current product variant.
 * @returns - The current canonical product variant, or null.
 */
export function getCurrentCanonicalVariant(
  canonicalOptionNames: string[],
  canonicalVariants: MergedProductVariant[],
  currentVariant: MergedProductVariant
) {
  if (canonicalOptionNames.length === 0) return null;
  if (canonicalOptionNames.length > 1) return currentVariant;

  // Extract the single canonical option name.
  const [optionName] = canonicalOptionNames;

  // Find the current variant's selected option that matches the canonical option name.
  const currentVariantCanonicalOption = currentVariant.selectedOptions.find(
    (option) => option.name === optionName
  );

  // If the current variant does not have the canonical option, return null.
  if (!currentVariantCanonicalOption) return null;

  // Find the canonical variant that has the same value for the canonical option as the current variant.
  const canonicalVariant = canonicalVariants.find((variant) =>
    variant.selectedOptions.some(
      (option) =>
        option.name === optionName &&
        option.value === currentVariantCanonicalOption.value
    )
  );

  // Return the found canonical variant or null if no match is found.
  return canonicalVariant || null;
}

type CanonicalUrlContextType = {
  canonical: string | null;
  setCanonical: (value: string | null) => void;
};

const stub = () => {};
export const CanonicalUrlContext = createContext<CanonicalUrlContextType>({
  canonical: null,
  setCanonical: stub,
});
