import { Button } from "../Button";
import { Icon } from "../Icon";

import selectors from "./selectors";

interface PaginationButtonProps {
  variant: "left" | "right";
  onClick: () => void;
  isDisabled: boolean;
}

export function PaginationButton({
  variant,
  onClick,
  isDisabled,
}: PaginationButtonProps) {
  return (
    <Button
      onClick={onClick}
      variant="unstyled"
      className="flex items-center justify-center w-8 h-8 border rounded-full bg-flint-10 disabled:opacity-50 border-flint-20 hover:bg-flint-20 hover:border-flint-25"
      disabled={isDisabled}
      data-testid={selectors.paginationButton[variant]}
    >
      <Icon
        name={
          variant === "left" ? "chevron-left-rounded" : "chevron-right-rounded"
        }
        className="w-5 h-5"
      />
    </Button>
  );
}

interface PaginationButtonPairProps {
  onPrevious: () => void;
  onNext: () => void;
  isPreviousDisabled?: boolean;
  isNextDisabled?: boolean;
}

export function PaginationButtonPair({
  onPrevious,
  onNext,
  isPreviousDisabled = false,
  isNextDisabled = false,
}: PaginationButtonPairProps) {
  return (
    <div className="flex gap-x-[10px]">
      <PaginationButton
        variant="left"
        onClick={onPrevious}
        isDisabled={isPreviousDisabled}
      />
      <PaginationButton
        variant="right"
        onClick={onNext}
        isDisabled={isNextDisabled}
      />
    </div>
  );
}
