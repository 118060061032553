import classNames from "classnames";

export type PromoBadgePreset =
  | "white-fill"
  | "white-outline"
  | "black-outline"
  | "slate-fill"
  | "sage-fill-rect"
  | "charcoal-fill";

export interface PromoBadgeProps {
  text: string;
  preset: PromoBadgePreset;
  size?: "small" | "regular" | "large";
  /** Should only be used for positioning */
  className?: string;
}

/**
 * Component used to render a promotional badge
 */
export default function PromoBadge({
  text,
  preset,
  size = "regular",
  className,
}: PromoBadgeProps) {
  return (
    <span
      data-testid="promo-badge"
      className={classNames(
        "leading-none rounded-md border",
        {
          "px-4 py-1 text-2xs": size === "regular",
          "px-1.5 py-0.5 text-tiny": size === "small",
          "p-1 text-xs leading-[13px]": size === "large",
          "bg-white text-charcoal border-white": preset === "white-fill",
          "text-white border-white": preset === "white-outline",
          "text-black border-black": preset === "black-outline",
          "text-white bg-slate border-flint": preset === "slate-fill",
          "text-white bg-sage border-sage rounded-none font-sans":
            preset === "sage-fill-rect",
          "text-white bg-charcoal border-charcoal": preset === "charcoal-fill",
        },
        className
      )}
    >
      {text}
    </span>
  );
}
