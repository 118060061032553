/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { CardCarousel, LoadingIndicator } from "@components";
import classNames from "classnames";
import { useRouter } from "next/router";
import { useVerticalSpacing } from "src/lib/context/VerticalSpacingContext";
import { IProductTileProps } from "src/lib/services/elevar/interfaces";
import { getIsInitiallyVisible } from "src/lib/util/getIsInitiallyVisible";

import {
  ICarouselConfigFields,
  IRecommendedProductsSectionFields,
} from "../../../../@types/generated/contentful";
import { useRunOnVisibleOnce } from "../../../hooks/useIntersectionObserver";
import { CrossingMindsContext } from "../../context/CrossingMindsContext";
import { useAnalytics } from "../../hooks/useAnalytics";
import useCollectionPageUrl from "../../hooks/useCollectionPageUrl";
import useRecommendedProductsQuery from "../../hooks/useRecommendedProductsQuery";
import { SelectedVariantsProvider } from "../../hooks/useSelectedVariant";
import {
  addHeapProductTileClicked,
  addHeapProductTileViewed,
  addHeapRecommendedItemClick,
  addHeapViewRecommendationListEvent,
  addProductSelectEvent,
  addViewCollectionEvent,
  formatRecommendedProduct,
} from "../../services/elevar/events";
import { Media } from "../Media";

import { RecommendedProductCardV2 } from "./components/RecommendedProductCardV2";
import type { RecommendedProductCardProps } from "./components";
import {
  RecommendedProductCard,
  RecommendedProductsCarousel,
} from "./components";
import selectors from "./selectors";

interface RecommendedProductsSectionProps
  extends Partial<
    Omit<IRecommendedProductsSectionFields, "name" | "carouselConfig">
  > {
  heading: string;
  rulesetId: string;
  max?: number;
  variant?: RecommendedProductCardProps["variant"];
  addToCart?: RecommendedProductCardProps["addToCart"];
  location?: "page" | "cart";
  carouselConfig?: ICarouselConfigFields;
}

export function RecommendedProductsSection({
  addToCart,
  heading,
  max = 12,
  rulesetId,
  variant = "normal",
  children,
  location = "page",
  carouselConfig = {
    underlineCarouselTitle: false,
    showCarouselPaginationButtons: true,
    showCarouselScrollBar: false,
    numberOfMobileCarouselCards: 1,
    numberOfTabletCarouselCards: 2,
    numberOfDesktopCarouselCards: 3,
    mobilePeekPercentage: 40,
    tabletPeekPercentage: 0,
    desktopPeekPercentage: 20,
  },
  showPricing = true,
}: React.PropsWithChildren<RecommendedProductsSectionProps>) {
  const {
    query: { slug },
    pathname,
    asPath,
  } = useRouter();
  const collectionPageUrl = useCollectionPageUrl();
  const { hasVerticalSpacing } = useVerticalSpacing();
  const { recordCustomItemInteraction } =
    React.useContext(CrossingMindsContext);
  const { data, isLoading } = useRecommendedProductsQuery({
    rulesetId,
  });

  // filter out product if we're looking at the pdp
  // the `slug` param on the PDP is always an array with "handle" and  "variantId";
  // if it isn't an array, we don't need to check it (hence undefined)
  const routerSlug = Array.isArray(slug) ? slug[0] : undefined;
  const filteredProducts = data
    ?.filter((product) => product.slug !== routerSlug)
    .slice(0, max);
  const hasProducts = data && data.length > 0 && filteredProducts?.length;
  const { currentObject } = useAnalytics();
  const analyticsName = currentObject.name;

  const heightClassMap = ["h-56", "h-64", "h-72", "h-80"];
  const maxOptionCount = filteredProducts
    ? filteredProducts.reduce(
        (oldMax, current) => Math.max(oldMax, current.options.length),
        0
      )
    : 0;
  const heightIndex = Math.min(maxOptionCount, heightClassMap.length - 1);
  const heightClass = heightClassMap[heightIndex];
  const ref = useRunOnVisibleOnce(() => {
    if (!filteredProducts) return;

    addViewCollectionEvent(filteredProducts, collectionPageUrl);

    // track old & new recommendation views
    // the "normal" variant is only used for page sections
    addHeapViewRecommendationListEvent(
      filteredProducts,
      variant === "normal" ? "page" : "cart"
    );
  });

  const interactionLocation = React.useMemo(() => {
    if (location === "cart") return "rw_cart";
    if (location === "page") {
      if (pathname === "/pages/[slug]" && asPath.split("?")[0] === "/")
        return "rw_hp";
      if (pathname === "/products/[...slug]") return "rw_pdp";
      if (pathname === "/collections/[slug]") return "rw_collection";
      return "rw_page";
    }
    return "";
  }, [location, pathname, asPath]);

  const makeEventHandlers = (
    product: RecommendedProductCardProps["product"] & { position: number },
    visibleOnPageload: "yes" | "no"
  ) => {
    const getProductTileEventProps = (
      selectedVariant: RecommendedProductCardProps["product"]["variants"][0]
    ) => {
      const productTileEventProps: IProductTileProps = {
        productID: product.id,
        productTitle: product.title,
        sku: selectedVariant.sku,
        variantID: selectedVariant.id,
        variantTitle: selectedVariant.title,
        productTileTitle: product.title,
        price: selectedVariant.price,
        productListSectionTitle: heading,
        productTileSectionPosition: product.position + 1,
        location:
          variant === "normal" ? "Recs Widget - PDP" : "Recs Widget - Cart",
        visibleOnPageload,
        defaultImageURL: product.defaultVariant.images[0].url,
        displayedImageURL: selectedVariant.images[0].url,
      };
      return productTileEventProps;
    };
    const onClick = (
      selectedVariant: RecommendedProductCardProps["product"]["variants"][0]
    ) => {
      const list = variant === "list" ? "" : collectionPageUrl;

      recordCustomItemInteraction({
        itemId: selectedVariant.id,
        interactionType: "product_click",
        properties: { location: interactionLocation },
      });
      addProductSelectEvent(
        formatRecommendedProduct(
          product,
          selectedVariant,
          `${window.location.pathname}#more-to-love`
        ),
        list
      );
      addHeapProductTileClicked(getProductTileEventProps(selectedVariant));
      addHeapRecommendedItemClick(product.title, selectedVariant.id);
    };
    const onViewed = (
      selectedVariant: RecommendedProductCardProps["product"]["variants"][0]
    ) => {
      addHeapProductTileViewed(getProductTileEventProps(selectedVariant));
    };
    return { onClick, onViewed };
  };

  if (!hasProducts && !isLoading)
    return <div data-testid={selectors.placeholder} className="h-8 lg:h-16" />;

  const makeSharedCardProps = (
    product: typeof filteredProducts[number] & {
      position: number;
      visibleOnPageLoad: "yes" | "no";
    }
  ) => ({
    addToCart,
    variant,
    product: { ...product, showPricing },
    location,
    ...makeEventHandlers(product, product.visibleOnPageLoad),
  });

  const title = {
    value: heading,
    underline: !!carouselConfig.underlineCarouselTitle,
  };

  const sharedCarouselConfig = {
    title,
    analyticsName,
    isLoading,
    showPagination: carouselConfig.showCarouselPaginationButtons,
    showProgressBar: carouselConfig.showCarouselScrollBar,
  };

  return (
    <SelectedVariantsProvider products={data}>
      <div
        className={classNames({
          "flex justify-center w-full": variant === "normal",
        })}
      >
        <div
          className={classNames({
            "w-full mt-12": variant === "tight",
            "flex justify-center w-full max-w-screen-king":
              variant === "normal",
            "py-8 lg:py-14": hasVerticalSpacing && variant === "normal",
          })}
          ref={ref}
        >
          {variant === "normal" ? (
            <>
              <Media lessThan="md">
                <CardCarousel
                  {...sharedCarouselConfig}
                  cardsToShow={carouselConfig.numberOfMobileCarouselCards}
                  peekPercentage={carouselConfig.mobilePeekPercentage}
                >
                  {filteredProducts.map((product, position) => (
                    <RecommendedProductCardV2
                      key={`${product.id}-mobile`}
                      {...makeSharedCardProps({
                        ...product,
                        position,
                        visibleOnPageLoad: getIsInitiallyVisible(
                          carouselConfig.numberOfMobileCarouselCards,
                          position,
                          Boolean(carouselConfig.mobilePeekPercentage)
                        ),
                      })}
                    />
                  ))}
                </CardCarousel>
              </Media>
              <Media lessThan="lg" greaterThanOrEqual="md">
                <CardCarousel
                  {...sharedCarouselConfig}
                  cardsToShow={carouselConfig.numberOfTabletCarouselCards}
                  peekPercentage={carouselConfig.tabletPeekPercentage}
                >
                  {filteredProducts.map((product, position) => (
                    <RecommendedProductCardV2
                      key={`${product.id}-tablet`}
                      {...makeSharedCardProps({
                        ...product,
                        position,
                        visibleOnPageLoad: getIsInitiallyVisible(
                          carouselConfig.numberOfTabletCarouselCards,
                          position,
                          Boolean(carouselConfig.tabletPeekPercentage)
                        ),
                      })}
                    />
                  ))}
                </CardCarousel>
              </Media>
              <Media greaterThanOrEqual="lg">
                <CardCarousel
                  {...sharedCarouselConfig}
                  cardsToShow={carouselConfig.numberOfDesktopCarouselCards}
                  peekPercentage={carouselConfig.desktopPeekPercentage}
                >
                  {filteredProducts.map((product, position) => (
                    <RecommendedProductCardV2
                      key={`${product.id}-desktop`}
                      {...makeSharedCardProps({
                        ...product,
                        position,
                        visibleOnPageLoad: getIsInitiallyVisible(
                          carouselConfig.numberOfDesktopCarouselCards,
                          position,
                          Boolean(carouselConfig.desktopPeekPercentage)
                        ),
                      })}
                    />
                  ))}
                </CardCarousel>
              </Media>
            </>
          ) : (
            <>
              {children ?? (
                <h3
                  className={classNames("font-serif", {
                    "mb-5 text-[1.125rem]": variant === "tight",
                  })}
                >
                  {heading}
                </h3>
              )}
              {isLoading && (
                <LoadingIndicator containerClassName="text-center" />
              )}
              {hasProducts &&
                !isLoading && // Loading gate added to prevent jumping between fallback and api data
                (variant === "list" ? (
                  <ul className="flex flex-col gap-6">
                    {filteredProducts.map((product, position) => (
                      <li key={product.id}>
                        <RecommendedProductCard
                          {...makeSharedCardProps({
                            ...product,
                            position,
                            visibleOnPageLoad: "yes",
                          })}
                        />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <RecommendedProductsCarousel
                    loop={filteredProducts.length > 3}
                    variant={variant}
                  >
                    {filteredProducts.map((product, position) => (
                      <div className="max-w-full" key={product.id}>
                        <RecommendedProductCard
                          className={heightClass}
                          {...makeSharedCardProps({
                            ...product,
                            position,
                            visibleOnPageLoad: getIsInitiallyVisible(
                              3,
                              position,
                              false
                            ),
                          })}
                        />
                      </div>
                    ))}
                  </RecommendedProductsCarousel>
                ))}
            </>
          )}
        </div>
      </div>
    </SelectedVariantsProvider>
  );
}
