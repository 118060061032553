import { createContext, useContext } from "react";

interface VerticalSpacingContextProps {
  hasVerticalSpacing: boolean;
}

const VerticalSpacingContext = createContext<VerticalSpacingContextProps>({
  hasVerticalSpacing: true,
});

export const useVerticalSpacing = () => useContext(VerticalSpacingContext);

export default VerticalSpacingContext;
