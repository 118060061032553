/* eslint-disable import/prefer-default-export */
import React from "react";

import tailwindConfig from "../../../tailwind.config";

type BreakpointMap = Record<
  `${keyof typeof tailwindConfig.theme.screens}Visible`,
  boolean
>;

export function useBreakpoints() {
  const [breakpoints, setBreakpoints] = React.useState<BreakpointMap>({
    xsVisible: false,
    twinVisible: false,
    smVisible: false,
    mdVisible: false,
    twinXlVisible: false,
    lgVisible: false,
    fullVisible: false,
    xlVisible: false,
    queenVisible: false,
    "2xlVisible": false,
    kingVisible: false,
    caliKingVisible: false,
  });

  const updateBreakpoints = () => {
    setBreakpoints(
      Object.keys(tailwindConfig.theme.screens).reduce((acc, key) => {
        acc[`${key}Visible` as keyof BreakpointMap] = Boolean(
          window.innerWidth >=
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            parseInt(tailwindConfig.theme.screens[key]!.slice(0, -2), 10)
        );
        return acc;
      }, {} as BreakpointMap)
    );
  };

  React.useEffect(() => {
    updateBreakpoints();
    window.addEventListener("resize", updateBreakpoints);
    return () => window.removeEventListener("resize", updateBreakpoints);
  }, []);
  return breakpoints;
}
