import { Icon } from "@components";

import selectors from "../selectors";

interface CloseButtonProps {
  onClick: () => void;
  className?: string;
}

export default function CloseButton({ onClick, className }: CloseButtonProps) {
  return (
    <button
      type="button"
      data-testid={selectors.closeButton}
      onClick={onClick}
      className={className}
    >
      <Icon name="close" />
    </button>
  );
}
