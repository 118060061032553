import React, { ChangeEvent } from "react";
import { SearchBar as BaseSearchBar } from "@components";
import { useDebouncedCallback } from "use-debounce";

export interface SearchBarProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "onSubmit"> {
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: (value: string) => void;
  autoFocus?: boolean;
  mobile?: boolean;
  isLoading?: boolean;
  sticky?: boolean;
}
export default function SearchBar({
  query,
  setQuery,
  onSubmit,
  autoFocus,
  isLoading = false,
  mobile = false,
  ...rest
}: SearchBarProps) {
  const [typedValue, setTypedValue] = React.useState(query);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const debouncedUpdateQuery = useDebouncedCallback(setQuery, 500);

  React.useEffect(() => {
    if (!autoFocus) return;
    inputRef.current?.focus({ preventScroll: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value: string) => {
    setTypedValue(value);
    debouncedUpdateQuery(value);
  };

  const handleSearch = () => {
    // skip debounce on submit
    debouncedUpdateQuery.flush();
    onSubmit(typedValue);
  };

  const handleKeyUp: React.EventHandler<React.KeyboardEvent> = (event) => {
    if (event.key !== "Enter") return;
    handleSearch();
  };

  const clearValue = () => {
    handleChange("");
    inputRef.current?.focus();
  };

  return (
    <BaseSearchBar
      // allow the parent to override the sticky props
      sticky
      {...rest}
      mobile={mobile}
      value={typedValue}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        handleChange(e.target.value)
      }
      onSubmit={mobile ? handleSearch : undefined}
      onClear={clearValue}
      onKeyUp={handleKeyUp}
      isLoading={isLoading}
      ref={inputRef}
    />
  );
}
