import { Transition } from "@headlessui/react";
import classNames from "classnames";

interface Props {
  show?: boolean;
  unmount?: boolean;
  className?: string;
  exitLeft?: boolean;
  children: React.ReactNode;
  testId?: string;
}
export default function SlidingTransition({
  show,
  unmount,
  className,
  exitLeft: enterFromLeft,
  children,
  testId,
}: Props) {
  return (
    <Transition
      show={show}
      unmount={unmount}
      enterFrom={classNames(
        "opacity-0 absolute",
        enterFromLeft ? "-translate-x-3/4" : "translate-x-3/4"
      )}
      enterTo="opacity-100 translate-x-0"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo={classNames(
        "opacity-0 absolute",
        enterFromLeft ? "-translate-x-3/4" : "translate-x-3/4"
      )}
      className={className}
      data-testid={testId}
    >
      {children}
    </Transition>
  );
}
