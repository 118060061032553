import * as React from "react";
import { Heading, LinkContext } from "@components";
import classNames from "classnames";
import { addHeapNavigationItemClick } from "src/lib/services/elevar/events";

import type { LinkGroupProps, LinkItemProps } from "../types";

import LinkItem from "./LinkItem";

const skeletons = Array.from(Array(5).keys());

type LinkGroupComponentProps = Omit<LinkGroupProps, "links"> &
  Partial<Pick<LinkGroupProps, "links">>;
export default function LinkGroup({
  title,
  text,
  url,
  links,
  listType = "link",
  holdTitleSpace = false,
  highlightTitle,
  useBoldHeadings,
  useUppercaseTitle = true,
  children,
}: LinkGroupComponentProps) {
  const LinkElement = React.useContext(LinkContext);
  const showTitle = holdTitleSpace || title;

  const handleClickAnalytics = (item: LinkItemProps, level: number) => {
    addHeapNavigationItemClick({
      style: "link",
      href: item.url,
      isMobile: Boolean(item.image),
      hasImage: false,
      level,
      version: "legacy",
    });
  };

  const titleElement =
    title && url ? (
      <LinkElement
        href={url}
        onClick={() => handleClickAnalytics({ text: title, url }, 2)}
      >
        {title}
      </LinkElement>
    ) : (
      title
    );

  const dataLinkGroupHeader = title ?? "No Header";

  return (
    <nav>
      {showTitle && (
        <Heading
          as="h6"
          fontFamily="sans"
          className={classNames("mb-5 tracking-wide", {
            "font-bold !text-tiny": useBoldHeadings,
            "text-slate": highlightTitle,
            uppercase: useUppercaseTitle,
          })}
          data-event-category="link-group-header"
        >
          {titleElement ?? <>&nbsp;</>}
        </Heading>
      )}

      {/* skeleton loading state */}
      {!links && (
        <div className="flex flex-col gap-4">
          {skeletons.map((item) => (
            <div
              key={`skeleton-${item}`}
              className="w-16 h-3 bg-lightgray animate-pulse"
              style={{
                animationDelay: `${100 * (item - 1)}ms`,
                animationDuration: `1s`,
              }}
            />
          ))}
        </div>
      )}

      {/* list of links */}
      {links && (
        <ul className="flex flex-col gap-2.5">
          {links.map((link) => (
            <li key={link.text} data-link-group-header={dataLinkGroupHeader}>
              <LinkItem
                url={link.url}
                text={link.text}
                image={link.image}
                onClick={() => handleClickAnalytics(link, 3)}
                // TODO: Uncomment this when ready to show badges on desktop nav links
                // badge={link.badge}
                type={listType}
              />
            </li>
          ))}
          {text && url && (
            <li data-link-group-header={dataLinkGroupHeader}>
              <LinkItem
                url={url}
                type={listType}
                text={text}
                highlight
                onClick={() => handleClickAnalytics({ text, url }, 3)}
              />
            </li>
          )}
        </ul>
      )}

      {/* children for error text */}
      {links && !links.length && children}
    </nav>
  );
}
