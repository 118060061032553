import React, { useEffect, useRef } from "react";

export default function useOneTrustModal() {
  const triggerModalRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    /* This page relies on a third-party oneTrust to manage user preferences
     * Since the content is in Contentful, we look for any link that has the URL
     * #trigger-onetrust-modal and forward those clicks to a hidden button on the page
     * That triggers the third-party user-preferences modal
     */
    /* istanbul ignore next */
    document
      .querySelectorAll('[href$="#trigger-onetrust-modal"]')
      .forEach((node) => {
        node.addEventListener("click", (e) => {
          e.preventDefault();
          triggerModalRef?.current?.click();
        });
      });
  }, []);

  const modalTrigger = (
    <button
      id="ot-sdk-btn"
      ref={triggerModalRef}
      className="hidden ot-sdk-show-settings"
      type="button"
      data-testid="trigger-modal-button"
    >
      Do Not Sell My Personal Information
    </button>
  );

  return modalTrigger;
}
