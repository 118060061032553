import Cookies from "js-cookie";
import { v4 as uuid } from "uuid";

import type env from "../../env";

import { ISearchResults, ISearchSuggestions } from "./types";
import { PAGE_LOAD_ID_KEY } from "./usePageLoadID";

type SiteID = typeof env.searchSpringConfig.siteId;

function getOrGenerateCookie(name: string) {
  let value = Cookies.get(name);
  if (!value) {
    value = uuid();
    Cookies.set(name, value);
  }

  return value;
}

function getHeaders() {
  const sessionId = getOrGenerateCookie("ssSessionIdNamespace");
  const userId = getOrGenerateCookie("ssUserId");
  const pageLoadID = getOrGenerateCookie(PAGE_LOAD_ID_KEY);

  return {
    "searchspring-session-id": sessionId,
    "searchspring-user-id": userId,
    "searchspring-page-load-id": pageLoadID,
  };
}

export default class SearchAPI {
  readonly url: string;

  readonly siteId: string;

  constructor(siteId: SiteID) {
    this.siteId = siteId;
    this.url = `https://${siteId}.a.searchspring.io/api`;
  }

  private sharedRequestParams() {
    return {
      resultsFormat: "json",
      redirectResponse: "minimal",
      siteId: this.siteId,
    };
  }

  fetchSuggestions = async (query: string) => {
    const searchParams = new URLSearchParams({
      q: query,
      siteId: this.siteId,
      integratedSpellCorrection: "true",
    });
    const response = await fetch(`${this.url}/suggest/query?${searchParams}`);
    if (!response.ok) throw new Error("Could not fetch search suggestions");

    const data = await response.json();
    return data as ISearchSuggestions;
  };

  fetchAutocompletions = async (query: string, originalQuery: string) => {
    const searchParams = new URLSearchParams({
      q: query,
      oq: originalQuery,
      ...this.sharedRequestParams(),
    });
    const response = await fetch(
      `${this.url}/search/autocomplete.json?${searchParams}`,
      { headers: getHeaders() }
    );
    if (!response.ok) throw new Error("Could not fetch autocomplete list");

    const data: ISearchResults = await response.json();
    if (data.merchandising.redirect) return data.merchandising.redirect;
    return data;
  };

  fetchSearchResults = async (
    query: string,
    originalQuery: string,
    page = 1,
    sort: Record<string, "asc" | "desc"> = {},
    filter: Record<string, string> = {}
  ) => {
    const searchParams = new URLSearchParams({
      q: query,
      oq: originalQuery,
      page: page.toString(),
      ...sort,
      ...filter,
      ...this.sharedRequestParams(),
    });

    const response = await fetch(
      `${this.url}/search/search.json?${searchParams}`,
      { headers: getHeaders() }
    );
    if (!response.ok) throw new Error("Could not fetch search results");

    const data: ISearchResults = await response.json();
    if (data.merchandising.redirect) return data.merchandising.redirect;
    return data;
  };
}
