import { createContext, useContext, useMemo } from "react";

type ProductTileContextProps = {
  productListSectionTitle?: string;
  tileIndex?: number;
  visibleOnPageload?: "yes" | "no";
};
const ProductTileContext = createContext<ProductTileContextProps | null>(null);

export function ProductTileContextProvider({
  children,
  value,
}: React.PropsWithChildren<{ value: ProductTileContextProps }>) {
  const currentValue = useContext(ProductTileContext);
  const newValue = useMemo(() => {
    if (currentValue === null) {
      return value;
    }
    return {
      ...currentValue,
      ...value,
    };
  }, [value, currentValue]);

  return (
    <ProductTileContext.Provider value={newValue}>
      {children}
    </ProductTileContext.Provider>
  );
}

export default ProductTileContext;
