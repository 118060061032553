/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Banner, LinkContext } from "@components";
import { useRouter } from "next/router";

import { BannerProps } from "../../../components/Banner/Banner";
import { isColorBright } from "../../../utils/makeColorStyles";
import { LinkItemProps } from "../Navigation/types";

import selectors from "./selectors";

export interface SiteBannerProps
  extends Omit<BannerProps, "secondaryChildren"> {
  navLinks?: LinkItemProps[];
}

function NavigationLinks({
  navLinks,
  bgColor,
}: Pick<SiteBannerProps, "navLinks" | "bgColor">) {
  const { useContext } = React;
  const LinkFromContext = useContext(LinkContext);
  return navLinks && navLinks.length > 0 ? (
    <nav
      className="absolute top-0 bottom-0 items-center justify-end hidden right-14 lg:flex"
      aria-labelledby={selectors.bannerNavigation}
      data-testid={selectors.bannerNavigation}
    >
      {/* TODO: i18N */}
      <span id={selectors.bannerNavigation} className="sr-only">
        Banner Navigation
      </span>
      {navLinks.map((link, index) => (
        <React.Fragment key={`${link.url}+${link.text}`}>
          <LinkFromContext
            variant="underline"
            href={link.url}
            title={link.text}
            className="mx-5 leading-5"
          >
            {link.text}
          </LinkFromContext>
          {index < navLinks.length - 1 ? (
            <span
              className="text-base"
              // TODO: Make more stylizeable if necessary
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              style={{ color: isColorBright(bgColor!) ? "white" : "flint" }}
            >
              |
            </span>
          ) : null}
        </React.Fragment>
      ))}
    </nav>
  ) : null;
}
/**
A component that renders the Banner based on data received from CMS
 */
export function SiteBanner({
  bgColor = "charcoal",
  color,
  navLinks = [],
  carouselAutoplay,
  carouselDelay,
  children = null,
  className,
}: React.PropsWithChildren<SiteBannerProps>) {
  const { asPath } = useRouter();
  return (
    <Banner
      key={asPath}
      bgColor={bgColor}
      color={color}
      data-testid={selectors.contentBanner}
      carouselAutoplay={carouselAutoplay}
      carouselDelay={carouselDelay}
      className={className}
      secondaryChildren={
        <NavigationLinks navLinks={navLinks} bgColor={bgColor} />
      }
    >
      {children}
    </Banner>
  );
}
