/**
 * @description calculates the width of the child element in the carousel,
 * based on the number of children and the peek percentage. The gap is 16px,
 * which is why the 16 is in there. The peek percentage is a number between 0 and 100,
 * so it is padded with a 0 if it is less than 10. The idea is you would show a
 * percentage of the next card, so if you want to show 20% of the next card, you would
 * pass in 20 for peek percentage.
 * @returns the width of the child element in the carousel
 */
export const getChildWidth = ({
  numberOfChildrenToShow,
  peekPercentage,
  isMobile,
}: {
  numberOfChildrenToShow: number;
  peekPercentage: number;
  isMobile: boolean;
}) => {
  const boundedChildrenToShow = Math.max(1, numberOfChildrenToShow);
  const boundedPeekPercentage = Math.max(0, Math.round(peekPercentage));

  return `calc(100% / ${boundedChildrenToShow}.${`${boundedPeekPercentage}`.padStart(
    2,
    "0"
  )} - ${
    ((isMobile ? 12 : 16) * (boundedChildrenToShow - 1)) / boundedChildrenToShow
  }px)`;
};
