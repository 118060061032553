/* eslint-disable import/prefer-default-export */
import * as React from "react";
import classNames from "classnames";

interface SpacerSectionProps {
  /** The height of the section */
  size?: "sm" | "md" | "lg";

  /** The type of element to render as */
  renderAs?: "section" | "inline";

  /** Data test id */
  dataTestId?: string;
}

const height: Record<Required<SpacerSectionProps>["size"], string> = {
  sm: "h-16",
  md: "h-24",
  lg: "h-36",
};

const inlineHeight: Record<Required<SpacerSectionProps>["size"], string> = {
  sm: "h-4",
  md: "h-8",
  lg: "h-12",
};

/**
 * An empty section that can be used to create vertical space between other
 * sections.
 */
export function SpacerSection({
  size = "md",
  renderAs = "section",
  dataTestId,
}: SpacerSectionProps) {
  return (
    <div
      aria-hidden="true"
      data-testid={dataTestId}
      className={classNames(
        (renderAs === "section" ? height : inlineHeight)[size]
      )}
    />
  );
}
