// Fine in this case since clearTimeout can be safely called with
// undefined
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* istanbul ignore file */
import * as React from "react";

import "intersection-observer";

type EntryCallback = (
  entry: IntersectionObserverEntry,
  observer: IntersectionObserver
) => void;

const observers = new Set<IntersectionObserver>();
const defaultSettings: IntersectionObserverInit = {
  rootMargin: "0px",
  threshold: 0,
};

function getObserver(
  callback: EntryCallback,
  settings: IntersectionObserverInit
) {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      // older versions of Edge don't support isIntersecting, so check
      // interectionRatio and fake it
      if (typeof entry.isIntersecting !== "boolean") {
        callback(
          {
            ...entry,
            isIntersecting: entry.intersectionRatio > 0,
          },
          observer
        );
      } else {
        callback(entry, observer);
      }
    });
  }, settings);

  observers.add(observer);

  return observer;
}

export const useIntersectionObserver = (
  callback: EntryCallback,
  options?: IntersectionObserverInit
) => {
  const [ref, setRef] = React.useState<HTMLElement | null>(null);

  React.useEffect(() => {
    let observer: IntersectionObserver;
    if (ref) {
      const settings = { ...defaultSettings, ...(options || {}) };
      observer = getObserver(callback, settings);
      observer.observe(ref);
    }

    return () => {
      if (observer && ref) {
        observer.unobserve(ref);
        observers.delete(observer);
      }
    };
  }, [callback, ref, options]);

  return setRef;
};

/**
 *
 * @param callback Callback to run once visible. This callback can update and not run multiple times, unlike
 * the useIntersectionObserver hook.
 * @param options options to pass to the underlying observer that tells the hook if it is visible
 * @returns a ref to attach to the element you want to observe
 */
export const useRunOnVisibleOnce = (
  callback?: () => void,
  options?: IntersectionObserverInit,
  delay?: number
) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [isCalled, setIsCalled] = React.useState(false);
  const [calculatedOptions, setCalculatedOptions] =
    React.useState<IntersectionObserverInit>(options || {});
  const timerRef = React.useRef<NodeJS.Timeout>();

  const setRef = useIntersectionObserver((entry, observer) => {
    // Set the calculated options
    if (
      entry.boundingClientRect.height >
      (entry.rootBounds?.height ?? window.innerHeight)
    ) {
      const percentage =
        options?.threshold ??
        (0.8 * (entry.rootBounds?.height ?? window.innerHeight)) /
          entry.boundingClientRect.height;
      if (calculatedOptions.threshold !== percentage) {
        setCalculatedOptions({ ...options, threshold: percentage });
      }
    }
    if (entry.isIntersecting) {
      // Exit early here
      if (!delay) {
        setIsVisible(true);
        observer.disconnect();
      }
      // Setup delay behavior here
      if (delay && !timerRef.current) {
        const startTime = Date.now();
        timerRef.current = setInterval(() => {
          if (Date.now() - startTime >= delay) {
            clearInterval(timerRef.current!);
            setIsVisible(true);
            observer.disconnect();
          }
        }, 100);
      }
    } else {
      // Clear when exiting the view threshold
      clearInterval(timerRef.current!);
      timerRef.current = undefined;
    }
  }, calculatedOptions);

  React.useEffect(() => {
    if (isVisible && !isCalled) {
      setIsCalled(true);
      callback?.();
    }
  }, [callback, isVisible, isCalled]);

  return setRef;
};
