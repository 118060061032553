import React from "react";
import { Modal } from "@components";

import useIsFromOrganicSearch from "./hooks/useIsFromOrganicSearch";
import { EmailFormVisualProps } from "./views/EmailForm";
import { PostSubmitViewProps } from "./views/PostSubmitView";
import { InputFocusRefContext } from "./InputFocusRefContext";
import selectors from "./selectors";
import Wizard from "./Wizard";

interface BaseMarketingCaptureModalProps {
  isOpen: boolean;
}

interface MarketingCaptureModalProps extends BaseMarketingCaptureModalProps {
  onClose: () => void;
  emailFormProps: EmailFormVisualProps;
  postSubmitProps: Pick<
    PostSubmitViewProps,
    "body" | "cta" | "title" | "dismissFormButtonText"
  >;
  dismissButtonText: string;
}

function ModalWrapper({
  isOpen,
  onClose,
  children,
}: BaseMarketingCaptureModalProps & {
  onClose: () => void;
  children: React.ReactNode;
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <InputFocusRefContext.Provider value={inputRef}>
      <Modal
        isOpen={isOpen}
        onDismiss={onClose}
        childContainerClassName="bg-lightgray"
        containerClassName="z-header"
        initialFocusRef={inputRef}
      >
        <div
          className="pb-14 md:pb-10 md:w-96"
          data-testid={selectors.modal.container}
        >
          {children}
        </div>
      </Modal>
    </InputFocusRefContext.Provider>
  );
}

function BottomBannerWrapper({
  isOpen,
  children,
}: BaseMarketingCaptureModalProps & {
  children: React.ReactNode;
}) {
  if (!isOpen) return null;
  return (
    <div
      className="fixed bottom-0 w-full z-max bg-lightgray"
      data-testid={selectors.organicSearchBanner.container}
    >
      <div className="max-w-screen-lg mx-auto mb-5">{children}</div>
    </div>
  );
}

function MarketingCaptureModal({
  isOpen,
  onClose,
  emailFormProps,
  dismissButtonText,
  postSubmitProps,
}: MarketingCaptureModalProps) {
  const isFromOrganicSearch = useIsFromOrganicSearch();

  if (isFromOrganicSearch === undefined) return null;

  const Wrapper = isFromOrganicSearch ? BottomBannerWrapper : ModalWrapper;

  return (
    <Wrapper isOpen={isOpen} onClose={onClose}>
      <Wizard
        onClose={onClose}
        isOrganicSearch={isFromOrganicSearch}
        emailFormProps={emailFormProps}
        postSubmitProps={postSubmitProps}
        dismissButtonText={dismissButtonText}
      />
    </Wrapper>
  );
}

export default MarketingCaptureModal;
