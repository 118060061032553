import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { ClientMutations } from "../queries/client";

export type ThumaMutationKey<
  MutationName extends keyof ClientMutations = keyof ClientMutations
> = [mutationName: MutationName];

/*
 * This is really just a wrapper for typescript conveniences, everything just gets passed through to useMutation
 * BTW, all mutation functions are only allowed one parameter, so if you need more than one...you'll have to convert to passing an object.
 */
function useTypedMutation<
  MK extends ThumaMutationKey,
  MutationName extends keyof ClientMutations = MK[0],
  MutationArgs = Parameters<ClientMutations[MutationName]>[0] extends undefined
    ? void
    : Parameters<ClientMutations[MutationName]>[0],
  Return extends Awaited<ReturnType<ClientMutations[MutationName]>> = Awaited<
    ReturnType<ClientMutations[MutationName]>
  >
>(
  [mutationName]: MK,
  options?: Omit<UseMutationOptions<Return, Error, MutationArgs>, "mutationKey">
): UseMutationResult<Return, Error, MutationArgs> {
  return useMutation([mutationName], options);
}

export default useTypedMutation;
