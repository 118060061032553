import * as React from "react";
import { Icon, LinkContext } from "@components";
import classNames from "classnames";

import { useTypedQuery } from "../../../hooks/useTypedQuery";

interface SiteLogoProps {
  mobile?: boolean;
  flip?: boolean;
}
export default function SiteLogo({ flip, mobile }: SiteLogoProps) {
  const LinkElement = React.useContext(LinkContext);
  const { data: preview } = useTypedQuery(["inPreviewMode"]);

  return (
    <LinkElement href="/" className="block leading-none">
      <Icon
        name="thuma-logo"
        size={mobile ? "inherit" : "initial"}
        className={classNames({ "-scale-x-100": flip })}
      />
      {preview && (
        <span
          aria-hidden
          className="absolute inset-x-0 text-center uppercase top-full text-tiny text-slate"
        >
          Preview
        </span>
      )}
    </LinkElement>
  );
}
