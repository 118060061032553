import { useAnalytics } from "../../../hooks/useAnalytics";
import {
  addHeapOnCardCarouselItemClick,
  addHeapOnGridItemClick,
} from "../../../services/elevar";

export function useAnalyticsHandler() {
  const { currentObject, parentObject } = useAnalytics();

  return (e: React.MouseEvent) => {
    const { target } = e;
    const typedTarget = target as HTMLElement;
    const clickedElement =
      typedTarget?.getAttribute("data-testid") ??
      typedTarget?.parentElement?.getAttribute("data-testid");
    if (parentObject.type === "grid-carousel") {
      addHeapOnCardCarouselItemClick(
        currentObject.name,
        parentObject.name,
        clickedElement
      );
    } else if (parentObject.type === "grid") {
      addHeapOnGridItemClick(
        currentObject.name,
        parentObject.name,
        clickedElement
      );
    }
  };
}
