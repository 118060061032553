/* eslint-disable import/prefer-default-export */
import type { Asset } from "contentful";

export function getVideoProps({
  fields: {
    title,
    file: { url, details },
  },
}: Asset) {
  return {
    alt: title,
    src: url,
    height: details?.image?.height,
    width: details?.image?.width,
  };
}
