/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { ImageContext, LinkContext } from "@components";
import classNames from "classnames";
import { useRunOnVisibleOnce } from "src/hooks/useIntersectionObserver";

import defaultSelectors from "./selectors";

export interface ProductCardProps {
  url: string;
  title: string;
  price: string;
  image: {
    width?: number;
    height?: number;
    url: string;
    title: string;
    description?: string;
  };
  showPricing?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onViewed?: () => void;
  selectors?: typeof defaultSelectors;
  "data-event-category"?: string;
}

export function ProductCard({
  url,
  title,
  price,
  image,
  showPricing = true,
  className,
  onClick,
  onViewed,
  selectors = defaultSelectors,
  ...rest
}: ProductCardProps) {
  const Image = React.useContext(ImageContext);
  const Link = React.useContext(LinkContext);

  const containerRef = useRunOnVisibleOnce(
    onViewed,
    {
      threshold: 0.8,
    },
    500
  );

  return (
    <div
      data-testid={selectors.card}
      data-event-category={rest["data-event-category"]}
      ref={containerRef}
      className={classNames(
        "flex flex-col overflow-hidden rounded-[5px] box-border focus-within:border border-transparent focus-within:border-charcoal",
        className
      )}
    >
      <Link
        draggable={false}
        href={url}
        onClick={onClick}
        className="relative flex"
      >
        <div
          role="img"
          className="flex"
          aria-label={`Product image for ${title}`}
        >
          <div className="absolute z-0 w-full h-full bg-lightgray animate-pulse" />
          <Image
            alt={image.description || image.title}
            data-testid={selectors.image}
            height={image.width}
            width={image.height}
            src={image.url}
            className="z-[1]"
          />
        </div>
        <div className="absolute bottom-0 left-0 right-0 flex justify-between px-4 pb-4 lg:pb-6 lg:px-8 z-[1]">
          <p data-testid={selectors.title} className="mr-4 truncate">
            {title}
          </p>
          {showPricing ? (
            <span data-testid={selectors.price}>{price}</span>
          ) : null}
        </div>
      </Link>
    </div>
  );
}
