import { useContext } from "react";
import { useExperience } from "@ninetailed/experience.js-react";
import { ExperienceMapper } from "@ninetailed/experience.js-utils-contentful";

import { INtExperience } from "../../../@types/generated/contentful";
import FeatureFlagContext from "../context/FeatureFlagContext";

type Entry = {
  sys: { id: string };
  fields: { nt_experiences?: INtExperience[] | undefined };
};

type BaselineEntry = Omit<Entry, "fields"> & {
  fields: { nt_experiences: INtExperience[] };
};

const hoistId = (entry: Entry) => ({
  ...entry,
  id: entry.sys.id,
});

const hasExperiences = (entry: Entry): entry is BaselineEntry =>
  entry.fields.nt_experiences !== undefined;

const parseExperiences = (entry: Entry) =>
  hasExperiences(entry)
    ? entry.fields.nt_experiences
        .filter((experience) => ExperienceMapper.isExperienceEntry(experience))
        .map((experience) => ExperienceMapper.mapExperience(experience))
    : [];

export default function useFeatureFlag(key: string) {
  const featureFlagMap = useContext(FeatureFlagContext);
  const entry = featureFlagMap[key];
  if (!entry) throw new Error(`Feature key '${key}' not found`);

  const { variant } = useExperience({
    baseline: hoistId(entry),
    experiences: parseExperiences(entry),
  });

  const value = "fields" in variant ? (variant.fields.value as string) : null;

  return { entry, value };
}
