/* eslint-disable import/prefer-default-export */
import * as React from "react";

import ArrowRight from "../svgs/arrow-right.svg";
import ArrowUp from "../svgs/arrow-up.svg";
import Basket from "../svgs/basket.svg";
import Card from "../svgs/card.svg";
import Checkmark from "../svgs/checkmark.svg";
import ChevronDown from "../svgs/chevron-down.svg";
import ChevronLeft from "../svgs/chevron-left.svg";
import ChevronLeftRounded from "../svgs/chevron-left-rounded.svg";
import ChevronRight from "../svgs/chevron-right.svg";
import ChevronRightRounded from "../svgs/chevron-right-rounded.svg";
import Close from "../svgs/close.svg";
import DeliveryTruck from "../svgs/delivery-truck.svg";
import EmptyStar from "../svgs/empty-star-icon.svg";
import Globe from "../svgs/globe.svg";
import Hamburger from "../svgs/hamburger.svg";
import Leaf from "../svgs/leaf.svg";
import Minus from "../svgs/minus.svg";
import Pause from "../svgs/pause.svg";
import Pencil from "../svgs/pencil.svg";
import Play from "../svgs/play.svg";
import Plus from "../svgs/plus.svg";
import Remove from "../svgs/remove.svg";
import Search from "../svgs/search.svg";
import Star from "../svgs/star-icon.svg";
import Tags from "../svgs/tags.svg";
import ThumaLogo from "../svgs/thuma-logo.svg";
import WhiteGlove from "../svgs/white-glove.svg";

export const IconMap: Record<
  string,
  React.VFC<React.SVGAttributes<SVGElement>>
> = {
  "arrow-right": ArrowRight,
  "arrow-up": ArrowUp,
  basket: Basket,
  card: Card,
  checkmark: Checkmark,
  "chevron-down": ChevronDown,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronRight,
  "chevron-left-rounded": ChevronLeftRounded,
  "chevron-right-rounded": ChevronRightRounded,
  close: Close,
  "delivery-truck": DeliveryTruck,
  "empty-star": EmptyStar,
  globe: Globe,
  hamburger: Hamburger,
  leaf: Leaf,
  minus: Minus,
  pause: Pause,
  pencil: Pencil,
  play: Play,
  plus: Plus,
  remove: Remove,
  star: Star,
  search: Search,
  tags: Tags,
  "thuma-logo": ThumaLogo,
  "white-glove": WhiteGlove,
};
