import { IPackageTrackingResponse } from "./types";

export default class PackageTrackingAPI {
  static url = "/api/package";

  public static async fetchPackageTrackingData(
    id: string
  ): Promise<IPackageTrackingResponse | undefined> {
    if (!id) return undefined;
    const response = await fetch(`${PackageTrackingAPI.url}/${id}`, {
      method: "GET",
    });
    if (!response.ok) throw new Error("Failed to fetch package tracking data");
    const data = await response.json();
    return data as IPackageTrackingResponse;
  }
}
