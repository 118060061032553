import { CardCarousel } from "@components";
import classNames from "classnames";

import { cn } from "../../../../utils/tailwind-merge";
import { Media } from "../../Media";
import { CardGroupProps, CardItemProps } from "../types";

import { MobileCard } from "./MobileCard";

export function MobileCardGroup({
  type,
  items,
  name,
  carouselConfig,
  className,
  onClick,
}: CardGroupProps) {
  const sharedCarouselConfig = {
    title: {
      value: "",
      underline: Boolean(carouselConfig?.underlineCarouselTitle),
    },
    showPagination: Boolean(carouselConfig?.showCarouselPaginationButtons),
    showProgressBar: Boolean(carouselConfig?.showCarouselScrollBar),
    analyticsName: name,
  };

  const handleCardClick = (item: CardItemProps) => () => {
    onClick(item);
  };
  return (
    <div
      className={cn(
        {
          "flex flex-col": type !== "2-col-grid",
          "space-y-5": type === "stacked",
          "grid grid-cols-2": type === "2-col-grid",
          "-mx-8": type === "full-bleed" || type === "2-col-grid",
        },
        className
      )}
    >
      {type === "carousel" ? (
        <>
          <Media lessThan="twinXl">
            <CardCarousel
              {...sharedCarouselConfig}
              cardsToShow={carouselConfig?.numberOfMobileCarouselCards}
              peekPercentage={carouselConfig?.mobilePeekPercentage}
              location="mobile_nav"
            >
              {items.map((item) => (
                <MobileCard
                  key={item.url}
                  {...item}
                  onClick={handleCardClick(item)}
                />
              ))}
            </CardCarousel>
          </Media>
          <Media greaterThanOrEqual="twinXl">
            <CardCarousel
              {...sharedCarouselConfig}
              cardsToShow={carouselConfig?.numberOfTabletCarouselCards}
              peekPercentage={carouselConfig?.tabletPeekPercentage}
              location="mobile_nav"
            >
              {items.map((item) => (
                <MobileCard
                  key={item.url}
                  {...item}
                  onClick={handleCardClick(item)}
                />
              ))}
            </CardCarousel>
          </Media>
        </>
      ) : (
        items.map((item, index) => (
          <MobileCard
            onClick={handleCardClick(item)}
            key={item.url}
            {...item}
            textClassName={classNames({
              "ml-[7px] mr-[7px]": type === "2-col-grid" && index % 2 === 0,
              "mr-[7px]": type === "2-col-grid" && index % 2 === 1,
              "mx-8": type === "full-bleed",
              "min-h-[48px]": type === "2-col-grid",
            })}
          />
        ))
      )}
    </div>
  );
}
