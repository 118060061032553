import React from "react";
import { useRouter } from "next/router";

import useLogger from "../../hooks/useLogger";
import cartUtils from "../components/Cart/utils";
import { CartMutation } from "../queries/data/cartData";
import { MATTRESS_FEE_HANDLE } from "../services/cart/constants";
import { LineItem } from "../services/cart/types";
import { getShopifyId } from "../services/util/shopifyID";
import { isTruthy } from "../util";
import { LocaleCode } from "../util/locale";

import useCatalogWithCartIds from "./useCatalogWithCartIds";
import useTypedMutation from "./useTypedMutation";
import { useTypedQuery } from "./useTypedQuery";

function useRemoveUnidentifiableLineItems() {
  const router = useRouter();
  const [hasRunOnce, setHasRunOnce] = React.useState(false);

  const { logger } = useLogger();
  const { data: cart, isLoading: isCartLoading } = useTypedQuery([
    "cart",
    router.locale,
  ]);

  const catalog = useCatalogWithCartIds();

  const { mutate: removeFromCartMutation } = useTypedMutation(
    [CartMutation.Remove],
    {
      onError: (error) => {
        cartUtils.onCartError(
          error,
          logger,
          cart,
          "Error removing unidentifiable item from cart"
        );
      },
    }
  );

  React.useEffect(() => {
    // Exit if we've already run once or if there's no catalog
    if (hasRunOnce || !catalog || !Object.keys(catalog).length) return;

    // Check the cart for unidentifiable items and remove them
    if (cart) {
      setHasRunOnce(true);
      const lineItemsToRemove = cart.lineItems.reduce((lineItemsAcc, item) => {
        const { product } = item.variant;
        const id = getShopifyId(product?.id);
        const catalogProduct = catalog[id];
        const variantId = getShopifyId(item.variant.id);
        const variant = catalogProduct?.variants[variantId];

        // TODO find an alternative to hard-coding this product handle
        if (product.handle === MATTRESS_FEE_HANDLE || !(id in catalog))
          return lineItemsAcc;

        if (!catalogProduct || !variant) return [...lineItemsAcc, item];

        return lineItemsAcc;
      }, [] as LineItem[]);

      if (lineItemsToRemove.length > 0) {
        // Log the unidentifiable items
        lineItemsToRemove.filter(isTruthy).forEach(
          ({
            variant: {
              id: variantId,
              title: variantTitle,
              product: { handle, title: productTitle },
            },
          }) => {
            logger.error(
              `Unexpected line item in cart. variantId: ${variantId}, handle: ${handle}, cartId: ${cart.id}, variantTitle: ${variantTitle}, productTitle: ${productTitle}.`
            );
          }
        );
        removeFromCartMutation({
          locale: router.locale as LocaleCode,
          payload: lineItemsToRemove.map((lineItem) => ({ lineItem })),
        });
      }
    }
    // If there is no cart and the cart is not loading, the user has not created a cart.
    // Which means there cannot be any unidentifiable line items because there are no line items.
    else if (!isCartLoading) setHasRunOnce(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRunOnce, cart, isCartLoading, catalog]);
}

export default useRemoveUnidentifiableLineItems;
