import { useContext } from "react";
import { Heading, ImageContext } from "@components";
import { BLOCKS, Document, Node } from "@contentful/rich-text-types";
import classNames from "classnames";

import { useRunOnVisibleOnce } from "../../../hooks/useIntersectionObserver";
import { Color, makeColorStyles } from "../../../utils/makeColorStyles";
import { AnalyticsProvider, useAnalytics } from "../../hooks/useAnalytics";
import { addHeapViewHighlightSection } from "../../services/elevar";
import { RichText } from "../RichText";
import { ImageData } from "../util";

import selectors from "./selectors";

type ContentAlignment = "left" | "center";

export interface Highlight {
  image?: ImageData;
  name: string;
  id: string;
  title: string;
  content: Document;
  alignment?: ContentAlignment;
  desktopAlignment?: ContentAlignment;
}

export interface HighlightItemProps {
  item: Highlight;
  colorStyles: Record<string, string>;
}

const renderBullets = (
  alignment?: ContentAlignment,
  desktopAlignment?: ContentAlignment
) =>
  function UnorderedList(_node: Node, children: React.ReactNode) {
    return (
      <ul
        className={classNames(
          "flex flex-col queen:pl-7 list-disc",
          alignment === "center" && "items-center",
          desktopAlignment === "center" && "queen:items-center",
          desktopAlignment === "left" && "queen:items-start"
        )}
      >
        {children}
      </ul>
    );
  };

function HighlightItem({
  item: { image, title, content, alignment = "left", desktopAlignment },
  colorStyles,
}: HighlightItemProps) {
  const ImageElement = useContext(ImageContext);

  return (
    <li
      key={title}
      className={classNames(
        "w-full queen:w-[200px] flex flex-col flex-initial",
        alignment === "center" && "items-center",
        alignment === "left" && "items-start",
        desktopAlignment === "center" && "queen:items-center",
        desktopAlignment === "left" && "queen:items-start"
      )}
    >
      {image ? (
        <div
          className={classNames(
            "self-center max-w-[40px] max-h-10",
            title && "mb-3"
          )}
        >
          <ImageElement
            src={image.src}
            alt={image.alt}
            width={image.width}
            height={image.height}
            data-testid={selectors.highlighSectionItemImage}
          />
        </div>
      ) : null}
      <Heading
        as="h3"
        variant="h5"
        data-testid={selectors.highlightSectionItemTitle}
        className={classNames(
          "!text-sm leading-5 pb-2 uppercase queen:leading-none",
          alignment === "center" && "text-center",
          desktopAlignment === "center" && "queen:text-center",
          desktopAlignment === "left" && "queen:text-left"
        )}
      >
        {title}
      </Heading>
      <div
        style={colorStyles}
        className={classNames(
          "text-base leading-[1.8em] font-normal",
          alignment === "center" && "text-center",
          desktopAlignment === "center" && "queen:text-center",
          desktopAlignment === "left" && "queen:text-left"
        )}
        data-testid={selectors.highlightSectionItemContent}
      >
        <RichText
          richTextResponse={content}
          customOptions={{
            [BLOCKS.UL_LIST]: renderBullets(alignment, desktopAlignment),
          }}
        />
      </div>
    </li>
  );
}

interface Props {
  items: Highlight[];
  title?: string;
  titleAlignment?: "center" | "left";
  backgroundColor?: Color;
  color?: Color;
  bodyTextColor?: Color;
}

export function HighlightsSection({
  items,
  title,
  titleAlignment = "left",
  backgroundColor = "dune",
  color = "charcoal",
  bodyTextColor = "slate",
}: Props) {
  const colorStyles = makeColorStyles(backgroundColor, color);
  const bodyColorStyles = makeColorStyles(backgroundColor, bodyTextColor);
  const { currentObject } = useAnalytics();
  const ref = useRunOnVisibleOnce(() =>
    addHeapViewHighlightSection(currentObject.name, currentObject.id)
  );

  return (
    <section style={colorStyles} className="flex justify-center">
      <div
        ref={ref}
        className={classNames(
          "flex flex-col w-full items-center px-6 py-8 max-w-screen-king gap-8 king:gap-24 full:flex-row queen:py-14 full:px-8",
          title && "justify-center full:justify-between",
          !title && "justify-center"
        )}
      >
        {title ? (
          <div className="flex items-center justify-center flex-auto max-w-[640px] queen:max-w-lg">
            <Heading
              as="h2"
              variant="h5"
              className={classNames(
                "!font-serif !leading-eighth !text-[25px] queen:!text-lg",
                titleAlignment === "left" && "text-center full:text-left", // Never left on mobile
                titleAlignment === "center" && "text-center"
              )}
              data-testid={selectors.highlightSectionTitle}
            >
              {title}
            </Heading>
          </div>
        ) : null}
        <ul className="flex flex-col flex-initial highlights-custom-gap full:flex-row">
          {items.map((item) => (
            <AnalyticsProvider
              object={{ name: item.name, id: item.id }}
              key={item.title}
            >
              <HighlightItem item={item} colorStyles={bodyColorStyles} />
            </AnalyticsProvider>
          ))}
        </ul>
      </div>
    </section>
  );
}
