import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { ClientQueries } from "../queries/client";

export type ThumaQueryKey<
  QueryName extends keyof ClientQueries = keyof ClientQueries,
  QueryArgs extends Parameters<ClientQueries[QueryName]> = Parameters<
    ClientQueries[QueryName]
  >
> = [queryName: QueryName, ...queryArgs: QueryArgs];

/*
 * This is really just a wrapper for typescript conveniences, everything just gets passed through to useQuery
 */
export function useTypedQuery<
  QK extends ThumaQueryKey,
  QueryName extends keyof ClientQueries = QK[0],
  Return extends Awaited<ReturnType<ClientQueries[QueryName]>> = Awaited<
    ReturnType<ClientQueries[QueryName]>
  >
>(
  [queryName, ...queryArgs]: QK,
  options?: Omit<UseQueryOptions<Return>, "queryKey">
): UseQueryResult<Return> {
  return useQuery([queryName, ...queryArgs], options);
}

/*
 * This is really just a wrapper for typescript conveniences, everything just gets passed through to useInfiniteQuery
 */
export function useTypedInfiniteQuery<
  QK extends ThumaQueryKey,
  QueryName extends keyof ClientQueries = QK[0],
  Return = Awaited<ReturnType<ClientQueries[QueryName]>>
>(
  [queryName, ...queryArgs]: QK,
  options?: Omit<UseInfiniteQueryOptions<Return>, "queryKey">
): UseInfiniteQueryResult<Return> {
  return useInfiniteQuery([queryName, ...queryArgs], options);
}
