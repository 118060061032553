import { MouseEvent } from "react";
import { Button, Swatch } from "@components";

import { MergedOption } from "../../../../services/util/optionRendering";

import selectors from "./selectors";

interface ToolbarProps {
  option: Omit<MergedOption, "fullWidth" | "mobileOrder"> | undefined;
  activeValue: string | undefined;
  truncatedItems: number;
  onOptionClick: (value: string) => void;
}
export default function Toolbar({
  option,
  activeValue,
  truncatedItems,
  onOptionClick,
}: ToolbarProps) {
  return (
    <div
      data-testid={selectors.toolbar.container}
      className="flex items-center gap-2 text-xs"
    >
      {option && option.values.length && (
        <div className="bg-white py-2 lg:py-1 flex items-center gap-2">
          {option.values.map(({ value, image }) => {
            const imageUrl = image?.src as string;
            const isActive = activeValue === value;
            const handleClick = (event: MouseEvent) => {
              event.stopPropagation();
              event.preventDefault();

              onOptionClick(value);
            };

            return (
              <Button
                key={value}
                data-testid={selectors.toolbar.swatchButton}
                type="button"
                variant="unstyled"
                onClick={handleClick}
                data-active={isActive}
              >
                <Swatch
                  image={imageUrl}
                  alt={value}
                  size="medium"
                  borderBehavior={isActive ? "always" : "hover"}
                />
              </Button>
            );
          })}

          {truncatedItems > 0 && (
            <span data-testid={selectors.toolbar.truncatedItemCount}>
              <Swatch
                text={`+${truncatedItems}`}
                size="medium"
                borderBehavior="never"
              />
            </span>
          )}
        </div>
      )}
    </div>
  );
}
