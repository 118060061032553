/* eslint-disable import/prefer-default-export */
import * as React from "react";
import classNames from "classnames";

interface LoadingIndicatorProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Additional classes */
  /** Value to be used as the element's ARIA label */
  label?: string;
  size?: "small" | "normal";
  numberOfDots?: number;

  containerClassName?: string;
  dotClassName?: string;
}

/* TODO: Translate the default label */
/**
 * An animated graphic used to indicate loading states.
 */
export function LoadingIndicator({
  containerClassName,
  dotClassName,
  label = "Loading",
  size = "normal",
  numberOfDots = 3,
  ...rest
}: LoadingIndicatorProps) {
  /** the number of dots to show in the indicator */
  const dots = Array.from(Array(numberOfDots).keys());

  const smallSizeDotClasses = "w-1 h-1 mx-0.5";
  const normalSizeDotClasses = "w-3 h-3 mx-1";

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div aria-label={label} className={containerClassName} {...rest}>
      {dots.map((number, index) => (
        <span
          key={number}
          className={classNames(
            "inline-block rounded-full bg-sage animate-big-bounce",
            {
              [smallSizeDotClasses]: size === "small",
              [normalSizeDotClasses]: size === "normal",
            },
            dotClassName
          )}
          style={{ animationDelay: `${index * 0.2}s` }}
        />
      ))}
    </div>
  );
}
