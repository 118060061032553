import { Cart } from "../../services/cart/types";
import {
  makeQueryFn,
  QueryClientPlugin,
} from "../../services/util/makeQueryClient";
import type { ClientDataServices } from "../client";

export const recommendationsQueryFns = ({
  recommendationsAPI,
}: ClientDataServices) => ({
  // This is typed this way so that recommendations are re-fetched when the cart changes
  recommendations: (
    ...args: [
      ...Parameters<typeof recommendationsAPI.getRecommendedProducts>,
      Cart | undefined
    ]
  ) => {
    const params = args.slice(0, args.length - 1) as Parameters<
      typeof recommendationsAPI.getRecommendedProducts
    >;
    return recommendationsAPI.getRecommendedProducts(...params);
  },
});

const recommendationsPlugin: QueryClientPlugin<ClientDataServices> = (
  queryClient,
  dataServices
) => {
  const { recommendations } = recommendationsQueryFns(dataServices);

  queryClient.setQueryDefaults(["recommendations"], {
    queryFn: makeQueryFn(recommendations),
  });
};

export default recommendationsPlugin;
