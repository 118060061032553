/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Heading } from "@components";

import { cn } from "../../utils/tailwind-merge";

const deprecatedColumnClasses: Record<number, string> = {
  2: "md:grid-cols-4 lg:grid-cols-2",
  3: "md:grid-cols-3",
  4: "md:grid-cols-3 lg:grid-cols-4",
  5: "md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5",
};

const mobileColumnClasses: Record<number, string> = {
  1: "grid-cols-1 gap-4", // if only one column, 16px gap
  2: "grid-cols-2",
  3: "grid-cols-3",
  4: "grid-cols-4",
};

const tabletColumnClasses: Record<number, string> = {
  1: "twinXL:grid-cols-1",
  2: "twinXl:grid-cols-2",
  3: "twinXl:grid-cols-3",
  4: "twinXl:grid-cols-4",
};

const desktopColumnClasses: Record<number, string> = {
  1: "full:grid-cols-1 full:gap-4",
  2: "full:grid-cols-2 full:gap-4",
  3: "full:grid-cols-3 full:gap-4",
  4: "full:grid-cols-4 full:gap-4",
};

export type DeprecatedColumnLimit = keyof typeof deprecatedColumnClasses;
export type ColumnLimit = keyof typeof mobileColumnClasses;

interface Props {
  children: React.ReactNode;
  /**
   * Underlines the title
   */
  underline?: boolean;
  title?: string;
  /**
   * Legacy grid columns, adjusts column count on several breakpoints
   */
  deprecatedColumns?: DeprecatedColumnLimit;
  /**
   * Supports modern Grid layout for Brand pages
   */
  columns?: {
    mobile: ColumnLimit;
    tablet?: ColumnLimit;
    desktop?: ColumnLimit;
  };
  className?: string;
}

export function Grid({
  columns: staticColumns,
  title,
  deprecatedColumns = 3,
  className,
  underline,
  children,
}: Props) {
  return (
    <>
      {title ? (
        <div className="mb-6 full:mb-8">
          <Heading
            as="h2"
            variant="h4"
            className="text-[25px] full:text-lg leading-eighth"
          >
            {title}
          </Heading>
          {underline && <div className="w-20 h-[3px] mt-4 bg-charcoal" />}
        </div>
      ) : null}
      <div
        className={cn(
          "grid gap-4",
          staticColumns
            ? [
                "gap-3 auto-rows-auto",
                mobileColumnClasses[staticColumns.mobile],
                staticColumns.tablet &&
                  tabletColumnClasses[staticColumns.tablet],
                staticColumns.desktop &&
                  desktopColumnClasses[staticColumns.desktop],
              ]
            : `${deprecatedColumnClasses[deprecatedColumns]} auto-rows-fr`,
          className
        )}
      >
        {children}
      </div>
    </>
  );
}
