import { RefObject, useEffect, useState } from "react";
import { useIsMutating } from "react-query";

import { CartMutation } from "../../../queries/data/cartData";

/**
 * Returns an element to place into the cart which
 * automatically scrolls into view when a new item is added
 * @param isLoading Whether the cart data is currently loading
 * @param isOpen Whether the cart is currently open
 * @returns An element to place at the bottom of the desired scroll container
 */
export default function useScrollCartToBottom(
  isLoading: boolean,
  isOpen: boolean,
  scrollElementRef: RefObject<HTMLLIElement>
) {
  const [isAddingItem, setIsAddingItem] = useState(isOpen);

  useIsMutating({
    predicate: ({ options: { mutationKey } }) => {
      if (mutationKey === undefined) return false;

      const key = Array.isArray(mutationKey) ? mutationKey[0] : mutationKey;
      if (key === CartMutation.AddToCart && !isAddingItem)
        setIsAddingItem(true);

      return true;
    },
  });

  useEffect(() => {
    if (!isAddingItem || isLoading || !scrollElementRef.current) return;

    const element = scrollElementRef.current;
    setTimeout(() => {
      element.scrollIntoView({ behavior: "smooth" });
    });
    setIsAddingItem(false);
  }, [isAddingItem, isLoading, scrollElementRef]);
}
