import { useContext, useMemo, useState } from "react";
import {
  Button,
  Heading,
  Icon,
  ImageContext,
  LoadingIndicator,
} from "@components";
import classNames from "classnames";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import { useVerticalSpacing } from "src/lib/context/VerticalSpacingContext";
import { useBreakpoints } from "src/lib/hooks/useBreakpoints";
import { IProductTileProps } from "src/lib/services/elevar/interfaces";

import { useRunOnVisibleOnce } from "../../../hooks/useIntersectionObserver";
import useVisibility from "../../../hooks/useVisibility";
import {
  addHeapProductTileClicked,
  addHeapProductTileViewed,
  addHeapShopTheLookMarkerClicked,
  addHeapShopTheLookProductCardClicked,
  addHeapShopTheLookProductListToggled,
  addHeapShopTheLookSectionViewed,
} from "../../services/elevar/events";
import { isTruthy } from "../../util";
import { Media } from "../Media";
import { ImageData } from "../util";

import MarkerCard from "./components/MarkerCard";
import selectors from "./selectors";
import type { Marker } from "./types";

interface ShopTheLookSectionProps {
  name: string;
  title: string;
  image: ImageData;
  mobileImage?: ImageData;
  buttonText: string;
  buttonTextOpen: string;
  productListTitle: string;
  detailText: string;
  markers: Marker[];
  isLoading: boolean;
}

// These are determined by the card widths for the different breakpoints
// mobile uses 3/4, so only the first 2 cards are initially visible
const MAX_VISIBLE_INDEX_MOBILE = 1;
// desktop uses 1/5, so only the first 5 cards are initially visible
const MAX_VISIBLE_INDEX_DESKTOP = 4;

/**
 * Renders an image with markers each tied to a product variant that,
 * when clicked, navigate the user to that product page
 */
export function ShopTheLookSection({
  name,
  title,
  image,
  mobileImage,
  buttonText,
  buttonTextOpen,
  productListTitle,
  detailText,
  markers,
  isLoading,
}: ShopTheLookSectionProps) {
  const [initiallyActiveMarker, setInitiallyActiveMarker] =
    useState<Marker | null>(null);
  const [activeMarker, setActiveMarker] = useState<Marker | null>(null);
  const ImageElement = useContext(ImageContext);

  const { lgVisible } = useBreakpoints();

  const sortedMarkers = useMemo(
    () =>
      markers
        .map((marker) => ({
          marker,
          isActive: initiallyActiveMarker?.variantID === marker.variantID,
        }))
        .filter(isTruthy)
        .sort((a, b) => {
          const getActiveStateValue = (m: { isActive: boolean }) =>
            m.isActive ? 1 : 0;
          return getActiveStateValue(b) - getActiveStateValue(a);
        }),
    [markers, initiallyActiveMarker]
  );

  const {
    isProductListVisible,
    showProductList,
    toggleProductList,
    hideProductList,
  } = useVisibility("ProductList", false, {
    onShow: () => {
      addHeapShopTheLookProductListToggled(name, "opened");
    },
    onHide: () => {
      addHeapShopTheLookProductListToggled(name, "closed");
      setActiveMarker(null);
      setInitiallyActiveMarker(null);
    },
  });

  const containerRef = useRunOnVisibleOnce(() => {
    addHeapShopTheLookSectionViewed(name);
  });

  const handleMarkerClick = (marker: Marker) => () => {
    addHeapShopTheLookMarkerClicked(
      name,
      marker.product.productTitle,
      marker.variantID,
      marker.product.variants[marker.variantID].title,
      marker.product.variants[marker.variantID].sku
    );

    setInitiallyActiveMarker(marker);
    setActiveMarker(marker);
    showProductList();
  };

  const getProductTileEventProps = (
    marker: Marker,
    index: number,
    compareIndex: number
  ): IProductTileProps => {
    const visibleOnPageload = index <= compareIndex ? "yes" : "no";

    return {
      productID: marker.product.id,
      productTitle: marker.product.productTitle,
      sku: marker.product.variants[marker.variantID].sku,
      variantID: marker.variantID,
      variantTitle: marker.product.variants[marker.variantID].title,
      productTileTitle: marker.product.productTitle,
      price: marker.product.variants[marker.variantID].price,
      location: "Shop The Look",
      productListSectionTitle: title,
      productTileSectionPosition: index + 1,
      defaultImageURL:
        marker.product.variants[marker.product.defaultVariant].images[0].url,
      displayedImageURL:
        marker.product.variants[marker.variantID].images[0].url,
      visibleOnPageload,
    };
  };

  const compareIndex = lgVisible
    ? MAX_VISIBLE_INDEX_DESKTOP
    : MAX_VISIBLE_INDEX_MOBILE;

  const handleProductCardClick = (marker: Marker, index: number) => () => {
    addHeapShopTheLookProductCardClicked(
      name,
      marker.product.productTitle,
      marker.variantID,
      marker.product.variants[marker.variantID].title,
      index + 1
    );
    addHeapProductTileClicked(
      getProductTileEventProps(marker, index, compareIndex)
    );
  };

  const handleProductTileViewed = (marker: Marker, index: number) => () => {
    addHeapProductTileViewed(
      getProductTileEventProps(marker, index, compareIndex)
    );
  };

  const { hasVerticalSpacing } = useVerticalSpacing();

  return (
    <MotionConfig transition={{ duration: 0.4 }}>
      <motion.section
        layout
        ref={containerRef}
        data-testid={selectors.container}
        className={classNames(
          "relative w-full mx-auto max-w-screen-caliKing lg:overflow-hidden  lg:text-white lg:bg-charcoal",
          { "lg:mb-12": hasVerticalSpacing }
        )}
      >
        <motion.div layout className="relative z-10 lg:z-0">
          {/* image */}
          <div
            data-testid={selectors.image.container}
            className="relative w-full aspect-square lg:aspect-video"
          >
            {mobileImage && (
              <Media
                lessThan="lg"
                className="w-full h-full"
                data-testid={selectors.image.mobileImage}
              >
                <ImageElement src={mobileImage.src} alt={mobileImage.alt} />
              </Media>
            )}

            <div
              className={classNames("w-full h-full", {
                "hidden lg:block": mobileImage,
              })}
            >
              <ImageElement src={image.src} alt={image.alt} />
            </div>
          </div>

          {/* info on top */}
          <div
            className={classNames(
              "absolute isolate inset-0 p-5 lg:p-8 flex flex-col justify-between items-start",
              "before:absolute before:top-0 before:inset-x-0 before:h-[10%] before:content-[''] before:-z-10",
              "before:invisible lg:before:visible before:bg-gradient-to-b before:from-charcoal/50 before:to-charcoal/0",
              "after:absolute after:bottom-0 after:inset-x-0 after:h-[10%] after:content-[''] after:-z-10",
              "after:invisible lg:after:visible after:bg-gradient-to-t after:from-charcoal/50 after:to-charcoal/0"
            )}
          >
            <Heading
              as="h3"
              variant="h5"
              className="invisible text-base lg:visible"
            >
              {title}
            </Heading>

            {/* marker container */}
            <ul
              data-testid={selectors.markerList.container}
              className={classNames(
                "absolute inset-0 invisible transition pointer-events-none lg:pointer-events-auto lg:visible",
                { "opacity-0": isLoading }
              )}
            >
              {sortedMarkers.map(({ marker }) => (
                <li
                  key={marker.variantID}
                  style={{ left: marker.x, top: marker.y }}
                  data-testid={selectors.markerList.marker}
                  className={classNames(
                    "absolute w-4 h-4 -translate-x-1/2 -translate-y-1/2 bg-white rounded-full transition",
                    "outline outline-1 outline-offset-4 outline-white shadow-sm shadow-flint-25 hover:opacity-[85%]",
                    {
                      "opacity-50":
                        activeMarker &&
                        activeMarker.variantID !== marker.variantID,
                    }
                  )}
                >
                  <button
                    type="button"
                    className="w-full h-full"
                    data-testid={selectors.markerList.button}
                    onClick={handleMarkerClick(marker)}
                    disabled={isLoading}
                  >
                    <span className="sr-only">{detailText}</span>
                  </button>
                </li>
              ))}
            </ul>

            <Button
              data-testid={selectors.toggleButton}
              className={classNames(
                "p-2 px-2.5 uppercase rounded-xl",
                "text-sm flex gap-1 items-center transition",
                "bg-charcoal/90 hover:bg-charcoal/75 lg:backdrop-blur",
                "before:content-[''] before:absolute before:inset-0 lg:before:hidden",
                { "lg:opacity-0": isProductListVisible }
              )}
              onClick={toggleProductList}
              disabled={isLoading}
            >
              {!isLoading ? (
                <>
                  <Icon name="tags" size="inherit" />
                  {isProductListVisible ? buttonTextOpen : buttonText}
                </>
              ) : (
                <LoadingIndicator size="small" />
              )}
            </Button>
          </div>
        </motion.div>

        <AnimatePresence>
          {isProductListVisible && (
            <>
              {/* backdrop */}
              <motion.button
                data-testid={selectors.productList.backdrop}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={hideProductList}
                type="button"
                key="backdrop"
                className="absolute inset-0 invisible lg:visible lg:bg-charcoal/5"
              >
                <span className="sr-only">{buttonTextOpen}</span>
              </motion.button>

              {/* product list */}
              <motion.div
                key="product-list"
                initial={{
                  opacity: "var(--opacity-from)",
                  translateY: "var(--translate-from)",
                }}
                animate={{ opacity: "100%", translateY: "0%" }}
                exit={{
                  opacity: "var(--opacity-to)",
                  translateY: "var(--translate-to)",
                }}
                className={classNames(
                  "inset-x-0 bottom-0 lg:absolute lg:border-t lg:border-t-white lg:bg-charcoal/50 lg:backdrop-blur",
                  "[--opacity-from:100%] [--opacity-to:100%] lg:[--opacity-from:0%] lg:[--opacity-to:0%]",
                  "[--translate-from:-50%] [--translate-to:-100%] lg:[--translate-from:0%] lg:[--translate-to:0%]"
                )}
              >
                <button
                  data-testid={selectors.productList.closeButton}
                  type="button"
                  className="absolute top-0 right-0 invisible p-3 lg:visible"
                  onClick={hideProductList}
                >
                  <Icon name="close" size="inherit" />
                </button>

                <p className="pl-6 mt-6 mb-4 lg:hidden">{productListTitle}</p>

                {/* Product card container */}
                <ul
                  data-testid={selectors.productList.container}
                  className="flex gap-3 px-6 pb-8 overflow-x-auto lg:py-8 lg:gap-1 group no-scrollbar"
                >
                  {sortedMarkers.map(({ marker }, index) => {
                    const isActive =
                      activeMarker?.variantID === marker.variantID;
                    const activate = () => setActiveMarker(marker);

                    return (
                      <li
                        data-testid={selectors.productList.product}
                        key={marker.variantID}
                        className={classNames(
                          "w-3/4 lg:w-1/5 lg:p-2 rounded shrink-0 transition",
                          {
                            "lg:bg-flint/40": isActive,
                          }
                        )}
                        data-active={isActive}
                        onMouseEnter={activate}
                        onFocus={activate}
                      >
                        <MarkerCard
                          data={marker}
                          buttonText={detailText}
                          onClick={handleProductCardClick(marker, index)}
                          onViewed={handleProductTileViewed(marker, index)}
                        />
                      </li>
                    );
                  })}
                </ul>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </motion.section>
    </MotionConfig>
  );
}
