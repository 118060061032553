import Script from "next/script";

export function Elevar() {
  return (
    <Script type="module" id="elevar" data-testid="elevar-non-shopify-script">{`
    try {
    const settings = {};
    const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/bd50852c2f7afe646616f4609f3914d3e68dd959/config.js")).default;
    const scriptUrl = settings.proxyPath
      ? settings.proxyPath + config.script_src_custom_pages_proxied
      : config.script_src_custom_pages;

    if (scriptUrl) {
      const { handler } = await import(scriptUrl);
      await handler(config, settings);
    }
  } catch (error) {
    console.error("Elevar Error:", error);
  }
  `}</Script>
  );
}
