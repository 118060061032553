import { useRouter } from "next/router";

import { updateLocaleCode } from "../services/elevar/events";
import type { LocaleCode } from "../util/locale";

export default function useSyncEventsLocale() {
  const router = useRouter();
  const localeCode = router.locale as LocaleCode;

  if (!localeCode) return;
  updateLocaleCode(localeCode);
}
