/* eslint-disable import/prefer-default-export */
import * as React from "react";

import type { theme } from "../../../tailwind.config";
import { cn } from "../../utils/tailwind-merge";

type TagOptions = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

type FontFamilyOptions = keyof typeof theme["fontFamily"];

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  /** The native html tag to use for the heading */
  as: TagOptions;
  /** The variant style to use for the heading */
  variant?: TagOptions;
  /** The font family to use for heading */
  fontFamily?: FontFamilyOptions;
  /** Additional classnames */
  className?: string;
}

/**
 * A heading component used to consistently style header text.
 */
export function Heading({
  as,
  children,
  variant = as,
  fontFamily = "serif",
  className,
  ...rest
}: React.PropsWithChildren<HeadingProps>) {
  const ResolvedTag = as;
  return (
    <ResolvedTag
      className={cn(
        `font-normal`,
        { "leading-tight text-3xl": variant === "h1" },
        { "leading-normal text-2xl": variant === "h2" },
        { "leading-normal text-xl": variant === "h3" },
        { "leading-eighth text-lg": variant === "h4" },
        { "leading-normal text-md": variant === "h5" },
        { "leading-tight text-xs md:text-base": variant === "h6" },
        { "font-serif": fontFamily === "serif" && variant !== "h5" },
        { "font-sans": fontFamily === "sans" || variant === "h5" },
        className
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </ResolvedTag>
  );
}
