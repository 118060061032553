import classNames from "classnames";

import { ICenteredRichText } from "../../../../../@types/generated/contentful";
import { CenteredRichText } from "../../CenteredRichText";
import DynamicComponents from "../dynamicImports";
import { PromoteH1Type } from "../types";

export default function CenteredRichTextSaddle({
  content,
}: {
  content: ICenteredRichText & PromoteH1Type;
}) {
  const {
    promoteH1,
    fields: {
      backgroundColor,
      backgroundImage,
      disablePadding,
      padding,
      text,
      textAlignment,
      textColor,
      title,
      width,
      overlayOpacity,
      overlayColorTop,
      overlayColorMiddle,
      overlayColorBottom,
      link,
    },
  } = content as ICenteredRichText & PromoteH1Type;
  const richText = <DynamicComponents.RichText richTextResponse={text} />;

  return (
    <CenteredRichText
      key={content.sys.id}
      bgColor={backgroundColor}
      textColor={textColor}
      bgImage={backgroundImage?.fields.file.url}
      textAlignment={textAlignment}
      padding={padding}
      title={title}
      width={width}
      promoteH1={promoteH1}
      overlayOpacity={overlayOpacity}
      overlayColorTop={overlayColorTop}
      overlayColorMiddle={overlayColorMiddle}
      overlayColorBottom={overlayColorBottom}
      link={link}
    >
      <div
        className={classNames("mx-auto lg:mx-0", {
          "max-w-xs lg:max-w-none": !width,
        })}
      >
        {title ? (
          richText
        ) : (
          <DynamicComponents.AnimateVisible
            fadeIn
            animateUp
            className={classNames(
              // TODO replace !disablePadding with padding === "none" when Contentful entries have been updated
              { "my-12 lg:my-[78px] lg:py-2": !disablePadding }
            )}
          >
            {richText}
          </DynamicComponents.AnimateVisible>
        )}
      </div>
    </CenteredRichText>
  );
}
