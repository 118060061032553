import * as React from "react";
import { Button, Icon } from "@components";
import classNames from "classnames";

import MarketingServiceContext from "../../context/MarketingServiceContext";
import { useBreakpoints } from "../../hooks/useBreakpoints";

import EmailForm, { EmailFormVisualProps } from "./views/EmailForm";
import { PhoneFormView } from "./views/PhoneFormView";
import { PostSubmitView, PostSubmitViewProps } from "./views/PostSubmitView";
import selectors from "./selectors";

type IWizardState = "email" | "phone" | "complete";

enum WizardState {
  EMAIL = "email",
  PHONE = "phone",
  COMPLETE = "complete",
}

interface IWizardProps {
  onClose: () => void;
  isOrganicSearch: boolean;
  emailFormProps: EmailFormVisualProps;
  postSubmitProps: Pick<PostSubmitViewProps, "title" | "body" | "cta">;
  dismissButtonText: string;
}

function Wizard({
  onClose,
  isOrganicSearch,
  emailFormProps,
  postSubmitProps,
  dismissButtonText,
}: IWizardProps) {
  const { mdVisible } = useBreakpoints();
  const marketingService = React.useContext(MarketingServiceContext);
  const [wizardState, setWizardState] = React.useState<IWizardState>(
    WizardState.EMAIL
  );

  return (
    <>
      <div
        className={classNames("flex justify-end", {
          "mb-10": !isOrganicSearch,
          "mt-4 mr-4": isOrganicSearch,
        })}
      >
        <Button
          variant="unstyled"
          onClick={onClose}
          data-testid={selectors.wizardFormView.closeButton}
        >
          <Icon name="close" />
        </Button>
      </div>
      {wizardState !== WizardState.COMPLETE && (
        <div
          className="flex flex-col items-center"
          data-testid={selectors.emailFormView.container}
        >
          {wizardState === WizardState.EMAIL && (
            <EmailForm
              onFormComplete={() => {
                setWizardState(
                  !mdVisible && !isOrganicSearch
                    ? WizardState.PHONE
                    : WizardState.COMPLETE
                );
                marketingService.setSubscribedOn(new Date().toString());
              }}
              orientation={isOrganicSearch ? "horizontal" : "vertical"}
              onClose={onClose}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...emailFormProps}
            />
          )}
          {wizardState === WizardState.PHONE && (
            <PhoneFormView
              onFormComplete={() => {
                setWizardState(WizardState.COMPLETE);
              }}
            />
          )}
          {!isOrganicSearch && (
            <Button
              variant="unstyled"
              data-testid={selectors.wizardFormView.noThanksButton}
              onClick={() => {
                if (wizardState === WizardState.PHONE) {
                  setWizardState(WizardState.COMPLETE);
                } else {
                  onClose();
                }
              }}
              className="uppercase mt-7"
            >
              {dismissButtonText}
            </Button>
          )}
        </div>
      )}

      {wizardState === WizardState.COMPLETE && (
        <div
          data-testid={selectors.postSubmitView.container}
          className={classNames({
            "px-5": isOrganicSearch,
            "mb-20": !isOrganicSearch,
          })}
        >
          <PostSubmitView
            variant={isOrganicSearch ? "organic_search" : "default"}
            onClose={onClose}
            dismissFormButtonText={dismissButtonText}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...postSubmitProps}
          />
        </div>
      )}
    </>
  );
}

export default Wizard;
