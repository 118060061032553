// @ts-check
/** @typedef {import('./locale').LocalizedConfig} LocalizedConfig */
/** @type {LocalizedConfig} */
const localeMap = {
  "en-CA": {
    countryCode: "CA",
    currencyCode: "CAD",
    languageCode: "EN",
    languageName: { "en-US": "English", "en-CA": "English" },
    countryName: { "en-US": "Canada", "en-CA": "Canada" },
    countryShortCode: { "en-US": "CAN", "en-CA": "CAN" },
    region: { "en-US": "North America", "en-CA": "North America" },
  },
  "en-US": {
    countryCode: "US",
    currencyCode: "USD",
    languageCode: "EN",
    languageName: { "en-US": "English", "en-CA": "English" },
    countryName: { "en-US": "United States", "en-CA": "United States" },
    countryShortCode: { "en-US": "USA", "en-CA": "USA" },
    region: { "en-US": "North America", "en-CA": "North America" },
  },
};

module.exports = localeMap;
