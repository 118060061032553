import { Button, Icon } from "@components";
import classNames from "classnames";

interface BackButtonProps {
  text?: string;
  onClick?: () => void;
  testID?: string;
}
export function BackButton({ text, onClick, testID }: BackButtonProps) {
  return (
    <Button
      variant="unstyled"
      className={classNames(
        // Offset for height of the top bar, would move to variable but this version
        // of tailwind doesn't love that.
        `sticky top-[88px] -mx-8 px-8 z-10 flex items-center w-screen gap-4 py-4`,
        "leading-tight text-left uppercase bg-white",
        { "border-b border-b-lightgray mb-6": !text, "mb-2": text }
      )}
      onClick={onClick}
      data-testid={testID}
    >
      <Icon name="chevron-left" size="inherit" className="text-tiny" />
      {text}
    </Button>
  );
}
