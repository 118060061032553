import React from "react";
import { AnimateVisible, Heading } from "@components";
import classNames from "classnames";
import { useVerticalSpacing } from "src/lib/context/VerticalSpacingContext";

import { RichText } from "../../RichText/RichText";
import { TextMedia } from "../../TextMedia/TextMedia";
import { PromoteH1Type, TextMediaType } from "../types";
import textMediaProp from "../util";

interface Props {
  content: TextMediaType & PromoteH1Type;
}

export default function TextImageSectionSaddle({ content }: Props) {
  const { hasVerticalSpacing } = useVerticalSpacing();
  const { promoteH1 = false } = content;
  const {
    preHeading,
    heading,
    image,
    contentRatio,
    alignTop,
    mobileLayoutStyle,
    desktopLayoutStyle,
    backgroundColor,
    body,
    videoConfig,
    textOverlayMedia,
  } = content.fields;

  const mediaDetails = image
    ? {
        title: image.fields.title,
        url: image.fields.file.url,
        width: image.fields.file.details.image?.width ?? 0,
        height: image.fields.file.details.image?.height ?? 0,
        type: image.fields.file.contentType,
      }
    : undefined;

  return (
    <div
      key={content?.sys?.id}
      className={classNames({
        "mb-12 lg:mb-16": hasVerticalSpacing,
      })}
    >
      <TextMedia
        media={mediaDetails}
        mobileLayoutStyle={mobileLayoutStyle}
        desktopLayoutStyle={desktopLayoutStyle}
        contentRatio={contentRatio}
        alignTop={alignTop}
        backgroundColor={backgroundColor}
        fadeIn
        videoConfig={videoConfig}
        textOverlayMedia={
          textOverlayMedia ? textMediaProp(textOverlayMedia) : undefined
        }
      >
        <AnimateVisible fadeIn animateUp>
          <p className="text-sm uppercase">{preHeading}</p>
          <Heading
            variant="h3"
            as={promoteH1 ? "h1" : "h3"}
            className="leading-tight lg:leading-snug lg:text-2xl"
          >
            {heading}
          </Heading>
          {body && <RichText richTextResponse={body} />}
        </AnimateVisible>
      </TextMedia>
    </div>
  );
}
