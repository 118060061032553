/* eslint-disable import/prefer-default-export */
import type {
  IResponsiveAsset,
  IResponsiveAssetFields,
} from "../../../@types/generated/contentful";
import { ImageProps } from "../../components/Image/Image";

import { getImageProps } from "./getImageProps";
import { getResponsiveAsset } from "./getResponsiveAsset";
import { getVideoProps } from "./getVideoProps";

type Viewports = Required<IResponsiveAssetFields>["viewport"];

export interface AssetType extends ImageProps {
  type: string;
  previewImage?: string;
}

export type ResponsiveImageAsset = {
  [key in Viewports]: AssetType;
};

export function getResponsiveImageOrVideo(
  assets: IResponsiveAsset[]
): ResponsiveImageAsset | undefined {
  const asset = getResponsiveAsset(assets);
  if (asset) {
    const mobileProps = asset.mobile.fields.file.contentType.includes("video")
      ? getVideoProps(asset.mobile)
      : getImageProps(asset.mobile);
    const desktopProps = asset.desktop.fields.file.contentType.includes("video")
      ? getVideoProps(asset.desktop)
      : getImageProps(asset.desktop);
    return (
      asset && {
        mobile: {
          ...mobileProps,
          type: asset.mobile.fields.file.contentType,
        },
        desktop: {
          ...desktopProps,
          type: asset.desktop.fields.file.contentType,
        },
      }
    );
  }
  return undefined;
}
