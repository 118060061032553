import { useEffect, useRef, useState } from "react";

type UseSectionObserverProps = {
  sectionIds: string[];
};

type ActiveSection = string | undefined;

export default function useSectionObserver({
  sectionIds,
}: UseSectionObserverProps): ActiveSection {
  const [activeSection, setActiveSection] = useState<ActiveSection>();
  const observersRef = useRef<IntersectionObserver[]>([]);

  useEffect(() => {
    // Function to handle intersection changes
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    // Set up IntersectionObservers for each section
    sectionIds.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        const observer = new IntersectionObserver(handleIntersection, {
          threshold: 0.5,
        });
        observer.observe(element);
        observersRef.current.push(observer);
      }
    });

    // Clean up observers when the component unmounts or sectionIds change
    return () => {
      observersRef.current.forEach((observer) => observer.disconnect());
      observersRef.current = [];
    };
  }, [sectionIds]);

  return activeSection;
}
