import React from "react";
import { useRouter } from "next/router";

export function isFromSearch(referrer: string) {
  const searchEngines = [
    "google",
    "bing",
    "yahoo",
    "baidu",
    "ask",
    "msn",
    "duckduckgo",
    "yandex",
    "aol",
  ];
  return searchEngines.some((searchEngine) => referrer.includes(searchEngine));
}

function useIsFromOrganicSearch() {
  const router = useRouter();

  const [isOrganicSearch, setIsOrganicSearch] = React.useState<
    boolean | undefined
  >(undefined);

  React.useEffect(() => {
    if (
      isOrganicSearch === undefined &&
      router.isReady &&
      isFromSearch(document.referrer) &&
      !router.query.gclid &&
      !router.query.wbraid &&
      !router.query.utm_medium
    ) {
      setIsOrganicSearch(true);
    } else if (isOrganicSearch === undefined && router.isReady) {
      setIsOrganicSearch(false);
    }
    //
  }, [
    isOrganicSearch,
    router.isReady,
    router.query.gclid,
    router.query.wbraid,
    router.query.utm_medium,
  ]);

  return isOrganicSearch;
}

export default useIsFromOrganicSearch;
