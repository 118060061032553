/* eslint-disable import/prefer-default-export */
/* eslint-disable react/no-danger */
import * as React from "react";
import Script from "next/script";

interface GTMProps {
  gtmConfig: {
    containerId: string;
  };
}

/**
 * Installs Google Tag Manager onto the page.
 */
export function GTM({ gtmConfig }: GTMProps) {
  return (
    <>
      <Script id="GTM">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmConfig.containerId}');`}
      </Script>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
        <iframe
          title="GTM"
          src="https://www.googletagmanager.com/ns.html?id=${gtmConfig.containerId}"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>`,
        }}
      />
    </>
  );
}
