/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Badge, Button, Icon, VisuallyHidden } from "@components";

import useCatalogWithCartIds from "../../hooks/useCatalogWithCartIds";
import { useTypedQuery } from "../../hooks/useTypedQuery";
import type { LineItem } from "../../services/cart/types";
import { getShopifyId } from "../../services/util/shopifyID";
import { CartContext } from "..";

interface CartBadgeProps {
  testId: string;
  onClick?: () => void;
}

export function CartBadge({ testId, onClick }: CartBadgeProps) {
  const setCartOpen = React.useContext(CartContext);

  const { data: cart } = useTypedQuery(["cart"]);

  const catalog = useCatalogWithCartIds();

  function isVisibleLineItem(item: LineItem) {
    if (!catalog) return false;
    if (Object.keys(catalog).length === 0) return true;

    const productID = getShopifyId(item.variant.product?.id);
    const variantID = getShopifyId(item.variant.id);
    const product = catalog[productID];
    return product && variantID in product.variants;
  }

  const cartQuantity = cart?.lineItems
    ?.filter(isVisibleLineItem)
    .reduce((acc, item) => acc + item.quantity, 0);

  const openCart = () => {
    if (onClick) onClick();
    setCartOpen(true);
  };

  return (
    <Button
      data-testid={testId}
      type="button"
      className="flex text-sm leading-none"
      variant="unstyled"
      onClick={openCart}
    >
      <span role="status">
        <Badge value={cartQuantity ?? 0} ariaLabel="cart-total">
          <Icon
            name="basket"
            label="cart"
            className="inline-block align-baseline"
            data-testid="mobile-header-cart-icon"
          />
          <VisuallyHidden>items in your cart</VisuallyHidden>
        </Badge>
      </span>
    </Button>
  );
}
