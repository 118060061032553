// @ts-expect-error -- not sure why this is giving an error
import { IHighlightsSection } from "@types/generated/contentful";
import { useRouter } from "next/router";

import FeatureWrapper from "../../FeatureWrapper";
import { HighlightsSectionSaddle } from "../../RenderSections/sections";

export function HighlightsSection({
  content,
}: {
  content: IHighlightsSection | null;
}) {
  const router = useRouter();

  if (!content) return null;

  if (router.pathname === "/products/[...slug]") {
    return (
      <FeatureWrapper
        flag="pdp-lowers-test"
        defaultState="off"
        stateMap={{
          on: <HighlightsSectionSaddle content={content} />,
          off: null,
        }}
      />
    );
  }

  return <HighlightsSectionSaddle content={content} />;
}
