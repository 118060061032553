interface SheetVariant {
  closedState: string;
  openState: string;
  containerState: string;
}

type SheetSide = "left" | "right" | "bottom";

const SheetSides: Record<SheetSide, SheetVariant> = {
  left: {
    closedState: "-translate-x-full",
    openState: "translate-x-0",
    containerState: "h-full w-full left-0 inset-y-0",
  },
  right: {
    closedState: "translate-x-full",
    openState: "translate-x-0",
    containerState: "h-full w-full md:w-1/2 lg:w-[480px] right-0 inset-y-0",
  },
  bottom: {
    closedState: "translate-y-full",
    openState: "translate-y-0",
    containerState: "w-full inset-x-0 bottom-0",
  },
};

export default SheetSides;
