import {
  getPersonalizedRecommendations,
  getPrecomputedItemBasedRecommendations,
} from "@crossingminds/beam-react";

import type env from "../../env";
import { isTruthy } from "../../util";
import { LocaleCode } from "../../util/locale";
import type ClientShopifyService from "../shopify/ClientShopifyService";

type Config = typeof env.crossingMindsConfig;

// TODO: Remove these types once AB test is resolved
interface CMScenario {
  scenarioType: "case" | "ab_test" | "condition" | "alias";
  scenarioName: string;
  to: string | undefined;
}

interface CMEvaluatedScenarios {
  runtime?: CMScenario[];
  default?: CMScenario[];
}

export interface CrossingMindsItem {
  productID: string;
  variantID: string;

  // here for parity with existing response
  brand: string;
  category: string;
}

const SHOPIFY_PRODUCT_PREFIX = "gid://shopify/Product/";
const SHOPIFY_VARIANT_PREFIX = "gid://shopify/ProductVariant/";

export default class CrossingMindsAPI {
  private databaseId: Config["databaseId"];

  private password: Config["password"];

  private serviceLoginId: Config["serviceLoginId"];

  private shopifyService: ClientShopifyService;

  constructor(
    { databaseId, password, serviceLoginId }: Config,
    shopifyService: ClientShopifyService
  ) {
    this.databaseId = databaseId;
    this.password = password;
    this.serviceLoginId = serviceLoginId;
    this.shopifyService = shopifyService;
  }

  private formatItemsResponse = async (
    variantIds: string[],
    evaluatedScenarios: CMEvaluatedScenarios | undefined
  ) => {
    const {
      data: { nodes },
    } = await this.shopifyService.request("GetProductsByVariantIds", {
      ids: variantIds.map((id) => `${SHOPIFY_VARIANT_PREFIX}${id}`),
    });

    // TODO: Remove this logic once AB test is resolved
    const scenarios = evaluatedScenarios?.runtime ?? [];
    const shouldUseCM = !scenarios.some((scenario) =>
      scenario.scenarioName.includes("rebuy")
    );

    const items: CrossingMindsItem[] = nodes
      .map((node) => {
        if (!node || !("id" in node && "product" in node)) return null;

        return {
          variantID: node.id.replace(SHOPIFY_VARIANT_PREFIX, ""),
          productID: node.product.id.replace(SHOPIFY_PRODUCT_PREFIX, ""),
          brand: node.product.vendor,
          category: node.product.productType,
        };
      })
      .filter(isTruthy);

    return { items, shouldUseCM };
  };

  private getItemBasedRecommendedProducts = async (
    variantId: string,
    sessionId: string,
    useCanadianScenario: boolean
  ) => {
    const scenario = useCanadianScenario
      ? "pdp_carousel_scenario_ca"
      : "pdp_carousel_scenario";

    const { databaseId, password, serviceLoginId } = this;
    const { itemIds: variantIds, evaluatedScenarios } =
      await getPrecomputedItemBasedRecommendations({
        databaseId,
        password,
        serviceLoginId,
        sessionId,
        itemId: variantId,
        scenario,
      });

    return this.formatItemsResponse(variantIds, evaluatedScenarios);
  };

  private getPersonalizedRecommendedProducts = async (
    sessionId: string,
    useCanadianScenario: boolean
  ) => {
    const sessionScenario = useCanadianScenario
      ? "cart_carousel_scenario_ca"
      : "cart_carousel_scenario";

    const { databaseId, password, serviceLoginId } = this;
    const { itemIds: variantIds, evaluatedScenarios } =
      await getPersonalizedRecommendations({
        databaseId,
        password,
        serviceLoginId,
        sessionId,
        sessionScenario,
      });

    return this.formatItemsResponse(variantIds, evaluatedScenarios);
  };

  getRecommendedProducts = async ({
    variantId,
    sessionId,
    locale,
  }: {
    variantId?: string;
    sessionId: string;
    locale: LocaleCode;
  }) => {
    const useCanadianScenario = locale === "en-CA";

    if (variantId)
      return this.getItemBasedRecommendedProducts(
        variantId,
        sessionId,
        useCanadianScenario
      );

    return this.getPersonalizedRecommendedProducts(
      sessionId,
      useCanadianScenario
    );
  };
}
