/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import { Fragment, useContext, useEffect, useState } from "react";
import { Button, Icon, LinkContext, Sheet } from "@components";
import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import { useRouter } from "next/router";
import {
  addHeapDesktopNavigationExpanded,
  addHeapNavigationItemClick,
} from "src/lib/services/elevar/events";

import useVisibility from "../../../../hooks/useVisibility";
import { CartBadge, LocaleButton, LocalePicker, SearchPane } from "../..";
import selectors from "../selectors";
import { DesktopNavigationProps, NavItem } from "../types";

import LinkGroup from "./LinkGroup";
import SiteLogo from "./SiteLogo";

export default function DesktopNavigation({
  items,
  featuredProducts,
  flipLogo,
  useBoldHeadings,
}: Omit<
  DesktopNavigationProps,
  | "pinnedLinks"
  | "footerLinks"
  | "socialLinks"
  | "transparentNav"
  | "marketingCapture"
  | "siteMetadata"
  | "pageMetadata"
>) {
  const LinkElement = useContext(LinkContext);
  const router = useRouter();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [focus, setFocus] = useState(false);
  const { isLocalePickerVisible, showLocalePicker, hideLocalePicker } =
    useVisibility("LocalePicker");

  const activeItem = items[activeIndex];

  /** hides the mega menu */
  const hideMegaMenu = () => {
    setActiveIndex(-1);
  };

  useEffect(() => {
    // close the menu when the route changes
    router.events?.on("routeChangeStart", hideMegaMenu);

    return () => {
      router.events?.off("routeChangeStart", hideMegaMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickAnalytics = (item: NavItem, level: number) => {
    addHeapNavigationItemClick({
      style: "link",
      href: item.url,
      isMobile: false,
      hasImage: false,
      level,
      version: "legacy",
    });
  };

  /** displays a menu associated with the link at the given index */
  const showLinkMenu = (index: number) => {
    const finalIndex = items[index]?.columns ? index : -1;
    setActiveIndex(finalIndex);
  };
  return (
    <nav
      className={classNames(
        "relative leading-none",
        focus && "bg-white text-charcoal"
      )}
      onMouseLeave={hideMegaMenu}
      data-testid={selectors.DesktopNavigation.container}
    >
      <div
        className="w-full flex items-center justify-between px-8 max-w-screen-king mx-auto h-[72px]"
        onMouseEnter={hideMegaMenu}
        onTouchEnd={hideMegaMenu}
      >
        {/* level 1 links */}
        <ul className="flex items-center gap-4 mr-4">
          <li
            onFocus={hideMegaMenu}
            onMouseEnter={hideMegaMenu}
            className="mr-4"
          >
            <SiteLogo flip={flipLogo} />
          </li>
          {items.map((item, itemIndex) => {
            const linkHandler = () => {
              showLinkMenu(itemIndex);
              addHeapDesktopNavigationExpanded();
            };
            return (
              <li key={item.title}>
                <LinkElement
                  className="block py-1 text-xs"
                  onFocus={linkHandler}
                  onMouseEnter={linkHandler}
                  onTouchEnd={linkHandler}
                  href={item.url}
                  data-testid={selectors.DesktopNavigation.level1Link}
                  variant="underline"
                  forceUnderline={activeIndex === itemIndex}
                  onClick={() => handleClickAnalytics(item, 1)}
                >
                  {item.title}
                </LinkElement>
              </li>
            );
          })}
        </ul>

        {/* cart & search */}
        <div className="flex items-center gap-4">
          <LocaleButton onClick={showLocalePicker} />

          {/* Search button & pane */}
          <Popover as={Fragment}>
            <Popover.Button
              onFocus={hideMegaMenu}
              as={Button}
              variant="unstyled"
              className="mb-0 leading-none h-fit"
              data-testid={selectors.DesktopNavigation.searchButton}
            >
              <Icon name="search" label="Open search" size="inherit" />
            </Popover.Button>

            <Transition
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute inset-x-0 z-10 transition-all bg-white shadow-lg top-full"
              data-testid={selectors.DesktopNavigation.searchPane}
            >
              <Popover.Panel
                className="container px-8 pb-10 mx-auto text-charcoal"
                unmount={false}
              >
                {({ close, open }) => {
                  setFocus(open);
                  return (
                    <SearchPane
                      onClose={close}
                      featuredProducts={featuredProducts}
                    />
                  );
                }}
              </Popover.Panel>
            </Transition>
          </Popover>

          <CartBadge
            testId={selectors.DesktopNavigation.cartBadge}
            onClick={hideMegaMenu}
          />
        </div>
      </div>

      {/* mega menu */}
      <Transition
        show={!!activeItem}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="absolute inset-x-0 transition-all duration-300 bg-white shadow-lg top-full"
        data-testid={selectors.DesktopNavigation.megaMenu}
        unmount={false}
      >
        <div className="container px-8 py-10 mx-auto">
          <div className="relative">
            {items.map((item, itemIndex) =>
              item.columns ? (
                <Transition
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  enter="transition-opacity"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  leave="absolute inset-0 transition-opacity"
                  key={`${item.title}-menu`}
                  className="grid grid-cols-5 gap-8"
                  show={activeIndex === itemIndex}
                  unmount={false}
                >
                  {item.columns.map((linkGroups, columnIndex) => (
                    <div key={`column-${columnIndex}`} className="grid gap-10">
                      {linkGroups?.map((linkGroup, linkGroupIndex) => (
                        <div
                          key={`column-${columnIndex}-linkgroup-${linkGroupIndex}`}
                          className="text-charcoal"
                        >
                          <LinkGroup
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...linkGroup}
                            useBoldHeadings={useBoldHeadings}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </Transition>
              ) : null
            )}
          </div>
        </div>
      </Transition>

      {/* locale picker */}
      <Sheet
        isOpen={isLocalePickerVisible}
        onDismiss={hideLocalePicker}
        side="right"
        top
      >
        <div className="mt-12 mb-8 text-right">
          <Button onClick={hideLocalePicker} variant="unstyled">
            <Icon name="close" />
          </Button>
        </div>
        <LocalePicker onSwitch={hideLocalePicker} />
      </Sheet>
    </nav>
  );
}
