import nextConfig from "../../../next.i18n";
import {
  IShopifyCountryCode,
  IShopifyLanguageCode,
} from "../graphql/shopify/client/sdk";

import localeMap from "./localeMap";

/**
 * This controls the typing of our exported
 * locales in [next.i18n.js](../../../next.i18n.js),
 * so this will automatically need to be updated
 * when we expand our offerings
 */
export type LocaleCode = "en-US" | "en-CA";

/**
 * Utility wrapper to ensure we provide values
 * for all our supported locales where necessary
 */
export type Localized<T> = Record<LocaleCode, T>;

interface Locale {
  /** The locale's language code, in uppercase */
  languageCode: Uppercase<IShopifyLanguageCode>;
  /** The locale's country code, in uppercase */
  countryCode: Uppercase<IShopifyCountryCode>;
  /** The locale's currency code, in uppercase */
  currencyCode: Uppercase<string>;
}

export interface LocaleConfig extends Locale {
  countryName: Localized<string>;
  countryShortCode: Localized<string>;
  languageName: Localized<string>;
  region: Localized<string>;
}

/** for use in `localeMap` since generic typedefs
 * cannot be in the same file
 */
export type LocalizedConfig = Localized<LocaleConfig>;

export interface FormattedLocale extends Locale {
  countryName: string;
  countryShortCode: string;
  languageName: string;
  region: string;
}

export const getLocale = (locale: LocaleCode): FormattedLocale => {
  const {
    languageName,
    countryName,
    countryShortCode,
    region,
    ...matchingLocale
  } = localeMap[locale];
  return {
    ...matchingLocale,
    languageName: languageName[locale],
    countryName: countryName[locale],
    countryShortCode: countryShortCode[locale],
    region: region[locale],
  };
};

export const filterListByLocales = (
  list: string[],
  allowedLocales?: LocaleCode[]
) => {
  if (!allowedLocales) return [];
  const filteredList = list.filter((item: string): item is LocaleCode =>
    (allowedLocales as string[]).includes(item)
  );

  return filteredList;
};

export const defaultLocale: LocaleCode = nextConfig.i18n
  .defaultLocale as LocaleCode;

export const isLocaleCode = (code: string): code is LocaleCode =>
  code in localeMap;
