/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from "react";
import { TextInputWithSubmit } from "@components";
import classNames from "classnames";

import { addSubscribeEvent } from "../../services/elevar/events";
import { isValidEmail } from "../util";
import { Media } from "..";

export interface IEmailSignupForm {
  placeholderText: string;
  mobilePlaceholderText: string;
  successText: string;
  mobileSuccessText: string;
  errorText: string;
  mobileErrorText: string;
  subscriberSource: string;
  klaviyoSubOptions: string;
  hasBrightBackground?: boolean;
  /**
   * Whether to render the form as an inline element or a block element
   * INLINE: use when it is a sub-component of another component. IE Email Signup Section
   * BLOCK: use when it is a standalone component. IE Hero Section
   * @default "inline"
   * @type {"inline" | "block"}
   */
  renderAs?: "inline" | "block";
}

/**
 * A section component that renders an email sign up form
 */
export function EmailSignupForm({
  placeholderText,
  mobilePlaceholderText,
  successText,
  mobileSuccessText,
  errorText,
  mobileErrorText,
  subscriberSource,
  klaviyoSubOptions,
  hasBrightBackground = false,
  renderAs = "inline",
}: IEmailSignupForm) {
  const [inputValue, setInputValue] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setInvalid(false);
  }, [inputValue]);

  const handleSubmit = () => {
    setInvalid(false);
    setSubmitted(false);
    const isValid = isValidEmail(inputValue);
    if (isValid) {
      addSubscribeEvent({
        customer_email: inputValue,
        klaviyo_list_id: klaviyoSubOptions,
        subscriber_source: subscriberSource,
      });
      setSubmitted(true);
    } else {
      setInvalid(true);
    }
  };

  return (
    <form
      className={classNames("z-10 flex", {
        "py-8 md:w-fit justify-center": renderAs === "inline",
      })}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Media greaterThanOrEqual="md">
        <TextInputWithSubmit
          type="email"
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          onKeyPress={() => {
            setInvalid(false);
            setSubmitted(false);
          }}
          invalid={invalid}
          success={submitted}
          successMessage={successText}
          errorMessage={errorText}
          placeholder={placeholderText}
          inputClasses={classNames("text-charcoal w-80", {
            "w-44": submitted || invalid,
          })}
          inputContainerClasses={classNames(
            "border-charcoal w-96",
            hasBrightBackground ? "!bg-transparent" : "!bg-white text-charcoal"
          )}
        />
      </Media>
      <Media lessThan="md" className="w-full">
        <TextInputWithSubmit
          type="email"
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          onKeyPress={() => {
            setInvalid(false);
            setSubmitted(false);
          }}
          invalid={invalid}
          success={submitted}
          successMessage={mobileSuccessText}
          errorMessage={mobileErrorText}
          placeholder={mobilePlaceholderText}
          inputClasses={classNames(
            "pl-2 pr-0 text-charcoal border-r-0",
            hasBrightBackground ? "!bg-transparent" : "bg-white",
            {
              "w-20": submitted || invalid,
            }
          )}
          size={23}
          inputContainerClasses={classNames(
            "w-full border-charcoal border-b-4",
            hasBrightBackground ? "!bg-transparent" : "!bg-white text-charcoal"
          )}
          buttonClasses={classNames(
            hasBrightBackground ? "!bg-transparent" : "bg-white"
          )}
        />
      </Media>
    </form>
  );
}
