/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/prefer-default-export */
import {
  ComponentProps,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Banner } from "@components";
import classNames from "classnames";
import { Router, useRouter } from "next/router";

import {
  IHighlightsSection,
  IMarketingCaptureFields,
} from "../../../../@types/generated/contentful";
import { FeaturedProductsContext } from "../../context/FeaturedProductsContext";
import useMarketingCapture from "../../hooks/useMarketingCapture";
import useTypedMutation from "../../hooks/useTypedMutation";
import { useTypedQuery } from "../../hooks/useTypedQuery";
import MarketingCaptureModal from "../MarketingCaptureModal";
import {
  DesktopNavigationProps,
  MobileNavigationProps,
} from "../Navigation/types";
import { CmsEditorContext } from "../RenderSections/CmsEditor";
import { TrimmedBannerSet } from "../RenderSiteBanner";
import {
  Cart,
  CartContext,
  EmailCaptureReopenBanner,
  FeatureWrapper,
  FooterNav,
  Navigation,
  RenderSiteBanner,
  RichText,
} from "..";

import { HighlightsSection } from "./components/HighlightsSection";

interface LayoutProps {
  /** Currently requested content environment */
  environment: string;

  /** Props provided to the DesktopNav component */
  desktopNav: PropsWithChildren<DesktopNavigationProps>;

  /** Props provided to the MobileNav component */
  mobileNav: MobileNavigationProps;

  /** whether to render the staging header */
  isStaging?: boolean;

  /** Props provided to the FooterNav component */
  footerNav: ComponentProps<typeof FooterNav>["data"];

  /** ID of the current content space */
  space: string;

  marketingCapture: IMarketingCaptureFields;

  banner: TrimmedBannerSet | null;

  /** Global highlighted section */
  globalHighlightedSection: IHighlightsSection | null;
}

const bracketContentsRegex = /\[(.*?)\]/;

export function getMainClassNames(pathname?: string, query?: Router["query"]) {
  return pathname
    ?.replace(bracketContentsRegex, (match, key: string) => {
      let parsedKey = key;

      // if the key has a spread in it,
      // we're going to need to pull that off
      // and deal with the value being an array.
      if (parsedKey.includes("...")) {
        parsedKey = parsedKey.substring(3);
      }

      const queryValue = query?.[parsedKey];
      if (!queryValue) return match;
      if (Array.isArray(queryValue)) {
        return queryValue.join("-");
      }
      return queryValue;
    })
    .split("/")
    .filter(Boolean)
    .join("-");
}

/**
 A component that renders a page between the HeaderNav and Footer
 */
export function Layout({
  environment,
  desktopNav,
  mobileNav,
  isStaging = false,
  footerNav,
  children = null,
  space,
  marketingCapture,
  banner,
  globalHighlightedSection,
}: PropsWithChildren<LayoutProps>) {
  const [cartOpen, setCartOpen] = useState(false);
  const [showEditorTools, setShowEditorTools] = useState(false);
  const router = useRouter();
  const { data: preview } = useTypedQuery(["inPreviewMode"]);
  const { mutate: leavePreviewMode } = useTypedMutation(
    ["disablePreviewMode"],
    {
      onSuccess: () => {
        router.reload();
      },
    }
  );
  const { mutate: rebuildCatalog, isLoading: isRebuilding } = useTypedMutation(
    ["rebuildCatalog"],
    {
      onSuccess: () => {
        router.reload();
      },
    }
  );

  // close the cart on route/query change
  useEffect(() => {
    setCartOpen(false);
  }, [router?.route, router?.query]);

  const contextValue = useMemo(
    () => ({ showEditorTools, environment, space }),
    [showEditorTools, environment, space]
  );

  const { topBanner, bottomBanner, modal } = useMarketingCapture();

  return (
    <CmsEditorContext.Provider value={contextValue}>
      <CartContext.Provider value={setCartOpen}>
        <FeatureWrapper
          flag="ca-marketing-capture"
          defaultState="off"
          stateMap={{
            on: (
              <MarketingCaptureModal
                isOpen={modal.show}
                onClose={modal.close}
                dismissButtonText={marketingCapture.dismissModalText}
                emailFormProps={{
                  body: marketingCapture.emailSubscriptionBody,
                  title: marketingCapture.emailSubscriptionTitle,
                  submitFormButtonText:
                    marketingCapture.emailSubscriptionSubmitText,
                  dismissFormButtonText: marketingCapture.dismissModalText,
                }}
                postSubmitProps={{
                  title: marketingCapture.postSubmitTitle,
                  body: marketingCapture.postSubmitBody,
                  cta: marketingCapture.postSubmitCta?.fields,
                  dismissFormButtonText: marketingCapture.postSubmitDismissText,
                }}
              />
            ),
            off: null,
          }}
        />

        <Navigation
          mobileNav={mobileNav}
          desktopNav={{ ...desktopNav, flipLogo: isStaging }}
        >
          {/* TODO: Figure out if this is needed? */}
          {topBanner.show && marketingCapture.promotionalBannerText && (
            <Banner bgColor="lightgray" className="px-4">
              <RichText
                richTextResponse={marketingCapture.promotionalBannerText}
              />
            </Banner>
          )}
          <RenderSiteBanner
            navLinks={desktopNav.bannerLinks}
            bannerSet={banner}
          />
        </Navigation>
        <FeaturedProductsContext.Provider value={desktopNav.featuredProducts}>
          <main className={getMainClassNames(router?.pathname, router?.query)}>
            {children}
          </main>
          <Cart isOpen={cartOpen} onDismiss={() => setCartOpen(false)} />
        </FeaturedProductsContext.Provider>
        <FeatureWrapper
          flag="ca-marketing-capture"
          defaultState="off"
          stateMap={{
            on: bottomBanner.show && (
              <EmailCaptureReopenBanner
                openEmailCapture={modal.open}
                close={bottomBanner.close}
                text={marketingCapture.reopenBannerText}
              />
            ),
            off: null,
          }}
        />
        <HighlightsSection content={globalHighlightedSection} />
        <FooterNav data={footerNav} />
        {preview ? (
          <Banner bgColor="dune" className="sticky bottom-0 z-10">
            <>
              <span
                data-testid="preview-banner"
                className="block uppercase md:mr-8 md:inline"
              >
                Previewing the <b>{preview.environment}</b> environment.
              </span>
              <button
                type="button"
                className="hover:underline"
                onClick={() => leavePreviewMode()}
              >
                Leave?
              </button>
              <span className="px-2">|</span>
              <button
                type="button"
                className={classNames("hover:underline", {
                  "animate-pulse": isRebuilding,
                })}
                onClick={() => rebuildCatalog()}
              >
                {isRebuilding ? "Rebuilding..." : "Rebuild Catalog?"}
              </button>
              <span className="px-2">|</span>
              <button
                type="button"
                className="hover:underline"
                onClick={() => setShowEditorTools((state) => !state)}
              >
                {!showEditorTools ? "Display" : "Close"} Editor Tools?
              </button>
            </>
          </Banner>
        ) : null}
      </CartContext.Provider>
    </CmsEditorContext.Provider>
  );
}
