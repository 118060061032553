import { IHighlightsSection } from "../../../../../@types/generated/contentful";
import { AnalyticsProvider } from "../../../hooks/useAnalytics";
import { HighlightsSection } from "../../HighlightsSection";

export default function HighlightsSectionSaddle({
  content,
}: {
  content: IHighlightsSection;
}) {
  const {
    name,
    items,
    backgroundColor,
    textColor,
    bodyTextColor,
    title,
    titleAlignment,
  } = content.fields;
  const { id } = content.sys;

  return (
    <AnalyticsProvider object={{ name, id }}>
      <HighlightsSection
        backgroundColor={backgroundColor}
        color={textColor}
        bodyTextColor={bodyTextColor}
        title={title}
        titleAlignment={titleAlignment}
        items={items.map(({ fields: item, sys }) => ({
          name: item.name,
          id: sys.id,
          title: item.title,
          content: item.richContent,
          alignment: item.contentAlignment,
          desktopAlignment: item.contentAlignmentDesktop,
          image: item.image && {
            src: item.image.fields.file.url,
            alt: item.image.fields.description || item.image.fields.title,
            height: item.image.fields.file.details.image?.height,
            width: item.image.fields.file.details.image?.width,
          },
        }))}
      />
    </AnalyticsProvider>
  );
}
