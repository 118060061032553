import { Button } from "@components";
import classNames from "classnames";

import useLocales from "../../hooks/useLocales";

import SelectedLocale from "./SelectedLocale";
import selectors from "./selectors";

interface LocaleButtonProps {
  onClick: () => void;
  showFullText?: boolean;
  children?: React.ReactNode;
}

export default function LocaleButton({
  onClick,
  showFullText,
  children,
}: LocaleButtonProps) {
  const { currentLocale, locales } = useLocales();
  return (
    <Button
      data-testid={selectors.ToggleButton}
      variant="pill"
      className={classNames("transition-none disabled:hidden", {
        "w-20": !showFullText,
      })}
      onClick={onClick}
      disabled={locales.length < 2} // no other locale to switch to
    >
      <SelectedLocale locale={currentLocale} displayInFull={showFullText}>
        {children}
      </SelectedLocale>
    </Button>
  );
}
