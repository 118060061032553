import * as React from "react";
import {
  Button,
  Icon,
  ImageContext,
  LinkContext,
  Sheet,
  VisuallyHidden,
} from "@components";
import classNames from "classnames";
import useFeatureFlag from "src/lib/hooks/useFeatureFlag";
import {
  addHeapMobileNavigationOpened,
  addHeapNavigationDuration,
} from "src/lib/services/elevar/events";

import useVisibility from "../../../../hooks/useVisibility";
import { isTruthy } from "../../../util";
import {
  CartBadge,
  FeatureWrapper,
  LocaleButton,
  LocalePicker,
  SearchPane,
} from "../..";
import { ImageData } from "../../util";
import selectors from "../selectors";
import type {
  DesktopNavigationProps,
  MenuItem,
  MobileNavigationProps,
} from "../types";

import { BackButton } from "./BackButton";
import LinkItem from "./LinkItem";
import MenuStack, { MenuStackProps } from "./MenuStack";
import MenuStackV2 from "./MenuStackV2";
import SiteLogo from "./SiteLogo";
import SlidingTransition from "./SlidingTransition";

interface TopBarProps {
  open?: boolean;
  onCartClick?: () => void;
  onClick: () => void;
  onSearchClick: () => void;
  flipped?: boolean;
}
function TopBar({
  open,
  onClick,
  onCartClick,
  onSearchClick,
  flipped,
}: TopBarProps) {
  return (
    <div
      className={classNames(
        `sticky top-0 grid items-center grid-cols-3 gap-8 px-8 overflow-x-hidden z-header place-content-center h-[88px]`,
        open && "bg-white"
      )}
    >
      <div>
        <Button
          onClick={onClick}
          variant="unstyled"
          data-testid={selectors.MobileNavigation.menuButton}
        >
          <Icon name={open ? "close" : "hamburger"} label="Toggle menu" />
        </Button>
      </div>
      <div className="flex justify-center">
        <SiteLogo mobile flip={flipped} />
      </div>
      <div className="flex items-baseline justify-end gap-4">
        <Button
          variant="unstyled"
          onClick={onSearchClick}
          data-testid={selectors.MobileNavigation.searchButton}
        >
          <Icon
            name="search"
            label="Open search"
            size="full"
            className="block w-5 h-5"
          />
        </Button>
        <CartBadge
          testId={selectors.MobileNavigation.cartBadge}
          onClick={onCartClick}
        />
      </div>
    </div>
  );
}

// Also need to figure out how to do the link highlighting

export type Props = MobileNavigationProps & {
  fromDesktop: Pick<
    DesktopNavigationProps,
    | "featuredProducts"
    | "pinnedLinks"
    | "footerLinks"
    | "socialLinks"
    | "flipLogo"
    | "mobileLinks"
    | "items"
  >;
};
export default function MobileNavigation({
  fromDesktop: {
    items: deprecatedItems,
    pinnedLinks,
    mobileLinks,
    featuredProducts,
    footerLinks: deprecatedFooterLinks,
    socialLinks: deprecatedSocialLinks,
    flipLogo,
  },
  items,
  topItems,
  footerLinks: newFooterLinks,
  socialLinks: newSocialLinks,
}: Props) {
  const LinkElement = React.useContext(LinkContext);
  const ImageElement = React.useContext(ImageContext);
  const { isMenuVisible, showMenu, hideMenu, toggleMenu } =
    useVisibility("Menu");
  const { isLocalePickerVisible, showLocalePicker, hideLocalePicker } =
    useVisibility("LocalePicker");
  const { isSearchVisible, hideSearch, toggleSearch } = useVisibility(
    "Search",
    false,
    { onShow: hideLocalePicker }
  );

  // Track if a the user did navigate to a link, and how long they spent
  // in navigation
  const navigated = React.useRef(false);
  React.useEffect(() => {
    if (isMenuVisible) {
      navigated.current = false;
      localStorage.setItem("menuOpened", Date.now().toString());
    }
    return () => {
      if (isMenuVisible) {
        const closedAt = Date.now();
        const openedAt = localStorage.getItem("menuOpened");
        if (openedAt === null) return;
        // Get the difference in seconds, to a precision of 2 decimal places
        const duration = (closedAt - parseInt(openedAt, 10)) / 1000;
        addHeapNavigationDuration(duration, navigated.current);
      }
    };
  }, [isMenuVisible]);

  const closeMenuAndSearch = () => {
    hideMenu();
    hideSearch();
  };

  const backToMenu = () => {
    showMenu();
    hideLocalePicker();
    hideSearch();
  };

  // Fire event to see when menu is visible
  React.useEffect(() => {
    if (isMenuVisible) {
      addHeapMobileNavigationOpened();
    }
  }, [isMenuVisible]);

  const menu = React.useMemo<MenuStackProps["items"]>(() => {
    const mainMenuItems: MenuItem[] = deprecatedItems.map((item) => ({
      text: item.title,
      // only populate the url for items without columns
      url: item.columns ? undefined : item.url,
      id: item.id,
      name: item.name,
      // only populate children for items with columns
      children: !item.columns
        ? undefined
        : [
            ...item.columns.filter(isTruthy).flatMap((linkGroups) =>
              linkGroups.flatMap((linkGroup) => {
                if (linkGroup.collapse) return linkGroup.links as MenuItem[];

                return {
                  text: linkGroup.title,
                  id: linkGroup.id,
                  name: linkGroup.name,
                  children: [
                    ...linkGroup.links.map((link) => ({
                      text: link.text,
                      url: link.url,
                      image: link.image,
                      badge: link.badge,
                      type: linkGroup.listType,
                    })),
                    linkGroup.text && linkGroup.url
                      ? {
                          text: linkGroup.text,
                          url: linkGroup.url,
                          highlight: true,
                          type: linkGroup.listType,
                        }
                      : null,
                  ].filter(isTruthy),
                };
              })
            ),
            item.text && item.url
              ? {
                  text: item.text,
                  url: item.url,
                  highlight: true,
                }
              : null,
          ].filter(isTruthy),
    }));

    // mobile only nav items
    const mobileMenuItems: MenuItem[] = [];
    if (mobileLinks.length) {
      mobileMenuItems.push({
        children: mobileLinks.map((link) => ({
          text: link.text,
          image: link.image,
          url: link.url,
          badge: link.badge,
          type: "card",
        })),
      });
    }

    // pinned links
    const pinnedMenuItems: MenuItem[] = pinnedLinks.map((link) => ({
      text: link.text,
      url: link.url,
      highlight: true,
    }));

    return {
      topItems: mobileMenuItems,
      mainItems: [...mainMenuItems, ...pinnedMenuItems],
    };
  }, [deprecatedItems, pinnedLinks, mobileLinks]);

  // Cannot mock navigation events in Jest
  // istanbul ignore next
  const closeNavOnLinkClick = (e: React.MouseEvent) => {
    const targetElement = e.target as HTMLElement | undefined;
    const parentLink = targetElement?.closest("a");
    const link = parentLink || targetElement;
    if (link?.nodeName !== "A") return;

    navigated.current = true;
    closeMenuAndSearch();
  };
  const { value } = useFeatureFlag("mobile-nav");
  const isModern = value === "modern";

  const footerLinks = isModern ? newFooterLinks : deprecatedFooterLinks;
  const socialLinks = isModern ? newSocialLinks : deprecatedSocialLinks;

  // TODO: Find out why ref version wouldn't work
  const resetScroll = () => {
    const element = document.getElementById(
      selectors.MobileNavigation.sheetContainer
    );
    element?.scrollTo(0, 0);
  };

  return (
    <nav className="relative leading-none isolate">
      {/* top bar */}
      <TopBar
        onClick={toggleMenu}
        onSearchClick={toggleSearch}
        flipped={flipLogo}
      />

      {/* menu sheet */}
      <Sheet
        side="left"
        isOpen={isMenuVisible || isSearchVisible}
        onDismiss={toggleMenu}
        className="p-0"
        contentId={selectors.MobileNavigation.sheetContainer}
        testId={selectors.MobileNavigation.sheetContainer}
        unmount={false}
        top
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className="flex flex-col min-h-screen"
          onClick={closeNavOnLinkClick}
        >
          <TopBar
            onCartClick={closeMenuAndSearch}
            onClick={closeMenuAndSearch}
            onSearchClick={toggleSearch}
            open
            flipped={flipLogo}
          />

          <div
            data-testid={selectors.MobileNavigation.menuContainer}
            className={classNames(
              "relative flex flex-col gap-8 flex-auto",
              !isModern && "py-8"
            )}
          >
            {/* Main Menu */}
            <SlidingTransition
              show={!isSearchVisible && !isLocalePickerVisible}
              unmount={false}
              exitLeft
              className="h-full transition-all duration-300"
            >
              <FeatureWrapper
                flag="mobile-nav"
                defaultState="legacy"
                stateMap={{
                  legacy: (
                    <MenuStack
                      items={menu}
                      isOpen={isMenuVisible}
                      addPadding
                      showChevronOverride
                      resetScroll={resetScroll}
                    >
                      <div className="my-4 text-center">
                        <LocaleButton onClick={showLocalePicker} showFullText>
                          {/* TODO: i18n */}
                          <span className="inline-block ml-1 text-slate">
                            / Change Location
                          </span>
                        </LocaleButton>
                      </div>
                    </MenuStack>
                  ),
                  modern: (
                    <MenuStackV2
                      items={{ topItems, mainItems: items }}
                      isOpen={isMenuVisible}
                      addPadding
                      resetScroll={resetScroll}
                    >
                      <div className="mt-12 mb-4 text-center">
                        <LocaleButton onClick={showLocalePicker} showFullText>
                          {/* TODO: i18n */}
                          <span className="inline-block ml-1 text-slate">
                            / Change Location
                          </span>
                        </LocaleButton>
                      </div>
                    </MenuStackV2>
                  ),
                }}
              />
            </SlidingTransition>

            <SlidingTransition
              show={isLocalePickerVisible}
              className={classNames(
                "absolute inset-0 p-8 pt-0 -mt-2 bg-white",
                {
                  "transition-all duration-300": isMenuVisible,
                  "pointer-events-none": !isLocalePickerVisible,
                }
              )}
              aria-hidden={!isLocalePickerVisible}
            >
              <BackButton onClick={backToMenu} />
              <LocalePicker onSwitch={backToMenu} />
            </SlidingTransition>

            {/* Search Pane */}
            <SlidingTransition
              show={isSearchVisible}
              className={classNames("px-8 bg-white", {
                "transition-all duration-300": isMenuVisible,
                "pointer-events-none": !isSearchVisible,
              })}
              testId={selectors.MobileNavigation.searchPane}
            >
              <BackButton
                testID={selectors.MobileNavigation.searchPaneCloseButton}
                text="Menu"
                onClick={backToMenu}
              />

              <SearchPane
                onClose={closeMenuAndSearch}
                featuredProducts={featuredProducts}
                mobile
              />
            </SlidingTransition>
          </div>

          <footer className="flex flex-col gap-4 px-6 py-8 mt-auto leading-none text-charcoal bg-pearl">
            {/* footer links */}
            <ul className="flex flex-col gap-4">
              {footerLinks.map((link) => (
                <li key={link.url}>
                  <LinkItem text={link.text} url={link.url} />
                </li>
              ))}
            </ul>

            {/* social links */}
            <ul className="flex gap-4">
              {socialLinks
                .filter((socialLink) => socialLink.image)
                .map((socialLink) => {
                  const socialImage = socialLink.image as ImageData;
                  return (
                    <li key={socialLink.url}>
                      <LinkElement
                        href={socialLink.url}
                        className="relative block w-5 h-5"
                      >
                        <ImageElement
                          src={socialImage.src}
                          height={socialImage.height}
                          width={socialImage.width}
                          alt={socialLink.text}
                        />
                        <VisuallyHidden>{socialLink.text}</VisuallyHidden>
                      </LinkElement>
                    </li>
                  );
                })}
            </ul>
          </footer>
        </div>
      </Sheet>
    </nav>
  );
}
