import { Heading } from "@components";

import { IPrivacySection } from "../../../../../@types/generated/contentful";
import { RichText } from "../../RichText";
import { PromoteH1Type } from "../types";

interface Props {
  content: IPrivacySection & PromoteH1Type;
}

export default function PrivacySectionSaddle({ content }: Props) {
  const { sectionTitle, sectionCopy, sectionId } = content.fields;
  const { promoteH1 = false } = content;

  return (
    <section
      key={content.sys.id}
      id={sectionId}
      className="max-w-2xl px-5 pb-16 mx-auto"
    >
      <Heading
        as={promoteH1 ? "h1" : "h4"}
        variant="h5"
        className="mt-16 mb-8 text-center"
      >
        {sectionTitle}
      </Heading>
      {sectionCopy && <RichText richTextResponse={sectionCopy} />}
    </section>
  );
}
