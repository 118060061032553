import type { QueryClient } from "react-query";

import {
  makeQueryFn,
  QueryClientPlugin,
} from "../../services/util/makeQueryClient";
import type { SharedDataServices } from "../shared";

export type PreviewModeData =
  | {
      environment: string;
    }
  | false;

export const previewQueryFns = {
  inPreviewMode: async function inPreviewMode() {
    const response = await fetch("/api/preview");
    const payload: PreviewModeData = await response.json();
    return payload;
  },
};

export const previewMutationFns = {
  enablePreviewMode: async function enablePreviewMode(pw: string) {
    return fetch("/api/preview", {
      method: "POST",
      body: JSON.stringify({ password: pw }),
      headers: { "Content-Type": "application/json" },
    }).then<PreviewModeData>((res) => {
      if (res.status === 401) {
        return Promise.reject(new Error("Incorrect password"));
      }

      return res.json();
    });
  },
  disablePreviewMode: async function disablePreviewMode() {
    return (
      await fetch("/api/exit-preview", {
        method: "POST",
      })
    ).json();
  },
  rebuildCatalog: async function rebuildPreviewMode() {
    return (
      await fetch("/api/rebuild-catalog", {
        method: "POST",
      })
    ).json();
  },
};

const previewPlugin: QueryClientPlugin<SharedDataServices> = (
  queryClient: QueryClient
) => {
  const { inPreviewMode } = previewQueryFns;
  const { enablePreviewMode, disablePreviewMode, rebuildCatalog } =
    previewMutationFns;

  queryClient.setQueryDefaults(["inPreviewMode"], {
    queryFn: makeQueryFn(inPreviewMode),
    meta: {
      dehydratable: true,
    },
  });
  queryClient.setMutationDefaults(["enablePreviewMode"], {
    mutationFn: enablePreviewMode,
    onSuccess: (data: PreviewModeData) => {
      queryClient.setQueryData(["inPreviewMode"], data);
    },
  });
  queryClient.setMutationDefaults(["disablePreviewMode"], {
    mutationFn: disablePreviewMode,
    onSuccess: (data: PreviewModeData) => {
      queryClient.setQueryData(["inPreviewMode"], data);
    },
  });
  queryClient.setMutationDefaults(["rebuildCatalog"], {
    mutationFn: rebuildCatalog,
  });
};

export default previewPlugin;
