import { useContext } from "react";
import { ImageContext, LinkContext } from "@components";
import classNames from "classnames";
import { useRunOnVisibleOnce } from "src/hooks/useIntersectionObserver";

import usePriceFormatter from "../../../hooks/usePriceFormatter";
import makeProductUrl from "../../../util/makeProductUrl";
import selectors from "../selectors";
import type { Marker } from "../types";

interface Props {
  data: Marker;
  buttonText: string;
  onClick: () => void;
  onViewed: () => void;
}

export default function MarkerCard({
  data: { variantID, product },
  buttonText,
  onClick,
  onViewed,
}: Props) {
  const formatPrice = usePriceFormatter();
  const ImageElement = useContext(ImageContext);
  const LinkElement = useContext(LinkContext);
  const variant = product.variants[variantID];
  const {
    images: [variantImage],
  } = variant;

  const itemUrl = makeProductUrl(product.slug, variant.id);
  const containerRef = useRunOnVisibleOnce(
    onViewed,
    {
      threshold: 0.8,
    },
    500
  );

  return (
    <div
      data-testid={selectors.productList.card.container}
      className="relative flex flex-col justify-end w-full gap-2 lg:justify-start aspect-square lg:aspect-auto isolate"
      ref={containerRef}
    >
      <div className="absolute inset-0 leading-none lg:w-full lg:aspect-square lg:relative -z-10">
        <ImageElement
          src={variantImage.url}
          alt={variantImage.description || variantImage.title}
          className="rounded"
        />
      </div>
      <div className="flex flex-col p-4 lg:p-0">
        <div className="flex items-start justify-between leading-small">
          <LinkElement
            href={itemUrl}
            className={classNames(
              "outline-none hover:opacity-100 static",
              "before:absolute before:content-[''] before:inset-0 before:opacity-0"
            )}
            data-testid={selectors.productList.card.link}
            onClick={onClick}
          >
            {product.productTitle}
          </LinkElement>
          <p>{formatPrice(variant.price)}</p>
        </div>

        <div className="hidden lg:block">
          <LinkElement
            variant="button-underline"
            className="mb-0 uppercase outline-none text-2xs hover:opacity-100"
            href={itemUrl}
          >
            <span className="sr-only lg:not-sr-only">{buttonText}</span>
          </LinkElement>
        </div>
      </div>
    </div>
  );
}
