import type { E164Number } from "libphonenumber-js";

import type { QueryClientPlugin } from "../../services/util/makeQueryClient";
import type { ClientDataServices } from "../client";

interface SubscriptionPayload {
  locale: string;
  customSource: string;
  email?: string;
  phoneNumber?: string;
  profileId?: string | null;
}

type SubscriptionResponse = {
  message?: string;
  profileId?: string;
  phoneNumber?: E164Number;
};

const handleSubscription = async ({
  email,
  phoneNumber,
  locale,
  customSource,
  profileId,
}: SubscriptionPayload) => {
  const response = await fetch("/api/marketing/subscribe", {
    method: "POST",
    body: JSON.stringify({
      email,
      phoneNumber,
      locale,
      customSource,
      profileId,
    }),
  });
  const data = await response.json();
  return data as SubscriptionResponse;
};

export type SubscribeMutationFns = {
  subscribe: typeof handleSubscription;
};

const subscribePlugin: QueryClientPlugin<ClientDataServices> = (
  queryClient
) => {
  queryClient.setMutationDefaults(["subscribe"], {
    mutationFn: handleSubscription,
  });
};

export default subscribePlugin;
