import React from "react";

interface UseVisibilityEventHandlers {
  /** A function to be called before visibility is set to `true` */
  onShow?: () => void;
  /** A function to be called before visibility is set to `false` */
  onHide?: () => void;
}

/** Hook that abstracts visibility state and methods used to control it */
export default function useVisibility<K extends string = "">(
  /** optional key to contextualize the returned properties */
  key = "" as K,
  /** optional initial state (defaults to `false`) */
  initialState?: boolean,
  /** optional even handlers for show and hide events */
  eventHandlers?: UseVisibilityEventHandlers
) {
  const [isVisible, setIsVisible] = React.useState(Boolean(initialState));

  const show = () => {
    eventHandlers?.onShow?.();
    setIsVisible(true);
  };

  const hide = () => {
    eventHandlers?.onHide?.();
    setIsVisible(false);
  };

  const toggle = () => {
    setIsVisible((currentState) => {
      const newVisibilityState = !currentState;
      if (newVisibilityState) {
        eventHandlers?.onShow?.();
      } else {
        eventHandlers?.onHide?.();
      }

      return newVisibilityState;
    });
  };

  type ResultKey = `is${K}Visible`;
  type ResultShow = `show${K}`;
  type ResultHide = `hide${K}`;
  type ResultToggle = `toggle${K}`;

  type Result = {
    [P in ResultKey]: boolean;
  } & {
    [P in ResultShow | ResultHide | ResultToggle]: () => void;
  };

  const keyPrefix = `is${key}`;

  return {
    [`${keyPrefix}Visible`]: isVisible,
    [`show${key}`]: show,
    [`hide${key}`]: hide,
    [`toggle${key}`]: toggle,
  } as Result;
}
