/* eslint-disable import/prefer-default-export, @typescript-eslint/naming-convention */
import { useCallback, useEffect } from "react";
import Cookie from "js-cookie";
import type { Router } from "next/router";
import { Md5 } from "ts-md5";
import create from "zustand";
import { persist } from "zustand/middleware";

export const STORAGE_KEY = "_thuma_attribution";
const COOKIES_TO_CLONE = ["_fbc", "_fbp", "_ga", "_ga_MFQR8SPE64"];

export enum AttributionParams {
  fbclid = "fbclid",
  gclid = "gclid",
  irclickid = "irclickid",
  ttclid = "ttclid",
  utm_campaign = "utm_campaign",
  utm_content = "utm_content",
  utm_medium = "utm_medium",
  utm_source = "utm_source",
  utm_term = "utm_term",
  ntProfileId = "ntProfileId",
  cmSessionId = "cmSessionId",
  pebblePostId = "pebblePostId",
}

export type AttributionData = Partial<
  Record<AttributionParams, string> & {
    other: Record<string, string>;
  }
>;

interface AttributionState {
  cookieHash?: string;
  data: AttributionData;
  clear: () => void;
  update: (query: AttributionData, newHash?: string) => void;
  push: (key: AttributionParams, value: string) => void;
}

/**
 * Checks if the first argument includes any elements of the second argument.
 */
function includesAny(haystack: unknown[], needles: unknown[]): boolean {
  return needles.some((needle) => haystack.includes(needle));
}

/**
 * The following heuristic for managing attribution data was provided by
 * Elevar:
 *
 * 1. On the first visit, save any utm and click ids present in the URL
 *
 * 2. On subsequent visits, if the user has new utms or a new gclid, clear the
 *    existing utms and gclid (but leave any other click ids) and replace them
 *    with whatever is present
 *
 * 3. If in a subsequent visit a click id other than gclid is present (fbclid,
 *    ttclid, ...) __without accompanying utms__, save this value without
 *    making changes to the existing parameters
 *
 * 4. If in a subsequent visit a click id other than gclid is present (fbclid,
 *    ttclid, ...) __AND__ new utm values __ARE__ present clear the utm values
 *    and gclid, and save the new click id and utms
 */

const useStore = create<AttributionState>()(
  persist(
    (set) => ({
      data: {},
      clear: () => {
        set({ data: {} });
      },
      update: (query, newHash) => {
        set((state) => {
          const cookieHash = newHash || state.cookieHash;

          if (Object.values(state.data).length < 1) {
            return { data: query, cookieHash };
          }

          const data: AttributionData = { ...state.data };

          if (
            includesAny(Object.keys(query), [
              AttributionParams.gclid,
              AttributionParams.utm_campaign,
              AttributionParams.utm_content,
              AttributionParams.utm_medium,
              AttributionParams.utm_source,
              AttributionParams.utm_term,
            ])
          ) {
            data.gclid = query.gclid;
            data.utm_campaign = query.utm_campaign;
            data.utm_content = query.utm_content;
            data.utm_medium = query.utm_medium;
            data.utm_source = query.utm_source;
            data.utm_term = query.utm_term;
          }

          if (query.fbclid) {
            data.fbclid = query.fbclid;
          }

          if (query.irclickid) {
            data.irclickid = query.irclickid;
          }

          if (query.ttclid) {
            data.ttclid = query.ttclid;
          }

          return { data, cookieHash };
        });
      },
      push: (key: AttributionParams, value: string) => {
        set((state) => ({
          ...state,
          data: { ...state.data, other: { ...state.data.other, [key]: value } },
        }));
      },
    }),
    {
      name: STORAGE_KEY,
    }
  )
);

function shouldUpdateAttributionStore(query: Router["query"]): boolean {
  return Object.keys(query).some((key) => key in AttributionParams);
}

/**
 * Removes attribution params from a router query and asPath if present.
 * Returns an object with the plucked params, asPath and resulting query
 */
function pluckAttributionParams(query: Router["query"]) {
  const params: AttributionData = {};
  Object.entries(query).forEach(([key, value]) => {
    if (key in AttributionParams) {
      if (value) {
        params[key as AttributionParams] = value.toString();
      }
    }
  });

  return params;
}

function makeCartAttributes(ogData: AttributionData) {
  const cartAttributes: { key: string; value: string }[] = [];

  const { other, ...data } = ogData;

  if (other)
    cartAttributes.push(
      ...Object.entries(other).map(([key, value]) => ({
        key,
        value,
      }))
    );

  if (Object.keys(data).length > 0) {
    cartAttributes.push({
      key: "_elevar_visitor_info",
      value: JSON.stringify(data),
    });
  }

  COOKIES_TO_CLONE.forEach((cookieName) => {
    const cookieValue = Cookie.get(cookieName);
    if (cookieValue) {
      cartAttributes.push({
        key: `_elevar_${cookieName}`,
        value: cookieValue,
      });
    }
  });
  return cartAttributes;
}

function getCookieHash() {
  const cookieString = COOKIES_TO_CLONE.map((key) => Cookie.get(key))
    .filter(Boolean)
    .join(",");
  return cookieString ? Md5.hashStr(cookieString) : undefined;
}

// Non-reactive method to get cartAttributes
export function getCartAttributes() {
  const { data } = useStore.getState();
  return makeCartAttributes(data);
}

/**
 * Checks router.query for specific search parameters and, when found, updates
 * the store and calls router.replace() with those parameters removed.
 */
export function usePersistUserAttributionData(router: Router) {
  const update = useStore(useCallback((state) => state.update, []));

  useEffect(() => {
    const { cookieHash } = useStore.getState();
    const newCookieHash = getCookieHash();
    const hasNewCookieHash = cookieHash !== newCookieHash;
    const hasAttributionParams = shouldUpdateAttributionStore(router.query);

    if (hasAttributionParams || hasNewCookieHash) {
      const params = pluckAttributionParams(router.query);
      update(params, hasNewCookieHash ? newCookieHash : undefined);
    }
  }, [router.query, update]);
}

/**
 * Returns user attribution data from the store
 */
export function useUserAttributionData() {
  return useStore(useCallback((state) => state.data, []));
}

/**
 * Returns user attribution data formatted as cart attributes
 */
export function useCartAttributes() {
  return useStore(useCallback((state) => makeCartAttributes(state.data), []));
}

/**
 * Returns a function that clears the user attribution store
 */
export function useClearUserAttributionData() {
  return useStore(useCallback((state) => state.clear, []));
}

export const useAttributionStore = useStore;
