import React from "react";
import { differenceInDays } from "date-fns";
import { useRouter } from "next/router";

import useIsFromMarketingEmail from "../components/MarketingCaptureModal/hooks/useIsFromMarketingEmail";
import useIsFromOrganicSearch from "../components/MarketingCaptureModal/hooks/useIsFromOrganicSearch";
import MarketingServiceContext from "../context/MarketingServiceContext";
import { addViewMarketingCaptureEvent } from "../services/elevar/events";
import MarketingCaptureService from "../services/marketing-capture/service";

const DAYS_TO_SHOW_TOP_BANNER = 60;

async function openModalIfEligible(
  marketingCaptureService: MarketingCaptureService,
  setShowModal: (show: boolean) => void
) {
  const isEligibleForModal =
    await marketingCaptureService.isEligibleForModalAutoShow();
  setShowModal(isEligibleForModal);
}

async function showTopBannerIfEligible(
  marketingCaptureService: MarketingCaptureService,
  setShowTopBanner: (show: boolean) => void
) {
  const subscribedDate = await marketingCaptureService.getSubscribedDate();
  const purchaseDate = await marketingCaptureService.getPurchasedDate();
  if (!subscribedDate) return;

  const daysSinceSubscribed = differenceInDays(
    new Date(),
    new Date(subscribedDate)
  );
  setShowTopBanner(
    daysSinceSubscribed < DAYS_TO_SHOW_TOP_BANNER && !purchaseDate
  );
}

export default function useMarketingCapture() {
  const router = useRouter();
  const isFromOrganicSearch = useIsFromOrganicSearch();
  const isFromMarketingEmail = useIsFromMarketingEmail();
  // TODO: find a better way to check if we're on the product page
  const isProductPage = router.pathname === "/products/[...slug]";

  const [showModal, setShowModal] = React.useState(false);
  const [showTopBanner, setShowTopBanner] = React.useState(false);
  const [showBottomBanner, setShowBottomBanner] = React.useState(false);

  const marketingCaptureService = React.useContext(MarketingServiceContext);

  React.useEffect(() => {
    if (!marketingCaptureService || isFromMarketingEmail === undefined) return;

    // Only run once on mount
    if (!isFromMarketingEmail) {
      openModalIfEligible(marketingCaptureService, setShowModal);
    }
    showTopBannerIfEligible(marketingCaptureService, setShowTopBanner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    marketingCaptureService,
    // This only changes once per session so we can add it as a dependency to only run once
    isFromMarketingEmail,
  ]);

  React.useEffect(() => {
    if (!marketingCaptureService) return;
    const handleModalStateChange = async () => {
      // Increment the number of modal views
      if (showModal) {
        marketingCaptureService.incrementNumberOfModalShows();
      } else {
        setShowBottomBanner(
          await marketingCaptureService.isEligibleForBottomBanner()
        );
        showTopBannerIfEligible(marketingCaptureService, setShowTopBanner);
      }
    };

    // Only run when the modal state has changed
    handleModalStateChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  React.useEffect(() => {
    if (showModal && isFromOrganicSearch !== undefined) {
      addViewMarketingCaptureEvent({
        marketing_channel: isFromOrganicSearch
          ? "Organic Search"
          : "Uncategorized",
      });
    }
  }, [showModal, isFromOrganicSearch]);

  if (router.locale !== "en-CA") {
    const fnStub = () => {};
    return {
      topBanner: { show: false },
      bottomBanner: { show: false, close: fnStub },
      modal: { show: false, open: fnStub, close: fnStub },
    };
  }

  return {
    topBanner: {
      show: !showModal && !showBottomBanner && showTopBanner,
    },
    bottomBanner: {
      show: !isProductPage && !showModal && !showTopBanner && showBottomBanner,
      close: () => {
        setShowBottomBanner(false);
      },
    },
    modal: {
      show: showModal,
      open: () => {
        setShowModal(true);
      },
      close: () => {
        setShowModal(false);
      },
    },
  };
}
