/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import {
  ContextLinkProps,
  ImageContext,
  LinkContext,
  Overlay,
  OverlayProps,
  Video,
} from "@components";
import classNames from "classnames";

import { IResponsiveAsset } from "../../../../@types/generated/contentful";
import {
  AssetType,
  getResponsiveImageOrVideo,
} from "../../util/getResponsiveImageOrVideo";
import { LocationalRichText, LocationalRichTextProps, Media } from "..";

interface ElementWithLinkProps extends Partial<ContextLinkProps> {
  children: JSX.Element;
}

interface TomAssetProps {
  asset: AssetType;
  videoConfig?: VideoConfigProps | null;
  previewImage?: string | undefined;
  keepDimensions?: boolean;
}

interface VideoConfigProps {
  controls?: boolean;
  loop?: boolean;
  autoPlay?: boolean;
  muted?: boolean;
  playsInline?: boolean;
}

export interface TextOverlayMediaProps extends OverlayProps {
  name?: string;
  fadeIn?: boolean;
  videoConfig?: VideoConfigProps | null;
  responsiveMedia?: IResponsiveAsset[];
  locationalRichTextSections?: LocationalRichTextProps[];
  className?: string;
  lrtClassName?: string;
  link?: string | null;
  keepDimensions?: boolean;
  onClick?: React.MouseEventHandler;
}

export function ElementWithLink({
  children,
  href,
  ...props
}: ElementWithLinkProps) {
  const LinkElement = React.useContext(LinkContext);
  if (!href) return children;

  return (
    <LinkElement href={href} {...props}>
      {children}
    </LinkElement>
  );
}

export function TomAsset({
  asset,
  videoConfig,
  previewImage,
  keepDimensions,
}: TomAssetProps) {
  const ImageElement = React.useContext(ImageContext);

  const containerStyle =
    asset.height && asset.width
      ? {
          aspectRatio: `${asset.width} / ${asset.height}`,
        }
      : {};

  return asset.type.includes("video") ? (
    <div style={containerStyle} className="object-cover w-full h-full">
      <Video
        sources={[{ src: asset.src, type: asset.type }]}
        controls={videoConfig?.controls}
        autoPlay={videoConfig?.autoPlay || true}
        muted={videoConfig?.muted}
        playsInline={videoConfig?.playsInline}
        loop={videoConfig?.loop}
        poster={previewImage}
      />
    </div>
  ) : (
    <ImageElement
      src={asset.src}
      alt={asset.alt}
      height={keepDimensions ? asset.height : undefined}
      width={keepDimensions ? asset.width : undefined}
      className="object-cover w-full h-full"
    />
  );
}

export function TextOverlayMedia({
  responsiveMedia = [],
  videoConfig,
  overlayOpacity = "0",
  overlayColorBottom,
  overlayColorMiddle,
  overlayColorTop,
  locationalRichTextSections,
  className,
  onClick,
  lrtClassName,
  link,
  keepDimensions = true,
}: React.PropsWithChildren<TextOverlayMediaProps>) {
  const respMedia = getResponsiveImageOrVideo(responsiveMedia);
  const mobilePreview =
    responsiveMedia.find((a) => a.fields.viewport === "mobile")?.fields
      .videoAsset?.fields.shopifyVideo?.previewImage.url || undefined;
  const desktopPreview =
    responsiveMedia.find((a) => a.fields.viewport === "desktop")?.fields
      .videoAsset?.fields.shopifyVideo?.previewImage.url || undefined;

  return (
    <ElementWithLink href={link ?? undefined} draggable={false}>
      <div
        className={classNames(
          "relative w-full h-full isolate object-cover",
          className
        )}
        onClick={onClick}
      >
        {/* Media */}
        {respMedia && (
          <div className="object-cover w-full h-full">
            {/* mobile image */}
            <Media
              lessThan="lg"
              displayStyle="flex"
              className="relative w-full h-full"
            >
              <TomAsset
                asset={respMedia.mobile}
                videoConfig={videoConfig}
                previewImage={mobilePreview}
                keepDimensions={keepDimensions}
              />
            </Media>
            {/* desktop image */}
            <Media
              greaterThanOrEqual="lg"
              displayStyle="flex"
              className="relative w-full h-full"
            >
              <TomAsset
                asset={respMedia.desktop}
                videoConfig={videoConfig}
                previewImage={desktopPreview}
                keepDimensions={keepDimensions}
              />
            </Media>
          </div>
        )}
        <Overlay
          overlayOpacity={overlayOpacity}
          overlayColorTop={overlayColorTop}
          overlayColorMiddle={overlayColorMiddle}
          overlayColorBottom={overlayColorBottom}
        />

        {/* Text Content */}
        {locationalRichTextSections?.length
          ? locationalRichTextSections.map((richText) => (
              <LocationalRichText
                key={richText.name}
                // eslint-disable-next-line react/no-array-index-key
                {...richText}
                lrtClassName={lrtClassName}
              />
            ))
          : null}
      </div>
    </ElementWithLink>
  );
}
