import { theme } from "../../tailwind.config";

type ThemeColor = keyof typeof theme.colors;
export type Color = ThemeColor | string;

/**
 * Determines whether a given color is considered bright or not.
 * Brightness is calculated based on the relative luminance of the color.
 *
 * @param value - The color value to check. It can be in hex format (#RRGGBB) or a color name defined in the theme.
 * @returns Returns `true` if the color is considered bright, `false` otherwise.
 */
export function isColorBright(value: Color) {
  // Remove any leading '#' symbol
  const [colorName, colorValue = "DEFAULT"] = value.split("-");
  let color = (
    typeof theme.colors[colorName] === "object"
      ? (theme.colors[colorName] as Record<string, string>)[colorValue]
      : (theme.colors[colorName] as string) || value
  ).replace("#", "");

  // Expand shorthand color to six digits if needed
  if (color.length === 3) {
    color = color.replace(/(.)/g, "$1$1");
  }

  // Parse the color values
  const red = parseInt(color.substring(0, 2), 16);
  const green = parseInt(color.substring(2, 4), 16);
  const blue = parseInt(color.substring(4, 6), 16);

  // Calculate the relative luminance using the sRGB color space formula
  const relativeLuminance =
    (red * 0.2126 + green * 0.7152 + blue * 0.0722) / 255;

  // Compare the relative luminance against the brightness threshold (0.5)
  return relativeLuminance > 0.5;
}

/**
 * Creates a color styles object with background and text colors.
 *
 * @param bgColor - The background color value. It can be in hex format (#RRGGBB) or a color name defined in the theme.
 * @param [textColor] - The text color value. It can be in hex format (#RRGGBB) or a color name defined in the theme. If not provided, it will be automatically determined based on the brightness of the background color.
 * @returns Returns an object with `backgroundColor` and `color` properties representing the background and text colors, respectively.
 */
export function makeColorStyles(
  bgColor: Color,
  textColor: Color = isColorBright(bgColor) ? "charcoal" : "white"
) {
  const [bgColorName, bgColorValue = "DEFAULT"] = bgColor.split("-");
  const [textColorName, textColorValue = "DEFAULT"] = textColor.split("-");
  return {
    backgroundColor:
      typeof theme.colors[bgColorName] === "object"
        ? (theme.colors[bgColorName] as Record<string, string>)[bgColorValue]
        : (theme.colors[bgColorName] as string) || bgColor,
    color:
      typeof theme.colors[textColorName] === "object"
        ? (theme.colors[textColorName] as Record<string, string>)[
            textColorValue
          ]
        : (theme.colors[textColorName] as string) || textColor,
  };
}
