import {
  ICollectionList,
  ITextOverlayMedia,
} from "../../../../../@types/generated/contentful";

export function isTextOverlayMediaEntry(
  entry: unknown
): entry is ITextOverlayMedia {
  return (
    typeof entry === "object" &&
    entry !== null &&
    // @ts-expect-error -- optional chaining will prevent errors here
    entry?.sys?.contentType?.sys?.id === "textOverlayMedia"
  );
}

export function isCollectionListEntry(
  entry: unknown
): entry is ICollectionList {
  return (
    typeof entry === "object" &&
    entry !== null &&
    // @ts-expect-error -- optional chaining will prevent errors here
    entry?.sys?.contentType?.sys?.id === "collectionList"
  );
}
