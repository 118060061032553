/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Button, Heading, Icon } from "@components";
import classNames from "classnames";

import selectors from "./selectors";

interface ReadMoreSectionProps {
  title: string;
  children: React.ReactNode;
  expandButtonText: string;
  collapseButtonText: string;
  defaultOpen?: boolean;
}

/**
 * Create collapsible content sections, enabling readers to explore additional details
 * while maintaining a concise and organized presentation.
 */
export function ReadMoreSection({
  title,
  children,
  expandButtonText,
  collapseButtonText,
  defaultOpen,
}: ReadMoreSectionProps) {
  // TODO: replace with useVisibility hook when merged
  const [isExpanded, setIsExpanded] = React.useState(Boolean(defaultOpen));
  const toggleExpanded = () => setIsExpanded((e) => !e);

  return (
    <div className="p-4 mx-auto max-w-screen-2xl lg:p-8">
      <Heading as="h2" variant="h5" className="!text-base">
        {title}
      </Heading>
      <div className="flex flex-col items-center text-2xs">
        <div
          className={classNames("w-full leading-normal", {
            "clamp clamp-1": !isExpanded,
          })}
        >
          {children}
        </div>
        <Button
          onClick={toggleExpanded}
          variant="unstyled"
          className="flex items-center gap-2"
          data-testid={selectors.toggleButton}
        >
          {isExpanded ? collapseButtonText : expandButtonText}
          <Icon
            name="chevron-down"
            className={classNames("transition", { "rotate-180": isExpanded })}
            size="inherit"
          />
        </Button>
      </div>
    </div>
  );
}
