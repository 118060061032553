import { Card } from "@components";

import type { CardType } from "../types";

export default function CardSectionSaddle({ content }: { content: CardType }) {
  const { title, bodyText, href, footerText, image } = content.fields;
  const {
    file,
    title: imageTitle,
    description: imageDescription,
  } = image.fields;
  return (
    <Card
      key={content.sys.id}
      href={href}
      title={title}
      bodyText={bodyText}
      footerText={footerText}
      image={{
        src: file.url,
        alt: imageDescription || imageTitle,
        width: 600,
        height: 600,
      }}
    />
  );
}
