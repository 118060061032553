import { useContext, useEffect } from "react";
import { useProfile } from "@ninetailed/experience.js-react";

import useLogger from "../../../../hooks/useLogger";
import { SessionIdContext } from "../../../context/SessionIdContext";
import usePebblePost from "../../../hooks/usePebblePost";
import useTypedMutation from "../../../hooks/useTypedMutation";
import { useTypedQuery } from "../../../hooks/useTypedQuery";
import useTypedRouter from "../../../hooks/useTypedRouter";
import { CartMutation } from "../../../queries/data/cartData";
import {
  AttributionParams,
  useAttributionStore,
  useCartAttributes,
} from "../../../services/util/userAttributionStore";
import cartUtils from "../utils";

export const useAttribution = () => {
  const { logger } = useLogger();
  const sessionId = useContext(SessionIdContext);
  const { data: cart } = useTypedQuery(["cart"]);
  const { locale } = useTypedRouter();
  const ntProfile = useProfile();

  const { mutate: updateCartAttributesMutation } = useTypedMutation(
    [CartMutation.UpdateAttributes],
    {
      onError: (error) =>
        cartUtils.onCartError(
          error,
          logger,
          cart,
          "Error updating cart attributes"
        ),
    }
  );
  const attribution = useCartAttributes();
  const pushToAttributionStore = useAttributionStore((state) => state.push);

  const pebblePostObj = usePebblePost();

  // Keep Ninetailed profile in sync with the attribution store
  useEffect(() => {
    if (pushToAttributionStore && ntProfile.profile?.id)
      pushToAttributionStore(
        AttributionParams.ntProfileId,
        ntProfile.profile.id
      );
  }, [ntProfile.profile?.id, pushToAttributionStore]);

  // Keep Crossingminds profile in sync with the attribution store
  useEffect(() => {
    if (pushToAttributionStore && sessionId)
      pushToAttributionStore(AttributionParams.cmSessionId, sessionId);
  }, [pushToAttributionStore, sessionId]);

  // Keep Pebble Post profile in sync with the attribution store
  useEffect(() => {
    if (pushToAttributionStore && pebblePostObj?.ppid)
      pushToAttributionStore(
        AttributionParams.pebblePostId,
        pebblePostObj.ppid
      );
  }, [pushToAttributionStore, pebblePostObj]);

  useEffect(() => {
    updateCartAttributesMutation({
      locale,
      payload: attribution,
    });
    // Disables exhaustive to avoid events firing on locale change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCartAttributesMutation, attribution]);
};
