/* eslint-disable import/prefer-default-export */
import * as React from "react";

import type { RecommendedProduct } from "./useRecommendedProductsQuery";

type SelecetdVariantsState<T extends RecommendedProduct> = Record<
  T["slug"],
  {
    product: T;
    selectedVariant: T["variants"][0];
  }
>;

type SelectedVariantsContextValue<T extends RecommendedProduct> = [
  SelecetdVariantsState<T>,
  React.Dispatch<React.SetStateAction<SelecetdVariantsState<T>>>
];

const SelectedVariantsContext =
  React.createContext<SelectedVariantsContextValue<RecommendedProduct> | null>(
    null
  );

function getInitialState(products: RecommendedProduct[]) {
  return Object.fromEntries(
    products.map((product) => {
      // if the product's default variant is unavailable,
      // preselect the first one that is
      const { slug, defaultVariant, variants } = product;
      const firstAvailableVariant =
        variants?.find((variant) => variant.available) || defaultVariant;
      const selectedVariant = defaultVariant?.available
        ? defaultVariant
        : firstAvailableVariant;

      return [
        slug,
        {
          product,
          selectedVariant,
        },
      ];
    })
  );
}

export function SelectedVariantsProvider({
  children,
  products,
}: React.PropsWithChildren<{ products: RecommendedProduct[] }>) {
  const value = React.useState<SelecetdVariantsState<typeof products[0]>>(
    getInitialState(products)
  );

  React.useEffect(() => {
    const [, setState] = value;
    setState(getInitialState(products));
    // adding all the dependencies here will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return (
    <SelectedVariantsContext.Provider value={value}>
      {children}
    </SelectedVariantsContext.Provider>
  );
}

export function useSelectedVariant(slug: RecommendedProduct["slug"]) {
  const context = React.useContext(
    SelectedVariantsContext
  ) as SelectedVariantsContextValue<RecommendedProduct>;

  const handleSelectedOptionChange = React.useCallback(
    (option: { name: string; value: string }) => {
      const [state, setState] = context;
      const entry = state[slug];
      const selectedOptions = entry.selectedVariant.selectedOptions.map((o) =>
        o.name === option.name ? option : o
      );

      const selectedVariant = entry.product.variants.find((variant) =>
        selectedOptions.every(({ name, value }) =>
          variant.selectedOptions.some(
            (o) => o.name === name && o.value === value
          )
        )
      );

      if (selectedVariant) {
        setState((s) => ({
          ...s,
          [slug]: { ...entry, selectedVariant },
        }));
      }
    },
    [context, slug]
  );

  return {
    handleSelectedOptionChange,
    selectedVariant: context?.[0]?.[slug]?.selectedVariant,
  };
}
