import { ReviewData } from "../../services/reviews";
import {
  makeQueryFn,
  QueryClientPlugin,
} from "../../services/util/makeQueryClient";
import type { SharedDataServices } from "../shared";

export const reviewsQueryFns = ({ reviewsAPI }: SharedDataServices) => ({
  async productReviews(productID: string, perPage?: number, page = 1) {
    return reviewsAPI.getProductReviews(productID, perPage, page);
  },

  reviewsFromList: async (reviewIDs: string[]) =>
    reviewsAPI.getReviewsFromList(reviewIDs),

  averageProductRating: async (productID: string) =>
    reviewsAPI.getProductAverageRating(productID),

  productReviewsFromSearch: async (
    productId: string,
    scores?: number[],
    topic_names?: string[],
    free_text_search?: string,
    per_page?: number,
    page = 1
  ) =>
    reviewsAPI.getProductReviewsFromSearch({
      productId,
      scores,
      topic_names,
      free_text_search,
      per_page,
      page,
    }),
});

const reviewsPlugin: QueryClientPlugin<SharedDataServices> = (
  queryClient,
  dataServices
) => {
  const queries = reviewsQueryFns(dataServices);
  const {
    productReviews,
    reviewsFromList,
    averageProductRating,
    productReviewsFromSearch,
  } = queries;

  queryClient.setQueryDefaults(["productReviews"], {
    queryFn: makeQueryFn(productReviews),
    getNextPageParam: (data?: ReviewData) =>
      data?.pagination &&
      data.pagination.page * data.pagination.perPage < data.pagination.total
        ? data.pagination.page + 1
        : false,
  });

  queryClient.setQueryDefaults(["reviewsFromList"], {
    queryFn: makeQueryFn(reviewsFromList),
  });
  queryClient.setQueryDefaults(["averageProductRating"], {
    queryFn: makeQueryFn(averageProductRating),
  });

  queryClient.setQueryDefaults(["productReviewsFromSearch"], {
    queryFn: makeQueryFn(productReviewsFromSearch),
    getNextPageParam: (
      data: Awaited<
        ReturnType<typeof dataServices.reviewsAPI.getProductReviewsFromSearch>
      >
    ) =>
      data?.pagination &&
      data.pagination.page * data.pagination.perPage < data.pagination.total
        ? data.pagination.page + 1
        : false,
    getPreviousPageParam: (
      data: Awaited<
        ReturnType<typeof dataServices.reviewsAPI.getProductReviewsFromSearch>
      >
    ) =>
      data?.pagination && data.pagination.page > 1
        ? data.pagination.page - 1
        : false,
  });
};

export default reviewsPlugin;
