/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { ImageContext, Overlay, OverlayProps } from "@components";
import classNames from "classnames";

import { DeprecatedColumnLimit } from "../../../../../components/Grid/Grid";
import { ImageProps } from "../../../../../components/Image/Image";
import { CTA } from "../../../CTA";
import {
  TextOverlayMedia,
  TextOverlayMediaProps,
} from "../../../TextOverlayMedia";

interface AdProps extends OverlayProps {
  /** Required properties to render the ad image */
  backgroundImage?: ImageProps;

  /** An title value for the cta component */
  ctaTitle?: string;

  /** An href value for the cta component */
  ctaUrl?: string;

  /** A string for the title text */
  title: string;

  /** The position of this ad on the grid */
  gridPosition: number;

  /** whether the ad is rendering in a carousel */
  inCarousel?: boolean;

  /** Maximum number of columns in the parent grid */
  maxColumns: DeprecatedColumnLimit;

  textOverlayMedia?: TextOverlayMediaProps | null;
}

/** An ad component showcasing a background image, title, and cta */
export function Ad({
  backgroundImage,
  ctaTitle,
  ctaUrl,
  title,
  gridPosition,
  inCarousel,
  overlayOpacity,
  overlayColorTop,
  overlayColorMiddle,
  overlayColorBottom,
  maxColumns,
  textOverlayMedia,
}: AdProps) {
  const ImageElement = React.useContext(ImageContext);

  const tabletGridColumnCount = 3;
  const desktopGridColumnCount = 4;
  const xlGridColumnCount = maxColumns;

  const mdDiff = tabletGridColumnCount - gridPosition;
  const lgDiff = desktopGridColumnCount - gridPosition;
  const xlDiff = xlGridColumnCount - gridPosition;

  const mdSpan = tabletGridColumnCount - (gridPosition % tabletGridColumnCount);
  const lgSpan =
    desktopGridColumnCount - (gridPosition % desktopGridColumnCount);
  const xlSpan = xlGridColumnCount - (gridPosition % xlGridColumnCount);

  const widthClasses = classNames("w-full aspect-square", {
    "md:aspect-[2/1] md:w-[200%]": mdDiff === 2,
    "md:aspect-[3/1] md:w-[300%]": mdDiff === 3,
    "lg:aspect-square lg:w-full": lgDiff <= 1,
    "lg:aspect-[2/1] lg:w-[200%]": lgDiff === 2,
    "lg:aspect-[3/1] lg:w-[300%]": lgDiff === 3,
    "lg:aspect-[4/1] lg:w-[400%]": lgDiff === 4,
    "xl:aspect-square xl:w-full": xlDiff <= 1,
    "xl:aspect-[2/1] xl:w-[200%]": xlDiff === 2,
    "xl:aspect-[3/1] xl:w-[300%]": xlDiff === 3,
    "xl:aspect-[4/1] xl:w-[400%]": xlDiff === 4,
    "xl:aspect-[5/1] xl:w-[500%]": xlDiff === 5,
  });

  const columnSpanClasses = classNames({
    "md:col-span-2": mdSpan === 2,
    "md:col-span-3": mdSpan === 3,
    "lg:col-span-1": lgSpan === 1,
    "lg:col-span-2": lgSpan === 2,
    "lg:col-span-3": lgSpan === 3,
    "lg:col-span-4": lgSpan === 4,
    "xl:col-span-1": xlSpan === 1,
    "xl:col-span-2": xlSpan === 2,
    "xl:col-span-3": xlSpan === 3,
    "xl:col-span-4": xlSpan === 4,
    "xl:col-span-5": xlSpan === 5,
  });

  return (
    <div
      className={classNames(
        "relative mb-10 isolate",
        // TODO: reassess this when we plug in actual image dimensions vs fixed ones
        inCarousel ? widthClasses : columnSpanClasses
      )}
      data-testid="collection-list-ad"
    >
      {textOverlayMedia ? (
        <div className="absolute inset-0 z-10 flex flex-col justify-center text-center reset">
          <TextOverlayMedia
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...textOverlayMedia}
          />
        </div>
      ) : (
        <>
          {backgroundImage && (
            <ImageElement src={backgroundImage.src} alt={backgroundImage.alt} />
          )}

          <Overlay
            overlayOpacity={overlayOpacity || "20"}
            overlayColorTop={overlayColorTop}
            overlayColorMiddle={overlayColorMiddle}
            overlayColorBottom={overlayColorBottom}
          />
          <div className="absolute inset-0 z-10 flex flex-col justify-center p-5 pt-6 text-center reset">
            <div
              className={classNames(
                "font-serif leading-none text-white text-lg lg:text-xl z-1"
              )}
            >
              <p>{title}</p>
            </div>
            {ctaUrl && ctaTitle && (
              <CTA
                url={ctaUrl}
                arrowIcon
                className={classNames("self-center mt-4", {
                  "px-4 md:px-8": inCarousel,
                  "py-4": !inCarousel,
                })}
                variant="button-light-to-dark"
                textClassName="!text-tiny sm:!text-2xs md:!text-xs"
              >
                {ctaTitle.toUpperCase()}
              </CTA>
            )}
          </div>
        </>
      )}
    </div>
  );
}
