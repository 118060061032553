import { QueryClient } from "react-query";

import {
  fetchCatalogProduct,
  fetchCatalogProducts,
} from "../../services/catalog/catalogQueryService";
import {
  makeQueryFn,
  QueryClientPlugin,
} from "../../services/util/makeQueryClient";
import { LocaleCode } from "../../util/locale";
import type { ClientDataServices } from "../client";

export const catalogQueryFns = (queryClient: QueryClient) => ({
  catalogProduct: fetchCatalogProduct,

  catalogProducts: (locale: LocaleCode = "en-US", ...ids: string[]) =>
    fetchCatalogProducts(queryClient, ids, locale),
});

const catalogPlugin: QueryClientPlugin<ClientDataServices> = (queryClient) => {
  const { catalogProduct, catalogProducts } = catalogQueryFns(queryClient);
  queryClient.setQueryDefaults(["catalogProduct"], {
    queryFn: makeQueryFn(catalogProduct),
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
  });
  queryClient.setQueryDefaults(["catalogProducts"], {
    queryFn: makeQueryFn(catalogProducts),
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
  });
};

export default catalogPlugin;
