import { useEffect, useRef } from "react";
import useVisibility from "src/hooks/useVisibility";

import { shouldShowToggleButton } from "./utils";

/**
 * Custom hook to determine if a toggle button should be displayed based on the height of a description element.
 *
 * @param description - The description text.
 * @returns An object containing a ref for the description element and a boolean indicating if the toggle button should be visible.
 */
export default function useDescriptionToggle(description: string) {
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const { isToggleButtonVisible, showToggleButton, hideToggleButton } =
    useVisibility("ToggleButton", false);

  /**
   * Checks the height of the description element and updates the visibility of the toggle button accordingly.
   */
  const checkDescriptionHeight = () => {
    if (descriptionRef.current) {
      const shouldBeVisible = shouldShowToggleButton(
        descriptionRef.current,
        getComputedStyle
      );

      if (shouldBeVisible) {
        showToggleButton();
      } else {
        hideToggleButton();
      }
    }
  };

  useEffect(() => {
    checkDescriptionHeight();

    window.addEventListener("resize", checkDescriptionHeight);
    return () => {
      window.removeEventListener("resize", checkDescriptionHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  return { descriptionRef, isToggleButtonVisible };
}
