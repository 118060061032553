/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Heading, ImageContext, Overlay, OverlayProps } from "@components";
import classNames from "classnames";

import { Color, makeColorStyles } from "../../../utils/makeColorStyles";
import { ElementWithLink } from "../TextOverlayMedia/TextOverlayMedia";

export interface CenteredRichTextProps {
  /** Background color of section */
  bgColor?: Color;

  /** Text color */
  textColor?: Color;

  /** Text Alignment */
  textAlignment?: "center" | "left" | "right";

  /** Background image */
  bgImage?: string;

  /** Optional classes for the section */
  className?: string;

  /** The section padding  */
  padding?: "none" | "tight" | "snug" | "normal";

  /** Title of the section */
  title?: string;

  /** The section padding  */
  width?: "md" | "lg";

  /** Whether to promote the title to an H1 */
  promoteH1?: boolean;

  /** link over centered rich text section */
  link?: string | undefined;
}

const paddingVariants = {
  none: "p-5",
  normal: "p-5 pt-12 lg:pt-24 lg:pb-20",
  tight: "p-5 pt-4 lg:pt-3 lg:pb-20",
  snug: "p-5 pt-0 lg:pt-8 lg:pb-20",
};

const widthVariants = {
  md: "max-w-lg",
  lg: "max-w-2xl",
};

/**
 * A section featuring centered Rich Text content
 */
export function CenteredRichText({
  bgColor = "white",
  textColor,
  bgImage,
  children,
  className,
  padding,
  title,
  textAlignment,
  width = "md",
  promoteH1 = false,
  overlayOpacity,
  overlayColorTop,
  overlayColorMiddle,
  overlayColorBottom,
  link,
}: React.PropsWithChildren<CenteredRichTextProps & OverlayProps>) {
  const ImageElement = React.useContext(ImageContext);

  const textAlignClass = `text-${textAlignment || "center"}`;
  const paddingClasses =
    paddingVariants[padding || (!className && title ? "normal" : "none")];

  return (
    <ElementWithLink href={link}>
      <section
        style={{ ...makeColorStyles(bgColor, textColor) }}
        className={classNames(
          "relative flex-1 isolate",
          textAlignClass,
          paddingClasses,
          className
        )}
      >
        {bgImage && (
          <div className="absolute inset-0 z-0">
            <ImageElement src={bgImage} className="w-full h-full" />
          </div>
        )}
        <Overlay
          overlayOpacity={overlayOpacity}
          overlayColorTop={overlayColorTop}
          overlayColorMiddle={overlayColorMiddle}
          overlayColorBottom={overlayColorBottom}
        />
        <div
          className={classNames(
            "relative mx-auto my-5 z-10",
            widthVariants[width]
          )}
        >
          {title && (
            <Heading
              as={promoteH1 ? "h1" : "h2"}
              variant="h2"
              className="!leading-tight"
            >
              {title}
            </Heading>
          )}
          {children}
        </div>
      </section>
    </ElementWithLink>
  );
}
