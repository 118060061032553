/* eslint-disable @next/next/no-img-element */
/* eslint-disable import/prefer-default-export */
import React from "react";
import { Document } from "@contentful/rich-text-types";
import classNames from "classnames";
import { cn } from "src/utils/tailwind-merge";

import { makeColorStyles } from "../../../utils/makeColorStyles";
import { Media, RichText } from "..";

export interface LocationalRichTextProps {
  name: string;
  richText: Document;
  screen: string;
  location:
    | "top-left"
    | "top-center"
    | "top-right"
    | "middle-left"
    | "middle-center"
    | "middle-right"
    | "bottom-left"
    | "bottom-center"
    | "bottom-right";
  textAlignment: string;
  textColor?: string;
  lrtClassName?: string;
}

/**
 LocationalRichText component that displays an image with text beside it.
 */
export function LocationalRichText({
  richText,
  screen,
  location,
  textAlignment,
  textColor = "charcoal",
  lrtClassName,
}: Omit<LocationalRichTextProps, "name">) {
  return (
    <Media
      className={cn(
        "box-border absolute top-0 bottom-0 left-0 right-0 z-10 w-full h-full p-6 full:p-8",
        lrtClassName
      )}
      lessThan={screen === "mobile" ? "lg" : undefined}
      greaterThanOrEqual={screen === "desktop" ? "lg" : undefined}
    >
      <div
        className={classNames(`flex z-10 h-full`, {
          "items-start": location.includes("top"),
          "items-center": location.includes("middle"),
          "items-end": location.includes("bottom"),
          "justify-start": location.includes("left"),
          "justify-center": location.includes("center"),
          "justify-end": location.includes("right"),
        })}
      >
        <div
          className={classNames("flex flex-col", {
            "items-start text-left": textAlignment === "left",
            "items-center text-center": textAlignment === "center",
            "items-end text-right": textAlignment === "right",
          })}
          style={makeColorStyles("", textColor)}
        >
          <RichText richTextResponse={richText} />
        </div>
      </div>
    </Media>
  );
}
