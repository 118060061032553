export interface OptionMatchConfig {
  key: string;
  value: string;
}

type SelectedOptions = Record<string, string>;

/**
 * Generates match configurations based on provided option values and product options.
 * The function maps each option name to its corresponding value in the given order,
 * excluding any configurations where the value is "*".
 *
 * @param optionValues - An array of strings representing option values.
 * @param selectedOptions - An array of ProductOption objects.
 * @returns An array of OptionMatchConfig objects.
 */
export function deriveMatchConfigs(
  optionValues: string[],
  selectedOptions: SelectedOptions
): OptionMatchConfig[] {
  const sortedOptionNames = Object.keys(selectedOptions).sort();
  const matchConfigs: OptionMatchConfig[] = sortedOptionNames
    .map((key, index) => ({
      key,
      value: optionValues[index],
    }))
    .filter((config) => config.value !== "*");

  return matchConfigs;
}

/**
 * Determines if the selected options match the provided match configurations.
 * The function evaluates each selected option against the match configurations
 * to check if the option's value is included in the configuration values.
 *
 * @param matchConfigs - An array of OptionMatchConfig objects.
 * @param selectedOptions - An object representing selected options as a record of key-value pairs.
 * @returns Returns true if all selected options match the configurations, otherwise false.
 */
export function isOptionConfigMatching(
  matchConfigs: OptionMatchConfig[],
  selectedOptions: SelectedOptions
): boolean {
  if (!matchConfigs.length) return true;

  const selectedOptionsList = Object.entries(selectedOptions).map(
    ([name, value]) => ({ name, value })
  );
  return selectedOptionsList.every((selectedOption) => {
    const config = matchConfigs.find(
      (matchConfig) => matchConfig.key === selectedOption.name
    );
    if (!config?.value) return true;

    const values = config.value.split(/,\s*/).map((value) => value.trim());
    return values.includes(selectedOption.value);
  });
}
