/* eslint-disable @next/next/no-img-element */
/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { ImageContext } from "@components";
import classNames from "classnames";

import selectors from "./selectors";

const sizes = {
  regular: ["h-6 w-6", "24px"],
  medium: ["h-4 w-4 text-xs", "16px"],
  small: ["h-3 w-3 text-2xs", "12px"],
  xs: ["h-2 w-2 text-[8px]", "8px"],
};

interface CommonSwatchProps {
  /** Whether aria-hidden is passed to the component  */
  ariaHidden?: boolean;
  /** Behavior of the border. 'hover' = only show on hover. 'always' = always show a border. 'never' = never show a border. */
  borderBehavior?: "hover" | "always" | "never";
  /** The size of the swatch */
  size?: keyof typeof sizes;
}

export interface ImageSwatchProps extends CommonSwatchProps {
  /** The alt for the swatch image */
  alt?: string;
  /** The image src to use for the swatch */
  image: string;
  text?: never;
}

interface TextSwatchProps extends CommonSwatchProps {
  alt?: never;
  image?: never;
  /** The text to use for the swatch */
  text: string;
}

export type SwatchProps = ImageSwatchProps | TextSwatchProps;

/**
 * The `Swatch` component renders a circular graphic of a material. The active and hover states show a gray border around the element.
 */
export function Swatch({
  alt = "swatch",
  ariaHidden = false,
  borderBehavior = "hover",
  image,
  size = "regular",
  text,
}: SwatchProps) {
  const ImageComponent = React.useContext(ImageContext);
  const [sizeClasses, dimensions] = sizes[size];

  return (
    <div
      aria-hidden={ariaHidden}
      data-testid={selectors.container}
      className={classNames(
        "rounded-full p-0.5 border border-solid flex-none",
        {
          "p-px": size === "xs",
          "p-0.5": size !== "xs",
          "border-transparent": borderBehavior !== "always",
          "hover:border-flint": borderBehavior === "hover",
          "border-flint": borderBehavior === "always",
        }
      )}
    >
      {image ? (
        <span className={classNames("relative flex", sizeClasses)}>
          <ImageComponent
            src={image}
            alt={alt}
            height={dimensions}
            width={dimensions}
            className="rounded-full"
          />
        </span>
      ) : (
        <div
          className={classNames(
            `rounded-full flex items-center justify-center`,
            sizeClasses
          )}
        >
          {text}
        </div>
      )}
    </div>
  );
}
