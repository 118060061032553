/* eslint-disable import/prefer-default-export */
import * as React from "react";
import classNames from "classnames";

import { Media } from "../../lib/components";
import { Color, makeColorStyles } from "../../utils/makeColorStyles";
import { Carousel } from "../Carousel";

import selectors from "./selectors";

export interface BannerProps {
  /** Banner background color  */
  bgColor?: Color;

  /** Banner text color  */
  color?: Color;

  /** additional classNames */
  className?: string;

  /** Non-Banner Children, this is used in the SiteBanner to absolute position B2B links */
  secondaryChildren?: JSX.Element | null;

  /** Carousel delay in milliseconds */
  carouselDelay?: number;

  /** Carousel autoplay */
  carouselAutoplay?: boolean;

  /** Data test id */
  dataTestId?: string;
}

/**
 * A full-width banner component used to display important or time-sensitive information. Usually placed at the top of the page, above the page header and navigation.
 */
export function Banner({
  bgColor = "charcoal",
  color,
  className = "",
  children,
  secondaryChildren = null,
  carouselDelay = 3000,
  carouselAutoplay = true,
  dataTestId,
}: React.PropsWithChildren<BannerProps>) {
  const isCarousel = React.Children.count(children) > 1;
  return (
    <aside
      style={{ ...makeColorStyles(bgColor, color) }}
      data-testid={dataTestId}
      className={classNames(
        "block py-1.5 text-center text-2xs relative",
        isCarousel && "flex justify-center",
        className
      )}
    >
      {isCarousel ? (
        <Carousel
          dataTestId={selectors.bannerCarousel}
          allowTouchMove={false}
          autoplay={{
            enabled: carouselAutoplay,
            delay: carouselDelay,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          loop
          pagination={{ type: undefined }}
          navigation={{
            alignment: "center",
            position: "inside",
            spacing: false,
            className: "swiper-banner-navigation",
            size: "inherit",
          }}
          className="max-w-[min(100%,_360px)]"
          enableFade
        >
          {children}
        </Carousel>
      ) : (
        children
      )}
      {/* Nav links section on the right, desktop only */}
      <Media greaterThanOrEqual="lg">{secondaryChildren}</Media>
    </aside>
  );
}
