/* eslint-disable import/prefer-default-export */
import * as React from "react";
import classNames from "classnames";

import useLogger from "../../hooks/useLogger";
import useViewportPosition, {
  ViewportPosition,
} from "../../hooks/useViewportPosition";

import selectors from "./selectors";

export interface VideoSource {
  src: string;
  type: string;
}

interface Track {
  src: string;
  lang: string;
  kind?: TrackType;
  label?: string;
  isDefault?: boolean;
}

type TrackType =
  | "captions"
  | "subtitles"
  | "descriptions"
  | "chapters"
  | "metadata";

export interface VideoProps {
  /** list of sources and types to provide to the element */
  sources: VideoSource[];

  /** url to an image to show before the video is played */
  poster?: string;

  id?: string;

  /** whether to render the video controls */
  controls?: boolean;

  /** whether the video should play automatically */
  autoPlay?: boolean;

  /** whether the video audio channel should be muted */
  muted?: boolean;

  /** whether the video plays inline */
  playsInline?: boolean;

  /** whether the video automatically repeats */
  loop?: boolean;

  /** list of track data associated with the video */
  tracks?: Track[];

  /** whether the video should use its intrinsic height to determine its size, or fill the parent container */
  useIntrinsicHeight?: boolean;

  objectFit?: "cover" | "contain" | undefined;

  className?: string | undefined;
}

/**
 * A `<video>` element. Used typically for inline, looping, auto-playing videos.
 */
export const Video = React.forwardRef<HTMLVideoElement, VideoProps>(
  (
    {
      sources,
      poster,
      controls = false,
      autoPlay = true,
      muted = true,
      playsInline = true,
      loop = true,
      tracks = [],
      useIntrinsicHeight = false,
      objectFit = "cover",
      id,
      className,
    },
    ref
  ) => {
    const internalRef = React.createRef<HTMLVideoElement>();
    const videoRef = (ref || internalRef) as React.RefObject<HTMLVideoElement>;
    const { logger } = useLogger();

    const { referenceElementProps } = useViewportPosition((viewportState) => {
      const videoElement = videoRef.current;
      if (!autoPlay || !videoElement || videoElement.dataset.playing) return;

      if (viewportState === ViewportPosition.InViewport) {
        videoElement.dataset.playing = "true";
        videoElement
          .play()
          .catch((e) => {
            // Catch if this triggers a NotAllowedError from WebKit due to autoplay policy
            // https://webkit.org/blog/6784/new-video-policies-for-ios/
            if (e.name === "NotAllowedError" && muted === true) {
              // Add muted attribute to bypass the policy
              videoElement.muted = true;
              logger.info(
                "Video autoplay failed, retrying with muted attribute"
              );
              setTimeout(() => {
                videoElement
                  .play()
                  .catch((error) =>
                    logger.error("Video autoplay failed", error)
                  );
              }, 300);
            }
          })
          .finally(() => delete videoElement.dataset.playing);
      } else {
        videoElement.pause();
      }
    });

    React.useEffect(() => {
      // We have to attach the muted attribute manually since React tends to omit this
      // See here for more info: https://github.com/facebook/react/issues/10389
      const { current: videoElement } = videoRef;
      if (muted) {
        videoElement?.setAttribute("muted", "");
      } else {
        videoElement?.removeAttribute("muted");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [muted]);

    const videoClasses = classNames(
      "w-full text-white",
      useIntrinsicHeight ? "h-auto" : "h-full",
      `object-${objectFit}`,
      className
    );

    const innerContent = (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        className={videoClasses}
        controls={controls}
        controlsList="nodownload"
        poster={poster}
        preload="metadata"
        muted={muted}
        playsInline={playsInline}
        loop={loop}
        ref={videoRef}
        src={sources.length === 1 ? sources[0].src : undefined}
        data-testid={selectors.video}
        id={id}
      >
        {tracks.map(
          ({
            src,
            isDefault = tracks.length === 1,
            lang,
            kind = "captions",
            label,
          }) => (
            <track
              key={src}
              default={isDefault}
              kind={kind}
              src={src}
              srcLang={lang}
              label={label}
            />
          )
        )}
        {sources.length > 1 &&
          sources.map(({ src, type }) => (
            <source key={type} src={src} type={type} />
          ))}
        {/* Fallback text if all the sources fail */}
        <p>Sorry, your browser doesn&apos;t support embedded videos.</p>
      </video>
    );

    if (!autoPlay) return innerContent;

    return (
      <div className={videoClasses} ref={referenceElementProps.ref}>
        {innerContent}
      </div>
    );
  }
);

Video.displayName = "Video";
