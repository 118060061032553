import React from "react";
import classNames from "classnames";
import { format } from "date-fns";

import { Button, Heading, LinkContext } from "../../../../components";
import { Media } from "../..";
import selectors from "../selectors";
import { ITrackDetailsProps, ITrackingDetailsProps } from "../types";
import * as utils from "../utils";

const packageStateArray = [
  { key: "PENDING", value: "Pre-Transit" },
  {
    key: "TRANSIT",
    value: "In Transit",
  },
  {
    key: "DELIVERED",
    value: "Delivered",
  },
];

function PackageStatusTiles({ status }: { status: string }) {
  const isHighlighted: { [key: string]: boolean } = {
    PENDING: true,
    TRANSIT: status === "TRANSIT" || status === "DELIVERED",
    DELIVERED: status === "DELIVERED",
  };
  return (
    <>
      {packageStateArray.map((statusObj) => (
        <Heading
          key={statusObj.key}
          as="h5"
          variant="h5"
          className={classNames("w-80 h-16 flex justify-center items-center", {
            "bg-[#D9D9D9]": !isHighlighted[statusObj.key],
            "bg-[#D2E2CD]": isHighlighted[statusObj.key],
          })}
        >
          {statusObj.value}
        </Heading>
      ))}
    </>
  );
}

function TrackDetails({ time, location, description }: ITrackDetailsProps) {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 my-7 md:px-4 md:my-3">
      <p className="col-span-1 pl-5 pr-8 text-base text-center md:text-l md:flex-1">
        {time}
      </p>
      <p className="col-span-1 text-base md:text-l md:flex-1">
        {location}
        <br />
        {description}
      </p>
    </div>
  );
}

function TrackingDetails({
  packageStatus,
  eta,
  carrierName,
  carrierService,
  trackingUrl,
  trackingNumber,
  events,
}: ITrackingDetailsProps) {
  const Link = React.useContext(LinkContext);

  const [isOpen, setIsOpen] = React.useState(false);

  const eventsGroupedByDate = React.useMemo(
    () => utils.groupPackageTrackingEventsByDate(events),
    [events]
  );

  const currentPackageState = packageStateArray
    .slice(1, 3)
    .reduce(
      (acc, status) => (status.key === packageStatus ? status : acc),
      packageStateArray[0]
    );

  return (
    <section
      data-testid={selectors.PackageTrackingDetails.container}
      className={classNames([
        "px-2 py-3 bg-pearl w-full",
        "md:p-5 md:max-w-2xl",
        "xl:max-w-5xl xl:px-20 xl:pt-12 xl:pb-32",
      ])}
    >
      <div className="bg-[#D2E2CD] flex flex-col justify-center items-center py-3 w-full">
        <Heading as="h5">{packageStatus}</Heading>
        {eta && (
          <Heading as="h5" className="text-center">
            Estimated Delivery Date:&nbsp;
            <Media lessThan="md" displayStyle="inline">
              <br />
            </Media>
            {eta}
          </Heading>
        )}
      </div>
      <div className="flex justify-center py-2 mt-3 bg-white lg:py-3 lg:mt-4">
        <Heading as="h5" variant="h5" className="text-center w-fit">
          {!carrierName &&
          !carrierService &&
          !trackingNumber &&
          !trackingUrl ? (
            "Your shipment doesn’t have a tracking number yet. Please check back soon!"
          ) : (
            <>
              {carrierName} {carrierService}
              {trackingUrl && trackingNumber && (
                <>
                  {carrierName || carrierService ? ": " : ""}
                  <b>
                    <Link href={trackingUrl} target="_blank" rel="noreferrer">
                      #{trackingNumber}
                    </Link>
                  </b>
                </>
              )}
            </>
          )}
        </Heading>
      </div>
      <div className="w-full mt-2 xl:my-8">
        <Media lessThan="lg" className="w-full">
          <Heading
            as="h5"
            variant="h5"
            className={classNames(
              "w-full h-16 flex justify-center items-center bg-[#D2E2CD]"
            )}
          >
            {currentPackageState.value}
          </Heading>
        </Media>
        <Media
          greaterThanOrEqual="lg"
          className="justify-between gap-6"
          displayStyle="flex"
        >
          <PackageStatusTiles status={packageStatus} />
        </Media>
      </div>
      <div
        className={classNames([
          "flex flex-col items-center bg-white mt-2",
          "py-10",
          "lg:pt-24 lg:pb-20",
        ])}
      >
        <Heading as="h5" variant="h5">
          Shipment Journey
        </Heading>
        <div className={classNames("w-full px-3 lg:px-20")}>
          {eventsGroupedByDate
            .slice(0, !isOpen ? 1 : eventsGroupedByDate.length)
            .map((eventGroup) => (
              <div key={eventGroup[0].date} className="mt-6">
                <Heading
                  as="h5"
                  variant="h5"
                  className="font-medium text-center"
                >
                  {format(new Date(eventGroup[0].date), "MMMM do, yyyy")}
                </Heading>
                {/* HR */}
                <div className="w-full h-px mt-3 bg-lightgray mb-11"> </div>
                {eventGroup
                  .slice(0, !isOpen ? 1 : eventGroup.length)
                  .map((event) => (
                    <TrackDetails
                      key={event.date}
                      time={format(new Date(event.date), "h:mm a")}
                      location={event.locationDisplay}
                      description={event.details}
                    />
                  ))}
              </div>
            ))}
        </div>
        {events.length > 1 && (
          <Button
            data-testid={selectors.PackageTrackingDetails.button}
            variant="light-to-dark"
            className="text-base bg-transparent hover:bg-transparent hover:text-charcoal md:text-l"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <b>{isOpen ? "Collapse Activity" : "See Activity"}</b>
          </Button>
        )}
      </div>
    </section>
  );
}

export default TrackingDetails;
