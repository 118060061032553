/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { LoadingIndicator } from "@components";
import classNames from "classnames";

import { ProductCard } from "../../../../components/ProductCard";
import usePriceFormatter from "../../../hooks/usePriceFormatter";
import type { RecommendedProduct } from "../../../hooks/useRecommendedProductsQuery";
import { useSelectedVariant } from "../../../hooks/useSelectedVariant";
import makeProductUrl from "../../../util/makeProductUrl";
import selectors from "../selectors";

export interface RecommendedProductCardV2Props {
  product: RecommendedProduct & { showPricing?: boolean };
  className?: string;
  onClick: (productVariant: RecommendedProduct["variants"][0]) => void;
  onViewed: (productVariant: RecommendedProduct["variants"][0]) => void;
}

export function RecommendedProductCardV2({
  product,
  className,
  onClick,
  onViewed,
}: RecommendedProductCardV2Props) {
  const formatPrice = usePriceFormatter();
  const { slug, title, showPricing } = product;

  const { selectedVariant } = useSelectedVariant(slug);

  if (!selectedVariant) {
    return (
      <div
        className={classNames(
          "max-w-full flex flex-col items-center justify-center"
        )}
      >
        <LoadingIndicator />
      </div>
    );
  }

  const {
    id,
    images: [image],
    price,
  } = selectedVariant;

  const url = makeProductUrl(slug, id);
  const handleClick = () => onClick(selectedVariant);
  const handleViewed = () => onViewed(selectedVariant);
  // Force image to be square, as per UX
  const imageWidth =
    image.width !== undefined && image.height !== undefined
      ? Math.min(image.width, image.height)
      : undefined;

  return (
    <ProductCard
      url={url}
      onClick={handleClick}
      onViewed={handleViewed}
      image={{ ...image, width: imageWidth, height: imageWidth }}
      price={formatPrice(price)}
      title={title}
      selectors={selectors}
      showPricing={showPricing}
      className={className}
    />
  );
}
