import {
  ComponentProps,
  PropsWithChildren,
  useCallback,
  useContext,
} from "react";
import {
  CardCarousel,
  Heading,
  ImageContext,
  LinkContext,
  SubNavigation,
} from "@components";
import { useIntersectionObserver } from "src/hooks/useIntersectionObserver";
import useVisibility from "src/hooks/useVisibility";
import { useDebouncedCallback } from "use-debounce";

import { Media } from "../Media";
import { ImageData } from "../util";

import useSectionObserver from "./hooks/useSectionObserver";
import selectors from "./selectors";

export interface CollectionAssemblyItem {
  title: string;
  image: ImageData;
  description: string;
  anchor: string;
}

interface CarouselConfig {
  showProgressBar?: boolean;
  showPagination?: boolean;
  mobileCards: number;
  tabletCards: number;
  desktopCards: number;
  mobilePeek?: number;
  tabletPeek?: number;
  desktopPeek?: number;
}

interface CollectionAssemblyProps {
  name: string;
  title: string;
  items: CollectionAssemblyItem[];
  carouselConfig: CarouselConfig;
  ctaText: string;
}

const makeFragmentLink = (anchor: string) => `#${anchor}`;

function CollectionCard({
  title,
  image,
  description,
  ctaText,
  anchor,
}: CollectionAssemblyItem & Pick<CollectionAssemblyProps, "ctaText">) {
  const ImageElement = useContext(ImageContext);
  const LinkElement = useContext(LinkContext);

  return (
    <div data-testid={selectors.carousel.card} className="flex flex-col gap-6">
      <div className="relative">
        <ImageElement
          src={image.src}
          alt={image.alt}
          height={image.height}
          width={image.width}
        />
      </div>
      <div className="flex flex-col items-start w-full gap-1">
        <Heading as="h3" className="text-[25px] leading-tight">
          {title}
        </Heading>
        <p className="whitespace-normal leading-small">{description}</p>
        <LinkElement
          className="uppercase"
          variant="button-underline"
          href={makeFragmentLink(anchor)}
        >
          {ctaText}
        </LinkElement>
      </div>
    </div>
  );
}

function Carousels({
  name,
  items,
  carouselConfig: config,
  ctaText,
}: Pick<
  CollectionAssemblyProps,
  "name" | "items" | "carouselConfig" | "ctaText"
>) {
  const sharedConfig: ComponentProps<typeof CardCarousel> = {
    analyticsName: name,
    showPagination: config.showPagination,
    showProgressBar: config.showProgressBar,
    children: items.map((item) => (
      <CollectionCard key={item.anchor} {...item} ctaText={ctaText} />
    )),
  };

  return (
    <>
      <Media lessThan="twinXl">
        <CardCarousel
          {...sharedConfig}
          cardsToShow={config.mobileCards}
          peekPercentage={config.mobilePeek}
        />
      </Media>
      <Media lessThan="full" greaterThanOrEqual="twinXl">
        <CardCarousel
          {...sharedConfig}
          cardsToShow={config.tabletCards}
          peekPercentage={config.tabletPeek}
        />
      </Media>
      <Media greaterThanOrEqual="full">
        <CardCarousel
          {...sharedConfig}
          cardsToShow={config.desktopCards}
          peekPercentage={config.desktopPeek}
        />
      </Media>
    </>
  );
}

/**
 * Group of collection sections
 */
export default function CollectionAssembly({
  name,
  title,
  items,
  carouselConfig,
  ctaText,
  children,
}: PropsWithChildren<CollectionAssemblyProps>) {
  const activeSectionId = useSectionObserver({
    sectionIds: items.map((item) => item.anchor),
  });

  const { isLinkListVisible, showLinkList, hideLinkList } = useVisibility(
    "LinkList",
    false
  );

  const debouncedHideLinkList = useDebouncedCallback(hideLinkList, 10);
  const debouncedShowLinkList = useDebouncedCallback(showLinkList, 10);

  const linkVisibilityRef = useIntersectionObserver(
    useCallback(
      (entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          debouncedHideLinkList();
        } else {
          debouncedShowLinkList();
        }
      },
      [debouncedHideLinkList, debouncedShowLinkList]
    )
  );

  const jumpLinks = items.map((item) => ({
    id: item.anchor,
    text: item.title,
    href: makeFragmentLink(item.anchor),
  }));

  return (
    <section className="w-full isolate" data-testid={selectors.container}>
      <SubNavigation
        title={title}
        className="sticky z-10 top-[88px] full:top-[72px]"
        showJumpLinks={isLinkListVisible}
        jumpLinks={jumpLinks}
        activeLinkId={activeSectionId}
      />
      <div
        className="px-6 mx-auto mb-2 max-w-screen-king full:mb-4 full:px-8"
        ref={linkVisibilityRef}
        data-testid={selectors.carousel}
      >
        <Carousels
          name={name}
          items={items}
          carouselConfig={carouselConfig}
          ctaText={ctaText}
        />
      </div>

      {/* Child sections */}
      {children}
    </section>
  );
}
