import { Grid } from "@components";

import type { CollectionListWrapperProps } from "../CollectionListSection";

export default function CollectionGrid({
  children,
  maxColumns,
}: CollectionListWrapperProps) {
  return <Grid deprecatedColumns={maxColumns}>{children}</Grid>;
}
