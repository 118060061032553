const selectors = {
  interactiveCard: "interactive-product-card",
  toolbar: {
    container: "interactive-product-card.toolbar",
    swatchButton: "interactive-product-card.toolbar.swatch-button",
    truncatedItemCount: "interactive-product-card.toolbar.item-count",
  },
};

export default selectors;
