import { useMemo } from "react";
import Cookies from "js-cookie";
import { useRouter } from "next/router";

import { FormattedLocale, getLocale, LocaleCode } from "../util/locale";

/** Next Locale cookie lifespan in days */
export const COOKIE_LIFESPAN = 399;

export default function useLocales(onLocaleSwitched?: () => void) {
  const router = useRouter();
  const localeCodes = router.locales as LocaleCode[];
  const localeCode = router.locale as LocaleCode;

  const currentLocale = getLocale(localeCode);
  const locales = localeCodes.map((code) => ({
    ...getLocale(code),
    tag: code,
  }));

  type Region = {
    name: FormattedLocale["region"];
    locales: typeof locales;
    isActive: boolean;
  };

  const regions = useMemo(() => {
    // Comparison function for sorting locales within a region by country name
    function compareLocalesByCountryName(
      a: FormattedLocale,
      b: FormattedLocale
    ) {
      return a.countryName.localeCompare(b.countryName, localeCode);
    }

    // Group locales by region
    const groupedLocales = locales.reduce<Record<string, Region>>(
      (acc, locale) => {
        const { region: name } = locale;
        if (!acc[name]) {
          acc[name] = {
            name,
            locales: [],
            isActive: false,
          };
        }

        acc[name].locales.push(locale);
        return acc;
      },
      {}
    );

    // Sort locales within each region by country name and mark the active region
    const regionsWithSortedLocales = Object.values(groupedLocales).map(
      (region) => ({
        ...region,
        locales: region.locales.sort(compareLocalesByCountryName),
        isActive: region.locales.some((locale) => locale.tag === localeCode),
      })
    );

    return regionsWithSortedLocales;
  }, [locales, localeCode]);

  const switchLocale = async (newLocaleCode: LocaleCode) => {
    onLocaleSwitched?.();
    const { query, pathname, asPath } = router;

    Cookies.set("NEXT_LOCALE", newLocaleCode, { expires: COOKIE_LIFESPAN });
    router
      .push({ query, pathname }, asPath, {
        locale: newLocaleCode,
      })
      .then(router.reload);
  };

  return { currentLocale, locales, localeCode, regions, switchLocale };
}
