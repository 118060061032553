import { MouseEvent } from "react";
import { useRouter } from "next/router";

export default function useSmoothScrolling(href: string | undefined) {
  const router = useRouter();

  const handleScrollOrNavigate = (event: MouseEvent<HTMLAnchorElement>) => {
    if (!href || !href.includes("#")) return;

    const [destination, fragment] = href.split("#");
    if (destination && router.asPath !== destination) return;

    const targetElement = document.getElementById(fragment);
    if (!targetElement) return;

    event.preventDefault();
    targetElement.scrollIntoView({ block: "start", behavior: "smooth" });
    setTimeout(() => {
      router.push(href);
    }, 500);
  };

  return handleScrollOrNavigate;
}
