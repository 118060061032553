/**
 * Utility function to check if the toggle button should be visible based on the height of the description element.
 *
 * @param element - The description element.
 * @param getComputedStyle - Function to get computed styles of the element.
 * @returns boolean indicating if the toggle button should be visible.
 */
export function shouldShowToggleButton(
  element: HTMLElement,
  getComputedStyle: (elt: Element) => CSSStyleDeclaration
): boolean {
  const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
  const maxHeight = lineHeight * 2;
  return element.scrollHeight > maxHeight;
}
