import * as React from "react";
import { Icon, LinkContext } from "@components";

type CmsEditorContextType = {
  showEditorTools: boolean;
  environment: string;
  space: string;
};
export const CmsEditorContext = React.createContext<CmsEditorContextType>({
  showEditorTools: false,
  environment: "master", // TODO: use env value for default here
  space: "",
});

export const makeEntryUrl = (
  environment: string,
  spaceID: string,
  entryId: string
) =>
  `https://app.contentful.com/spaces/${spaceID}/environments/${environment}/entries/${entryId}`;

interface CmsEditorProps {
  entryID: string;
  environment: string;
  spaceID: string;
}

export default function CmsEditor({
  entryID,
  environment,
  spaceID,
}: React.PropsWithChildren<CmsEditorProps>) {
  const LinkElement = React.useContext(LinkContext);

  return (
    <LinkElement
      className="!absolute z-10 flex items-center justify-center w-6 h-6 text-white rounded-full cursor-pointer top-3 left-3 bg-blush"
      href={makeEntryUrl(environment, spaceID, entryID)}
      target="_blank"
      data-testid="cms-editor-link"
    >
      <Icon name="pencil" />
    </LinkElement>
  );
}
