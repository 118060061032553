/* istanbul ignore file */

import { ReactNode } from "react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { useRouter } from "next/router";

function VercelSpeedInsightsProvider({ children }: { children: ReactNode }) {
  const { pathname } = useRouter();

  return (
    <>
      <SpeedInsights route={pathname} sampleRate={0.5} />
      {children}
    </>
  );
}

export default VercelSpeedInsightsProvider;
