import { getShopifyId } from "../services/util/shopifyID";
import { isTruthy } from "../util";

import { useTypedQuery } from "./useTypedQuery";
import useTypedRouter from "./useTypedRouter";

function useCatalogWithCartIds(itemIds: string[] = []) {
  const router = useTypedRouter();
  const { data: cart, isLoading } = useTypedQuery(["cart"]);

  const cartProductIds = (
    cart?.lineItems
      ?.map((item) => item?.variant?.product?.id)
      .filter(isTruthy) ?? ([] as string[])
  ).map((id) => getShopifyId(id));

  const { data: catalog } = useTypedQuery(
    ["catalogProducts", router.locale, ...itemIds, ...cartProductIds],
    { enabled: !isLoading }
  );

  return catalog;
}

export default useCatalogWithCartIds;
