import classNames, { type Argument } from "classnames";
import { extendTailwindMerge } from "tailwind-merge";

import { theme } from "../../tailwind.config";

export const twMerge = extendTailwindMerge(
  {
    extend: {
      theme: {
        colors: [
          ...Object.keys(theme.colors),
          ...Object.keys(theme.colors.flint),
        ],
      },
      classGroups: {
        "font-size": [{ text: Object.keys(theme.fontSize) }],
        leading: [{ leading: Object.keys(theme.extend.lineHeight) }],
        h: [{ h: Object.keys(theme.extend.height) }],
        w: [{ w: Object.keys(theme.extend.width) }],
        "bg-size": [{ bg: Object.keys(theme.extend.backgroundSize) }],
        "grid-rows": [
          { "grid-rows": Object.keys(theme.extend.gridTemplateRows) },
        ],
        "grid-cols": [{ "grid-cols": Object.keys(theme.extend.gridRow) }],
        "max-h": [{ "max-h": Object.keys(theme.extend.maxHeight) }],
        "max-w": [{ "max-w": Object.keys(theme.extend.maxWidth) }],
        z: [{ z: Object.keys(theme.extend.zIndex) }],
        animate: [{ animate: Object.keys(theme.extend.animation) }],
      },
    },
  },
  (config) => {
    // This is needed to prevent twMerge from removing leading-x
    // from before trailing text-x classes. See https://github.com/dcastil/tailwind-merge/issues/218#issuecomment-1509889519
    // eslint-disable-next-line no-param-reassign
    delete config.conflictingClassGroups["font-size"];
    return config;
  }
);

/**
 *
 * @description A wrapper for tailwind-merge that also accepts classNames function arguments
 * @param inputs Same as classNames function, this is just a wrapper for tailwind-merge
 * @returns twMerged classnames
 */
export function cn(...inputs: Argument[]) {
  return twMerge(classNames(...inputs));
}
