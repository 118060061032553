import { Button, Heading, Icon } from "@components";
import { Document } from "@contentful/rich-text-types";
import classNames from "classnames";

import { ICtaButtonFields } from "../../../../../@types/generated/contentful";
import { CTA, RichText } from "../..";
import { Media } from "../../Media";
import selectors from "../selectors";

export interface PostSubmitViewProps {
  onClose: () => void;
  variant?: "default" | "organic_search";
  title: string;
  body: Document;
  cta?: ICtaButtonFields;
  dismissFormButtonText: string;
}

// eslint-disable-next-line import/prefer-default-export
export function PostSubmitView({
  onClose,
  title,
  body,
  cta,
  dismissFormButtonText,
  variant = "default",
}: PostSubmitViewProps) {
  return (
    <div
      className={classNames("grid grid-cols-1", {
        "md:grid-cols-2": variant === "organic_search",
      })}
    >
      {variant === "default" && (
        <div className="flex justify-center col-span-full">
          <Icon name="thuma-logo" />
        </div>
      )}
      <Media lessThan="md">
        <Heading as="h4" className="mt-5 text-center">
          {title}
        </Heading>
      </Media>
      <Media greaterThanOrEqual="md">
        <Heading
          as="h3"
          className={classNames("mt-5 text-center", {
            "": variant === "default",
            "row-start-1 col-start-1": variant === "organic_search",
          })}
        >
          {title}
        </Heading>
      </Media>
      <p
        className={classNames("px-8 text-center", {
          "md:col-start-1 md:row-start-2": variant === "organic_search",
          "mt-5": variant === "default",
        })}
      >
        <RichText richTextResponse={body} />
      </p>
      <div
        className={classNames("w-full mt-6", {
          "grid grid-cols-2 gap-x-2 md:col-start-2 md:row-start-1 md:row-span-2 md:items-center":
            variant === "organic_search",
          "flex flex-col items-center": variant === "default",
        })}
      >
        {cta && (
          <CTA
            url={cta.url}
            variant={cta.variant}
            size={cta.size}
            arrowIcon={cta.arrowIcon}
            onClick={onClose}
            className="w-full max-w-[270px] text-center"
          >
            {cta.text.toUpperCase()}
          </CTA>
        )}
        <Button
          className={classNames("w-full  fit-content max-w-[270px]", {
            "mt-2": variant === "default",
          })}
          onClick={onClose}
          data-testid={selectors.postSubmitView.secondCTA}
        >
          {dismissFormButtonText.toUpperCase()}
        </Button>
      </div>
    </div>
  );
}
