import { createContext, ReactNode, useContext, useMemo } from "react";
import { useRecordItemInteractions } from "@crossingminds/beam-react";

import { SessionIdContext } from "./SessionIdContext";

interface ICrossingMindsContextValue {
  recordCustomItemInteraction: ReturnType<
    typeof useRecordItemInteractions
  >["recordCustomItemInteraction"];
}

export const CrossingMindsContext = createContext<ICrossingMindsContextValue>({
  recordCustomItemInteraction: () => {},
});

export function CrossingMindsProvider({
  children,
  databaseId,
  password,
  serviceLoginId,
}: {
  children: ReactNode;
  databaseId: string;
  password: string;
  serviceLoginId: string;
}) {
  const sessionId = useContext(SessionIdContext);

  const { recordCustomItemInteraction } = useRecordItemInteractions({
    databaseId,
    password,
    serviceLoginId,
    userId: undefined,
    sessionId,
  });

  const value = useMemo(
    () => ({
      recordCustomItemInteraction,
    }),
    [recordCustomItemInteraction]
  );

  return (
    <CrossingMindsContext.Provider value={value}>
      {children}
    </CrossingMindsContext.Provider>
  );
}
