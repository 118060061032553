/* eslint-disable import/prefer-default-export */
import {
  createContext,
  createRef,
  Dispatch,
  RefObject,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useIsMutating } from "react-query";
import { Heading, LinkContext, LoadingIndicator, Sheet } from "@components";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";

import useLogger from "../../../hooks/useLogger";
import { CrossingMindsContext } from "../../context/CrossingMindsContext";
import { AnalyticsProvider } from "../../hooks/useAnalytics";
import useCatalogWithCartIds from "../../hooks/useCatalogWithCartIds";
import useFeatureFlag from "../../hooks/useFeatureFlag";
import usePriceFormatter from "../../hooks/usePriceFormatter";
import useRecommendedProductsQuery from "../../hooks/useRecommendedProductsQuery";
import useRemoveUnidentifiableLineItems from "../../hooks/useRemoveUnidentifiableLineItems";
import useTypedMutation from "../../hooks/useTypedMutation";
import { useTypedQuery } from "../../hooks/useTypedQuery";
import { CartMutation } from "../../queries/data/cartData";
import { MATTRESS_FEE_HANDLE } from "../../services/cart/constants";
import type {
  Cart as CartType,
  LineItem as LineItemType,
} from "../../services/cart/types";
import { CatalogProduct } from "../../services/catalog/types";
import { addViewCartEvent } from "../../services/elevar/events";
import useDataLayerTracker from "../../services/elevar/useDataLayerTracker";
import { getShopifyId } from "../../services/util/shopifyID";
import { isTruthy } from "../../util";
import { LocaleCode } from "../../util/locale";
import makeProductUrl from "../../util/makeProductUrl";
import { formatOptionValues, getCheckoutUrl, ImageData } from "../util";
import {
  CartBadge,
  FeatureWrapper,
  Media,
  RecommendedProductsSection,
} from "..";

import CloseButton from "./components/CloseButton";
import { LineItem, LineItemProps } from "./components/LineItem";
import { useAttribution } from "./hooks/useAttribution";
import useScrollCartToBottom from "./hooks/useScrollCartToBottom";
import selectors from "./selectors";
import cartUtils from "./utils";

interface CartProps {
  /** Boolean for whether or not the cart is open */
  isOpen: boolean;

  /** method to call when the cart is dismissed */
  onDismiss: () => void;
}

const CART_RULESET_ID = "10762"; // TODO: specify this in Contentful rather than hard-coding it here
type FormattedLineItems = LineItemProps & { id: string };

function RecsWrapper({
  children,
  shouldShowRecs,
}: React.PropsWithChildren<{ shouldShowRecs: number | boolean }>) {
  return shouldShowRecs ? (
    <div
      data-testid={selectors.cartRecommendations}
      className="p-8 lg:flex-1 lg:pt-8 lg:mt-0"
    >
      <AnalyticsProvider object={{ id: "cart-rec-widget", name: "cart" }}>
        <RecommendedProductsSection
          addToCart
          heading="You may also like" // TODO: specify this in Contentful rather than hard-coding it here
          max={3}
          rulesetId={CART_RULESET_ID}
          variant="list"
          location="cart"
        >
          {children}
        </RecommendedProductsSection>
      </AnalyticsProvider>
    </div>
  ) : null;
}

function CartSectionWrapper({
  children,
  isEmpty,
  formattedLineItems,
  scrollElementRef,
  isMutatingCart,
  opacityFlip,
  formattedSubtotal,
  cart,
  lineItems,
}: React.PropsWithChildren<{
  isEmpty: boolean;
  formattedLineItems: FormattedLineItems[];
  scrollElementRef: RefObject<HTMLLIElement>;
  isMutatingCart: number;
  opacityFlip: boolean;
  formattedSubtotal: string;
  cart: CartType | null | undefined;
  lineItems:
    | (LineItemType & { catalogProduct: CatalogProduct | null | undefined })[]
    | undefined;
}>) {
  const Link = useContext(LinkContext);
  return (
    <div className="px-6 lg:px-8 lg:overflow-y-auto lg:flex-1">
      {/* Header */}
      {children}

      {/* Scrollable cart item list */}
      <div className="relative">
        <ul className="flex flex-col pt-6" data-testid={selectors.lineItemList}>
          <AnimatePresence>
            {formattedLineItems.map(({ id, ...item }) => (
              <motion.li
                key={id}
                initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, height: 0, marginBottom: 0 }}
                transition={{ duration: 0.3 }}
                className="mb-6"
              >
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <LineItem {...item} />
              </motion.li>
            ))}
            <li data-testid={selectors.scrollElement} ref={scrollElementRef} />
          </AnimatePresence>
          {!formattedLineItems.length && (
            <li
              className={classNames("py-10 text-center transition", {
                "opacity-0": isMutatingCart,
              })}
            >
              Your cart is empty
            </li>
          )}
        </ul>
        {isEmpty && (
          <div
            className={classNames(
              "absolute top-0 left-0 h-full w-full flex items-center justify-center bg-white/60 transition",
              { "opacity-0 pointer-events-none": !isMutatingCart }
            )}
          >
            {/* TODO: i18n this string */}
            <LoadingIndicator label="Loading cart items" />
          </div>
        )}
      </div>

      {/* Subtotal section */}
      <div
        className={classNames(
          "border-flint/30 bg-white sticky bottom-0 pb-4 lg:pb-8",
          { "border-t": !isEmpty }
        )}
      >
        <div className="flex justify-between py-4 justify-items-end">
          <span className="text-base">Subtotal</span>
          <span
            className={classNames(
              "flex-1 text-right will-change-opacity",
              opacityFlip ? "opacity-100" : "opacity-[0.99]"
            )}
          >
            {formattedSubtotal}
          </span>
        </div>
        <div className="text-center">
          {cart && !!lineItems?.length && (
            <Link
              href={getCheckoutUrl(cart.checkoutUrl)}
              variant="button-pine-to-dark"
              disabled={!formattedLineItems.length}
              className="w-full"
            >
              <span className="tracking-wider uppercase">
                Continue to Checkout
              </span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
/**
 * A component that renders cart data and controls cart functionality using the Shopify API
 */
export function Cart({ isOpen, onDismiss }: CartProps) {
  const router = useRouter();
  const formatPrice = usePriceFormatter();
  const { logger } = useLogger();
  const { recordCustomItemInteraction } = useContext(CrossingMindsContext);
  useAttribution();

  // queries
  const {
    data: cart,
    isLoading: isCartLoading,
    isFetched,
  } = useTypedQuery(["cart"]);
  const isMutatingCart = useIsMutating({
    predicate: ({ options: { mutationKey } }) => {
      if (mutationKey === undefined) return false;

      const key = Array.isArray(mutationKey) ? mutationKey[0] : mutationKey;
      return Object.values(CartMutation).includes(key);
    },
  });

  const scrollElementRef = createRef<HTMLLIElement>();
  useScrollCartToBottom(isCartLoading, isOpen, scrollElementRef);

  const catalog = useCatalogWithCartIds();
  const lineItems = isFetched
    ? cart?.lineItems
        ?.map((item) => {
          const { product } = item.variant;
          const id = getShopifyId(product?.id);
          const catalogProduct = catalog?.[id];
          const variantId = getShopifyId(item.variant.id);
          const variant = catalogProduct?.variants[variantId];

          // TODO: find an alternative to hard-coding this product handle
          if (product.handle === MATTRESS_FEE_HANDLE) {
            return undefined;
          }

          // Let us know when variants in carts were faked
          if (variant?.isFake) {
            logger.log(
              `Item was faked: {"productId": ${id}, "handle": ${product?.handle}, "variantId": ${variantId}}`
            );
          }
          return { ...item, catalogProduct };
        })
        .filter(isTruthy) ?? []
    : undefined;

  /**
   * Safari has a bug where it doesn't automatically
   * repaint contents of `position: sticky` elements within
   * a `position: fixed` parent
   * We're using this variable to (invisibly) change the opacity
   * of the affected element to force a repaint every time
   * the cart subtotal changes.
   * Bug report: https://bugs.webkit.org/show_bug.cgi?id=244139
   */
  const [opacityFlip, setOpacityFlip] = useState(false);
  useEffect(() => {
    setOpacityFlip((o) => !o);
  }, [cart?.cost?.subtotalAmount?.amount]);

  // mutations
  const { mutate: removeFromCartMutation } = useTypedMutation(
    [CartMutation.Remove],
    {
      onError: (error) =>
        cartUtils.onCartError(
          error,
          logger,
          cart,
          "Error removing item from cart"
        ),
    }
  );
  const { mutate: updateCartMutation } = useTypedMutation(
    [CartMutation.Update],
    {
      onError: (error) =>
        cartUtils.onCartError(error, logger, cart, "Error updating cart"),
    }
  );

  function getQuantityChangeHandler(lineItem: LineItemType) {
    return (quantity: number) =>
      updateCartMutation({
        locale: router.locale as LocaleCode,
        payload: [{ lineItem, quantity }],
      });
  }

  useEffect(() => {
    if (isOpen && !isCartLoading) {
      addViewCartEvent({
        cartTotal: cart?.cost.subtotalAmount.amount || "0",
        items: cart?.lineItems || [],
      });
    }
    // Disables exhaustive to avoid multiple events firing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, addViewCartEvent, isCartLoading]);

  // We must calculate the subtotal this way because shopify's subtotal may include a mattress fee
  const subtotal =
    lineItems?.reduce(
      (acc, item) =>
        acc +
        Number(item.cost.amountPerQuantity.amount) * Number(item.quantity),
      0
    ) ?? 0;

  useDataLayerTracker(router, lineItems, subtotal.toString());

  const formattedSubtotal = formatPrice(`${subtotal}`);

  const formattedLineItems: FormattedLineItems[] =
    lineItems?.map((item) => {
      const { catalogProduct, variant } = item;
      const catalogVariant =
        catalogProduct?.variants[getShopifyId(item.variant.id)];
      const shippingMessage = catalogVariant?.tooltipInStock;
      const formattedPrice = formatPrice(item.cost.amountPerQuantity.amount);

      const { selectedOptions } = catalogVariant || variant;

      const { primaryOptionValue, optionValues } = formatOptionValues(
        catalogProduct?.options ?? [],
        selectedOptions
      );

      const image: ImageData = {
        src: catalogVariant?.images[0].url || variant.image?.src || "",
        alt:
          catalogVariant?.images[0].description ||
          catalogVariant?.images[0].title ||
          variant.image?.altText ||
          variant.title,
        width: catalogVariant?.images[0].width || variant.image?.width || 0,
        height: catalogVariant?.images[0].height || variant.image?.height || 0,
      };

      return {
        id: variant.id,
        title: item.catalogProduct?.productTitle || variant.product.title,
        href: makeProductUrl(
          catalogProduct?.slug || variant.product.handle,
          item.variant.id
        ),
        image,
        optionValues,
        price: formattedPrice,
        primaryOptionValue,
        shippingMessage,
        quantity: item.quantity,
        useSquareImageOnMobile: true,
        onQuantityChange: getQuantityChangeHandler(item),
        onRemove: () => {
          removeFromCartMutation({
            locale: router.locale as LocaleCode,
            payload: [{ lineItem: item }],
          });
          recordCustomItemInteraction({
            interactionType: "remove_from_cart",
            itemId: getShopifyId(item.variant.id),
          });
        },
      };
    }) ?? [];

  useRemoveUnidentifiableLineItems();

  const { data: recommendations, isLoading: isLoadingRecommendations } =
    useRecommendedProductsQuery({
      rulesetId: CART_RULESET_ID,
    });

  const isEmpty = formattedLineItems.length === 0;
  const hasRecommendations =
    !!recommendations.length || isLoadingRecommendations;
  const shouldShowRecs = isMutatingCart || (!isEmpty && hasRecommendations);

  const { value } = useFeatureFlag("cart-rec-swap");
  const isSwapped = value === "swapped";

  return (
    <Sheet
      side="right"
      isOpen={isOpen}
      onDismiss={onDismiss}
      top
      className="!p-0 lg:flex items-stretch lg:!w-auto"
    >
      <div
        className={classNames(
          "lg:flex lg:h-full lg:items-stretch lg:divide-x divide-flint/30",
          {
            "lg:w-[800px] xl:w-[1100px]": shouldShowRecs,
            "lg:w-[400px] xl:w-[550px]": !shouldShowRecs,
            "lg:flex-row-reverse lg:divide-x-reverse": isSwapped,
          }
        )}
      >
        <CartSectionWrapper
          isEmpty={isEmpty}
          formattedLineItems={formattedLineItems}
          scrollElementRef={scrollElementRef}
          isMutatingCart={isMutatingCart}
          opacityFlip={opacityFlip}
          formattedSubtotal={formattedSubtotal}
          cart={cart}
          lineItems={lineItems}
        >
          {/* Cart Section Header */}
          <div
            className={classNames(
              "flex flex-row-reverse sticky top-0 z-10 pt-8 bg-white items-center pb-3 border-b-2 border-b-lightgray",
              {
                "lg:flex-row": !isEmpty || isCartLoading,
                "lg:flex-row-reverse": isSwapped,
              }
            )}
          >
            <CloseButton
              onClick={onDismiss}
              className={classNames({
                "lg:hidden": !isSwapped && (!isEmpty || isCartLoading),
              })}
            />
            <span
              className={classNames("mx-auto", {
                "lg:ml-0 lg:mr-auto": !isSwapped && (!isEmpty || isCartLoading),
                "lg:ml-8 lg:mr-auto": isSwapped,
              })}
            >
              <Heading as="h4">Your Cart</Heading>
            </span>
            <CartBadge testId={selectors.cartIcon} />
          </div>
        </CartSectionWrapper>
        <RecsWrapper shouldShowRecs={shouldShowRecs}>
          {/* Recs Section Header */}
          <div className="flex items-center justify-between mb-4 lg:pb-3 lg:mb-6 lg:border-b-2 border-b-lightgray">
            {/* TODO: i18n */}
            <Heading as="h4" className="text-[24px] lg:text-lg">
              You may also like
            </Heading>
            <Media greaterThanOrEqual="lg">
              <FeatureWrapper
                flag="cart-rec-swap"
                defaultState="original"
                stateMap={{
                  original: (
                    <CloseButton onClick={onDismiss} className="block" />
                  ),
                  swapped: null,
                }}
              />
            </Media>
          </div>
        </RecsWrapper>
      </div>
    </Sheet>
  );
}

export const CartContext = createContext<Dispatch<SetStateAction<boolean>>>(
  () => {}
);
