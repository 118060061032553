/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Heading } from "@components";
import classNames from "classnames";

import { Color, makeColorStyles } from "../../../utils/makeColorStyles";
import { HR } from "../RichText/RichText";

interface SplitTextSectionProps {
  title?: string;
  columns: { id: string; content: React.ReactNode }[];
  contentRatio?: "40/60" | "50/50" | "60/40";
  showSeparator?: boolean;
  background?: Color;
  promoteH1?: boolean;
}

/**
 * A section that renders columns of rich text
 */
export function SplitTextSection({
  title,
  columns,
  contentRatio = "50/50",
  showSeparator = false,
  background,
  promoteH1 = false,
}: SplitTextSectionProps) {
  const sectionClasses = [
    classNames({
      "pb-8 lg:pb-0 lg:pr-24 xl:pr-28": contentRatio !== "50/50",
      "lg:w-1/3": contentRatio === "40/60",
      "sm:flex-1 lg:w-1/2": contentRatio === "50/50",
      "lg:w-2/3": contentRatio === "60/40",
      "border-b lg:border-b-0 lg:border-r lg:border-px border-black/20":
        showSeparator,
    }),
    classNames({
      "leading-loose": background,
      "pt-4 lg:pt-0 lg:pl-24 xl:pl-28": contentRatio !== "50/50",
      "lg:w-2/3": contentRatio === "40/60",
      "sm:flex-1 lg:w-1/2": contentRatio === "50/50",
      "lg:w-1/3": contentRatio === "60/40",
    }),
  ];
  const backgroundColorStyles = makeColorStyles(background || "white");

  return (
    <section
      className={classNames(
        "relative max-w-screen-xl px-8 mx-auto my-12 lg:my-32",
        {
          "py-8 max-w-screen-2xl": background,
          "lg:px-20": contentRatio !== "50/50",
        }
      )}
    >
      {background && (
        <div
          style={{ ...backgroundColorStyles }}
          className={classNames(
            "transition-all absolute z-0 hidden lg:block -top-8 -bottom-8 left-8 right-8 opacity-30"
          )}
        />
      )}
      <div
        className={classNames("relative z-0", {
          "px-8 lg:px-16 xl:px-24": background,
        })}
      >
        {background && (
          <div
            style={{ ...backgroundColorStyles }}
            className={classNames(
              "absolute z-0 left-0 right-0 -top-8 -bottom-8 lg:hidden opacity-30"
            )}
          />
        )}
        {title && (
          <>
            <Heading as={promoteH1 ? "h1" : "h2"} variant="h2">
              {title}
            </Heading>
            <HR className="!mb-6 lg:!mb-6" />
          </>
        )}
        <div
          className={classNames("flex flex-col lg:flex-row lg:gap-4", {
            "items-stretch gap-4": contentRatio !== "50/50",
          })}
        >
          {columns.map(({ id, content }, index) => (
            <div
              key={id}
              className={classNames(
                "transition-all",
                {
                  "lg:mt-8 lg:pl-6": index === 0 && !title,
                },
                sectionClasses[index]
              )}
            >
              {content}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
