import { Icon } from "@components";

import type { FormattedLocale } from "../../util/locale";

interface SelectedLocaleProps {
  locale: FormattedLocale;
  displayInFull?: boolean;
  children?: React.ReactNode;
}

export default function SelectedLocale({
  locale,
  displayInFull,
  children,
}: SelectedLocaleProps) {
  return (
    <span className="flex items-center gap-2">
      <Icon name="globe" size="inherit" />
      <span>
        {displayInFull
          ? `${locale.countryName} (${locale.currencyCode})`
          : locale.countryShortCode}
        {children}
      </span>
    </span>
  );
}
