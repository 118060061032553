import { Asset } from "contentful";

import type { IVideoAsset } from "../../../@types/generated/contentful";

interface ShopifyVideoSource {
  url: string;
  width: number;
  format: string;
  height: number;
  mimeType: string;
}

interface ShopifyVideoPreviewImage {
  id: string;
  url: string;
  width: number;
  height: number;
  altText: null | string;
}

export interface ShopifyVideo {
  id: string;
  alt: string;
  sources: ShopifyVideoSource[];
  previewImage: ShopifyVideoPreviewImage;
  mediaContentType: "VIDEO";
}

function isShopifyVideoPreviewImage(
  // since this function is specifically for narrowing the type from any, this is allowed here.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previewImageCandidate: any
): previewImageCandidate is ShopifyVideo["previewImage"] {
  return (
    typeof previewImageCandidate?.id === "string" &&
    typeof previewImageCandidate?.url === "string" &&
    (typeof previewImageCandidate?.altText === "string" ||
      previewImageCandidate?.altText === null) &&
    typeof previewImageCandidate?.width === "number" &&
    typeof previewImageCandidate?.height === "number"
  );
}

function isShopifyVideoSource(
  // since this function is specifically for narrowing the type from any, this is allowed here.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  videoSourceCandidate: Record<string, any>
): videoSourceCandidate is ShopifyVideoSource {
  return (
    typeof videoSourceCandidate === "object" &&
    videoSourceCandidate !== null &&
    typeof videoSourceCandidate?.url === "string" &&
    typeof videoSourceCandidate?.format === "string" &&
    typeof videoSourceCandidate?.mimeType === "string" &&
    typeof videoSourceCandidate?.width === "number" &&
    typeof videoSourceCandidate?.height === "number"
  );
}

export function isShopifyVideo(
  videoAsset: IVideoAsset["fields"]["shopifyVideo"],
  entryId = ""
): videoAsset is ShopifyVideo {
  if (
    typeof videoAsset === "object" &&
    typeof videoAsset?.id === "string" &&
    typeof videoAsset?.alt === "string" &&
    videoAsset?.mediaContentType === "VIDEO" &&
    typeof videoAsset?.previewImage === "object" &&
    isShopifyVideoPreviewImage(videoAsset.previewImage) &&
    typeof videoAsset?.sources === "object" &&
    Array.isArray(videoAsset.sources) &&
    videoAsset.sources.length > 0 &&
    videoAsset.sources.every(isShopifyVideoSource)
  ) {
    return true;
  }
  throw new Error(
    `Encountered a misconfigured ShopifyVideo in entry: ${entryId}`
  );
}

function findHighestResolutionMP4(
  sources: ShopifyVideoSource[]
): ShopifyVideoSource {
  const mp4s = sources.filter((source) => source.format.includes("mp4"));

  return mp4s.reduce((bestSource, candidateSource) => {
    if (bestSource.height < candidateSource.height) {
      return candidateSource;
    }
    return bestSource;
  });
}

// function to transform a videoAsset into a "Contentful" Asset
export function makeContentfulAsset(shopifyVideo: ShopifyVideo): Asset {
  const isoNow = new Date().toISOString();
  const source = findHighestResolutionMP4(shopifyVideo.sources);

  return {
    sys: {
      id: shopifyVideo.id,
      type: "Shopify Video",
      createdAt: isoNow,
      updatedAt: isoNow,
      locale: "*",
      contentType: {
        sys: {
          type: "Link",
          linkType: "ContentType",
          id: "Asset",
        },
      },
    },
    fields: {
      title: shopifyVideo.id,
      description: shopifyVideo.alt,
      file: {
        url: source.url,
        details: {
          size: source.height * source.width,
          image: {
            height: source.height,
            width: source.width,
          },
        },
        fileName: source.url,
        contentType: source.mimeType,
      },
    },
    metadata: {
      tags: [],
    },
    toPlainObject: /* istanbul ignore next */ () => ({}),
  };
}
