import Rollbar from "rollbar";

import { Cart } from "../../services/cart/types";

function onCartError(
  error: Error,
  logger: Rollbar,
  cart: Partial<Cart> | null | undefined,
  message: string
) {
  logger.error(
    `${message}\n`,
    `error: ${error}\n`,
    `cart: ${JSON.stringify(cart || null)}`
  );
}

const utils = { onCartError };

export default utils;
