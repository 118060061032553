import { RefObject } from "react";

export function paginate(
  direction: "left" | "right",
  ref: RefObject<HTMLDivElement>
) {
  if (!ref.current) return;
  const scrollContainerBoundClientRect = ref.current.getBoundingClientRect();

  // Note: This relies on the scroll container having no vertical padding. It may stop working if added.
  const elementsFromPoint = Array.from(
    document.elementsFromPoint(
      scrollContainerBoundClientRect.x,
      scrollContainerBoundClientRect.y + 4 // 4px is the vertical padding of the scroll container
    )
  );
  const scrollChildren = Array.from(ref.current.children);
  const leftMostChildInViewIndex = scrollChildren.findIndex((node) =>
    elementsFromPoint.includes(node)
  );

  const leftMostChildInView = scrollChildren[leftMostChildInViewIndex];
  if (direction === "left") {
    const leftMostChildInViewBoundingBox =
      leftMostChildInView.getBoundingClientRect();

    const diff =
      leftMostChildInViewBoundingBox.x - scrollContainerBoundClientRect.x;
    const isLeftMostChildPartiallyInView = diff < 0;

    if (isLeftMostChildPartiallyInView)
      ref.current.scrollBy({
        left: diff,
        behavior: "smooth",
      });
    else {
      const firstOutOfViewChildOnTheLeft =
        scrollChildren[leftMostChildInViewIndex - 1];
      ref.current.scrollBy({
        left: -firstOutOfViewChildOnTheLeft.clientWidth,
        behavior: "smooth",
      });
    }
  } else
    ref.current.scrollBy({
      left: leftMostChildInView.clientWidth,
      behavior: "smooth",
    });
}
