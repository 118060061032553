import * as React from "react";
import Cookies from "js-cookie";
import { Router } from "next/router";
import { v4 as uuid } from "uuid";

/** Key used to store the page load ID for Searchspring */
export const PAGE_LOAD_ID_KEY = "ssPageLoadID";

/** Updates the page load ID on each route change event */
export default function usePageLoadID(router: Router) {
  React.useEffect(() => {
    const updatePageLoadID = () => {
      Cookies.set(PAGE_LOAD_ID_KEY, uuid());
    };

    router.events.on("routeChangeComplete", updatePageLoadID);
    return () => {
      router.events.off("routeChangeComplete", updatePageLoadID);
    };
  }, [router.events]);
}
