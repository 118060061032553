import { useTypedQuery } from "./useTypedQuery";
import useTypedRouter from "./useTypedRouter";

function useCatalog(itemIds: string[] = []) {
  const router = useTypedRouter();

  const {
    data: catalog,
    isFetched,
    isLoading,
  } = useTypedQuery(["catalogProducts", router.locale, ...itemIds]);

  return { catalog, isFetched, isLoading };
}

export default useCatalog;
