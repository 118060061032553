import { useState } from "react";

interface Props {
  fullText: string;
  characterLimit: number;
  isExpandedByDefault?: boolean;
}

export default function useCollapsibleText({
  fullText,
  characterLimit,
  isExpandedByDefault,
}: Props) {
  const canCollapse = fullText.length > characterLimit;

  const [isCollapsed, setIsCollapsed] = useState(
    canCollapse && !isExpandedByDefault
  );
  const toggleCollapsedState = () => setIsCollapsed((s) => !s);

  const text = isCollapsed
    ? `${fullText.slice(0, characterLimit)}...`
    : fullText;

  // TODO: i18n
  const buttonText = isCollapsed ? "Read More" : "Read Less";

  return {
    canCollapse,
    text,
    buttonText,
    toggleCollapsedState,
  };
}
