const selectors = {
  container: "dynamic-grid-container",
  mobileItemsContainer: "dynamic-grid-container--mobile-container",
  desktopItemsContainer: "dynamic-grid-container--desktop-container",
  item: "dynamic-grid-container--item",
  description: "dynamic-grid-container--description",
  descriptionToggle: "dynamic-grid-container--description-toggle",
};

export default selectors;
