const createCustomSource = ({
  isOrganicSearch = false,
  device,
}: {
  isOrganicSearch?: boolean;
  device: "desktop" | "mobile";
}) => {
  const parts = [
    isOrganicSearch ? "organic" : "",
    "email capture form",
    device,
  ];
  return parts.join(" ").trim();
};

const utils = {
  createCustomSource,
};

export default utils;
