import type CartAPI from "../../services/cart/api";
import type { LineItem } from "../../services/cart/types";
import {
  makeQueryFn,
  QueryClientPlugin,
} from "../../services/util/makeQueryClient";
import { encodeShopifyVariantId } from "../../services/util/shopifyID";
import { LocaleCode } from "../../util/locale";
import type { ClientDataServices } from "../client";

export interface CartQueryFns {
  cart: CartAPI["fetchCart"];
}

export enum CartMutation {
  AddToCart = "cart:addItems",
  Update = "cart:updateItems",
  Remove = "cart:removeItems",
  UpdateAttributes = "cart:updateAttributes",
  Clear = "cart:clear",
}

interface VariantImage {
  description?: string;
  title: string;
  url: string;
  height?: number;
  width?: number;
}

interface WithLocale {
  locale?: LocaleCode;
}

export interface AddToCartPayload extends WithLocale {
  location?: string;
  lineItems: {
    customAttributes?: { key: string; value: string }[];
    product: {
      id: string;
      productType?: string;
      title: string;
      vendor?: string;
    };
    quantity: number;
    variant: {
      compareAtPrice?: string;
      id: string;
      images: VariantImage[];
      price: string;
      sku: string;
      title: string;
    };
  }[];
}

export interface RemoveFromCartPayload extends WithLocale {
  payload: {
    lineItem: LineItem;
  }[];
}

export interface UpdateCartPayload extends WithLocale {
  payload: {
    lineItem: LineItem;
    quantity: number;
  }[];
}

export interface UpdateAttributesPayload extends WithLocale {
  payload: {
    key: string;
    value: string;
  }[];
}

export interface CartMutationFns {
  [CartMutation.AddToCart]: (
    items: AddToCartPayload
  ) => ReturnType<CartAPI["addToCart"]>;
  [CartMutation.Update]: (
    items: UpdateCartPayload
  ) => ReturnType<CartAPI["updateCart"]>;
  [CartMutation.Remove]: (
    items: RemoveFromCartPayload
  ) => ReturnType<CartAPI["removeFromCart"]>;
  [CartMutation.UpdateAttributes]: (
    customAttributes: UpdateAttributesPayload
  ) => ReturnType<CartAPI["updateAttributes"]>;
  [CartMutation.Clear]: (
    locale: LocaleCode
  ) => ReturnType<CartAPI["clearCartID"]>;
}

const cartPlugin: QueryClientPlugin<ClientDataServices> = (
  queryClient,
  { localizedCartApi }
) => {
  queryClient.setQueryDefaults(["cart"], {
    queryFn: makeQueryFn((locale: LocaleCode = "en-US") =>
      localizedCartApi[locale].fetchCart()
    ),
  });

  queryClient.setMutationDefaults([CartMutation.Update], {
    mutationFn: ({ locale = "en-US", payload: items }: UpdateCartPayload) =>
      localizedCartApi[locale].updateCart(
        items.map(({ lineItem, quantity }) => ({
          id: lineItem.id.toString(),
          quantity,
          variantId: lineItem.variant.id.toString(),
          customAttributes: lineItem.attributes.map(({ key, value }) => ({
            key,
            value: value ?? "",
          })),
        }))
      ),
    onSuccess: (data) => {
      queryClient.setQueryData(["cart"], data);
    },
  });

  queryClient.setMutationDefaults([CartMutation.AddToCart], {
    mutationFn: ({ locale = "en-US", lineItems: items }: AddToCartPayload) =>
      localizedCartApi[locale].addToCart(
        items.map(({ customAttributes, variant, quantity }) => ({
          attributes: customAttributes || [],
          quantity,
          variantId: encodeShopifyVariantId(variant.id),
        }))
      ),
    onSuccess: (data) => {
      queryClient.setQueryData(["cart"], data);
    },
  });

  queryClient.setMutationDefaults([CartMutation.Remove], {
    mutationFn: ({ locale = "en-US", payload: items }: RemoveFromCartPayload) =>
      localizedCartApi[locale].removeFromCart(
        items.map(({ lineItem }) => lineItem.id.toString())
      ),
    onSuccess: (data) => {
      queryClient.setQueryData(["cart"], data);
    },
  });

  queryClient.setMutationDefaults([CartMutation.UpdateAttributes], {
    mutationFn: async ({
      locale = "en-US",
      payload: customAttributes,
    }: UpdateAttributesPayload) =>
      localizedCartApi[locale].updateAttributes(customAttributes),
    onSuccess: (data) => {
      if (data) {
        queryClient.setQueryData(["cart"], data);
      }
    },
  });

  queryClient.setMutationDefaults([CartMutation.Clear], {
    mutationFn: async (locale: LocaleCode = "en-US") =>
      localizedCartApi[locale].clearCartID(),
    onSuccess: () => {
      queryClient.setQueryData(["cart"], null);
    },
  });
};

export default cartPlugin;
