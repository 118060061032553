/* istanbul ignore file */

import { PropsWithChildren, useMemo } from "react";
import { NinetailedProvider } from "@ninetailed/experience.js-next";
import NinetailedGoogleTagmanagerPlugin from "@ninetailed/experience.js-plugin-google-tagmanager";
import NinetailedInsightsPlugin from "@ninetailed/experience.js-plugin-insights";
import NinetailedPreviewPlugin from "@ninetailed/experience.js-plugin-preview";

import { useTypedQuery } from "../hooks/useTypedQuery";
import { Audience, Experiment } from "../services/ninetailed/types";

interface NinetailedConfig {
  environment: string;
  token: string;
  experiments: Experiment[];
  audiences: Audience[];
}

function NTProvider({
  environment,
  token,
  experiments,
  audiences,
  children,
}: PropsWithChildren<NinetailedConfig>) {
  const { data: inPreviewMode } = useTypedQuery(["inPreviewMode"]);
  const ninetailedPlugins = useMemo(
    () => [
      new NinetailedGoogleTagmanagerPlugin(),
      new NinetailedInsightsPlugin(),
      ...(inPreviewMode
        ? [
            new NinetailedPreviewPlugin({
              experiences: experiments || [],
              audiences: audiences || [],
            }),
          ]
        : []),
    ],
    [inPreviewMode, experiments, audiences]
  );
  return (
    <NinetailedProvider
      environment={environment}
      clientId={token}
      plugins={ninetailedPlugins}
      componentViewTrackingThreshold={2000}
      requestTimeout={5000}
    >
      {children}
    </NinetailedProvider>
  );
}

export default NTProvider;
