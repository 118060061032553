import { useEffect, useRef } from "react";
import type { NextRouter, Router } from "next/router";

import { LineItem } from "../cart/types";

import eventManager from "./eventManager";
import * as Events from "./events";

function useDataLayerTracker(
  router: Router | NextRouter,
  lineItems: LineItem[] | undefined,
  subtotal?: string
) {
  const { events } = router;
  const baseEventRun = useRef(false);

  // Fire a base event on first initialization
  useEffect(() => {
    // If the base event has already been fired or lineItems hasn't been set, return
    if (baseEventRun.current || !lineItems) return;
    Events.addBaseEvent(lineItems, subtotal);
    Events.addRouteChangeEvent();
    baseEventRun.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItems]); // Run only once after lineItems is set

  // Add a listener to reset the queue on each route change start
  useEffect(() => {
    const handleRouteChangeStart = (
      url: string,
      config: { shallow: boolean }
    ) => {
      if (!config.shallow) eventManager.disableEventProcessing();
    };
    events?.on("routeChangeStart", handleRouteChangeStart);
    return () => events?.off("routeChangeStart", handleRouteChangeStart);
  }, [events]);

  // Add a listener to fire the base event on each route change completion
  useEffect(() => {
    const handleRouteChangeComplete = (
      url: string,
      config: { shallow: boolean }
    ) => {
      if (!config.shallow) Events.addBaseEvent(lineItems, subtotal);
      Events.addRouteChangeEvent();
    };
    events?.on("routeChangeComplete", handleRouteChangeComplete);
    return () => events?.off("routeChangeComplete", handleRouteChangeComplete);
  }, [events, lineItems, subtotal]);
}

export default useDataLayerTracker;
