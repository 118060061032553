// @ts-check ensure we use the correct types
const localeMap = require("./src/lib/util/localeMap");

/**
 * Hard-typed locale definitions thanks to TypeScript + JSDoc
 * @typedef {import('./src/lib/util/locale').LocaleCode} LocaleCode
 * */

/** @typedef {NonNullable<import('next').NextConfig["i18n"]>} I18NConfig */

/** @type {LocaleCode} */
const defaultLocale = "en-US";
const locales = /** @type {LocaleCode[]} */ (Object.keys(localeMap));

module.exports = {
  /** @type {I18NConfig} */
  i18n: {
    locales,
    defaultLocale,
  },
};
