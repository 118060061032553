/* eslint-disable import/prefer-default-export */
import * as React from "react";
import Image from "next/image";

import { ImageProps } from "../../../components/Image/Image";
import makeSrcWithProtocol from "../../util/makeSrcWithProtocol";

/**
 * A site image component that provides the next/image for use within components
 */
export function SiteImage({
  src,
  alt = "",
  height,
  width,
  className,
  priority,
  objectFit = "cover",
  ...props
}: ImageProps) {
  return (
    <Image
      src={makeSrcWithProtocol(src)}
      alt={alt}
      height={height}
      width={width}
      layout={width && height ? "intrinsic" : "fill"}
      objectFit={objectFit}
      draggable={false}
      className={className}
      priority={priority}
      decoding={priority ? "sync" : "async"}
      data-testid={props["data-testid"]}
    />
  );
}
