import * as React from "react";
import { Button, Heading, Icon } from "@components";
import { Disclosure, RadioGroup } from "@headlessui/react";
import classNames from "classnames";

import useLocales from "../../hooks/useLocales";

import SelectedLocale from "./SelectedLocale";
import selectors from "./selectors";

interface LocalePickerProps {
  onSwitch?: () => void;
}
export default function LocalePicker({ onSwitch }: LocalePickerProps) {
  const { currentLocale, localeCode, regions, switchLocale } =
    useLocales(onSwitch);

  return (
    <>
      <div className="grid gap-8 mb-8">
        <p className="text-xs leading-snug">
          Your current selected location is {currentLocale.countryName} and your
          order will be billed in {currentLocale.currencyCode}.
        </p>

        <SelectedLocale locale={currentLocale} displayInFull />
      </div>

      <Heading as="h3" variant="h5" className="!text-base md:!text-input">
        Choose your location:
      </Heading>

      <RadioGroup className="mt-8" value={localeCode} onChange={switchLocale}>
        {regions.map((region) => (
          <Disclosure key={region.name} defaultOpen={region.isActive}>
            {({ open }) => (
              <>
                <Disclosure.Button
                  as={Button}
                  variant="list-button"
                  className={classNames(
                    "uppercase md:normal-case hover:bg-white !pl-0",
                    "flex items-center w-full gap-2",
                    { hidden: regions.length < 2 }
                  )}
                >
                  <span className="text-base md:text-input">{region.name}</span>
                  <Icon name={open ? "minus" : "plus"} size="inherit" />
                </Disclosure.Button>

                <Disclosure.Panel>
                  {region.locales.map(
                    (
                      { tag, countryName, currencyCode, languageName },
                      index
                    ) => (
                      <RadioGroup.Option
                        key={tag}
                        value={tag}
                        as={Button}
                        className={classNames(
                          "flex items-center w-full gap-2",
                          {
                            "border-b-0 md:border-b":
                              index === region.locales.length - 1,
                          }
                        )}
                        disabled={localeCode === tag}
                        variant="list-button"
                        data-testid={selectors.LocaleButton}
                      >
                        <span className="md:text-base">
                          {countryName}
                          {/* Only show the language name for non-selected locales */}
                          {localeCode !== tag && ` (${languageName})`}
                        </span>
                        <span className="text-2xs text-slate">
                          {currencyCode}
                        </span>
                      </RadioGroup.Option>
                    )
                  )}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </RadioGroup>
    </>
  );
}
