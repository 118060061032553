/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/prefer-default-export */
import {
  ComponentProps,
  Fragment,
  PropsWithChildren,
  ReactNode,
  useState,
} from "react";
import { Heading, Icon } from "@components";
import { Disclosure, Transition } from "@headlessui/react";
import classNames from "classnames";

import { addHeapAccordionPaneInteracted } from "../../lib/services/elevar/events";

interface AccordionData {
  id: string;
  icon?: ReactNode;
  anchor?: string;
  title: string;
  children: ReactNode;
  /** additional attributes to add to each accordion button */
  dataAttributes?: Record<string, string>;
}

interface AccordionProps {
  /** Name to identify the accordion for tracking */
  name: string;

  /** the various accordion items */
  items: AccordionData[];

  /** additional classes to apply to the accordion */
  className?: string;

  /** whether to use headings for each item title */
  useHeadings?: boolean;

  /** the icon to use for each accordion item when collapsed */
  iconName?: ComponentProps<typeof Icon>["name"];

  /** the icon to use for each accordion item when expanded */
  expandedIconName?: ComponentProps<typeof Icon>["name"];

  /** whether to expand the first item by default */
  expandFirstItem?: boolean;

  /** additional classes to apply to each accordion button */
  buttonClasses?: string;

  /** whether to allow multiple items to be open at once */
  mode?: "single-open" | "multi-open";

  /** whether to unmount the content when closed */
  unmount?: boolean;

  /** whether the Accordion is containing Tabs */
  isTabs?: boolean;

  /** whether to track pane changes */
  trackPaneChanges?: boolean;
}

type HeaderProps = PropsWithChildren<Pick<AccordionProps, "useHeadings">>;

interface Panel {
  open: boolean;
  close: VoidFunction;
  id: string;
}

function ItemHeader({ children, useHeadings }: HeaderProps) {
  if (useHeadings) {
    return <Heading as="h4">{children}</Heading>;
  }

  return <p className="text-base">{children}</p>;
}

function matchHashToAnchor(anchor?: string) {
  if (!anchor || typeof window === "undefined") return false;
  return decodeURI(window.location.hash) === `#${anchor}`;
}

interface ButtonContentProps {
  title: string;
  icon?: ReactNode;
  toggleIconName: string;
}
function ButtonContent({ title, icon, toggleIconName }: ButtonContentProps) {
  return (
    <>
      <span className="flex items-center">
        {title}
        {icon}
      </span>
      <Icon className="ml-4" name={toggleIconName} />
    </>
  );
}

function TransitionWrapper({ children }: { children: ReactNode }) {
  return (
    <Transition
      enter="transition-all duration-100"
      enterFrom="transform opacity-0"
      enterTo="transform opacity-100"
      leave="transition-all duration-100"
      leaveFrom="transform opacity-100"
      leaveTo="transform opacity-0"
    >
      {children}
    </Transition>
  );
}

/**
 * Displays various expandable and collapsible content blocks
 */
export function Accordion({
  name,
  items,
  iconName = "chevron-down",
  expandedIconName = "close",
  className,
  useHeadings = true,
  expandFirstItem,
  buttonClasses,
  trackPaneChanges,
  mode = "multi-open",
  unmount = true,
  isTabs = false,
}: AccordionProps) {
  const itemClassNames = useHeadings
    ? "border-b border-b-lightgray"
    : undefined;

  const [panels, setPanels] = useState<Panel[]>([]);

  function togglePanel({ open, close, id }: Panel) {
    const currentlyActivePanel =
      mode === "single-open" ? panels[0] : panels.find((p) => p.id === id);

    let opened = true;

    if (currentlyActivePanel) {
      currentlyActivePanel.close();
      setPanels((list) => list.filter((l) => l.id !== id));
      opened = false;
    } else {
      setPanels((list) =>
        mode === "single-open"
          ? [{ open, close, id }]
          : [...list, { open, close, id }]
      );
    }

    const tabIndex = items.findIndex((item) => item.id === id);
    const tab = items[tabIndex];

    if (tab && trackPaneChanges) {
      addHeapAccordionPaneInteracted({
        accordionSectionName: name,
        accordionID: id,
        accordionTitle: tab.title,
        accordionPosition: tabIndex + 1,
        interactionType: opened ? "opened" : "closed",
        visibleOnPageload: "yes",
      });
    }
  }

  const PanelWrapper = unmount ? TransitionWrapper : Fragment;

  return (
    <div data-testid="accordion" className={className}>
      {items.map(
        ({ id, anchor, children, title, icon, dataAttributes = {} }, index) => (
          <Disclosure
            key={id}
            defaultOpen={
              matchHashToAnchor(anchor) || (expandFirstItem && index === 0)
            }
          >
            {({ open, close }) => (
              <div
                id={anchor}
                className={itemClassNames}
                suppressHydrationWarning
              >
                <ItemHeader useHeadings={useHeadings}>
                  <span onClick={() => togglePanel({ open, close, id })}>
                    <Disclosure.Button
                      className={classNames(
                        "flex items-center justify-between w-full text-left",
                        buttonClasses,
                        {
                          "py-8": useHeadings,
                          "py-4 md:py-6": !useHeadings && isTabs,
                          "py-4": !useHeadings && !isTabs,
                          "border-b-[0.25px] border-b-lightgray":
                            !useHeadings && !open,
                        }
                      )}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...dataAttributes}
                      suppressHydrationWarning
                    >
                      <ButtonContent
                        title={title}
                        icon={icon}
                        toggleIconName={open ? expandedIconName : iconName}
                      />
                    </Disclosure.Button>
                  </span>
                </ItemHeader>

                <PanelWrapper>
                  <Disclosure.Panel unmount={unmount}>
                    {children}
                  </Disclosure.Panel>
                </PanelWrapper>
              </div>
            )}
          </Disclosure>
        )
      )}
    </div>
  );
}
