import React from "react";

import ZustandMarketingCapturePersister from "../services/marketing-capture/persister";
import MarketingCaptureService from "../services/marketing-capture/service";

const MarketingServiceContext = React.createContext<MarketingCaptureService>(
  new MarketingCaptureService(new ZustandMarketingCapturePersister())
);

export default MarketingServiceContext;
