import { useContext, useEffect, useRef } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "src/utils/tailwind-merge";

import { useBreakpoints } from "../../lib/hooks/useBreakpoints";
import { Button } from "../Button";
import { Heading } from "../Heading";
import { LinkContext } from "../Link";
import { PaginationButtonPair } from "../PaginationButton/PaginationButton";

import { useScrollHandler } from "./hooks/useScrollHandler";
import selectors from "./selectors";

interface JumpLinkProps {
  text: string;
  href: string;
  isActive?: boolean;
}

function JumpLink({ text, href, isActive }: JumpLinkProps) {
  const Link = useContext(LinkContext);
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView({ behavior: "smooth", inline: "start" });
    }
  }, [isActive]);

  return (
    <Link
      ref={ref}
      key={text}
      href={href}
      className="inline-block scroll-bar-snap-item whitespace-nowrap"
      variant="underline"
      forceUnderline={isActive}
    >
      {text}
    </Link>
  );
}

interface JumpLinkListProps {
  jumpLinks: JumpLinkProps[];
}

function JumpLinkList({ jumpLinks }: JumpLinkListProps) {
  const ref = useRef<HTMLDivElement>(null);

  const {
    isPreviousDisabled,
    isNextDisabled,
    showPagination,
    paginateLeft,
    paginateRight,
  } = useScrollHandler(ref);

  return (
    <>
      <motion.div
        initial={{ y: -20 }}
        animate={{ y: 0 }}
        exit={{ y: -20 }}
        transition={{ ease: "linear", duration: 0.15 }}
        ref={ref}
        className="flex items-center w-full max-w-full mr-3 space-x-6 overflow-x-scroll full:space-x-8 king:space-x-14 whitespace-nowrap no-scrollbar scroll-bar-snap-x full:ml-9 full:mr-3 full:h-9"
        data-testid={selectors.jumpLinksContainer}
      >
        {jumpLinks.map(({ text, href, isActive }) => (
          <JumpLink key={text} text={text} href={href} isActive={isActive} />
        ))}
      </motion.div>
      <div
        className={classNames({
          hidden: !showPagination,
          "full:h-9 full:flex full:pr-6 items-center": showPagination,
        })}
      >
        <PaginationButtonPair
          onPrevious={paginateLeft}
          onNext={paginateRight}
          isPreviousDisabled={isPreviousDisabled}
          isNextDisabled={isNextDisabled}
        />
      </div>
    </>
  );
}

type JumpLinkWithID = JumpLinkProps & { id: string };

interface SubNavigationProps {
  title: string;
  jumpLinks?: JumpLinkWithID[];
  showJumpLinks?: boolean;
  activeLinkId?: string;
  className?: string;
}

export function SubNavigation({
  title,
  jumpLinks = [],
  showJumpLinks = false,
  activeLinkId,
  className,
}: SubNavigationProps) {
  const { fullVisible } = useBreakpoints();
  const isMobile = !fullVisible;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const jumpLinksWithActive = jumpLinks.map((jumpLink) => ({
    ...jumpLink,
    isActive: activeLinkId === jumpLink.id,
  }));

  return (
    <div className={cn("bg-white", className)}>
      <div className="px-6 pb-6 mx-auto king:max-w-screen-king full:px-8 full:py-6">
        <div className="flex justify-between">
          <div className="border-charcoal border-b-[3px] pb-4">
            <Heading
              as="h1"
              variant="h4"
              className="pr-5 text-[25px] leading-7 full:leading-eighth full:text-lg"
            >
              {title}
            </Heading>
          </div>

          <AnimatePresence>
            {!isMobile && showJumpLinks && (
              <JumpLinkList jumpLinks={jumpLinksWithActive} />
            )}
          </AnimatePresence>

          <div className="flex items-center h-7 full:h-9">
            <Button
              variant="underline"
              className="uppercase whitespace-nowrap text-2xs leading-[13px] mb-0 h-fit"
              onClick={scrollToTop}
              data-testid={selectors.scrollToTopButton}
            >
              {/* TODO: i18n */}
              Back to Top
            </Button>
          </div>
        </div>
        <AnimatePresence>
          {showJumpLinks && isMobile && (
            <motion.div
              layout
              className="flex max-w-full mt-6 full:hidden"
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
              transition={{ ease: "linear", duration: 0.3 }}
              data-testid={selectors.mobileJumpLinksContainer}
            >
              <JumpLinkList jumpLinks={jumpLinksWithActive} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
