import { createContext, useContext, useMemo } from "react";

import { MergedProduct } from "../queries/data/productData";
import type { MergedProductVariant } from "../services/catalog/types";

export interface ProductContextValue {
  product: MergedProduct;
  variant: MergedProductVariant;
  selectedOptions: Record<string, string>;
}

const ProductContext = createContext<Nullable<ProductContextValue>>(null);

function assertContextValueIsProvided(
  context: Nullable<ProductContextValue>
): asserts context is ProductContextValue {
  if (!context) {
    throw Error("Product data was not provided to ProductContext.Provider");
  }
}

export function useOptionalProductContext() {
  const context = useContext(ProductContext);
  return context;
}

export function useProductContext() {
  const context = useContext(ProductContext);
  assertContextValueIsProvided(context);
  return context;
}

export type ProductContextProviderProps = Omit<
  ProductContextValue,
  "selectedOptions"
>;

export function ProductContextProvider({
  children,
  product,
  variant,
}: React.PropsWithChildren<ProductContextProviderProps>) {
  const value = useMemo(
    () => ({
      product,
      selectedOptions: Object.fromEntries(
        variant.selectedOptions.map((o) => [o.name, o.value])
      ),
      variant,
    }),
    [product, variant]
  );
  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
}
