import { RefObject, useEffect, useState } from "react";

import { paginate } from "./utils";

export function useScrollHandler(ref: RefObject<HTMLDivElement>) {
  const [isPreviousDisabled, setIsPreviousDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [showPagination, setShowPagination] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollRef = ref.current;
      if (currentScrollRef) {
        const { scrollWidth, scrollLeft, clientWidth } = currentScrollRef;
        setIsPreviousDisabled(scrollLeft === 0);
        setIsNextDisabled(
          Math.floor(scrollWidth - scrollLeft - clientWidth) <= 0
        );
        setShowPagination(scrollWidth > clientWidth);
      }
    };

    handleScroll();

    const currentScrollRef = ref.current;

    currentScrollRef?.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      currentScrollRef?.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, [ref]);

  return {
    isPreviousDisabled,
    isNextDisabled,
    showPagination,
    paginateLeft: () => paginate("left", ref),
    paginateRight: () => paginate("right", ref),
  };
}
