/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import { Icon } from "@components";
import classNames from "classnames";

import { TextInput } from "./TextInput";

interface TextInputWithSubmitProps
  extends Omit<React.ComponentProps<typeof TextInput>, "children"> {
  errorMessage: string;
  onSubmit?: () => void;
  success: boolean;
  successMessage: string;
  buttonClasses?: string;
}

export function TextInputWithSubmit(props: TextInputWithSubmitProps) {
  const {
    onSubmit,
    success,
    successMessage,
    errorMessage,
    inputClasses,
    buttonClasses,
    ...inputProps
  } = props;
  const { disabled, invalid } = inputProps;
  return (
    <TextInput
      {...inputProps}
      inputClasses={classNames("placeholder:text-xs", inputClasses)}
    >
      <button
        onClick={!disabled ? onSubmit : undefined}
        data-testid="text-input-submit-btn"
        type="submit"
        className={classNames(
          "w-12 md:w-16 py-5 bg-white active:shadow-inner",
          buttonClasses
        )}
      >
        <Icon name="arrow-right" className="text-charcoal" />
      </button>
      {success && successMessage && (
        <span className="px-3 text-sm bg-transparent">{successMessage}</span>
      )}
      {invalid && errorMessage && (
        <span className="px-3 text-sm">{errorMessage}</span>
      )}
    </TextInput>
  );
}
