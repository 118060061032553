/* eslint-disable import/prefer-default-export */
import { BLOCKS } from "@contentful/rich-text-types";

import { ICenteredRichText } from "../../../../@types/generated/contentful";
import { IPackageTrackingResponse } from "../../services/package-tracking/types";

import { PackageTrackingEvent } from "./types";

export function groupPackageTrackingEventsByDate(
  events: PackageTrackingEvent[]
): PackageTrackingEvent[][] {
  return events.reduce((acc, cur) => {
    if (acc.length === 0) return [[cur]];
    const lastGroupDate = new Date(acc[acc.length - 1][0].date);
    const curDate = new Date(cur.date);
    if (
      lastGroupDate.getMonth() === curDate.getMonth() &&
      lastGroupDate.getDate() === curDate.getDate()
    ) {
      acc[acc.length - 1].push(cur);
    } else {
      acc.push([cur]);
    }
    return acc;
  }, [] as PackageTrackingEvent[][]);
}

export const createCenteredRichText = (text: string): ICenteredRichText => ({
  metadata: {
    tags: [],
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error leaving out unused arbitrary fields
  sys: {
    id: "6xWBdVKiMcXIKmRmuaYANy123",
    type: "Entry",
    contentType: {
      sys: {
        type: "Link",
        linkType: "ContentType",
        id: "centeredRichText",
      },
    },
    locale: "en-US",
  },
  fields: {
    name: "CenteredRichText",
    backgroundColor: "transparent",
    textColor: "charcoal",
    text: {
      nodeType: BLOCKS.DOCUMENT,
      data: {},
      content: [
        {
          nodeType: BLOCKS.PARAGRAPH,
          content: [
            {
              nodeType: "text",
              value: text,
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  toPlainObject: () => ({}),
  update: async () => ({} as unknown as ICenteredRichText),
});

export const sortEventsByDate = (events: PackageTrackingEvent[]) =>
  [...events].sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

export const findTrackingUrlWithTheBed = (
  response: IPackageTrackingResponse
) => {
  const shipmentWithTheBed = response.shipments.find((shipment) =>
    shipment.lineItems.some((lineItem) => lineItem.productName === "The Bed")
  );
  return shipmentWithTheBed?.trackingUrl;
};
