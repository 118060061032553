import { useRouter } from "next/router";

import { getLocale, LocaleCode } from "../util/locale";

function usePriceFormatter(currencyOverride?: string) {
  const router = useRouter();
  const localeInfo = getLocale((router?.locale || "en-US") as LocaleCode);
  const currency = currencyOverride || localeInfo?.currencyCode;

  function formatPrice(price: string) {
    const amount = parseInt(price, 10);
    // If amount cannot be converted reliably, return original value
    if (Number.isNaN(amount)) return price;
    const formatter = new Intl.NumberFormat(router?.locale || "en-US", {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
    });
    return formatter.format(amount);
  }

  return formatPrice;
}

export default usePriceFormatter;
