import { MergedProductVariant } from "../services/catalog/types";
import { OptionImage } from "../services/cms/api";

import { deriveMatchConfigs, isOptionConfigMatching } from "./optionMatch";

export function getOptionImage(
  images: OptionImage[],
  selectedOptions: Record<string, string>
): OptionImage | undefined {
  const matchingImage = images.find((image) => {
    const matchConfigs = deriveMatchConfigs(
      image.optionValues,
      selectedOptions
    );
    return isOptionConfigMatching(matchConfigs, selectedOptions);
  });

  return matchingImage;
}

export function validateOptionImages(
  images: OptionImage[],
  variants: MergedProductVariant[]
) {
  const configurations = variants.map((variant) => {
    const selectedOptions = Object.fromEntries(
      variant.selectedOptions.map(({ name, value }) => [name, value])
    );
    return {
      title: variant.title,
      image: getOptionImage(images, selectedOptions),
    };
  });

  const configsWithMissingImage = configurations.filter(
    (config) => !config.image
  );
  if (configsWithMissingImage.length) {
    const variantTitles = configsWithMissingImage.map((config) => config.title);
    const formattedTitles = variantTitles.map((title) => `- ${title}`);
    const message = `The following variants have no matching option image:\n${formattedTitles.join(
      "\n"
    )}`;

    throw new Error(message);
  }

  return true;
}
