/* eslint-disable import/prefer-default-export */
import { useContext } from "react";
import Head from "next/head";
import type { NextRouter } from "next/router";
import { useRouter } from "next/router";
import makeSrcWithProtocol from "src/lib/util/makeSrcWithProtocol";

import { useTypedQuery } from "../../hooks/useTypedQuery";
import {
  getLocalizedPathname,
  getTranslatedPath,
} from "../../routes/routeConfig";
import type { CommonProps } from "../../services/util/compose";
import { CanonicalUrlContext } from "../../util";

export const getUrl = (
  router: NextRouter,
  origin: string,
  stripParams = false
) => {
  const pathname = getLocalizedPathname(router);
  const url = new URL(getTranslatedPath(pathname, router), origin);

  // removes search params for instances such as canonical urls
  if (stripParams) url.search = "";

  return url.toString();
};

export function IconLinks({
  icons,
}: {
  icons: ContentfulSEOProps["siteMetadata"]["icons"];
}) {
  return (
    <Head>
      {icons.map(({ color, image, type, size }) => {
        if (type === "mask-icon") {
          return (
            <link
              color={color}
              href={image.fields.file.url}
              key={type}
              rel={type}
              type={image.fields.file.contentType}
            />
          );
        }
        return (
          <link
            href={image.fields.file.url}
            key={size ? `${type}-${size}` : type}
            rel={type}
            sizes={size}
            type={image.fields.file.contentType}
          />
        );
      })}
    </Head>
  );
}

export function LocalizationLinks({
  origin,
}: Pick<ContentfulSEOProps, "origin">) {
  const router = useRouter();
  const { canonical: canonicalUrl } = useContext(CanonicalUrlContext);

  if (!router?.locales) return null;

  const canonical = canonicalUrl
    ? new URL(canonicalUrl, origin).toString()
    : getUrl(router, origin, true);

  return (
    <>
      {router.locales.map((locale) => (
        <Head key={locale}>
          <link
            href={getUrl(
              {
                ...router,
                locale,
              },
              origin
            )}
            hrefLang={locale}
            rel="alternate"
            key={locale}
          />
        </Head>
      ))}
      <Head>
        <link
          href={getUrl(
            {
              ...router,
              locale: router.defaultLocale,
            },
            origin
          )}
          hrefLang="x-default"
          key="x-default"
          rel="alternate"
        />
        <link href={canonical} key="canonical" rel="canonical" />
      </Head>
    </>
  );
}

interface TitleAndDescriptionMetaProps {
  title: string;
  description?: string;
  image?: string;
}

/**
 * Renders metadata that powers link previews,
 * including the page title, description and share image
 * Intended to be used within document `head`
 */
export function LinkPreviewMeta({
  title,
  description,
  image,
}: TitleAndDescriptionMetaProps) {
  const imageUrl = image ? makeSrcWithProtocol(image) : undefined;

  return (
    <>
      <Head>
        <title key="page-title">{title}</title>
        <meta key="og:title" property="og:title" content={title} />
      </Head>
      {description && (
        <Head>
          <meta key="descripton" name="description" content={description} />
          <meta
            key="og:descripton"
            property="og:description"
            content={description}
          />
          {imageUrl && (
            <meta key="og:image" property="og:image" content={imageUrl} />
          )}
        </Head>
      )}
    </>
  );
}

export function Meta({
  description,
  image,
  title,
  siteTitle,
  url,
  noIndex,
}: {
  description?: string;
  image?: string;
  title: string;
  siteTitle: string;
  url: string;
  noIndex?: boolean;
}) {
  return (
    <>
      <LinkPreviewMeta
        key="title-desc-meta"
        title={title}
        description={description}
        image={image}
      />
      <Head>
        <meta
          key="og:site_title"
          property="og:site_title"
          content={siteTitle}
        />
        <meta key="og:url" property="og:url" content={url} />
        {noIndex && <meta key="robots" name="robots" content="noindex" />}
      </Head>
    </>
  );
}

type ContentfulSEOProps = Pick<
  CommonProps,
  "origin" | "siteMetadata" | "pageMetadata"
> & { isStaging: boolean; locale?: string };

function makeTitle(
  siteTitle: string,
  page: ContentfulSEOProps["pageMetadata"]
) {
  if (!page?.title) {
    return siteTitle;
  }

  return page?.appendSiteTitle ? `${page.title} | ${siteTitle}` : page.title;
}

export function ContentfulSEO({
  origin,
  locale,
  siteMetadata: site,
  pageMetadata: page,
  isStaging,
}: ContentfulSEOProps) {
  const router = useRouter();
  const { data: preview } = useTypedQuery(["inPreviewMode"]);
  const isLaunchedLocale = ["en-US", "en-CA"].includes(locale || "");

  const images = page?.images ?? site.images;
  const noIndex = page?.noIndex || isStaging || !isLaunchedLocale || !!preview;
  const siteTitle = site.title || "Thuma";
  const documentTitle = makeTitle(siteTitle, page);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta charSet="utf-8" content="text/html" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      </Head>
      <LocalizationLinks origin={origin} />
      <IconLinks icons={site.icons} />
      <Meta
        description={page?.description ?? site.description}
        image={images?.[0].fields.file.url}
        siteTitle={siteTitle}
        title={documentTitle}
        url={getUrl(router, origin)}
        noIndex={noIndex}
      />
    </>
  );
}
