/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import { Carousel } from "@components";
import classNames from "classnames";

import { Media } from "../..";

export interface RecommendedProductsCarouselProps {
  loop?: boolean;
  variant: "normal" | "tight";
}

export function RecommendedProductsCarousel({
  children,
  variant,
  loop,
}: React.PropsWithChildren<RecommendedProductsCarouselProps>) {
  const carouselProps: React.ComponentProps<typeof Carousel> = {
    autoplay: { enabled: false },
    loop,
    navigation: {
      position: "outside",
      alignment: variant === "normal" ? "top-half" : "center",
    },
    pagination: {
      clickable: true,
      type: "bullets",
      position: "center",
    },
    slidesPerView: 3,
    spaceBetween: variant === "tight" ? 16 : 0,
    touchStartPreventDefault: variant === "normal",
    className: classNames("overflow-y-auto", {
      "lg:px-20": variant === "normal",
    }),
  };

  const tinyCarouselProps: React.ComponentProps<typeof Carousel> = {
    ...carouselProps,
    slidesPerView: variant === "tight" ? 2 : 1.05,
    navigation: false,
  };

  return (
    <>
      <Media greaterThanOrEqual="md">
        <Carousel
          {...carouselProps}
          slidingContentContainerClassName={classNames({
            "!w-[90%]": variant === "normal",
          })}
        >
          {children}
        </Carousel>
      </Media>
      <Media lessThan="md">
        <Carousel {...tinyCarouselProps}>{children}</Carousel>
      </Media>
    </>
  );
}
