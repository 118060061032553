import { differenceInDays } from "date-fns";

import { MarketingCapturePersister } from "./persister";

export const MAX_MODAL_SHOWS = 3;

export default class MarketingCaptureService {
  private emailCapturePersister: MarketingCapturePersister;

  constructor(emailCapturePersister: MarketingCapturePersister) {
    this.emailCapturePersister = emailCapturePersister;
  }

  getProfileId = async () => this.emailCapturePersister.getProfileId();

  getSubscribedDate = async () =>
    this.emailCapturePersister.getSubscribedDate();

  getPurchasedDate = async () => this.emailCapturePersister.getPurchasedDate();

  setProfileId = async (id: string | null) =>
    this.emailCapturePersister.setProfileId(id);

  setSubscribedOn = async (dateString: string | null) =>
    this.emailCapturePersister.setSubscribedOn(dateString);

  setPurchasedOn = async (dateString: string | null) =>
    this.emailCapturePersister.setPurchasedOn(dateString);

  incrementNumberOfModalShows = async () => {
    this.emailCapturePersister.setLastModalShowDate(new Date().toISOString());
    this.emailCapturePersister.incrementNumberOfModalShows();
  };

  getNumberOfModalShows = async () =>
    this.emailCapturePersister.getNumberOfModalShows();

  isEligibleForBottomBanner = async () => {
    const [isSubscribed, hasPurchase] = await Promise.all([
      this.getSubscribedDate().then((subscribedDate) =>
        Boolean(subscribedDate)
      ),
      this.getPurchasedDate().then((purchaseDate) => Boolean(purchaseDate)),
    ]);
    return !isSubscribed && !hasPurchase;
  };

  isEligibleForModalAutoShow = async () => {
    const [
      subscribedDate,
      purchasedDate,
      numOfModalShows,
      hasBeenAtLeastOneDaySinceLastShow,
    ] = await Promise.all([
      this.getSubscribedDate(),
      this.getPurchasedDate(),
      this.getNumberOfModalShows(),
      this.emailCapturePersister
        .getLastModalShowDate()
        .then((lastModalShowDate) => {
          if (!lastModalShowDate) return true;
          const lastShownDate = new Date(lastModalShowDate);
          const today = new Date();
          return differenceInDays(today, lastShownDate) >= 1;
        }),
    ]);
    const isSubscribed = subscribedDate != null;
    const hasPurchased = purchasedDate !== null;
    const hasSeenModalEnoughTimes = numOfModalShows >= MAX_MODAL_SHOWS;
    return (
      hasBeenAtLeastOneDaySinceLastShow &&
      !isSubscribed &&
      !hasPurchased &&
      !hasSeenModalEnoughTimes
    );
  };
}
