import { QueryClient } from "react-query";
import { Document } from "@contentful/rich-text-types";
import { Asset } from "contentful";
import path from "path";

import {
  ICard,
  IGrid,
  IProductVariant,
} from "../../../../@types/generated/contentful";
import { BreadcrumbsItem } from "../../../components/Breadcrumbs/Breadcrumbs";
import variantReplacements from "../../data/variantReplacements";
import virtualProductMap from "../../data/virtualProductMap";
import type { MergedProductVariant } from "../../services/catalog/types";
import type { OptionImage } from "../../services/cms/api";
import { Product, Rating } from "../../services/product/api";
import { formatSwatches, formatVariants } from "../../services/product/utils";
import { AverageRating } from "../../services/reviews";
import {
  makeQueryFn,
  QueryClientPlugin,
} from "../../services/util/makeQueryClient";
import {
  formatProductOptions,
  MergedOption,
} from "../../services/util/optionRendering";
import { getShopifyId } from "../../services/util/shopifyID";
import {
  getCanonicalOptionNames,
  getCanonicalVariantIds,
  isTruthy,
} from "../../util";
import { AccordionData, formatAccordions } from "../../util/accordions";
import { formatProductBadges, ProductBadge } from "../../util/badges";
import { BuyBanner, formatBuyBanners } from "../../util/buyBaners";
import { validateOptionImages } from "../../util/getOptionImage";
import type { LocaleCode, Localized } from "../../util/locale";
import localeMap from "../../util/localeMap";
import processSections from "../../util/process-sections";
import { getValidatedKeyValueArray } from "../../util/toKeyValue";
import type { ServerDataServices } from "../server";

interface RawCustomField {
  label: string;
  type?: "text" | "email" | "password" | "tel" | "url";
  required?: boolean;
}

export interface CustomField extends RawCustomField {
  id: string;
  type: "text" | "email" | "password" | "tel" | "url";
}

export type WithProductID = {
  /** **NOTE:** Only use for 3rd party vendors (analytics, product recommendations, etc).
   *
   * This may differ from the product-level ID in cases
   * where the variant is from a merged product and not the primary one. */
  productId: string;
};

export interface MergedProduct extends Omit<Product, "variants"> {
  breadcrumbs: BreadcrumbsItem[] | [];
  id: string;
  title: string;
  description: string;
  vendor: string;
  rating?: {
    stars: number;
    reviewCount: number;
  };
  options: MergedOption[];
  optionImages: OptionImage[];
  badges: ProductBadge[];
  banners: BuyBanner[];
  detailAccordions: AccordionData[];
  variants: MergedProductVariant[];
  defaultVariant: MergedProductVariant["id"];
  richDescription: Document | null;
  displayImagesAs?: "grid" | "carousel";
  shouldHidePrice?: boolean;
}

const allLocales = Object.keys(localeMap) as LocaleCode[];

const basePath = path.join(process.cwd(), "public/data");
export const catalogFilePaths = Object.fromEntries(
  allLocales.map((locale) => [
    locale,
    {
      prod: path.join(basePath, `${locale}/catalog.json`),
      preview: path.join(basePath, `${locale}/catalog-preview.json`),
    },
  ])
);

export function formatOptionImages(
  optionLength: number,
  variants: MergedProductVariant[],
  rawAssets: Asset[] | undefined,
  shouldThrowOnNotFound: boolean
): OptionImage[] {
  const assets = rawAssets || [];
  const matchPattern = /\(([^)]+)\)/;
  const optionImages = assets.map(
    ({
      fields: {
        title,
        description,
        file: {
          url: src,
          details: { image },
        },
      },
    }) => {
      const match = title.match(matchPattern);
      if (!match)
        throw new Error(`Improperly formatted option image title: "${title}"`);

      const optionValuesString = match[1];
      const optionValues = optionValuesString
        .split("/")
        .map((value) => value.trim());

      if (optionValues.length !== optionLength)
        throw new Error(
          `Option value count mismatch for image with title "${title}"`
        );

      return {
        optionValues,
        image: {
          src,
          alt: description || title,
          height: image?.height,
          width: image?.width,
        },
      };
    }
  );

  if (shouldThrowOnNotFound) validateOptionImages(optionImages, variants);
  return optionImages;
}

export interface FeaturedContentItem {
  item: ICard;
  optionImages: OptionImage[] | null;
}

export function formatFeaturedContent(
  entry: IGrid | undefined,
  optionLength: number,
  variants: MergedProductVariant[]
) {
  if (!entry || entry.fields.carouselConfig) return null;

  return {
    title: entry.fields.title || null,
    items: (
      entry.fields.items.filter(
        (item) => item.sys.contentType.sys.id === "card"
      ) as ICard[]
    ).map<FeaturedContentItem>((item) => {
      if (!item.fields.optionImages?.length)
        return { item, optionImages: null };

      return {
        item,
        optionImages: formatOptionImages(
          optionLength,
          variants,
          item.fields.optionImages,
          false
        ),
      };
    }),
  };
}

function formatCustomFields(
  rawFields?: Record<string, unknown>
): CustomField[] {
  return Object.entries(
    (rawFields || {}) as Record<string, RawCustomField>
  ).map(([id, o]) => ({
    id,
    label: o.label,
    type: o.type || "text",
    required: !!o.required,
  }));
}

function formatRating({
  totalReviews: reviewCount,
  averageScore: stars,
}: AverageRating): Rating {
  return { reviewCount, stars };
}

const assertVariantsAreUnique = (variants?: IProductVariant[]) => {
  if (!variants) {
    return;
  }

  // create a map of entries keyed by variantId
  const entriesByVariantId = variants
    .filter((v) => v.fields)
    .reduce(
      (acc, { fields: { name, variantId }, sys: { id: entryId } }) => ({
        ...acc,
        [variantId]: (acc[variantId] || []).concat({
          name,
          url: `https://app.contentful.com/spaces/t15gr55mpxw1/entries/${entryId}`,
        }),
      }),
      {} as Record<string, { name: string; url: string }[]>
    );

  // create an array of duplicates
  const duplicates = Object.values(entriesByVariantId)
    .filter((o) => o.length > 1)
    .flat();

  // throw if there are duplicates
  if (duplicates.length > 1) {
    throw new Error(
      [
        "The following Contentful variants are pointing to the same product variant on Shopify:",
        ...duplicates.map((o) => `- ${o.name} ${o.url}`),
      ].join("\n")
    );
  }
};

const replacedVariantIds: { [id: string]: boolean } =
  variantReplacements.reduce(
    (acc, replacement) => ({ ...acc, [replacement.original.variantId]: true }),
    {}
  );

export const productQueryFns = ({
  productAPI,
  contentAPI,
  reviewsAPI,
}: ServerDataServices) => ({
  productData: async (slug: string) => {
    const contentEntry = await contentAPI.getItemBySlug("shopifyProduct", slug);
    if (!contentEntry) return null;
    const {
      fields: content,
      fields: {
        product: rawProductId,
        mergeWith = [],
        mergeMode,
        badges: rawBadges = [],
        detailAccordions: rawDetailAccordions = [],
      },
    } = contentEntry;

    const productId = getShopifyId(rawProductId);
    const [swatchAssets, product, averageRating, ...mergedProducts] =
      await Promise.all([
        contentAPI.getTaggedAssets("in", "swatch", "graphic"),
        productAPI.getProductById(rawProductId),
        reviewsAPI.getProductAverageRating(productId),
        ...mergeWith.map((rawMergedProductId) =>
          productAPI.getProductById(rawMergedProductId)
        ),
      ]);

    if (!product || !content) return null;
    assertVariantsAreUnique(content.variants);

    const combinedProducts = [product, ...mergedProducts].filter(isTruthy);
    const validatedMappings = getValidatedKeyValueArray(
      content.mergeOptionMapping
    );

    const options = formatProductOptions(
      {
        content,
        products: combinedProducts,
        swatches: formatSwatches(swatchAssets),
      },
      mergeMode,
      validatedMappings
    );
    const variants = formatVariants(
      combinedProducts,
      content.variants,
      mergeMode,
      validatedMappings
    );

    const defaultVariantId = getShopifyId(
      content.defaultVariant.fields.variantId
    );
    const defaultVariant = variants.find(
      (variant) => variant.id === defaultVariantId
    );

    // We can't get to this path directly since we verify variant integrity
    // in the `formatVariants` function. This is just to make TypeScript happy
    // since we're using the `find` method, which technically can return undefined
    // istanbul ignore next
    if (!defaultVariant)
      throw new Error(
        `Default variant with id "${defaultVariantId}" not found for product ${content.productTitle}`
      );

    const canonicalOptionNames = getCanonicalOptionNames(options);
    const canonicalVariantIds = getCanonicalVariantIds(
      canonicalOptionNames,
      variants,
      defaultVariant
    );

    const hasRichGroupOption = options.some(
      (option) => option.appearance === "rich"
    );

    return {
      customFields: formatCustomFields(content.customFields),
      page: {
        sections: await processSections(
          content.sections || [],
          productAPI,
          contentAPI
        ),
      },
      pageMetadata: content?.pageMetadata?.fields || null,
      bannerSet: content?.bannerSet || null,
      product: {
        defaultVariant: defaultVariantId,
        // TODO: update after description field is removed
        description: content.productDescription,
        displayImagesAs: content.displayImagesAs || "grid",
        richDescription: content.description || null,
        handle: product.handle,
        breadcrumbs: [content.breadcrumbLink?.fields],
        id: product.id,
        badges: formatProductBadges(rawBadges),
        detailAccordions: formatAccordions(rawDetailAccordions),
        options,
        optionImages: formatOptionImages(
          options.length,
          variants,
          content.optionImages,
          hasRichGroupOption
        ),
        productType: product.productType,
        rating: averageRating && formatRating(averageRating),
        title: content.productTitle,
        banners: formatBuyBanners(content.buyBanners),
        variants,
        vendor: product.vendor,
        shouldHidePrice: content.shouldHidePricing ?? false,

        // key elements
        featuredContent: formatFeaturedContent(
          content.featuredContent,
          options.length,
          variants
        ),

        // To power canonical variant urls
        canonicalOptionNames,
        canonicalVariantIds,
      },
    };
  },
  productPaths: async () => {
    const paths = await contentAPI.getProductPaths();
    return paths.map(({ slug: rawSlug, variants: rawVariants }) => {
      const locales = Object.keys(rawSlug) as LocaleCode[];
      const slug = {} as Localized<string>;
      const variants = {} as Localized<string[]>;

      locales.forEach((locale) => {
        const localizedSlug = rawSlug[locale];
        const virtualProduct = virtualProductMap[locale][localizedSlug];
        if (virtualProduct) return;

        slug[locale] = localizedSlug;
        variants[locale] = ["default"].concat(
          rawVariants[locale]
            .map((variantId) => getShopifyId(variantId))
            .filter((id) => !replacedVariantIds[id])
        );
      });

      return { slug, variants };
    });
  },
});

const productPlugin: QueryClientPlugin<ServerDataServices> = (
  queryClient: QueryClient,
  dataServices
) => {
  const queries = productQueryFns(dataServices);
  const { productData, productPaths } = queries;

  queryClient.setQueryDefaults(["productData"], {
    queryFn: makeQueryFn(productData),
  });

  queryClient.setQueryDefaults(["productPaths"], {
    queryFn: makeQueryFn(productPaths),
  });
};

export default productPlugin;
