/* eslint-disable import/prefer-default-export */
import type { ReactNode } from "react";
import classNames from "classnames";
import { useVerticalSpacing } from "src/lib/context/VerticalSpacingContext";

interface DualSectionsProps {
  left: ReactNode | null;
  right: ReactNode | null;
  flipOrderForDesktop?: boolean;
}

export function DualSections({
  left,
  right,
  flipOrderForDesktop,
}: DualSectionsProps) {
  const keys = ["left-content", "right-content"];
  const { hasVerticalSpacing } = useVerticalSpacing();

  return (
    <div
      className={classNames(
        "items-stretch w-full space-y-4 full:space-y-0 full:gap-x-4 full:flex max-w-screen-king full:px-8 mx-auto",
        {
          "md:flex-row-reverse": flipOrderForDesktop,
          "md:mb-6 mb-4 full:mb-6": hasVerticalSpacing,
        }
      )}
    >
      {[left, right].map((element, index) => (
        <section
          className="relative w-full overflow-hidden isolate"
          key={keys[index]}
          data-testid={keys[index]}
        >
          {element}
        </section>
      ))}
    </div>
  );
}
