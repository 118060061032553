import useCollapsibleText from "../../lib/hooks/useCollapsibleText";
import { Button } from "../Button";

import selectors from "./selectors";

interface Props {
  title?: string;
  bodyText?: string;
}

export const CLAMP_CHARACTER_LIMIT = 35;

export default function CardContent({ title, bodyText = "" }: Props) {
  const { text, buttonText, canCollapse, toggleCollapsedState } =
    useCollapsibleText({
      fullText: bodyText,
      characterLimit: CLAMP_CHARACTER_LIMIT,
    });

  if (!title && !bodyText) return null;

  return (
    <div
      data-testid={selectors.cardContent}
      className="flex flex-col items-start gap-1 pt-3 max-w-fit"
    >
      {title && (
        <p data-testid={selectors.cardTitle} className="font-serif text-l">
          {title}
        </p>
      )}
      {text && (
        <p
          data-testid={selectors.cardBodyText}
          className="text-sm leading-normal text-slate"
        >
          {text}
        </p>
      )}

      {canCollapse && (
        <Button
          data-testid={selectors.cardBodyTextToggle}
          variant="unstyled"
          className="text-sm"
          onClick={toggleCollapsedState}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
}
