import {
  LEVEL_CRITICAL,
  LEVEL_DEBUG,
  LEVEL_ERROR,
  LEVEL_INFO,
  useRollbar,
} from "@rollbar/react";
import { Level } from "rollbar";

const levelMap: { [level: string]: Level } = {
  critical: LEVEL_CRITICAL,
  debug: LEVEL_DEBUG,
  error: LEVEL_ERROR,
  info: LEVEL_INFO,
};

function useLogger() {
  const rollbar = useRollbar();

  function captureEvent(data: object, level: Level) {
    return rollbar.captureEvent(data, levelMap[level]);
  }

  return {
    logger: rollbar,
    captureEvent,
  };
}

export default useLogger;
