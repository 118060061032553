/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useMemo, useState } from "react";
import { Card, PromoBadge } from "@components";

import type { CardProps } from "../../../../../components/Card/Card";
import { PromoBadgePreset } from "../../../../../components/PromoBadge";
import useCatalog from "../../../../hooks/useCatalog";
import type { FormattedCollectionItem } from "../../../../queries/data/collectionData";
import type { CatalogProduct } from "../../../../services/catalog/types";
import { getShopifyId } from "../../../../services/util/shopifyID";
import { isTruthy } from "../../../../util";
import makeProductUrl from "../../../../util/makeProductUrl";
import { isOptionConfigMatching } from "../../../../util/optionMatch";

import selectors from "./selectors";
import Toolbar from "./Toolbar";

type ProductVariant = CatalogProduct["variants"][number];

type Props = CardProps &
  Pick<FormattedCollectionItem, "optionName" | "optionValues" | "id"> & {
    variantId: string;
    badgePreset: PromoBadgePreset | null;
  };

const MAX_DESKTOP_OPTION_VALUES = 4;

export default function ProductCard({
  optionName,
  optionValues,
  badgePreset,
  id: rawProductId,
  variantId: rawVariantId,
  ...props
}: Props) {
  const initialVariantId = getShopifyId(rawVariantId);
  const productId = getShopifyId(rawProductId);
  const { catalog } = useCatalog([productId]);

  const [currentVariant, setCurrentVariant] = useState<ProductVariant>();

  const product = useMemo(() => {
    if (!catalog) return null;

    const catalogProduct = catalog[productId];

    if (!catalogProduct) {
      // eslint-disable-next-line no-console
      console.error(
        `Product with id "${rawProductId}" was not found in the catalog`
      );
      return null;
    }

    return catalogProduct;
  }, [catalog, productId, rawProductId]);

  useEffect(() => {
    const matchingVariant = product?.variants[initialVariantId];
    setCurrentVariant(matchingVariant);
  }, [product, initialVariantId]);

  const { option, truncatedItemCount = 0 } = useMemo(() => {
    if (!optionName || !product) return { option: null };

    const matchingOption = product.options.find((o) => o.name === optionName);
    if (!matchingOption) {
      // eslint-disable-next-line no-console
      console.error(
        `Could not find matching option with name "${optionName}" in ${product.productTitle}`
      );
      return { option: null };
    }

    const sourceValues = optionValues?.length
      ? optionValues
          .map((value) =>
            matchingOption.values.find(
              (optionValue) => optionValue.value === value
            )
          )
          .filter(isTruthy)
      : matchingOption.values;
    const values = sourceValues.slice(0, MAX_DESKTOP_OPTION_VALUES);

    if (!values.length) {
      // eslint-disable-next-line no-console
      console.error(
        `No resulting option values for "${optionName}" in ${product.productTitle}`
      );
      return {
        option: null,
      };
    }

    return {
      option: {
        ...matchingOption,
        values,
      },
      truncatedItemCount: Math.max(
        matchingOption.values.length - values.length,
        0
      ),
    };
  }, [product, optionName, optionValues]);

  const currentImageUrl = useMemo(() => {
    const defaultImage = props.image.src;
    if (!currentVariant) return defaultImage;

    const variantImage = currentVariant.images[0].url;
    if (currentVariant.id === initialVariantId) return defaultImage;
    return variantImage;
  }, [currentVariant, props.image.src, initialVariantId]);

  const imageProps = {
    ...props.image,
    src: currentImageUrl,
  };

  if (!product || !option) return <Card {...props} />;

  const activeValue = currentVariant?.selectedOptions.find(
    (o) => o.name === optionName
  )?.value;

  const currentHref =
    product && currentVariant
      ? makeProductUrl(product.slug, currentVariant.id)
      : props.href;

  const selectedOptions = Object.fromEntries(
    (currentVariant?.selectedOptions ?? []).map(({ name, value }) => [
      name,
      value,
    ])
  );

  const handleOptionClick = (value: string) => {
    // We can't get into this function without any of the following
    // but there isn't a way to make them falsy and run the event handler
    // istanbul ignore next
    if (!product || !optionName || !currentVariant) return;

    const selectedOptionsMap = new Map(Object.entries(selectedOptions));

    // Update the value for the clicked option
    selectedOptionsMap.set(optionName, value);

    // Find the variant that matches the updated selected options
    const newVariant = Object.values(product.variants).find((variant) =>
      Array.from(selectedOptionsMap).every(
        ([selectedOptionName, selectedOptionValue]) =>
          variant.selectedOptions.some(
            (o) =>
              o.name === selectedOptionName && o.value === selectedOptionValue
          )
      )
    );

    setCurrentVariant(newVariant);
  };

  const sharedToolbarProps = {
    option,
    truncatedItems: truncatedItemCount,
    activeValue,
    onOptionClick: handleOptionClick,
  };

  const activeBadge = product.badges?.find((badge) =>
    isOptionConfigMatching(badge.optionMatchConfigs, selectedOptions)
  );

  return (
    <div data-testid={selectors.interactiveCard}>
      <Card {...props} image={imageProps} href={currentHref}>
        {activeBadge && (
          <PromoBadge
            text={activeBadge.text}
            preset={badgePreset || activeBadge.preset}
            className="absolute top-4 left-4"
          />
        )}
      </Card>
      <Toolbar {...sharedToolbarProps} />
    </div>
  );
}
