import * as React from "react";
import { useRouter } from "next/router";

import useTypedMutation from "../../hooks/useTypedMutation";
import { CartMutation } from "../../queries/data/cartData";
import { LocaleCode } from "../../util/locale";

export default function ResetCartOnLocaleChangeProvider() {
  const router = useRouter();
  const [locale, setLocale] = React.useState(
    router.locale as LocaleCode | undefined
  );
  const { mutate: clearCart } = useTypedMutation([CartMutation.Clear]);

  React.useEffect(() => {
    if (locale !== undefined && locale !== router.locale) {
      clearCart(router.locale as LocaleCode);
      setLocale(router.locale as LocaleCode | undefined);
    }
    // Only reset cart when locale changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.locale]);

  return null;
}
