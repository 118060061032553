import classNames from "classnames";
import { useAnalytics } from "src/lib/hooks/useAnalytics";

import { ITextOverlayMedia } from "../../../../../@types/generated/contentful";
import { TextOverlayMedia } from "../../TextOverlayMedia";
import { useAnalyticsHandler } from "../hooks/useAnalyticsHandler";

export default function TextOverlayMediaSaddle({
  content,
  isSection,
  lrtClassName,
}: {
  content: ITextOverlayMedia;
  isSection?: boolean;
  lrtClassName?: string;
}) {
  // istanbul ignore next
  const renderWrapper = isSection === undefined ? true : isSection;
  const {
    name,
    responsiveMedia,
    overlayColorTop,
    overlayColorMiddle,
    overlayColorBottom,
    overlayOpacity,
    link,
    locationalRichTextSections: lrt,
    fadeIn,
    videoConfig,
  } = content.fields;

  const locationalRichTextSections = lrt?.map((section) => ({
    ...section.fields,
  }));
  const onClick = useAnalyticsHandler();
  const { parentObject } = useAnalytics();
  const isInCardGrid = parentObject.type === "grid";

  const tomElement = (
    <TextOverlayMedia
      name={name}
      fadeIn={fadeIn}
      responsiveMedia={responsiveMedia}
      videoConfig={videoConfig}
      overlayOpacity={overlayOpacity}
      overlayColorTop={overlayColorTop}
      overlayColorMiddle={overlayColorMiddle}
      overlayColorBottom={overlayColorBottom}
      link={link}
      onClick={onClick}
      locationalRichTextSections={locationalRichTextSections}
      lrtClassName={classNames(
        {
          "px-4 py-5 full:px-4 full:py-5": !renderWrapper && !isInCardGrid,
        },
        lrtClassName
      )}
    />
  );

  if (renderWrapper)
    return <div className="w-screen min-h-[300px] h-[33vw]">{tomElement}</div>;

  return tomElement;
}
