import React, { useContext } from "react";

import { FeaturedProduct } from "../components/Navigation/types";

const FEATURED_BED_PRODUCT_ID = "567859380252";

export const FeaturedProductsContext = React.createContext<FeaturedProduct[]>(
  []
);

export function useFeaturedProductsContext() {
  const context = useContext(FeaturedProductsContext);
  return context.filter((product) => product.id !== FEATURED_BED_PRODUCT_ID);
}
