/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Icon } from "@components";

import { cn } from "../../utils/tailwind-merge";

export type ButtonVariants =
  | "dark-to-light"
  | "dark-to-pine" // dark
  | "light-to-dark" // light
  | "light-to-gray" // gray
  | "pine-to-dark"
  | "pill"
  | "list-button"
  | "sage-to-white"
  | "transparent-to-white"
  | "transparent-to-dark"
  | "underline"
  | "unstyled";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** The render style of the button */
  variant?: ButtonVariants;
  /** A boolean to enable an icon on hover */
  arrowIcon?: boolean;
}

export const buttonClasses =
  "group inline-block p-5 text-xs leading-none disabled:pointer-events-none disabled:opacity-50";
export const lightToDarkButtonClasses =
  "bg-white text-charcoal hover:bg-charcoal hover:text-white";
export const darkToPineButtonClasses =
  "bg-charcoal text-white hover:bg-pine hover:text-white";
export const darkToLightButtonClasses =
  "bg-charcoal text-white hover:bg-white hover:text-charcoal";
export const sageToWhiteButtonClasses =
  "bg-sage text-charcoal hover:bg-white hover:text-charcoal";
export const lightToGrayButtonClasses =
  "bg-[#f8f9f9] text-charcoal hover:bg-[#ebeef1] hover:text-charcoal";
export const pineToDarkButtonClasses =
  "bg-pine text-white hover:bg-charcoal hover:text-white";
export const pillButtonClasses =
  "border transition text-inherit px-4 py-2 hover:text-charcoal hover:bg-lightgray/50 rounded-full text-2xs border-current";
export const listButtonClasses =
  "text-charcoal hover:text-charcoal justify-between transition-all p-3 pl-0 hover:pl-3 bg-white hover:bg-[#eee] border-b border-lightgray";
export const transparentToWhiteButtonClasses =
  "bg-transparent text-white border-white border hover:bg-white hover:text-charcoal";
export const transparentToDarkButtonClasses =
  "bg-transparent text-charcoal border-charcoal border hover:bg-charcoal hover:text-white";
export const underlineButtonClasses =
  "bg-transparent text-inherit border-b-current border-b !px-0 py-2 hover:opacity-70 mb-4 transition-all";
const unstyledButtonClasses =
  "focus-visible:outline-offset-4 focus-visible:outline-pine focus-visible:outline";

/**
 * A button component
 */
function Button(
  {
    children,
    variant = "dark-to-pine",
    arrowIcon,
    className,
    ...props
  }: React.PropsWithChildren<ButtonProps>,
  buttonRef: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <button
      type="button"
      ref={buttonRef}
      className={cn(
        {
          [buttonClasses]: variant !== "unstyled",
          [lightToDarkButtonClasses]: variant === "light-to-dark",
          [darkToPineButtonClasses]: variant === "dark-to-pine",
          [darkToLightButtonClasses]: variant === "dark-to-light",
          [sageToWhiteButtonClasses]: variant === "sage-to-white",
          [lightToGrayButtonClasses]: variant === "light-to-gray",
          [pineToDarkButtonClasses]: variant === "pine-to-dark",
          [pillButtonClasses]: variant === "pill",
          [listButtonClasses]: variant === "list-button",
          [transparentToWhiteButtonClasses]: variant === "transparent-to-white",
          [unstyledButtonClasses]: variant === "unstyled",
          [underlineButtonClasses]: variant === "underline",
          [transparentToDarkButtonClasses]: variant === "transparent-to-dark",
        },
        className
      )}
      {...props}
    >
      {children}
      {arrowIcon && (
        <Icon
          name="arrow-right"
          className="hidden pl-3 group-hover:inline-block"
        />
      )}
    </button>
  );
}

export default React.forwardRef(Button);
