import create, { StoreApi } from "zustand";
import { persist } from "zustand/middleware";

interface MarketingCaptureStore {
  profileId?: string | null;
  subscribedOn?: string | null;
  purchasedOn?: string | null;
  numberOfModalShows: number;
  lastModalShowDate?: string | null;
  setProfileId(id: string | null): void;
  setSubscribedOn(dateString: string | null): void;
  setPurchasedOn(dateString: string | null): void;
  setNumberOfModalShows(numberOfModalShows: number): void;
  setLastModalShowDate(dateString: string | null): void;
}

export interface MarketingCapturePersister {
  getSubscribedDate: () => Promise<string | null>;
  getPurchasedDate: () => Promise<string | null>;
  getProfileId: () => Promise<string | null>;
  getLastModalShowDate: () => Promise<string | null>;
  setLastModalShowDate: (dateString: string | null) => Promise<void>;
  setProfileId(id: string | null): Promise<void>;
  setSubscribedOn(dateString: string | null): Promise<void>;
  setPurchasedOn(dateStrng: string | null): Promise<void>;
  incrementNumberOfModalShows: () => Promise<void>;
  getNumberOfModalShows: () => Promise<number>;
}

export default class ZustandMarketingCapturePersister
  implements MarketingCapturePersister
{
  private store: Promise<StoreApi<MarketingCaptureStore>>;

  constructor() {
    let onRehydrateStorage: (value: unknown) => void;
    const rehydrationComplete = new Promise((res) => {
      onRehydrateStorage = res;
    });
    this.store = new Promise((res) => {
      const useStore = create<MarketingCaptureStore>(
        // @ts-expect-error can't seem to make this happy
        persist<MarketingCaptureStore>(
          (set, get) => ({
            profileId: get()?.profileId ?? null,
            purchasedOn: get()?.purchasedOn ?? null,
            subscribedOn: get()?.subscribedOn ?? null,
            numberOfModalShows: get()?.numberOfModalShows,
            lastModalShowDate: get()?.lastModalShowDate ?? null,
            setProfileId: (id: string | null) => set({ profileId: id }),
            setSubscribedOn: (dateString: string | null) =>
              set({ subscribedOn: dateString }),
            setPurchasedOn: (dateString: string | null) =>
              set({ purchasedOn: dateString }),
            setNumberOfModalShows: (numberOfModalShows: number) =>
              set({ numberOfModalShows }),
            setLastModalShowDate: (dateString: string | null) =>
              set({ lastModalShowDate: dateString }),
          }),
          {
            name: "__email_capture__",
            onRehydrateStorage,
          }
        )
      );
      rehydrationComplete.then(() => res(useStore));
    });
  }
  // eslint-disable-next-line @typescript-eslint/lines-between-class-members
  setLastModalShowDate = async (dateString: string | null) => {
    (await this.store).getState().setLastModalShowDate(dateString);
  };

  getProfileId = async () => (await this.store).getState().profileId ?? null;

  getSubscribedDate = async () =>
    (await this.store).getState().subscribedOn ?? null;

  getPurchasedDate = async () =>
    (await this.store).getState().purchasedOn ?? null;

  setProfileId = async (id: string | null) =>
    (await this.store).getState().setProfileId(id);

  setSubscribedOn = async (dateString: string | null) =>
    (await this.store).getState().setSubscribedOn(dateString);

  setPurchasedOn = async (dateString: string | null) =>
    (await this.store).getState().setPurchasedOn(dateString);

  incrementNumberOfModalShows = async () => {
    const store = await this.store;
    const numberOfModalShows = store.getState().numberOfModalShows + 1;
    store.getState().setNumberOfModalShows(numberOfModalShows);
    store.getState().setLastModalShowDate(new Date().toISOString());
  };

  getNumberOfModalShows = async () =>
    (await this.store).getState().numberOfModalShows;

  getLastModalShowDate = async () =>
    (await this.store).getState().lastModalShowDate ?? null;
}
