/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Icon } from "@components";
import { Listbox as HeadlessUiListbox, Transition } from "@headlessui/react";
import classNames from "classnames";

import selectors from "./selectors";

interface OptionObject {
  /** The disabled state of the option */
  disabled?: boolean;
  /** A key for the option component */
  key?: string;
  /** The displayed text of the option */
  label?: string | null;
  /** The value of the option */
  value: string;
}

type ListboxOption = string | OptionObject;

export interface ListboxProps<T extends ListboxOption> {
  /** Sets width according to the longest option label */
  autoWidth?: boolean;
  /** The disabled state of the input */
  disabled?: boolean;
  /** The position of the list relative to the button for desktop */
  listPosition?: "bottom" | "top";
  /** The position of the list relative to the button for mobile */
  listMobilePosition?: "bottom" | "top";
  /** The change event handler */
  onChange: (nextValue: string) => void;
  /** An array of options */
  options: T[];
  /** A function that renders the content of the button */
  renderButton?: React.VFC<RenderButtonProps<T>>;
  /** A function that renders the content of individual options */
  renderOption?: React.VFC<RenderOptionProps<T>>;
  /** the predefined set of styles that will be used */
  variant?: "normal" | "tight";
  /** The current value of the input */
  value?: string;
  /** Additional class names to pass to the list box */
  className?: string;
  /** Additional class names for the list box button */
  buttonClassName?: string;
  /** Additional class names for the option list */
  listClassName?: string;
}

type FullOptionObject = Required<OptionObject> & { label: string };

/**
 * Returns an option object constructed from the provided option, which could be a string or
 * could be an object with missing properties
 */
export function getOptionObject(option: ListboxOption): FullOptionObject {
  return typeof option === "string"
    ? {
        disabled: false,
        key: option,
        label: option,
        value: option,
      }
    : {
        disabled: option.disabled || false,
        key: option.key || option.value,
        label: option.label || option.value,
        value: option.value,
      };
}

export interface RenderButtonProps<T extends ListboxOption> {
  /** The current value of the component */
  currentValue?: string;
  /** The disabled state of the listbox button */
  isDisabled?: boolean;
  /** A boolean indicating the expanded state of the component */
  isExpanded: boolean;
  /** The currently selected option */
  selectedOption?: T;
}

/**
 * The default button rendering component
 */
function DefaultRenderButton<T extends ListboxOption>({
  currentValue,
  isDisabled,
  isExpanded,
  selectedOption,
}: RenderButtonProps<T>) {
  return (
    <span
      className={classNames(
        "flex items-center gap-2 justify-between px-3 py-2 text-left whitespace-nowrap",
        { "text-flint": isDisabled }
      )}
    >
      <span className="truncate">
        {selectedOption ? getOptionObject(selectedOption).label : currentValue}
      </span>
      <Icon
        name="chevron-down"
        className={classNames({
          "rotate-180": isExpanded,
        })}
      />
    </span>
  );
}

export interface RenderOptionProps<T extends ListboxOption> {
  /** A boolean indicating the active state of the option */
  isActive: boolean;
  /** A boolean indicating the disabled state of the option */
  isDisabled: boolean;
  /** A boolean indicating the selected state of the option */
  isSelected: boolean;
  /** The option to render */
  option: T;
}

/**
 * The default option rendering component
 */
function DefaultRenderOption<T extends ListboxOption>({
  isActive,
  isDisabled,
  isSelected,
  option,
}: RenderOptionProps<T>) {
  const { label } = getOptionObject(option);
  return (
    <span
      className={classNames(
        "block px-3 py-1 truncate select-none",
        { "bg-flint": isActive },
        { "font-bold": isSelected },
        { "cursor-pointer": !isDisabled },
        { "cursor-default text-flint": isDisabled }
      )}
      data-active={isActive || undefined}
    >
      {label}
    </span>
  );
}

/**
 * The `Listbox` component allows users to select a value from a list of options. It behaves
 * much like the `<select>` HTML element, persisting its selected value.
 */
export function Listbox<T extends ListboxOption>({
  autoWidth = false,
  disabled,
  // TODO address aria labeling for Listbox
  // label,
  // labelledBy,
  listPosition = "bottom",
  listMobilePosition = "bottom",
  onChange,
  options,
  renderButton: RenderButton = DefaultRenderButton,
  renderOption: RenderOption = DefaultRenderOption,
  variant = "normal",
  value,
  className,
  buttonClassName,
  listClassName,
}: ListboxProps<T>) {
  const selectedOption = options.find(
    (option) => getOptionObject(option).value === value
  );

  const maxLength = options.reduce(
    (acc, o) => Math.max(acc, getOptionObject(o).label.length),
    0
  );

  return (
    <HeadlessUiListbox disabled={disabled} onChange={onChange} value={value}>
      {({ open: isExpanded }) => (
        <div
          className={classNames(
            "relative flex flex-col max-w-full focus-within:ring-2",
            { "w-48 bg-white border border-flint": variant === "normal" },
            { "drop-shadow-lg z-popover": isExpanded },
            className
          )}
          style={autoWidth ? { minWidth: `${maxLength * 0.75}em` } : undefined}
        >
          <HeadlessUiListbox.Button
            className={classNames("focus:outline-none", buttonClassName)}
            id="my-button-id"
            data-testid={selectors.listBoxButton}
          >
            <RenderButton
              currentValue={value}
              isDisabled={disabled}
              isExpanded={isExpanded}
              selectedOption={selectedOption}
            />
          </HeadlessUiListbox.Button>
          <Transition
            as={React.Fragment}
            show={isExpanded}
            enter={classNames("transition overflow-hidden", {
              "origin-top": listMobilePosition === "bottom",
              "origin-bottom": listMobilePosition === "top",
              "xl:origin-top": listPosition === "bottom",
              "xl:origin-bottom": listPosition === "top",
            })}
            enterFrom="scale-y-0 opacity-0"
            enterTo="scale-y-100 opacity-100"
            leave={classNames("transition overflow-hidden", {
              "origin-top": listMobilePosition === "bottom",
              "origin-bottom": listMobilePosition === "top",
              "xl:origin-top": listPosition === "bottom",
              "xl:origin-bottom": listPosition === "top",
            })}
            leaveFrom="scale-y-100 opacity-100"
            leaveTo="scale-y-0 opacity-0"
            unmount={false}
          >
            <HeadlessUiListbox.Options
              className={classNames(
                "absolute w-full flex flex-col max-h-96 overflow-auto overscroll-auto bg-white focus:outline-none",
                {
                  "top-full": listMobilePosition === "bottom",
                  "bottom-full": listMobilePosition === "top",
                  "xl:top-full xl:bottom-auto": listPosition === "bottom",
                  "xl:bottom-full xl:top-auto": listPosition === "top",
                },
                listClassName
              )}
              static
            >
              {options.map((option) => {
                const o = getOptionObject(option);
                return (
                  <HeadlessUiListbox.Option
                    data-value={o.value}
                    disabled={o.disabled}
                    key={o.key}
                    value={o.value}
                  >
                    {(props) => (
                      <RenderOption
                        isActive={props.active}
                        isDisabled={props.disabled}
                        isSelected={props.selected}
                        option={option}
                      />
                    )}
                  </HeadlessUiListbox.Option>
                );
              })}
            </HeadlessUiListbox.Options>
          </Transition>
        </div>
      )}
    </HeadlessUiListbox>
  );
}
