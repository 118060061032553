import type env from "../../env";
import { isTruthy } from "../../util";
import type { Cart } from "../cart/types";
import { getShopifyId } from "../util/shopifyID";

import type { RebuyProduct } from "./types";

export type RecommmendedProducts = Awaited<
  ReturnType<RecommendedProductsAPI["getRecommendedProducts"]>
>;

function arrayUnique(arr: unknown[]) {
  return Array.from(new Set(arr));
}

export default class RecommendedProductsAPI {
  private key: typeof env.rebuyConfig.key;

  constructor(rebuyKey: typeof env.rebuyConfig.key) {
    this.key = rebuyKey;
  }

  getRecommendedProducts = async ({
    cart,
    productId,
    variantId,
    rulesetId,
  }: {
    cart?: Cart;
    productId?: string;
    variantId?: string;
    rulesetId: string;
  }) => {
    let params: Record<string, string> = {
      key: this.key,
    };

    if (productId) {
      params.shopify_product_ids = getShopifyId(productId);
    }
    if (variantId) {
      params.shopify_variant_ids = getShopifyId(variantId);
    }

    if (cart) {
      const { lineItems, cost, quantity } = cart;
      params = {
        ...params,
        cart_subtotal: cost.subtotalAmount.amount,
        item_count: `${quantity}`,
        line_count: `${lineItems.length}`,
        shopify_product_ids: arrayUnique(
          lineItems
            .map(({ variant }) => getShopifyId(variant.product?.id))
            .concat(productId ? getShopifyId(productId) : "")
            .filter(isTruthy)
        ).join(","),
        shopify_variant_ids: arrayUnique(
          lineItems
            .map(({ variant }) => getShopifyId(variant.id))
            .concat(variantId ? getShopifyId(variantId) : "")
            .filter(isTruthy)
        ).join(","),
      };
    }

    const search = new URLSearchParams(params);
    const url = `https://rebuyengine.com/api/v1/custom/id/${rulesetId}/?${search}`;

    const response = await fetch(url, {
      headers: { "Content-Type": "application/json" },
      method: "GET",
      redirect: "follow",
    });

    const { data } = await response.json();
    return data as RebuyProduct[];
  };
}
