import {
  IShopifyCartFragmentFragment,
  IShopifyCartInput,
  IShopifyCartLineInput,
  IShopifyCheckoutFragmentFragment as IShopifyCheckoutFragment,
} from "../../graphql/shopify/client/sdk";
import type { KeyValue } from "../../util/toKeyValue";
import { addCartInitializedEvent } from "../elevar/events";
import ClientShopifyService from "../shopify/ClientShopifyService";
import { getCartAttributes } from "../util/userAttributionStore";

import type { CartPersister } from "./persister";
import {
  Cart,
  CartAttribute,
  LineItemInput,
  UpdateLineItemInput,
} from "./types";

export default class CartAPI {
  private shopifyService: ClientShopifyService;

  private initialized = false;

  private cartPersister: CartPersister;

  private pendingAttribution?: KeyValue[];

  constructor(
    shopifyService: ClientShopifyService,
    cartPersister: CartPersister
  ) {
    this.shopifyService = shopifyService;
    this.cartPersister = cartPersister;
  }

  private initCheckout = (cart: Cart) => {
    // only trigger this once
    // because of how we call this function in the cart api this code path is not currently possible but we should still check
    // istanbul ignore if
    if (this.initialized) return;
    this.initialized = true;

    addCartInitializedEvent({
      cartTotal: cart.cost.subtotalAmount.amount,
      items: cart.lineItems,
    });
  };

  private static transformShopifyCartToInternalCart = (
    shopifyCart: IShopifyCartFragmentFragment
  ): Cart => {
    const cart: Cart = {
      id: shopifyCart.id,
      checkoutUrl: shopifyCart.checkoutUrl,
      note: shopifyCart.note,
      quantity: shopifyCart.totalQuantity,
      attributes: shopifyCart.attributes.map(({ key, value }) => ({
        key,
        value: value ?? "",
      })),
      buyerIdentity: {
        countryCode: shopifyCart.buyerIdentity?.countryCode,
        email: shopifyCart.buyerIdentity?.email,
        phone: shopifyCart.buyerIdentity?.phone,
      },
      cost: {
        subtotalAmount: {
          amount: shopifyCart.cost.subtotalAmount.amount,
          currencyCode: shopifyCart.cost.subtotalAmount.currencyCode,
        },
      },
      lineItems: shopifyCart.lines.nodes.map((item) => ({
        __typename: "CartLineItem",
        attributes: [],
        cost: {
          amountPerQuantity: {
            amount: item.cost.amountPerQuantity.amount,
            currencyCode: item.cost.amountPerQuantity.currencyCode,
          },
          subtotalAmount: {
            amount: item.cost.subtotalAmount.amount,
            currencyCode: item.cost.subtotalAmount.currencyCode,
          },
        },
        id: item.id,
        quantity: item.quantity,
        variant: {
          id: item.merchandise.id,
          title: item.merchandise.title,
          weight: item.merchandise.weight,
          sku: item.merchandise.sku || "",
          available: item.merchandise.available,
          product: {
            id: item.merchandise.product.id,
            handle: item.merchandise.product.handle,
            productType: item.merchandise.product.productType,
            vendor: item.merchandise.product.vendor,
            onlineStoreUrl: item.merchandise.product.onlineStoreUrl,
            title: item.merchandise.product.title,
          },
          price: {
            amount: item.merchandise.price.amount,
            currencyCode: item.merchandise.price.currencyCode,
          },
          compareAtPrice: item.merchandise.compareAtPrice
            ? {
                amount: item.merchandise.compareAtPrice.amount,
                currencyCode: item.merchandise.compareAtPrice.currencyCode,
              }
            : null,
          image: item.merchandise.image
            ? {
                id: item.merchandise.image.id,
                altText: item.merchandise.image.altText,
                width: item.merchandise.image.width,
                height: item.merchandise.image.height,
                src: item.merchandise.image.src,
              }
            : null,
          selectedOptions: item.merchandise.selectedOptions.map((option) => ({
            name: option.name,
            value: option.value,
          })),
          shippingText: item.merchandise.shippingText
            ? {
                value: item.merchandise.shippingText.value,
              }
            : null,
          variantReserveShippingText: item.merchandise
            .variantReserveShippingText
            ? {
                value: item.merchandise.variantReserveShippingText.value,
              }
            : null,
        },
      })),
    };
    return cart;
  };

  private static transformShopifyCartToInternalCartOrThrowErrorIfNull = (
    shopifyCart?: IShopifyCartFragmentFragment | null
  ): Cart => {
    if (!shopifyCart) {
      throw new Error("No Cart Found");
    }
    return CartAPI.transformShopifyCartToInternalCart(shopifyCart);
  };

  private static isCheckoutApiId = (id: string | number) =>
    (typeof id === "string" && id.includes("gid://shopify/Checkout")) ||
    typeof id === "number";

  private createCart = async (
    input: Omit<IShopifyCartInput, "attributes"> = {}
  ) => {
    const { data: cartData } = await this.shopifyService.request("CreateCart", {
      input: {
        ...input,
        buyerIdentity: {
          ...(input.buyerIdentity ?? {}),
          countryCode: this.shopifyService.getCountryCode(),
        },
        attributes: getCartAttributes(),
      },
    });

    let cart: Cart | null = null;

    try {
      cart = CartAPI.transformShopifyCartToInternalCartOrThrowErrorIfNull(
        cartData.cartCreate?.cart
      );
    } catch {
      throw new Error("Unable to create cart");
    }

    await this.cartPersister.setCartID(`${cart.id}`);

    this.checkAttribution(cart);
    this.initCheckout(cart);

    return cart;
  };

  private upgradeCart = async (checkoutId: string) => {
    const { data } = await this.shopifyService.request("FetchCheckout", {
      id: `${checkoutId}`,
    });

    const checkout = data.node as IShopifyCheckoutFragment | undefined;
    if (!checkout || checkout.completedAt) return null;

    const cart = await this.createCart({
      lines: checkout.lineItems.edges.reduce(
        (lines, { node: item }) =>
          item.variant
            ? [
                ...lines,
                {
                  merchandiseId: item.variant.id,
                  quantity: item.quantity,
                  attributes: item.customAttributes.reduce(
                    (attrs, { key, value }): KeyValue[] =>
                      value ? [...attrs, { key, value }] : attrs,
                    [] as KeyValue[]
                  ),
                },
              ]
            : lines,
        [] as Array<IShopifyCartLineInput>
      ),
    });

    this.cartPersister.setCartID(`${cart.id}`);
    return cart;
  };

  private shopifyAddItemsToCart = async (lineItems: LineItemInput[]) => {
    try {
      const cartId = await this.cartPersister.getCartID();

      const { data } = await this.shopifyService.request("AddToCart", {
        cartId: `${cartId}`,
        lines: lineItems.map((item) => ({
          merchandiseId: item.variantId,
          quantity: item.quantity,
          attributes: item.attributes,
        })),
      });

      return CartAPI.transformShopifyCartToInternalCartOrThrowErrorIfNull(
        data.cartLinesAdd?.cart
      );
    } catch (e) {
      throw new Error("Unable to add items to cart");
    }
  };

  private shopifyUpdateCartLineItems = async (items: UpdateLineItemInput[]) => {
    try {
      const cartId = await this.cartPersister.getCartID();

      const { data } = await this.shopifyService.request(
        "UpdateCartItemsMutations",
        {
          cartId: `${cartId}`,
          lines: items.map((item) => ({
            merchandiseId: item.variantId,
            quantity: item.quantity,
            attributes: item.attributes,
            id: item.id,
          })),
        }
      );

      return CartAPI.transformShopifyCartToInternalCartOrThrowErrorIfNull(
        data.cartLinesUpdate?.cart
      );
    } catch {
      throw new Error("Unable to update items in cart");
    }
  };

  private shopifyCartLineItemsRemove = async (lineIds: string[]) => {
    try {
      const cartId = await this.cartPersister.getCartID();

      const { data } = await this.shopifyService.request("RemoveFromCart", {
        cartId: `${cartId}`,
        lineIds,
      });

      return CartAPI.transformShopifyCartToInternalCartOrThrowErrorIfNull(
        data.cartLinesRemove?.cart
      );
    } catch {
      throw new Error("Unable to remove items from cart");
    }
  };

  private shopifyCartAttributesUpdate = async (
    attributes: CartAttribute[],
    cart: Cart
  ) => {
    const { data } = await this.shopifyService.request(
      "UpdateCartAttributesMutation",
      {
        cartId: `${cart.id}`,
        attributes,
      }
    );

    return CartAPI.transformShopifyCartToInternalCartOrThrowErrorIfNull(
      data.cartAttributesUpdate?.cart
    );
  };

  private async shopifyNoteUpdate(note?: string | null) {
    const cartId = await this.cartPersister.getCartID();

    if (!cartId) return null;

    const { data } = await this.shopifyService.request("UpdateCartNote", {
      note,
      cartId,
    });

    return CartAPI.transformShopifyCartToInternalCartOrThrowErrorIfNull(
      data.cartNoteUpdate?.cart
    );
  }

  private checkAttribution = (cart?: Cart) => {
    if (this.pendingAttribution) {
      this.updateAttributes(this.pendingAttribution, cart); // TODO: needs coverage
    }
  };

  public fetchCart = async (): Promise<Cart | null> => {
    const cartId = await this.cartPersister.getCartID();

    // Run check if it's a checkout id or cart id
    if (cartId) {
      if (CartAPI.isCheckoutApiId(cartId)) {
        return this.upgradeCart(cartId);
      }
      // update this request to use the cart api
      const { data } = await this.shopifyService.request("CartQuery", {
        input: `${cartId}`,
      });

      // If we have a cart id and fetching the cart is null this is because the user has made a purchase or the cart has been deleted
      if (!data.cart) {
        this.clearCartID();
        return null;
      }

      return CartAPI.transformShopifyCartToInternalCart(data.cart);
    }

    return null;
  };

  public fetchOrCreateCart = async () =>
    (await this.fetchCart()) || (await this.createCart());

  public addToCart = async (items: LineItemInput[]) => {
    const cart = await this.fetchOrCreateCart();
    this.checkAttribution(cart);
    return this.shopifyAddItemsToCart(items);
  };

  public updateCart = async (items: UpdateLineItemInput[]) => {
    const cart = await this.fetchOrCreateCart();
    this.checkAttribution(cart);
    return this.shopifyUpdateCartLineItems(items);
  };

  public removeFromCart = async (itemsToRemove: string[]) => {
    const cart = await this.fetchCart();

    if (cart) {
      this.checkAttribution(cart);
      return this.shopifyCartLineItemsRemove(itemsToRemove);
    }

    // If there is no cart, there is nothing to remove. Create a cart and return it.
    return this.createCart();
  };

  public updateAttributes = async (
    customAttributes: CartAttribute[],
    cart?: Cart
  ) => {
    const cartToUpdate = cart || (await this.fetchCart());

    if (cartToUpdate) {
      this.pendingAttribution = undefined;

      return this.shopifyCartAttributesUpdate(customAttributes, cartToUpdate);
    }

    this.pendingAttribution = customAttributes;
    return null;
  };

  public updateNote = async (note?: string | null) =>
    this.shopifyNoteUpdate(note);

  public clearCartID = () => this.cartPersister.clearCartID();
}
