/** Function to determine what banner to render.
 * There are four possible banner sources that can be rendered:
 * 1. Page Banner Set
 * 2. Page Banner (Legacy)
 * 3. Global Banner Set
 * 4. Global Banner (Legacy)
 *
 * The order listed is the order in which the banners are prioritized.
 * If no banner is provided, the function will return null. Also, if the
 * override flag is set to true, the function will return the Page Banner
 * or nothing.
 */

import { IBanner, IBannerSet } from "../../../@types/generated/contentful";
import { TrimmedBannerSet } from "../components/RenderSiteBanner";

export function getBanner({
  pageBannerSet,
  pageBanner,
  globalBannerSet,
  globalBanner,
  overrideGlobalBanner = false,
}: {
  pageBannerSet?: IBannerSet | null;
  pageBanner?: IBanner | null;
  globalBannerSet?: IBannerSet | null;
  globalBanner?: IBanner | null;
  overrideGlobalBanner?: boolean;
}): TrimmedBannerSet | null {
  const bannerSet = pageBannerSet || globalBannerSet;
  const legacyBanner = pageBanner || globalBanner;
  const shouldRenderBanner =
    pageBanner || (!overrideGlobalBanner && globalBanner);

  let banner: TrimmedBannerSet | null = null;

  // The banner sets take priority, except in a special case where the
  // pageBannerSet is not set and the pageBanner is set. In this case,
  // the pageBanner will be rendered.
  if (bannerSet && !(!pageBannerSet && pageBanner)) {
    banner = bannerSet;
  }
  // If no banner set is provided, the legacy banner will be rendered if available.
  else if (shouldRenderBanner && legacyBanner?.fields) {
    banner = {
      ...legacyBanner,
      fields: {
        ...legacyBanner.fields,
        banners: [legacyBanner],
        carouselAutoplay: true,
        carouselDelay: 3000,
      },
      sys: {
        ...legacyBanner.sys,
        contentType: {
          ...legacyBanner.sys.contentType,
          sys: {
            ...legacyBanner.sys.contentType.sys,
            id: "bannerSet",
          },
        },
      },
    };
  }

  return banner;
}
