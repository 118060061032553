/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Link as LinkComponent } from "@components";
import Link from "next/link";
import { useRouter } from "next/router";
import { classicRenaming } from "src/lib/data/redirectsMap";

import { ContextLinkProps } from "../../../components/Link/Link";
import { getTranslatedPath } from "../../routes/routeConfig";

import useSmoothScrolling from "./useSmoothScrolling";

export function getRedirectedHref(href: string | undefined) {
  const [path, query] = href ? href.split("?") : [undefined, undefined];
  const redirectedHref =
    path && path in classicRenaming
      ? [classicRenaming[path], query].filter(Boolean).join("?")
      : href;
  return redirectedHref;
}

/**
 A site link component that wraps next/link around the component library Link component
 */
export function SiteLink({
  href,
  variant,
  children,
  className,
  draggable,
  disabled,
  arrowIcon,
  onClick,
  ...props
}: React.PropsWithChildren<ContextLinkProps>) {
  const router = useRouter();
  // TODO: remove this at some point
  // Temporary local redirects for classic products
  const redirectedHref = getRedirectedHref(href);
  const translatedHref = redirectedHref
    ? getTranslatedPath(redirectedHref, {
        ...router,
        locale: props.locale ?? router?.locale,
      })
    : undefined;

  // setting prefetch to `true` is deprecated
  // https://nextjs.org/docs/messages/prefetch-true-deprecated
  const prefetch = router?.isPreview ? false : undefined;

  const handleScrollOrNavigate = useSmoothScrolling(translatedHref);

  // TODO: figure out how to cover this
  // istanbul ignore next
  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    handleScrollOrNavigate(event);
    onClick?.(event);
  };

  const innerContent = (
    <LinkComponent
      {...props}
      href={translatedHref}
      onClick={handleLinkClick}
      variant={variant}
      className={className}
      disabled={disabled}
      draggable={draggable}
      arrowIcon={arrowIcon}
    >
      {children}
    </LinkComponent>
  );

  return translatedHref && !translatedHref.includes("#") ? (
    <Link href={translatedHref} passHref prefetch={prefetch}>
      {innerContent}
    </Link>
  ) : (
    innerContent
  );
}
