const selectors = {
  container: "shop-the-look-section",
  image: {
    container: "shop-the-look-section--image",
    mobileImage: "shop-the-look-section--image--mobile",
  },
  markerList: {
    container: "shop-the-look-section--marker-list",
    marker: "shop-the-look-section--marker-list--marker",
    button: "shop-the-look-section--marker-list--button",
  },
  toggleButton: "shop-the-look-section--toggle-button",
  productList: {
    backdrop: "shop-the-look-section--product-list--backdrop",
    container: "shop-the-look-section--product-list",
    product: "shop-the-look-section--product-list--product",
    closeButton: "shop-the-look-section--product-list--close-button",
    card: {
      container: "shop-the-look-section--product-list--card",
      link: "shop-the-look-section--product-list--card--link",
    },
  },
};

export default selectors;
