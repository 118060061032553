import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { addHeapOnCardCarouselInteracted } from "../../lib/services/elevar/events";

export function getInteractionType(isButtonScroll: boolean) {
  return isButtonScroll ? "button click" : "swipe";
}

export function useScrollHandler({
  scrollRef,
  numberOfChildren,
  carouselName,
  initialScrollIndex = 0,
  location,
}: {
  scrollRef: RefObject<HTMLDivElement>;
  numberOfChildren: number;
  carouselName: string;
  initialScrollIndex?: number;
  location?: string;
}) {
  const isButtonScroll = useRef(false);

  const [scrollOffset, setScrollOffset] = useState(0);
  const [isAtEndOfScroll, setIsAtEndOfScroll] = useState(false);
  // Start scroll index at 0
  const scrollIndex = useRef(initialScrollIndex);

  const getCardWidth = useCallback(() => {
    const scrollWidth = Number(scrollRef.current?.scrollWidth);
    return scrollWidth / numberOfChildren;
  }, [scrollRef, numberOfChildren]);

  const scrollInteractionAnalytics = useDebouncedCallback(
    useCallback(() => {
      if (scrollRef.current?.scrollLeft !== undefined) {
        const offset = scrollRef.current.scrollLeft;
        const cardWidth = getCardWidth();
        if (cardWidth === 0) return;
        const newScrollIndex = Math.floor(offset / cardWidth);
        if (newScrollIndex !== scrollIndex.current) {
          const direction =
            newScrollIndex > scrollIndex.current ? "right" : "left";
          addHeapOnCardCarouselInteracted(
            carouselName,
            direction,
            scrollIndex.current,
            newScrollIndex,
            getInteractionType(isButtonScroll.current),
            location
          );
          scrollIndex.current = newScrollIndex;
          isButtonScroll.current = false;
        }
      }
    }, [scrollRef, isButtonScroll, getCardWidth, carouselName, location]),
    500
  );

  useEffect(() => {
    const currentScrollRef = scrollRef.current;
    if (currentScrollRef) {
      scrollRef.current.addEventListener("scroll", scrollInteractionAnalytics);
    }
    return () => {
      currentScrollRef?.removeEventListener(
        "scroll",
        scrollInteractionAnalytics
      );
    };
  }, [scrollInteractionAnalytics, scrollRef]);

  const handleScroll = useCallback(() => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      const isAtEnd = Math.floor(scrollWidth - scrollLeft) === clientWidth;
      const offset = scrollRef.current.scrollLeft;

      setIsAtEndOfScroll(isAtEnd);
      setScrollOffset(offset);
    }
  }, [scrollRef]);

  useEffect(() => {
    let intervalCount = 0;
    const intervalId = setInterval(() => {
      handleScroll();
      intervalCount += 1;
      if (intervalCount >= 10) {
        clearInterval(intervalId);
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, [handleScroll]);

  // Description: Keeps track of the scroll offset and if the scroll is at the end
  useEffect(() => {
    handleScroll();
    const ref = scrollRef.current;
    ref?.addEventListener("scroll", handleScroll);
    return () => {
      ref?.removeEventListener("scroll", handleScroll);
    };
  }, [scrollRef, handleScroll, getCardWidth, carouselName]);

  const onPrevClick = () => {
    const diff = getCardWidth();
    isButtonScroll.current = true;
    scrollRef.current?.scrollBy({ left: -diff, behavior: "smooth" });
  };
  const onNextClick = () => {
    const diff = getCardWidth();
    isButtonScroll.current = true;
    scrollRef.current?.scrollBy({ left: diff, behavior: "smooth" });
  };

  const isAtBeginningOfScroll = scrollOffset === 0;

  return {
    onPrevClick,
    onNextClick,
    isAtBeginningOfScroll,
    isAtEndOfScroll,
  };
}
