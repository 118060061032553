/* istanbul ignore file */
import * as React from "react";

import { useIntersectionObserver } from "./useIntersectionObserver";

export enum ViewportPosition {
  InViewport = "OBSERVER_IN_VIEW",
  OutsideViewport = "OBSERVER_NOT_IN_VIEW",
}

type ViewportPositionCallback = (state: ViewportPosition) => void;

export default function useViewportPosition(
  callback: ViewportPositionCallback
) {
  const handleIntersection = React.useCallback(
    (entry) => {
      const state = entry.isIntersecting
        ? ViewportPosition.InViewport
        : ViewportPosition.OutsideViewport;
      callback(state);
    },
    [callback]
  );

  const observerRef = useIntersectionObserver(handleIntersection);
  const referenceElementProps = React.useMemo(
    () => ({
      ref: observerRef,
    }),
    [observerRef]
  );

  return {
    referenceElementProps,
  };
}
