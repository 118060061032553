/* eslint-disable import/prefer-default-export */
import { ChangeEvent, useEffect, useState } from "react";
import { TextInputWithSubmit } from "@components";
import classNames from "classnames";

import { addSubscribeEvent } from "../../services/elevar/events";
import { isValidEmail } from "../util";
import { EmailSignupProps, Media } from "..";

export function FooterEmailSection({
  placeholderText,
  mobilePlaceholderText,
  successText,
  mobileSuccessText,
  errorText,
  mobileErrorText,
  subscriberSource,
  klaviyoSubOptions,
}: EmailSignupProps) {
  const [inputValue, setInputValue] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setInvalid(false);
  }, [inputValue]);

  const handleSubmit = () => {
    setSubmitted(false);
    const isValid = isValidEmail(inputValue);
    if (isValid) {
      addSubscribeEvent({
        customer_email: inputValue,
        klaviyo_list_id: klaviyoSubOptions,
        subscriber_source: subscriberSource,
      });
      setSubmitted(true);
    } else {
      setInvalid(true);
    }
  };

  const sharedInputProps = {
    type: "email",
    onChange: (e: ChangeEvent<HTMLInputElement>) =>
      setInputValue(e.target.value),
    value: inputValue,
    buttonClasses: "bg-pearl py-0",
    onKeyPress: () => {
      setInvalid(false);
      setSubmitted(false);
    },
    invalid,
    success: submitted,
    inputClasses: classNames(
      "text-charcoal bg-pearl placeholder:text-charcoal w-full px-0 py-0",
      {
        "w-20 md:w-44": submitted || invalid,
      }
    ),
    inputContainerClasses: "border-charcoal pb-3",
  } as const;

  return (
    <form
      className="z-10 flex justify-start w-full max-w-sm"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Media greaterThanOrEqual="md" className="w-full">
        <TextInputWithSubmit
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...sharedInputProps}
          successMessage={successText}
          errorMessage={errorText}
          placeholder={placeholderText}
        />
      </Media>
      <Media lessThan="md" className="w-full">
        <TextInputWithSubmit
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...sharedInputProps}
          successMessage={mobileSuccessText}
          errorMessage={mobileErrorText}
          placeholder={mobilePlaceholderText}
        />
      </Media>
    </form>
  );
}
