/* eslint-disable react/jsx-no-useless-fragment */
import { createRef } from "react";
import { Experience } from "@ninetailed/experience.js-react";
import { ExperienceMapper } from "@ninetailed/experience.js-utils-contentful";

import useFeatureFlag from "../../hooks/useFeatureFlag";

interface FeatureComponentProps<
  T extends Record<string, React.ReactNode | null>
> {
  state: keyof T;
  stateMap: T;
}

function FeatureComponent<T extends Record<string, React.ReactNode | null>>({
  state,
  stateMap,
}: FeatureComponentProps<T>) {
  const elementRef = createRef<HTMLDivElement>();
  return <div ref={elementRef}>{stateMap?.[state]}</div>;
}

interface FeatureWrapperProps<
  T extends Record<string, React.ReactNode | null>
> {
  flag: string;
  defaultState: keyof T;
  stateMap: T;
}

export default function FeatureWrapper<
  T extends Record<string, React.ReactNode | null>
>({ flag, defaultState, stateMap }: FeatureWrapperProps<T>) {
  const { entry } = useFeatureFlag(flag);
  const experiences = entry?.fields.nt_experiences;
  if (!experiences?.length)
    return <FeatureComponent state={defaultState} stateMap={stateMap} />;

  const mappedExperiences = experiences
    .filter((experience) => ExperienceMapper.isExperienceEntry(experience))
    .map((experience) =>
      ExperienceMapper.mapCustomExperience(experience, (entryVariant) => ({
        id: entryVariant.sys.id,
        state: entryVariant.fields.value,
        stateMap,
      }))
    );

  const baselineProps: FeatureComponentProps<T> = {
    state: defaultState,
    stateMap,
  };
  return (
    <Experience
      id={entry.sys.id}
      component={FeatureComponent}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...baselineProps}
      experiences={mappedExperiences}
    />
  );
}
