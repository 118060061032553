import create, { StoreApi } from "zustand";
import { persist } from "zustand/middleware";

interface CartStore {
  cartId: string | undefined;
  cartIdV2: string | undefined;
  setCartId: (newId?: string) => void;
  setCartIdV2: (newId?: string) => void;
}

export interface CartPersister {
  clearCartID: () => Promise<void>;
  getCartID: () => Promise<string | undefined | null>;
  setCartID: (newID: string) => Promise<void>;
}

export default class ZustandCartPersister implements CartPersister {
  private store: Promise<StoreApi<CartStore>>;

  constructor() {
    let onRehydrateStorage: (value: unknown) => void;
    const rehydrationComplete = new Promise((res) => {
      onRehydrateStorage = res;
    });
    this.store = new Promise((res) => {
      const useStore = create<CartStore>(
        // @ts-expect-error can't seem to make this happy
        persist<CartStore>(
          (set) => ({
            cartId: undefined,
            cartIdV2: undefined,
            setCartId: (newId?: string) => set({ cartId: newId }),
            setCartIdV2: (newId?: string) => set({ cartIdV2: newId }),
          }),
          {
            name: "_cart_id",
            onRehydrateStorage,
          }
        )
      );
      rehydrationComplete.then(() => res(useStore));
    });
  }

  clearCartID = async () => {
    const state = (await this.store).getState();
    state.setCartId();
    state.setCartIdV2();
  };

  getCartID = async () => {
    const state = (await this.store).getState();
    const { cartId, cartIdV2 } = state;
    return cartIdV2 ?? cartId;
  };

  setCartID = async (newId: string) =>
    (await this.store).getState().setCartIdV2(newId);
}
