import * as React from "react";
import { Button, Icon, Modal } from "@components";

import useVisibility from "../../../hooks/useVisibility";
import { addHeapModalOpened } from "../../services/elevar/events";
import { CTA, CTAProps } from "../CTA";

import selectors from "./selectors";

interface ModalContentProps {
  name: string;
  buttonText: string;
  buttonVariant: CTAProps["variant"];
  children: React.ReactNode;
}

/**
 * Renders an inline toggle button that opens a modal
 * with the provided children as content
 */
export default function ModalContent({
  name,
  children,
  buttonText,
  buttonVariant,
}: ModalContentProps) {
  const { isModalVisible, toggleModal } = useVisibility("Modal", false, {
    onShow() {
      // fire heap event to track that the modal was opened
      addHeapModalOpened(name, buttonText);
    },
  });

  return (
    <>
      <CTA
        data-testid={selectors.toggleButton}
        variant={buttonVariant}
        onClick={toggleModal}
      >
        {buttonText}
      </CTA>
      <Modal
        isOpen={isModalVisible}
        onDismiss={toggleModal}
        childContainerClassName="w-full max-w-4xl z-header"
        containerClassName="z-header"
      >
        <div className="text-right ">
          <Button onClick={toggleModal} variant="unstyled">
            <Icon name="close" />
          </Button>
        </div>
        <div className="my-4 h-[60vh] overflow-auto">{children}</div>
      </Modal>
    </>
  );
}
