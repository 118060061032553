import type { IEvent } from "./interfaces";

export const FLUSH_EVENT: IEvent["event"] = "dl_user_data";

// This event manager exists because it's critical that Elevar receives the `dl_user_data` event first,
// then all other events. Every other event gets added to a queue until we receive a `dl_user_data` event.
// Once this happens, we can cleanup our buffer and allow events to pass into the datalayer directly.

export class EventManager {
  /** Buffer of events that is only flushed when a FLUSH_EVENT is fired */
  private queue: IEvent[] = [];

  /** Whether a FLUSH_EVENT has been received, allowing events to be pushed to the data layer */
  private flushEventReceived = false;

  /** Initializes the Elevar data layer */
  constructor() {
    /* istanbul ignore next */
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.ElevarDataLayer = window.ElevarDataLayer ?? [];

      // Push the initial location into the data layer
      // See: https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
      window.ElevarDataLayer.push({
        originalLocation: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
      });
    }
  }

  /** Pushes an event to the Elevar data layer */
  private static pushToDataLayer(event: IEvent) {
    try {
      window.ElevarDataLayer.push(event);
    } catch (error) {
      // Handle potential errors gracefully
      // eslint-disable-next-line no-console
      console.error("Failed to push event to ElevarDataLayer", error);
    }
  }

  /** Flushes the queue and pushes all events to the data layer */
  private processAndFlushQueue(flushEvent: IEvent) {
    this.flushEventReceived = true;

    const events = [flushEvent, ...this.queue];
    events.forEach(EventManager.pushToDataLayer);

    this.queue = [];
  }

  /** Adds an event to the queue or pushes it directly if a FLUSH_EVENT has been received */
  pushEvent(event: IEvent) {
    if (this.flushEventReceived) {
      EventManager.pushToDataLayer(event);
    } else if (event.event === FLUSH_EVENT) {
      this.processAndFlushQueue(event);
    } else {
      this.queue.push(event);
    }
  }

  /**
   * Resets the queue, preventing any events from being pushed to
   * the data layer until another FLUSH_EVENT occurs
   */
  disableEventProcessing() {
    this.flushEventReceived = false;
  }
}

const eventManager = new EventManager();
export default eventManager;
