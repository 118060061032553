import type { IShopTheLookSection } from "../../../../../@types/generated/contentful";
import useCatalog from "../../../hooks/useCatalog";
import { isTruthy } from "../../../util";
import { ShopTheLookSection } from "../../ShopTheLookSection";
import type { Marker } from "../../ShopTheLookSection/types";
import { assetToImageData } from "../util";

export function useMarkers(
  markerList: string[],
  productIds: string[],
  variantIds: string[]
) {
  const rawMarkers = markerList
    .map<(Omit<Marker, "product"> & { productID: string }) | null>(
      (rawMarker, index) => {
        const coordinates = JSON.parse(rawMarker) as {
          x: number;
          y: number;
          w: number;
          h: number;
        };
        const variantID = variantIds[index];
        const productID = productIds?.[index];
        if (!variantID || !productID) return null;

        // format the x and y values into percent
        const x = `${(coordinates.x / coordinates.w) * 100}%`;
        const y = `${(coordinates.y / coordinates.h) * 100}%`;

        return {
          x,
          y,
          variantID,
          productID,
        };
      }
    )
    .filter(isTruthy);

  const { catalog, isLoading } = useCatalog(
    rawMarkers.map((marker) => marker.productID)
  );

  const markers = rawMarkers
    .map<Marker | null>(({ productID, ...marker }) => {
      if (!catalog) return null;

      const product = catalog[productID];
      if (!product) return null;

      const variant = product.variants[marker.variantID];
      if (!variant) return null;

      return {
        ...marker,
        product,
      };
    })
    .filter(isTruthy);

  return { markers, products: catalog, isLoading };
}

export default function ShopTheLookSectionSaddle({
  content: {
    sys: { id: sectionId },
    fields: rawSection,
  },
}: {
  content: IShopTheLookSection;
}) {
  // this cast is safe assuming that the transformation has taken place on the server
  const section = rawSection as typeof rawSection & {
    productIds: string[];
  };

  const desktopImage = assetToImageData(section.image);
  const mobileImage =
    section.mobileImage && assetToImageData(section.mobileImage);

  const { markers, products, isLoading } = useMarkers(
    section.markers,
    section.productIds,
    section.productVariants
  );

  if (!products && !isLoading) return null;

  return (
    <ShopTheLookSection
      key={sectionId}
      name={section.name}
      title={section.title}
      image={desktopImage}
      mobileImage={mobileImage}
      buttonText={section.buttonText}
      buttonTextOpen={section.buttonTextOpen}
      productListTitle={section.productListTitle}
      detailText={section.productCardButtonText}
      markers={markers}
      isLoading={isLoading}
    />
  );
}
