import {
  makeQueryFn,
  QueryClientPlugin,
} from "../../services/util/makeQueryClient";
import type { ClientDataServices } from "../client";

export const recommendedProductsQueryFns = ({
  recommendedProductsAPI,
}: ClientDataServices) => ({
  recommendedProducts: recommendedProductsAPI.getRecommendedProducts,
});

const recommendedProductsPlugin: QueryClientPlugin<ClientDataServices> = (
  queryClient,
  dataServices
) => {
  const { recommendedProducts } = recommendedProductsQueryFns(dataServices);

  queryClient.setQueryDefaults(["recommendedProducts"], {
    queryFn: makeQueryFn(recommendedProducts),
  });
};

export default recommendedProductsPlugin;
