/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Document } from "@contentful/rich-text-types";

import { Button, Icon } from "../../../components";
import { RichText } from "../RichText";

import selectors from "./selectors";

interface EmailCaptureReopenBannerProps {
  openEmailCapture: () => void;
  close: () => void;
  text: Document;
}

export function EmailCaptureReopenBanner({
  openEmailCapture,
  close,
  text,
}: EmailCaptureReopenBannerProps) {
  return (
    <div className="fixed bottom-0 left-0 w-full z-header">
      <Button
        variant="light-to-gray"
        onClick={openEmailCapture}
        className="w-full p-3 uppercase md:w-72 md:p-5"
        data-testid={selectors.openEmailCaptureButton}
      >
        <RichText richTextResponse={text} />
      </Button>
      <Button
        variant="unstyled"
        className="absolute top-0 right-0 z-20 w-5 h-5 md:left-0"
        data-testid={selectors.closeBottomBannerButton}
        onClick={(event) => {
          event.stopPropagation();
          close();
        }}
      >
        <Icon name="close" size="inherit" />
      </Button>
    </div>
  );
}
