import ReviewsAPI from "../services/reviews/api";
import { QueryClientPlugins } from "../services/util/makeQueryClient";

import previewPlugin, {
  previewMutationFns,
  previewQueryFns,
} from "./data/previewMode";
import reviewsPlugin, { reviewsQueryFns } from "./data/reviewsData";

export interface SharedDataServices {
  reviewsAPI: ReviewsAPI;
}

export type SharedQueries = typeof previewQueryFns &
  ReturnType<typeof reviewsQueryFns>;

export type SharedMutations = typeof previewMutationFns;

const sharedPlugins: QueryClientPlugins<SharedDataServices> = {
  previewPlugin,
  reviewsPlugin,
};

export default sharedPlugins;
