import { BLOCKS, Document } from "@contentful/rich-text-types";

export const mockDocument: Document = {
  nodeType: BLOCKS.DOCUMENT,
  data: {},
  content: [
    {
      nodeType: BLOCKS.PARAGRAPH,
      content: [
        {
          nodeType: "text",
          value:
            "Pieces engineered to fit together seamlessly. The Bed assembles without tools, in minutes.",
          marks: [],
          data: {},
        },
        {
          nodeType: BLOCKS.EMBEDDED_ENTRY,
          content: [],
          data: {
            target: {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: "Link",
                    id: "t15gr55mpxw1",
                  },
                },
                contentType: {
                  sys: {
                    type: "Link",
                    linkType: "ContentType",
                    id: "ctaButton",
                  },
                },
                locale: "en-US",
              },
              fields: {
                title: "SHOP NOW - GIFTS",
                url: "https://www.thuma.co/collections/the-gift-shop",
                text: "SHOP NOW",
                variant: "underline",
              },
            },
          },
        },
      ],
      data: {},
    },
  ],
};

export const mockLargeDocument: Document = {
  nodeType: BLOCKS.DOCUMENT,
  data: {},
  content: [
    {
      nodeType: BLOCKS.PARAGRAPH,
      content: [
        {
          nodeType: "text",
          value:
            "Pieces engineered to fit together seamlessly. The Bed assembles without tools, in minutes.",
          marks: [],
          data: {},
        },
        {
          nodeType: BLOCKS.EMBEDDED_ENTRY,
          content: [],
          data: {
            target: {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: "Link",
                    linkType: "Space",
                    id: "t15gr55mpxw1",
                  },
                },
                type: "Entry",
                id: "2tfcqEFGTxF0Kio85diMw2",
                contentType: {
                  sys: {
                    type: "Link",
                    linkType: "ContentType",
                    id: "spacerSection",
                  },
                },
                revision: 2,
                createdAt: "2023-10-20T16:37:26.257Z",
                updatedAt: "2023-10-20T16:38:37.437Z",
                environment: {
                  sys: {
                    id: "master",
                    type: "Link",
                    linkType: "Environment",
                  },
                },
                locale: "en-US",
              },
              fields: {
                name: "spacer 100px",
                size: "sm",
              },
            },
          },
        },
        {
          nodeType: BLOCKS.EMBEDDED_ENTRY,
          content: [],
          data: {
            target: {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: "Link",
                    id: "t15gr55mpxw1",
                  },
                },
                contentType: {
                  sys: {
                    type: "Link",
                    linkType: "ContentType",
                    id: "non-existant",
                  },
                },
                locale: "en-US",
              },
              fields: {
                title: "SHOP NOW - GIFTS",
                url: "https://www.thuma.co/collections/the-gift-shop",
                text: "SHOP NOW",
                variant: "underline",
              },
            },
          },
        },
        {
          nodeType: "text",
          value: "More text after spacer",
          marks: [],
          data: {},
        },
      ],
      data: {},
    },
  ],
};

export const mockDocumentOtherCTAVariant: Document = {
  nodeType: BLOCKS.DOCUMENT,
  data: {},
  content: [
    {
      nodeType: BLOCKS.PARAGRAPH,
      content: [
        {
          nodeType: "text",
          value:
            "Pieces engineered to fit together seamlessly. The Bed assembles without tools, in minutes.",
          marks: [],
          data: {},
        },
        {
          nodeType: BLOCKS.EMBEDDED_ENTRY,
          content: [],
          data: {
            target: {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: "Link",
                    id: "t15gr55mpxw1",
                  },
                },
                contentType: {
                  sys: {
                    type: "Link",
                    linkType: "ContentType",
                    id: "ctaButton",
                  },
                },
                locale: "en-US",
              },
              fields: {
                title: "SHOP NOW - GIFTS",
                url: "https://www.thuma.co/collections/the-gift-shop",
                text: "SHOP NOW",
                variant: "button-light-to-gray",
              },
            },
          },
        },
      ],
      data: {},
    },
  ],
};
