/* eslint-disable import/prefer-default-export */
import {
  CatalogProduct,
  CatalogVariant,
  ProductCatalog,
} from "../services/catalog/types";
import { ISearchProduct } from "../services/search/types";

export function getVariantBySku(sku: string, variants: CatalogVariant[]) {
  return variants.find((variant) => variant.sku === sku);
}

export function getProductIdFromSearchSpringUID(uid: string) {
  return uid.includes("-") ? uid.split("-")[0] : uid;
}

export function getCatalogProductAndVariantFromSearchSpringItem(
  item: ISearchProduct,
  catalog: ProductCatalog
):
  | { product: null; variant: null; productId: string }
  | { product: CatalogProduct; variant: CatalogVariant; productId: string } {
  const productId = getProductIdFromSearchSpringUID(item.uid);
  const catalogProduct = catalog[productId];
  if (!catalogProduct) return { product: null, variant: null, productId };
  let variant = getVariantBySku(
    item.sku,
    Object.values(catalogProduct.variants)
  );
  if (!variant) {
    variant = catalogProduct.variants[catalogProduct.defaultVariant];
  }
  return { product: catalogProduct, variant, productId };
}
