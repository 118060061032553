import type { QueryClient } from "react-query";

import type { QueryData } from "../../queries/client";
import type { LocaleCode } from "../../util/locale";

import type { CatalogProductCache, ProductCatalog } from "./types";
import { getURL } from "./utils";

export const fetchCatalogProduct = async (id: string, locale: LocaleCode) => {
  // Have to use this url get pattern to mock the url in the test
  const url = getURL(id, locale);
  const catalogProductResponse = await fetch(url);
  if (catalogProductResponse.status === 404) return null;
  if (!catalogProductResponse.ok)
    throw new Error("Error fetching catalog product");
  try {
    const product =
      (await catalogProductResponse.json()) as CatalogProductCache | null;
    return product;
  } catch (err) {
    throw new Error("Invalid response");
  }
};

export const fetchCatalogProducts = async (
  queryClient: QueryClient,
  ids: string[],
  locale: LocaleCode
) => {
  const products = await Promise.allSettled(
    ids.map((id) =>
      queryClient.fetchQuery<QueryData<"catalogProduct">>([
        "catalogProduct",
        id,
        locale,
      ])
    )
  );

  return products.reduce<ProductCatalog>((acc, p, i) => {
    if (p.status === "fulfilled") {
      const product: CatalogProductCache | null = p.value;
      return {
        ...acc,
        [ids[i]]: product,
      };
    }
    return acc;
  }, {});
};
