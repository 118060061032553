import PackageTrackingAPI from "../../services/package-tracking/api";
import {
  makeQueryFn,
  QueryClientPlugin,
} from "../../services/util/makeQueryClient";
import type { ClientDataServices } from "../client";

export interface PackageTrackingQueryFns {
  packageTracking: typeof PackageTrackingAPI.fetchPackageTrackingData;
}

const packageTrackingPlugin: QueryClientPlugin<ClientDataServices> = (
  queryClient
) => {
  queryClient.setQueryDefaults(["packageTracking"], {
    queryFn: makeQueryFn(PackageTrackingAPI.fetchPackageTrackingData),
  });
};

export default packageTrackingPlugin;
